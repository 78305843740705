import React, { useRef, useState } from "react";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import {
  UserDataRefreshStore,
  useCanvasSideStepStore,
  useFinalContentStore,
  useOverallStepStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
} from "../../ZustandStores/LinkedInZustandStore";
import axios from "axios";
import { toast } from "react-toastify";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import { IoPlayCircleOutline } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { Popconfirm } from "antd";

const SavedDrafts = () => {
  let { userData } = useLinkedInUserContext();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setOverallStep } = useOverallStepStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userLinkedInData } = useUserLinkedInStore();

  const { setSelectedDraftId } = useSavedDraftIdStore();
  const { setSelectedScheduledPostId } = useScheduledPostIdStore();

  // DELETE SAVED DRAFT
  const handleDeleteDraft = async (draftIdParam) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/delete-saved-draft`,
        {
          data: {
            userId: userData?.userId,
            draftId: draftIdParam,
          },
        }
      );
      setUserDataRefresh(!userDataRefresh);
      toast.success("Draft deleted successfully");
    } catch (error) {
      toast.error("Something went wrong, Please try again!");
    }
  };

  return (
    <main className="flex flex-col items-center justify-center gap-2 m-3">
      {userLinkedInData?.savedDrafts?.length > 0 ? (
        <>
          {userLinkedInData?.savedDrafts
            .slice()
            .reverse()
            .map((eachDraft, key) => {
              return (
                <div
                  key={key}
                  //   className="relative flex justify-between w-full gap-3 p-5 text-left transition-shadow duration-300 ease-in-out border border-gray-400 rounded-md shadow-none hover:shadow-lg hover:shadow-black/30 hover:bg-white"
                  // >
                  //   <main className="flex flex-col items-start justify-between w-2/3 h-full max-w-6xl">
                  className="flex justify-between w-full gap-3 p-5 text-left transition-shadow duration-300 ease-in-out border-2 border-gray-400 rounded-md shadow-none hover:shadow-lg hover:shadow-black/30 hover:bg-white"
                >
                  <main className="flex flex-col items-start justify-between w-2/3 h-full max-w-6xl overflow-hidden ">
                    <div className="p-2">
                      {eachDraft?.textContent.length > 50
                        ? eachDraft?.textContent?.slice(0, 50) + "..."
                        : eachDraft?.textContent}
                    </div>
                    {/* Edit saved draft button */}
                    <div className="flex flex-wrap gap-2">
                      <button
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md bg-triklPeachLight text-triklAccentBlue hover:bg-triklPeachLight focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => {
                          let contentText = eachDraft?.textContent;
                          if (
                            !contentText.includes("<br>") &&
                            !contentText.includes("<p>")
                          ) {
                            contentText = contentText.replace(/\n/g, "<br>");
                          }
                          setFinalContent({
                            ...finalContent,
                            finalText: contentText,
                            // finalMediaUrl: eachDraft?.mediaUrl,
                            // finalMediaType: eachDraft?.mediaType,
                          });
                          setSelectedDraftId(eachDraft._id);
                          setSelectedScheduledPostId("");
                          setCanvasSideStep(1);
                          setOverallStep(1);
                        }}
                      >
                        Edit
                      </button>
                      <Popconfirm
                        title="Are you sure to delete this draft?"
                        onConfirm={() => handleDeleteDraft(eachDraft?._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
                          Delete
                        </button>
                      </Popconfirm>
                    </div>
                  </main>
                  {/* DISPLAY DIFFERENT MEDIA CONTENT */}

                  {/* Image content */}
                  {eachDraft?.mediaUrl.length > 0 &&
                    eachDraft?.mediaType == "image" && (
                      <aside className="w-1/3 aspect-square">
                        <img
                          className="object-cover w-full rounded-md aspect-square"
                          src={eachDraft?.mediaUrl}
                          alt="Saved draft"
                        />
                      </aside>
                    )}
                  {/* video Content */}
                  {eachDraft?.mediaUrl.length > 0 &&
                    eachDraft?.mediaType == "video" && (
                      <aside className="flex items-center justify-center w-1/3 h-full rounded-md bg-triklAccentBlue/10 aspect-square ">
                        <IoPlayCircleOutline className="w-10 h-10 text-triklAccentBlue" />
                      </aside>
                    )}

                  {/* File content */}
                  {eachDraft?.mediaUrl.length > 0 &&
                    eachDraft?.mediaType == "document" && (
                      <aside className="flex items-center justify-center w-1/3 h-full rounded-md bg-triklAccentBlue/10 aspect-square ">
                        <FaFileAlt className="w-10 h-10 text-triklAccentBlue" />
                      </aside>
                    )}
                  {eachDraft?.mediaUrl.length == 0 && (
                    <aside className="flex items-center justify-center w-1/3 h-full bg-red-200 aspect-square ">
                      <MdOutlineCheckBoxOutlineBlank className="w-10 h-10 text-red-500" />
                    </aside>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <h1 className="text-2xl font-semibold text-slate-500">
          No Saved Drafts
        </h1>
      )}
    </main>
  );
};

export default SavedDrafts;
