const V2BackgroundTemplate5 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3493)">
        <rect
          x="444"
          y="484"
          width="90"
          height="90"
          fill={colorPallet?.secondary}
        />
        <rect x="445" width="90" height="90" fill={colorPallet?.primary} />
        <circle cx="488.5" cy="619.5" r="45.5" fill={colorPallet?.primary} />
        <circle cx="489" cy="135" r="45" fill={colorPallet?.secondary} />
        <path
          d="M451 665.5C451 653.615 448.659 641.847 444.111 630.867C439.563 619.887 432.897 609.911 424.493 601.507C416.089 593.103 406.113 586.437 395.133 581.889C384.153 577.341 372.385 575 360.5 575L360.5 665.5H451Z"
          fill={colorPallet?.secondary}
        />
        <path
          d="M271 665C282.819 665 294.522 662.672 305.442 658.149C316.361 653.626 326.282 646.997 334.64 638.64C342.997 630.282 349.626 620.361 354.149 609.442C358.672 598.522 361 586.819 361 575L271 575L271 665Z"
          fill={colorPallet?.primary}
        />
        <path
          d="M-0.999998 181C10.819 181 22.5222 178.672 33.4415 174.149C44.3608 169.626 54.2823 162.997 62.6396 154.64C70.9969 146.282 77.6262 136.361 82.1492 125.442C86.6721 114.522 89 102.819 89 91L-0.999999 91L-0.999998 181Z"
          fill={colorPallet?.secondary}
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate5;
