import React from "react";

const BackgroundTemplate1 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0"
      width="100%"
      height="100%"
      // width="480px"
      // height="600px"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask id="mask0_2563_1506" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="535" height="665">
<rect width="535" height="665" fill="#D9D9D9"/>
</mask> */}
      <g mask="url(#mask0_2563_1506)">
        <rect
          x="281"
          width="192"
          height="465"
          //   fill="#6875E6"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="468.151"
          y="598.53"
          width="173"
          height="122"
          transform="rotate(15.9907 468.151 598.53)"
          //   stroke="#4E00CD"
          stroke={colorPallet?.primary}
          stroke-width="2"
        />
        <rect
          x="-98.154"
          y="-37.7632"
          width="173"
          height="122"
          transform="rotate(15.9907 -98.154 -37.7632)"
          //   stroke="#4E00CD"
          stroke={colorPallet?.primary}
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default BackgroundTemplate1;
