import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  useActiveThreadIdStore,
  useAssistantIdStore,
  useThreadMsgArr,
  useThreadDataStore,
} from "./AssistantZustandStore";
import { PropagateLoader } from "react-spinners";
import { AiOutlineSend } from "react-icons/ai";
import ReactMarkdown from "react-markdown";

import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";
import TextArea from "antd/es/input/TextArea";
import { Button, Card, Layout, Spin } from "antd";
import {
  ArrowUpOutlined,
  CopyOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import {
  triklLogo,
  triklPurpleWithoutText,
} from "../../../../assets/Trikl_logo";
import { toast } from "react-toastify";
import { UserDataRefreshStore } from "../../ZustandStores/LinkedInZustandStore";

const AssistantChat = ({ handleFetchThread }) => {
  // state
  const [message, setMessage] = useState("");
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  // zustand
  const { messageArray } = useThreadMsgArr();
  const { activeThreadId, setActiveThreadId } = useActiveThreadIdStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { setThreadData } = useThreadDataStore();
  const { activeAssistantId } = useAssistantIdStore();
  const { userMainData } = useUserMainDataStore();

  // CREATING A FUNCTION TO SCROLL CHAT AREA TO BOTTOM
  const scrollContainerRef = useRef(null);
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      scrollContainerRef.current.scrollTo({
        top: scrollHeight,
        behavior: "auto",
      });
    }
  };

  function LinkRenderer(props) {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className="text-triklAccentBlue"
      >
        {props.children}
      </a>
    );
  }

  // COPY TO CLIPBOARD
  const copyToClipboard = (content, isHtml = false) => {
    if (isHtml) {
      const listener = (e) => {
        e.clipboardData.setData("text/html", content);
        e.clipboardData.setData("text/plain", content);
        e.preventDefault();
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    } else {
      navigator.clipboard
        .writeText(content)
        .then((err) => console.error("Failed to copy text to clipboard", err));
    }
  };

  // //get assistant instructions to pass with the request //
  const sendMessage = async () => {
    setIsResponseLoading(true);
    scrollToBottom();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/assitant-chat`,
        {
          userId: userMainData?.userId,
          userQuestion: message,
          assistant: {
            assistantId: activeAssistantId,
            threadId: activeThreadId,
          },
        }
      );

      const threadId = response.data.threadId;
      if (!activeThreadId) {
        setUserDataRefresh(true);
      }
      // FETCH LATEST THREAD WITH UPDATED MESSAGES
      await handleFetchThread(threadId);

      // Set the thread ID as active (to handle newly created thread)
      setActiveThreadId(threadId);
      setMessage("");
      setIsResponseLoading(false);
    } catch (error) {
      setIsResponseLoading(false);
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    scrollToBottom();
    handleFetchThread(activeThreadId);
  }, [activeThreadId, messageArray.length]); // Add messageArray.length as dependency

  // RANDOM DATA
  const cards = [
    {
      text: "LinkedIn Strategy",
      icon: <ShareAltOutlined className="text-red-500" />,
      prompt:
        "You're a LinkedIn expert. Create a detailed LinkedIn Strategy for me (refer to the uploaded files) for the next month.",
    },
    {
      text: "Post to Twitter",
      icon: <ShareAltOutlined className="text-teal-500" />,
      prompt:
        "You're a Twitter content creation expert. Convert the below mentioned post into a Twitter thread with 5 threads. Keep it simple, plain, natural sounding and same tone/style/voice as the below post:",
    },
    {
      text: "Evaluate My Post",
      icon: <ShareAltOutlined className="text-voilet-400" />,
      prompt:
        "You're a LinkedIn expert. Do a detailed analysis of my following LinkedIn Post. How can I improve it further. Rate all your suggestions in ranks 1 to 5",
    },
    {
      text: "Repurpose To Email",
      icon: <ShareAltOutlined className="text-blue-500" />,
      prompt:
        "You're a Cold Emailing expert. Convert the below mentioned post into a cold email with subject and body. Length must be less than 500 characters. Keep it simple, plain, natural sounding and same tone/style/voice as the below post:",
    },
  ];

  const handleKeyPress = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      sendMessage();
    }
  };

  const removeCustomCitations = (text) => {
    return text.replace(/【\d+(:\d+)?†[a-zA-Z0-9-]+】/g, "");
  };

  return (
    <Layout>
      <Content className="relative">
        {activeThreadId ? (
          // IF thread has been selected
          <main
            className="flex flex-col items-center justify-start w-full h-[80vh] gap-8 overflow-scroll custom-scrollbar pt-32"
            ref={scrollContainerRef}
          >
            {messageArray?.map((msg, index) =>
              msg?.role === "user" ? (
                <div
                  key={index} // Input by User
                  className="flex flex-col items-end w-2/3 gap-2 leading-relaxed"
                >
                  <div className="flex items-center justify-end w-2/3">
                    <div className="p-8 bg-white rounded-md">
                      {msg.messageText?.split("\n").map((line, index) => (
                        <p key={index} className="text-left">
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {line}
                          </ReactMarkdown>
                        </p>
                      ))}
                    </div>
                  </div>
                  <Button
                    icon={<CopyOutlined />}
                    type="text"
                    size="small"
                    className="text-gray-400 translate-x-1"
                    onClick={() => {
                      copyToClipboard(msg.messageText);
                      toast.success("Copied");
                    }}
                  />
                </div>
              ) : (
                <div // Response from AI
                  key={index}
                  className="flex items-start justify-start w-2/3 gap-8 p-4 pr-20"
                >
                  <img // TRIKL LOGO
                    src={triklPurpleWithoutText}
                    alt="Logo"
                    style={{ width: "25px", height: "25px" }}
                  />

                  <div className="flex flex-col items-start w-5/6">
                    {msg.messageText?.split("\n").map((line, index) => {
                      const cleanedLine = removeCustomCitations(line);
                      return (
                        <p key={index} className="text-left">
                          <ReactMarkdown components={{ a: LinkRenderer }}>
                            {cleanedLine}
                          </ReactMarkdown>
                        </p>
                      );
                    })}

                    <Button
                      icon={<CopyOutlined />}
                      type="text"
                      size="small"
                      className="text-gray-400 -translate-x-1"
                      onClick={() => {
                        copyToClipboard(msg.messageText);
                        toast.success("Copied");
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </main>
        ) : (
          // If no thread has been selected.
          // or if it's the start of AI Assistant
          <main className="flex flex-col items-center justify-center w-full min-h-full gap-8">
            {/* TRIKL LOGO */}
            <img
              src={triklPurpleWithoutText}
              alt="Logo"
              style={{ width: "50px", height: "50px" }}
            />

            {/* Suggestions Bar */}
            <div className="flex items-center w-2/3 gap-4">
              {cards.map((card, index) => (
                <Card
                  key={index}
                  className="flex flex-col items-start justify-start w-64 h-32 text-left cursor-pointer"
                  onClick={
                    () => setMessage(card.prompt) // setting suggestions as the value if someone clicks on 4 cards
                  }
                >
                  <div>{card.icon}</div>
                  <div>{card.text}</div>
                </Card>
              ))}
            </div>
          </main>
        )}

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/* typing and sending message section */}
        <aside className="absolute flex flex-col items-center justify-center w-full gap-1 bottom-8">
          {/* Input box */}
          <div className="flex items-center justify-start w-2/3 gap-4 px-2 py-2 text-left bg-white rounded-3xl">
            {/* <Button type="text" shape="circle" icon={<PaperClipOutlined />} /> */}
            <TextArea
              autoSize={{
                minRows: 1,
                maxRows: 8,
              }}
              className="border-none outline-none"
              value={message}
              disabled={isResponseLoading}
              onKeyDown={handleKeyPress}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here"
            />
            <Button
              icon={
                isResponseLoading ? <LoadingOutlined /> : <ArrowUpOutlined />
              }
              type="primary"
              shape="circle"
              disabled={isResponseLoading}
              onClick={sendMessage}
            />
          </div>

          {/* Warning text for AI usage */}
          <div className="text-xs text-gray-400">
            TRIKL AI can make mistakes. Review before using!
          </div>
        </aside>
      </Content>
    </Layout>
  );
};

export default AssistantChat;
