import React, { useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";

import {
  CalendarOutlined,
  EditOutlined,
  BulbOutlined,
  RobotOutlined,
  HistoryOutlined,
  LinkOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../context/userAuthContext";
import { StarBorder } from "@mui/icons-material";

const { Sider } = Layout;
function getItem(label, key, icon, link) {
  return {
    key,
    icon,
    label,
    link,
  };
}

const items = [
  getItem("Create Post", "1", <EditOutlined />, "/linkedin/content-creation"),
  getItem("My Calendar", "2", <CalendarOutlined />, "/linkedin/scheduler"),
  getItem(
    "Idea Generator",
    "3",
    <BulbOutlined />,
    "/linkedin/content-calendar"
  ),
  // getItem(" Inspiration Profile", "4", <StarBorder />, "/linkedin/inspiration"),
  getItem(
    "AI Assistant",
    "5",
    <RobotOutlined />,
    "/linkedin/personal-assistant"
  ),
  getItem(
    "Profile Analysis",
    "6",
    <SearchOutlined />,
    "/linkedin/profileAnalysis"
  ),

  getItem("Onboarding", "7", <HistoryOutlined />, "/user/onboarding"),

  // getItem(
  //   <a
  //     href="https://aiconnect.trikl.ai/"
  //     target="_blank"
  //     rel="noopener noreferrer"
  //   >
  //     AiConnect
  //   </a>,
  //   "7",
  //   <LinkOutlined />,
  //   "https://aiconnect.trikl.ai/"
  // ),
];

const PagesSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUserAuth();
  // Determine the active menu item based on the current route
  const findActiveItemKey = (pathname) => {
    const activeItem = items.find((item) => pathname.includes(item.link));
    return activeItem ? activeItem.key : items[0].key;
  };

  const activeKey = findActiveItemKey(location.pathname);

  const onMenuClick = (link) => {
    navigate(link);
  };

  return (
    <Layout>
      <Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={60}
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
        className="transition-all duration-300 ease-in-out "
        style={{ overflow: "auto" }}
      >
        <div
          className={`${
            user?.emailVerified ? "pt-20" : "pt-28"
          } text-left  demo-logo-vertical`}
        />
        <Menu
          theme="light"
          mode="inline"
          className="text-left border-0 "
          items={items.map((item) => ({
            ...item,
            style:
              activeKey === item.key
                ? { color: "#6F42C1", backgroundColor: "#F5EAFA" }
                : { color: "#6F42C1" },
            onClick: () => onMenuClick(item.link),
          }))}
          selectedKeys={[activeKey]}
        />
      </Sider>
    </Layout>
  );
};

export default PagesSidebar;
