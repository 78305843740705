// store for the canvas editor
import { create } from "zustand";

const useCanvasImageUrlStore = create((set) => ({
  canvasImageUrl: "",
  setCanvasImageUrl: (newState) => {
    set(() => ({ canvasImageUrl: newState }));
  },
}));

// ALL EXPORTS BELOW THIS
export { useCanvasImageUrlStore };
