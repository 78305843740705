import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import InspirationProfilesTable from "./InspirationProfilesTable";

const AddInspirationProfile = () => {
  const { userLinkedInData } = useUserLinkedInStore();
  const [profileLink, setProfileLink] = useState("");
  const [isAddingInspiration, setIsAddingInspiration] = useState(false);
  const AddInspiration = async () => {
    setIsAddingInspiration(true);
    try {
      const ProfileData = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/addInspirationProfile`,
        {
          userId: userLinkedInData.userId,
          profileUrl: profileLink,
        }
      );
    } catch (error) {
      console.log("Error while adding inspiration --->", error);
    } finally {
      setIsAddingInspiration(false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center max-w-lg gap-6 mt-12 ml-11">
        <Card className="flex flex-col gap-3 mt-5 border">
          <p className="font-semibold text-left">
            Add Your LinkedIn Inspirational Profile URL
          </p>
          <Input
            type="text"
            className="my-2 text-black placeholder:text-midGray"
            placeholder="Enter the profile link"
            onChange={(e) => {
              setProfileLink(e.target.value);
            }}
          />

          <Button
            loading={isAddingInspiration}
            type="primary"
            className="w-full mt-3 font-semibold"
            onClick={AddInspiration}
          >
            Add Inspiration
          </Button>
        </Card>
      </div>

      {/* // InspirationProfilesTable component */}
      {/* <div className="flex items-center justify-center">
        <InspirationProfilesTable />
      </div> */}
    </>
  );
};

export default AddInspirationProfile;
