import { Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
// import Modal from "react-modal";
import TemplatesDisplay from "./TemplatesDisplay";
import { usePostTemplateStore } from "../../ZustandStores/PostTemplateStore";
import { Modal } from "antd";
import { LoadingSpinnerLarge } from "../../../components/widgets/LoadingSpinner";

const TemplateSelectionModal = ({ isOpen, onClose }) => {
  const { selectedPostTemplate } = usePostTemplateStore();

  return (
    <Modal
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      maskClosable={false}
      width={"90vw"}
      hight={"90vh"}
      footer={null}
    >
      <main className="flex flex-col items-center justify-center">
        {/* Header */}
        <h4 className="text-lg font-semibold ">
          Most Advanced AI Templates for LinkedIn
        </h4>
        <p className="text-triklAccentBlue">More templates coming soon!</p>

        {/* all templates including its side navigation */}
        <TemplatesDisplay />

        {/* Button for selecting any template */}
        <button
          disabled={selectedPostTemplate?.length > 0 ? false : true}
          className={`z-[100] absolute p-4 px-10 font-semibold -translate-x-1/2 rounded-full cursor-pointer  bottom-8 left-1/2 shadow-md shadow-gray-300
              ${
                selectedPostTemplate?.length > 0
                  ? "text-white bg-triklAccentBlue"
                  : "text-triklAccentBlue bg-white"
              }
              `}
          onClick={onClose}
        >
          {selectedPostTemplate?.length > 0
            ? "Continue with Selected Template"
            : "Select Any Template"}
        </button>
      </main>
    </Modal>
  );
};

export default TemplateSelectionModal;

///////////////

{
  /* <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute z-50 w-full px-10 -translate-x-1/2 bg-green-200 left-1/2"
    >
      <Transition appear show={true} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <main className="mt-20 ">
            <TemplatesDisplay />
            <button
              disabled={selectedPostTemplate?.length > 0 ? false : true}
              className={` z-[100] absolute p-4 px-10 font-semibold -translate-x-1/2 rounded-full cursor-pointer  bottom-4 left-1/2 border-2 border-triklAccentBlue shadow-md shadow-gray-300
              ${
                selectedPostTemplate?.length > 0
                  ? "text-white bg-gradient-to-tr from-triklAccentBlue via-triklAccentBlue to-triklDarkAccentBlue"
                  : "text-triklAccentBlue bg-white"
              }
              `}
              onClick={onClose}
            >
              {selectedPostTemplate?.length > 0
                ? "Template Selected"
                : "Select Any Template"}
            </button>

            <div
              className="absolute cursor-pointer text-gray-500/50 hover:text-triklRed top-5 right-5"
              onClick={onClose}
            >
              <AiOutlineCloseCircle className="w-8 h-8 " />
            </div>
          </main>
        </Transition.Child>
      </Transition>
    </Modal> */
}

// {/* <div
//               className="absolute right-0 bottom-0 w-[40vw] h-[70vh] -z-10 flex justify-end items-end rounded-full bg-triklAccentBlue/60 blur-3xl"
//               onClick={onClose}
//             > */}
//             {/* BLURRED CIRCLE */}
//             {/* </div>
//             <div
//               className="absolute right-0 top-0 w-[60vw] h-[70vh] -z-10 flex justify-end items-end rounded-full bg-triklDarkAccentBlue/60 blur-3xl"
//               onClick={onClose}
//             > */}
//             {/* BLURRED CIRCLE */}
//             {/* </div> */}
