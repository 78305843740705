import { Button, Modal } from "antd";
import React from "react";
import {
  useFinalContentStore,
  useIsCreateFromClickStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
} from "../../ZustandStores/LinkedInZustandStore";

const ClearUpdatedContent = ({ isOpen, onClose, clearPostType }) => {
  const { setSelectedDraftId } = useSavedDraftIdStore();
  const { setSelectedScheduledPostId } = useScheduledPostIdStore();
  const { setFinalContent } = useFinalContentStore();
  const { setIsCreateFromClick } = useIsCreateFromClickStore();

  return (
    <>
      <Modal
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        // width={"80vw"}
        footer={null}
      >
        <main className="flex flex-col items-center justify-center w-full h-full gap-4 text-lg text-left">
          <span className="text-red-600"> Are you sure? </span>
          This will discard the changes in your {clearPostType} .
          <Button
            className="w-2/3 bg-triklAccentBlue"
            type="primary"
            onClick={() => {
              if (clearPostType === "Saved Draft") {
                setSelectedDraftId("");
              } else if (clearPostType === "Scheduled Post") {
                setSelectedScheduledPostId("");
              }
              setFinalContent({
                finalText: "",
                finalMediaUrl: "",
                finalMediaType: "",
              });
              setIsCreateFromClick(false);
              onClose();
            }}
          >
            Yes, I'm sure
          </Button>
        </main>
      </Modal>
    </>
  );
};

export default ClearUpdatedContent;
