import { Button, Segmented, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useFinalContentStore } from "../../../ZustandStores/LinkedInZustandStore";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import { useUserAuth } from "../../../../../context/userAuthContext";
import { CopyOutlined, EditOutlined, PicLeftOutlined } from "@ant-design/icons";

const CarouselTopicSelection = () => {
  const { finalContent } = useFinalContentStore();
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();
  const [creatingCarousel, setCreatingCarousel] = useState(false);
  const [enteredTopic, setEnteredTopic] = useState("");
  const { user } = useUserAuth();

  const [selectedCarouselType, setSelectedCarouselType] =
    useState("Manually Add Text");

  // Function to get the contents for a carousel
  const getCarouselContent = async () => {
    setCreatingCarousel(true);
    // Determine prompt based on the carousel type
    const promptText =
      selectedCarouselType === "Create From Post"
        ? finalContent?.finalText
        : enteredTopic;

    // Early exits for specific conditions when creating from post
    if (selectedCarouselType === "Create From Post") {
      if (!user?.uid) {
        toast.error("Please Login To Create Carousel From Post");
        setCreatingCarousel(false);
        return;
      } else if (!finalContent?.finalText?.length) {
        toast.error(
          "Please Generate Text Content In TRIKL And Then Create The Carousel"
        );
        setCreatingCarousel(false);
        return;
      }
    }

    try {
      // Send the request to create the carousel
      const res = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/linkedIn-carousel`,
        {
          userId: user?.uid,
          prompt: promptText,
          type: selectedCarouselType,
        }
      );

      // Process the response
      const contentArray = JSON.parse(res?.data?.content);

      console.log("contentArray >>>>", contentArray);
      const transformedContentArray = transformContentArray(
        contentArray?.carouselContent
      );

      // Update the carousel state
      setCarouselStates({
        ...carouselStates,
        editedTexts: transformedContentArray,
      });
    } catch (error) {
      console.error("Error getting carousel content", error);
    } finally {
      setCreatingCarousel(false);
    }
  };

  // Change the format of response array
  const transformContentArray = (contentArray) => {
    return contentArray?.map((item, index) => ({
      id: index,
      type: index === 0 ? "introduction" : "content",
      title: item.title,
      titleActive: true,
      description: item.description,
      descriptionActive: true,
      invisible: false,
    }));
  };

  //  Menu click handler
  const handleSelectionChange = (value) => {
    setSelectedCarouselType(value);
  };

  //   Render the CarouselTopicSelection component
  const CarouselTopicSelection = () => {
    switch (selectedCarouselType) {
      case "Manually Add Text":
        return (
          <TextArea
            rows={1}
            value={enteredTopic}
            itemActiveBg="rgba(220, 0, 0, 0.15)"
            placeholder="Paste The LinkedIn post you Liked"
            onChange={(e) => {
              setEnteredTopic(e.target.value);
            }}
          />
        );
    }
  };

  return (
    <div className="flex items-center gap-4 p-4 mt-3 bg-white rounded-md">
      {/* <div className="flex max-w-md gap-2"> */}
      <Segmented
        onChange={handleSelectionChange}
        defaultValue={"Manually Add Text"}
        options={[
          {
            label: "From Post",
            value: "Create From Post",
            icon: <PicLeftOutlined />,
          },
          {
            label: "Manual",
            value: "Manually Add Text",
            icon: <EditOutlined />,
          },
        ]}
        className="p-2"
      />

      {/* Dynamically render the Input box based on the selection */}
      {CarouselTopicSelection()}

      <Button
        type="primary"
        onClick={() => {
          getCarouselContent();
        }}
        loading={creatingCarousel}
      >
        Generate
      </Button>
      {/* </div> */}
    </div>
  );
};

export default CarouselTopicSelection;
