const V2BackgroundTemplate18 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3545)">
        <path
          d="M495.061 155.944C579.743 98.9906 499.931 0.000511338 495.061 0H536.996V665H500.472C496.955 650.355 480.496 634.5 465.842 602.08C458.431 585.685 448.202 552.449 482.616 507.971C517.03 463.493 512.935 421.456 495.061 401.387C425.259 323.008 410.38 212.898 495.061 155.944Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <path
          d="M518.061 38.9441C602.743 -18.0094 522.931 -116.999 518.061 -117H559.996V548H542.496C538.979 533.355 503.496 517.5 488.842 485.08C481.431 468.685 471.202 435.449 505.616 390.971C540.03 346.493 535.935 304.456 518.061 284.387C448.259 206.008 433.38 95.8976 518.061 38.9441Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <path
          d="M27.9975 491.5C-18.4776 582.356 67.1305 664.999 71.9999 665H7.62939e-05V0H17.5001C21.0175 14.6447 56.5001 30.5 71.1545 62.92C78.5653 79.3151 88.7943 112.551 54.3804 157.029C19.9666 201.507 29.2894 239.899 41.9347 263.613C91.9983 357.5 83.498 383 27.9975 491.5Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate18;
