import axios from "axios";
import { toast } from "react-toastify";

const audioToContentFunction = async (
  trascriptDataParam,
  contentTypeParam,
  selectedPostType
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/openai/media-to-content`,
      {
        transcriptTxt: trascriptDataParam,
        contentType: contentTypeParam,
        platformType: selectedPostType,
      },
      {
        withCredentials: true,
      }
    );
    const tempResponse = res.data.content;
    const formattedResponse = tempResponse.replace(/\n/g, "<br />");

    return formattedResponse;
  } catch (error) {
    toast.error("Something went wrong, please try again! ");
  }
};

export { audioToContentFunction };
