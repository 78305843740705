const V2BackgroundTemplate3 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3452)">
        <path
          d="M-6 80L62.4066 15L130.813 80L198.468 15L266.875 80L334.028 15L402.936 80L469.839 15L540 80"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 270L62.4066 205L130.813 270L198.468 205L266.875 270L334.028 205L402.936 270L469.839 205L540 270"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 555L62.4066 490L130.813 555L198.468 490L266.875 555L334.028 490L402.936 555L469.839 490L540 555"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 175L62.4066 110L130.813 175L198.468 110L266.875 175L334.028 110L402.936 175L469.839 110L540 175"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 460L62.4066 395L130.813 460L198.468 395L266.875 460L334.028 395L402.936 460L469.839 395L540 460"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 365L62.4066 300L130.813 365L198.468 300L266.875 365L334.028 300L402.936 365L469.839 300L540 365"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
        <path
          d="M-6 650L62.4066 585L130.813 650L198.468 585L266.875 650L334.028 585L402.936 650L469.839 585L540 650"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.05"
          stroke-width="15"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate3;
