import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Canvas from "../linkedIn/Canvas";
import LinkedinLogin from "../linkedIn/authentication/LinkedinLogin";
import LinkedInOnboarding from "../linkedIn/authentication/LinkedInOnboarding";
import LinkedInUserProfile from "../linkedIn/components/userProfile/LinkedInUserProfile";
import Pricing from "../../home/pages/pricing/Pricing";
import OrgAnalytics from "./analyticsComponent/OrgAnalytics";
import CanvasNavbar from "../linkedIn/components/CanvasNavbar";
import Assistant from "./components/assistant/Assistant";
import Scheduler from "./components/schedulerComponents/Scheduler";
import SpeechToText from "./components/speechToContent/SpeechToText";
import UserAnalytics from "./analyticsComponent/UserAnalytics";
import PublicContentShowcase from "./components/speechToContent/PublicContentShowcase";
import ProfileApp from "./analyticsComponent/ProfileApp";
import ShortContentCreator from "./components/shortContentCreation/ShortContentCreator";
import ContentCalendar from "./contentCalender";
import UserRoutes from "./routes/UserRoutes";
import ProtectedRoute from "../authentication/ProtectedRoute";
import AddInspirationProfile from "./components/inspirationalProfile/AddInspirationProfile";

import AdvancedCarousel from "./components/mediaComponents/carousel";

const AppHome = () => {
  let locationPath;
  const location = useLocation();
  locationPath = location.pathname;
  useEffect(() => {}, [location]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <>
      {/* MAIN APPLICATION AREA */}
      <main className="w-full h-screen overflow-y-scroll bg-linkedInBase">
        <Routes>
          {/* User Routes */}
          <Route path="*" element={<Navigate to="/user/signup" />} />
          {UserRoutes()}

          {/* Media Generation  Routes */}

          {/* Carousel Generation */}
          <Route
            path="/linkedin/carousel-generation"
            element={<AdvancedCarousel />}
          />
          {/* LinkedIn Routes */}

          {/* ----------------------------------------- LinkedIn v1 routes */}
          <Route
            path="/pricing"
            element={
              <Elements stripe={stripePromise}>
                <Pricing />
              </Elements>
            }
          />
          {/* <Route path="/linkedin/login" element={<LinkedinLogin />} /> */}

          {/* <Route
            path="/linkedin/userProfile"
            element={
              <CanvasNavbar>
                <LinkedInUserProfile />
              </CanvasNavbar>
            }
          /> */}
          {/* <Route path="/linkedin/onboarding" element={<LinkedInOnboarding />} /> */}
          {/* -------------------------------------------------------------------------------------------------- */}

          <Route
            path="/linkedin/content-creation"
            element={
              <ProtectedRoute>
                <CanvasNavbar>
                  <Canvas />
                </CanvasNavbar>
              </ProtectedRoute>
            }
          />
          <Route
            path="/linkedin/scheduler"
            element={
              <CanvasNavbar>
                <Scheduler />
              </CanvasNavbar>
            }
          />

          <Route
            path="/linkedin/personal-assistant"
            element={
              <CanvasNavbar>
                <Assistant />
              </CanvasNavbar>
            }
          />

          <Route
            path="/linkedin/content-calendar"
            element={
              <CanvasNavbar>
                <ContentCalendar />
              </CanvasNavbar>
            }
          />

          <Route
            path="/linkedin/short-content"
            element={
              <CanvasNavbar>
                <ShortContentCreator />
              </CanvasNavbar>
            }
          />

          <Route
            path="/linkedin/speech-to-content"
            element={
              // <CanvasNavbar>
              <SpeechToText />
              // </CanvasNavbar>
            }
          />

          <Route
            path="/linkedin/share/:id"
            element={<PublicContentShowcase />}
          />

          {/* ------------ Analytics Routes ----------------- */}
          {/* <Route
            path="/linkedin/userProfile/analytics"
            element={
              <CanvasNavbar>
                <UserAnalytics />
              </CanvasNavbar>
            }
          /> */}
          {/* ---------------------------------------------- */}

          {/* ----------------- Profile Analysis Route --------------- */}
          <Route
            path="/linkedin/profileAnalysis"
            element={
              <CanvasNavbar>
                <ProfileApp />
              </CanvasNavbar>
            }
          />

          {/* Inspiration Profiles */}
          <Route
            path="/linkedin/inspiration"
            element={
              <CanvasNavbar>
                <AddInspirationProfile />
              </CanvasNavbar>
            }
          />

          {/* --------------- Organisation's Post analytics ---------------- */}
          {/* <Routes
            path="/linkedin/orgAnalysis"
            element={
              <CanvasNavbar>
                <OrgAnalytics />
              </CanvasNavbar>
            }
          /> */}
        </Routes>
      </main>
    </>
  );
};

export default AppHome;
