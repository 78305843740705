import { create } from "zustand";

// REFRESH USER DATA
const CalendarDataRefreshStore = create((set) => ({
  calendarDataRefresh: false,
  setCalendarDataRefresh: (prev) => set({ calendarDataRefresh: prev }),
}));

// Click manual Data item
const useCalendarContentGenStore = create((set) => ({
  calendarContent: {
    textContent: "",
    ManualDataClick: false,
  },
  setCalendarContent: (newState) =>
    set((state) => ({
      calendarContent: {
        ...state.calendarContent,
        ...newState,
      },
    })),
}));

// const useWhatsSelectedStore = create((set) => ({
//   whatsSelected: {
//     tabIndex: 0,
//     itemIndex: 10000,
//     title: "",
//     description: "",
//     // url: "",
//     allData: {},
//   },
//   setWhatsSelected: (newState) =>
//     set((state) => ({
//       whatsSelected: {
//         ...state.whatsSelected,
//         ...newState,
//       },
//     })),
// }));

export { CalendarDataRefreshStore, useCalendarContentGenStore };
