// store for the canvas editor
import { create } from "zustand";

const useUserOrganizationsStore = create((set) => ({
  organizationsData: [],
  setOrganizationsData: (newState) => {
    set(() => ({ organizationsData: newState }));
  },
}));

// ALL EXPORTS BELOW THIS
export { useUserOrganizationsStore };
