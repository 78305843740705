import React from "react";

const ButtonsOutline = ({ clickAction, title }) => {
  return (
    <button
      className="bg-white text-triklAccentBlue border-triklAccentBlue box-border
      rounded-md border-[1px] px-8 py-1 flex w-full
      font-semibold items-center justify-center"
      onClick={clickAction}
    >
      {title}
    </button>
  );
};

const ButtonsOutlineGrey = ({ clickAction, title, classParam }) => {
  return (
    <button
      className={
        !classParam || classParam === ""
          ? "border bg-white border-gray-400 rounded-md py-1 mt-3 w-full hover:bg-red-500 hover:text-white"
          : classParam
      }
      onClick={clickAction}
    >
      {title}
    </button>
  );
};

const ButtonsFilledBlue = ({ clickAction, title }) => {
  return (
    <button
      className="bg-triklAccentBlue text-white w-full border-triklAccentBlue box-border 
        rounded-md border-[1px] mt-3 px-8 py-1 flex 
        font-semibold items-center justify-center"
      onClick={clickAction}
    >
      {title}
    </button>
  );
};

const ButtonsFilledGray = ({ clickAction, title, classParam }) => {
  return (
    <button
      className={
        !classParam || classParam === ""
          ? "bg-darkGrey text-white box-border rounded-md border-[1px] px-8 py-1 flex w-full font-semibold items-center justify-center"
          : classParam
      }
      onClick={clickAction}
    >
      {title}
    </button>
  );
};

export {
  ButtonsOutline,
  ButtonsOutlineGrey,
  ButtonsFilledBlue,
  ButtonsFilledGray,
};
