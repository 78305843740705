// ALL IMAGE IMPORTS
import hyperRealisticBrightBackground from "./hyperRealisticBrightBackground.png";
import hyperRealisticDarkBackground from "./hyperRealisticDarkBackground.png";
import hyperRealisticHardLight from "./hyperRealisticHardLight.png";
import hyperRealisticCyberpunk from "./hyperRealisticCyberpunk.png";
import digitalArtPortrait from "./digitalArtPortrait.png";
import watercolorPortrait from "./watercolorPortrait.png";
import tattooPortrait from "./tattooPortrait.png";
import tattooVideoGameCharacter from "./tattooVideoGameCharacter.png";
import fantasyVideoGameCharacter from "./fantasyVideoGameCharacter.png";
import cartoonThreeD from "./cartoonThreeD.png";
import oilPaintingPortrait from "./oilPaintingPortrait.png";
import productCyberpunk from "./productCyberpunk.png";
import productHyperRealistic from "./productHyperRealistic.png";
import productColorBlast from "./productColorBlast.png";
import productLiquidSplash from "./productLiquidSplash.png";
import productLighting from "./productLighting.png";
import wildlifePhotography from "./wildlifePhotography.png";
import spaceHyperRealistic from "./spaceHyperRealistic.png";
import apocalypticHyperRealistic from "./apocalypticHyperRealistic.png";
import fantasyWorld from "./fantasyWorld.png";
import sciFiSynthwave from "./sciFiSynthwave.png";
import colorfulCreativePainting from "./colorfulCreativePainting.png";
import beautifulLandscape from "./beautifulLandscape.png";
import blueprint from "./blueprint.png";
import brokenGlassSculpture from "./brokenGlassSculpture.png";
import abstractPainting from "./abstractPainting.png";

// OVERALL ARRAY
const OverallImgCategories = [
  { name: "character", img: hyperRealisticBrightBackground },
  { name: "product", img: productCyberpunk },
  { name: "technology", img: sciFiSynthwave },
  { name: "creativeDesign", img: fantasyWorld },
  { name: "environment", img: spaceHyperRealistic },
  { name: "animals", img: wildlifePhotography },
];

// ALL IMAGE PROMPTS
const AllImageCategories = {
  character: [
    {
      uniqueName: "hyperRealisticBrightBackground",
      displayName: "Real Bright",
      sampleImg: hyperRealisticBrightBackground,
      subject: "character",
    },
    {
      uniqueName: "hyperRealisticDarkBackground",
      displayName: "Real Dark",
      sampleImg: hyperRealisticDarkBackground,
      subject: "character",
    },
    {
      uniqueName: "hyperRealisticHardLight",
      displayName: "Hard Light",
      sampleImg: hyperRealisticHardLight,
      subject: "character",
    },
    {
      uniqueName: "hyperRealisticCyberpunk",
      displayName: "Real Cyberpunk",
      sampleImg: hyperRealisticCyberpunk,
      subject: "character",
    },
    {
      uniqueName: "digitalArtPortrait",
      displayName: "Digital Art",
      sampleImg: digitalArtPortrait,
      subject: "character",
    },
    {
      uniqueName: "watercolorPortrait",
      displayName: "Watercolor",
      sampleImg: watercolorPortrait,
      subject: "character",
    },
    {
      uniqueName: "oilPaintingPortrait",
      displayName: "Oil Painting",
      sampleImg: oilPaintingPortrait,
      subject: "character",
    },
    {
      uniqueName: "tattooPortrait",
      displayName: "Tattoo",
      sampleImg: tattooPortrait,
      subject: "character",
    },
    {
      uniqueName: "tattooVideoGameCharacter",
      displayName: "Tattoo Game",
      sampleImg: tattooVideoGameCharacter,
      subject: "character",
    },
    {
      uniqueName: "fantasyVideoGameCharacter",
      displayName: "Video Game",
      sampleImg: fantasyVideoGameCharacter,
      subject: "character",
    },
    {
      uniqueName: "cartoonThreeD",
      displayName: "Cartoon",
      sampleImg: cartoonThreeD,
      subject: "character",
    },
  ],
  product: [
    {
      uniqueName: "productCyberpunk",
      displayName: "Cyberpunk",
      sampleImg: productCyberpunk,
      subject: "product",
    },
    {
      uniqueName: "productHyperRealistic",
      displayName: "Real",
      sampleImg: productHyperRealistic,
      subject: "product",
    },
    {
      uniqueName: "productColorBlast",
      displayName: "Color Blast",
      sampleImg: productColorBlast,
      subject: "product",
    },
    {
      uniqueName: "productLiquidSplash",
      displayName: "Splash",
      sampleImg: productLiquidSplash,
      subject: "product",
    },
    {
      uniqueName: "productLighting",
      displayName: "Sunny",
      sampleImg: productLighting,
      subject: "product",
    },
  ],
  animals: [
    {
      uniqueName: "wildlifePhotography",
      displayName: "Wild Real",
      sampleImg: wildlifePhotography,
      subject: "animal",
    },
  ],
  environment: [
    {
      uniqueName: "spaceHyperRealistic",
      displayName: "Space",
      sampleImg: spaceHyperRealistic,
      subject: "environment",
    },
    {
      uniqueName: "apocalypticHyperRealistic",
      displayName: "Apocalypse",
      sampleImg: apocalypticHyperRealistic,
      subject: "environment",
    },
    {
      uniqueName: "beautifulLandscape",
      displayName: "Landscape",
      sampleImg: beautifulLandscape,
      subject: "environment",
    },
  ],
  technology: [
    {
      uniqueName: "sciFiSynthwave",
      displayName: "Sci-Fi",
      sampleImg: sciFiSynthwave,
      subject: "technology",
    },
  ],
  creativeDesign: [
    {
      uniqueName: "fantasyWorld",
      displayName: "Fantasy",
      sampleImg: fantasyWorld,
      subject: "creativeDesign",
    },
    {
      uniqueName: "colorfulCreativePainting",
      displayName: "Creative Painting",
      sampleImg: colorfulCreativePainting,
      subject: "creativeDesign",
    },

    {
      uniqueName: "blueprint",
      displayName: "Blueprint",
      sampleImg: blueprint,
      subject: "creativeDesign",
    },
    {
      uniqueName: "brokenGlassSculpture",
      displayName: "Sculpture",
      sampleImg: brokenGlassSculpture,
      subject: "creativeDesign",
    },
    {
      uniqueName: "abstractPainting",
      displayName: "Abstract",
      sampleImg: abstractPainting,
      subject: "creativeDesign",
    },
  ],
};

// const AllImagePrompts = {
//   hyperRealisticBrightBackground: {
//     prePrompt: "",
//     postPrompt:
//       ", rich skin texture, ID photo, front view, chest up, medium shot, 8k, rich details, real, high resolution, extremely high quality, detailed background, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality, versatile clothing with high details, natural proportions, natural face and skin, natural eyes",
//   },
//   hyperRealisticDarkBackground: {
//     prePrompt: "",
//     postPrompt:
//       ", rich skin texture, ID photo, front view, chest up, medium shot, clear background, 8k, rich details, real, high resolution, extremely high quality, detailed background, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality",
//   },
//   hyperRealisticHardLight: {
//     prePrompt: "",
//     postPrompt:
//       ", (Rembrandt lighting, hard Lighting : 1.5), rim light, rich skin texture, colored face lighting, ID photo, Beauty Dish, front view, chest up, medium shot, 8k, rich details, real, high resolution, extremely high quality, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality",
//   },
//   hyperRealisticCyberpunk: {
//     prePrompt: "",
//     postPrompt:
//       " pastel neon background, 32k uhd, triadic color grading --s 75 --v 5.2 --ar 4:3",
//   },
//   digitalArtPortrait: {
//     prePrompt: "portrait of ",
//     postPrompt:
//       ", inspired by Krenz Cushart, neoism, kawacy, wlop, gits anime, perfect face, medium shot, face symmetry, clear background, 8k, rich details, high resolution, extremely high quality, detailed background, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality",
//   },
//   watercolorPortrait: {
//     prePrompt: "portrait of ",
//     postPrompt:
//       ", watercolor, inspired by Krenz Cushart, neoism, kawacy, wlop, gits anime, perfect face, medium shot, face symmetry, clear background, 8k, rich details, high resolution, extremely high quality, detailed background, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality",
//   },
//   oilPaintingPortrait: {
//     prePrompt: "",
//     postPrompt:
//       " Digital art by wadim Kashin, Sakimichan, Jeremy Mann, Louis Icart, Valentin Serov, Makoto Kobayashi, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger",
//   },
//   tattooPortrait: {
//     prePrompt: "",
//     postPrompt:
//       ", tattoos, niji style, hip hop, rich skin texture, ID photo, front view, chest up, medium shot, clear background, 8k, rich details, real, high resolution, extremely high quality, detailed background, excellent details and textures, highly detailed, ultra-detailed photograph 4k, high resolution, detailed skin, detailed eyes, 8k UHD, high quality",
//     internalDescription: "Hyper-realistic character with tattoos",
//   },
//   tattooVideoGameCharacter: {
//     prePrompt: "",
//     postPrompt:
//       ", tattoos, niji style, hip hop, , epic video game key visual, RPG style, masterpiece, illustration, best quality, detailed, intricate, 8k, HDR, wallpaper, cinematic lighting, sharp focus, fantasy",
//   },
//   fantasyVideoGameCharacter: {
//     prePrompt: "",
//     postPrompt:
//       ", epic video game key visual, RPG style, masterpiece, illustration, best quality, detailed, intricate, 8k, HDR, wallpaper, cinematic lighting, sharp focus, fantasy",
//   },
//   cartoonThreeD: {
//     prePrompt: "",
//     postPrompt:
//       "; storybook style, warm dreamy lighting, white background, volumetric lighting, adventure style, dynamic gradients, bright color, illustration, highly detailed vector curves, simple, smooth and clean, vector graphics, smooth, Johan Grenier, character design, 3D shading, cinematic, ornate patterns, elegant organic framing, hyperrealism, posterized, masterpiece collection, juicy bright colors, twilight, highly detailed, vibrant, HDR, 500px, by GIlSam-paio octane rendering representing innovation and truth, 8k, by Lee Jeffries, Alessio Albi, Adrian Kuipers, glamour, intricate detailed environment.",
//   },

//   productCyberpunk: {
//     prePrompt: "commercial photography of cyberpunk ",
//     postPrompt:
//       " pastel neon background, 32k uhd, triadic color grading --s 75 --v 5.2 --ar 4:3",
//   },
//   productHyperRealistic: {
//     prePrompt: "",
//     postPrompt:
//       ", fine ultra-detailed realistic, ultra photorealistic, Hasselblad H6D,high definition, 8k, cinematic, color grading, depth of field, photo-realistic, film lighting, rim lighting, intricate, realism, maximalist detail, very realistic, epic real background, versatile background",
//   },
//   productColorBlast: {
//     prePrompt: "",
//     postPrompt:
//       ", powerful explosion of mix color dust, commercial photography, fine ultra-detailed realistic, ultra photorealistic, Hasselblad H6D, high definition, 8k, cinematic, color dust background, color grading, depth of field, photo-realistic, stock photo, film lighting, rim lighting, intricate, realism, maximalist detail, very realistic, professional color grading, award winning photograph",
//   },
//   productLiquidSplash: {
//     prePrompt: "",
//     postPrompt:
//       ", adorned with glistening liquid droplets, powerful liquid liquid explosion, fine ultra-detailed realistic, ultra photorealistic,  Hasselblad H6D,high definition, 8k, cinematic, color grading, depth of field, photo-realistic,  film lighting, rim lighting, intricate, realism, maximalist detail, very realistic",
//   },
//   productLighting: {
//     prePrompt: "",
//     postPrompt:
//       ", photorealistic, vivid, sharp focus, reflection, refraction, sunrays, very detailed, intricate, intense cinematic composition",
//   },
//   wildlifePhotography: {
//     prePrompt: "",
//     postPrompt:
//       ", wildlife photography, photograph, high quality, wildlife, f 1.8, soft focus, 8k, national geographic, award - winning photograph",
//   },
//   spaceHyperRealistic: {
//     prePrompt: "",
//     postPrompt:
//       ", outer space, centered shot, wide angle, dd, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, digital art, ~*~aesthetic~*~, cinematic, stars and galaxy, vivid colors",
//   },
//   apocalypticHyperRealistic: {
//     prePrompt: "",
//     postPrompt:
//       ", postapocalyptic,apocalyptic, overgrown with plant life and, 8k, octane render, unreal engine",
//   },
//   beautifulLandscape: {
//     prePrompt: "",
//     postPrompt:
//       ", masterpiece, best quality, high quality, extremely detailed CG unity 8k wallpaper, artstation, digital illustration, intricate, trending,, award winning photography,HDR",
//   },

//   sciFiSynthwave: {
//     prePrompt: "sci-fi style, ",
//     postPrompt:
//       ", synthwave neon lights illuminate amidst a vibrant scene, energy, molecular, textures, pure perfection, divine presence, unforgettable, volumetric light, auras, rays, vivid colors reflects",
//   },
//   fantasyWorld: {
//     prePrompt: "magical",
//     postPrompt:
//       ", fantasy art, high quality, highly detailed, elegant, sharp focus, concept art, character concepts, digital painting, mystery, adventure",
//   },
//   colorfulCreativePainting: {
//     prePrompt: "t-shirt design, 2D, vector illustration of ",
//     postPrompt:
//       ", center design, black background, vivid color splash, hd, UHD, concept art, masterpiece, best quality, cinematic shading, soft motion blur, Adobe Illustration, Photoshop_details, Petros Afshar, by Pascal Blanche Rutkowski Repin Artstation Hyperrealism Painting Art Concept of Detail Character Design Matte Painting, 4k resolution slide runner, cinematic, 4k, epic Steven Spielberg film still, sharp focus, emitting diodes, smoke, artillery, sparks, racks, drive system power supply, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting detailed concept art, perfect composition, beautiful and intricate insanely detailed octane rendering trending in artstation, artistic photography 8k, photorealistic concept art, cinematic perfect light soft natural volumetric, chiaroscuro, award-winning photography, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, gige",
//   },
//   blueprint: {
//     prePrompt: "23rd century scientific schematics for ",
//     postPrompt:
//       ", blueprint, hyperdetailed vector technical documents, callouts, legend, patent registry",
//   },
//   brokenGlassSculpture: {
//     prePrompt: "logo icon, 3D, ",
//     postPrompt:
//       ", realistic, high detail raw color art, paisagem. 12K, 3D, Broken Glass effect, no background, stunning, something that even doesn't exist, mythical being, energy, molecular, textures, iridescent and luminescent scales, breathtaking beauty, pure perfection, divine presence, unforgettable, impressive, breathtaking beauty, Volumetric light, auras, rays, vivid colors reflects",
//   },
//   abstractPainting: {
//     prePrompt: "Abstract painting representing the ",
//     postPrompt: ", using vibrant colors and erratic shapes.",
//   },
// };

export { OverallImgCategories, AllImageCategories };
