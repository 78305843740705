import React from "react";

const V2BackgroundTemplate1 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask id="mask0_3208_3467" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="535" height="665">
<rect width="535" height="665" fill="white"/>
</mask> */}
      <g mask="url(#mask0_3208_3467)">
        <rect
          x="611.551"
          y="107"
          width="246.879"
          height="650.536"
          rx="123.44"
          transform="rotate(52.5648 611.551 107)"
          fill={colorPallet?.secondary}
          fill-opacity="0.17"
        />
        <path
          d="M490.738 581.891L499.738 590.891L490.738 599.891"
          stroke={colorPallet?.primary}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M499.738 590.891L454.738 590.891"
          stroke={colorPallet?.primary}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate1;
