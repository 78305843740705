import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UserAuthContextProvider } from "./context/userAuthContext";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";

// app
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LinkedinHome from "./app/linkedIn/LinkedinHome";
import { ConfigProvider } from "antd";

// Create a react-query client
const queryClient = new QueryClient();

function App() {
  const [isApp, setIsApp] = useState(false);

  useEffect(() => {
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    setIsApp(subdomain === "app");
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorTextDisabled: "#ffffff",
            colorBgContainerDisabled: "#6F42C1",
          },
          Segmented: {
            itemSelectedBg: "#f5eafa",
            itemSelectedColor: "#6F42C1",
            itemHoverBg: "#989898",
            itemHoverColor: "#6F42C1",
            trackBg: "#fff",
          },
          Input: {
            activeBorderColor: "#6F42C1",
            activeShadow: "0 0 0 1px #6F42C1",
            hoverBorderColor: "#6F42C1",
            boxShadow: "0 0 0 1px #6F42C1",
          },
          Select: {
            optionActiveBg: "rgba(111, 66, 193, 0.2)",
            optionSelectedBg: "rgba(111, 66, 193, 0.2)",
            colorBorder: "#6F42C1",
            colorPrimaryHover: "#6F42C1",
          },
          Menu: {
            itemSelectedColor: "#6F42C1",
            itemHoverBg: "#c3c3c3",
            color: "#6F42C1",
            itemActiveBg: "#F5EAFA",
            itemColor: "#6F42C1",
          },
          DatePicker: {
            activeBorderColor: "#6F42C1",
          },
          Spin: {
            colorPrimary: "#6F42C1",
            dotSize: 60,
          },
        },
      }}
    >
      <UserAuthContextProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          draggable
          theme="light"
        />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <div className="text-sm App">
              {isApp ? (
                <LinkedinHome />
              ) : (
                <section className="relative flex justify-start">""</section>
              )}
            </div>
          </BrowserRouter>
        </QueryClientProvider>
      </UserAuthContextProvider>
    </ConfigProvider>
  );
}

export default App;
