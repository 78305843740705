import { ConfigProvider, Spin } from "antd";
import React from "react";

const MainAppLoader = () => {
  return (
    <main className="flex items-center justify-center w-screen h-screen ">
      <Spin tip="Loading" size="large" />
    </main>
  );
};

export default MainAppLoader;
