import React from "react";
import { BglightCircle } from "../../../../assets/background";
import { Button, Col, Form, Input, Row } from "antd";
import { triklPurple } from "../../../../assets/Trikl_logo";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../../context/userAuthContext";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { resetPassword } = useUserAuth();

  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    const { email } = values;
    if (email === "") {
      toast.error("Please fill all the fields");
      return;
    }
    setIsLoading(true);
    resetPassword(email)
      .then(() => {
        setIsLoading(false);
        toast.success("Password reset email sent.");
        setIsEmailSent(true);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Error sending password reset email");
        setIsEmailSent(false);
      });
  };

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const token = searchParams.get('resetToken');
  //   if (token) {
  //     // If there's a reset token, you might want to automatically show a modal/form for password reset
  //     setResetToken(token);
  //   }
  // }, [location.search]);

  // const handleSubmit = async (values) => {
  //   const { password } = values;
  //   if (!password) {
  //     toast.error("Please enter a new password");
  //     return;
  //   }
  //   setIsLoading(true);
  //   try {
  //     // Assuming the user is already signed in; otherwise, you need to sign in the user first
  //     await auth.currentUser.updatePassword(password);
  //     toast.success("Password has been updated successfully.");
  //   } catch (error) {
  //     toast.error("Failed to update password. Make sure you're logged in.");
  //     console.error("Error updating password:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div>
      <div className="h-full bg-white bg-top bg-no-repeat">
        <div className="">
          <div className="absolute flex justify-center">
            <img className="" src={BglightCircle} alt="" />
          </div>

          <Row justify="center">
            <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
              <div className="flex justify-center mt-6">
                <img src={triklPurple} alt="Trikl" className="w-28 h-28" />
              </div>
              <div className="bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
                <div className="px-10 pt-8 pb-6">
                  <Form
                    name="forgotPass"
                    onFinish={handleSubmit}
                    layout="vertical"
                  >
                    <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
                      <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
                        Forgot Password?
                      </h2>
                    </div>
                    <div className="px-10 pt-8 pb-6">
                      <p className="mb-4 dark:text-white60">
                        Enter the email address you used when you joined and
                        we’ll send you instructions to reset your password.
                      </p>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                            type: "email",
                          },
                        ]}
                      >
                        <Input placeholder="name@example.com" />
                      </Form.Item>
                      <Form.Item>
                        {isEmailSent ? (
                          <h2 className="font-semibold text-linkedInPeach">
                            Email sent successfully
                          </h2>
                        ) : (
                          <Button
                            className="block w-full h-12 p-0 text-sm font-medium"
                            htmltype="submit"
                            type="primary"
                            size="large"
                            disabled={isLoading}
                          >
                            Send Reset Instructions
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                    <div className="p-6 text-center bg-section dark:bg-white10 rounded-b-md">
                      <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                        Return to &nbsp;
                        <Link
                          to="/user/signin"
                          className="ltr:ml-1.5 rtl:mr-1.5 text-info text-triklAccentBlue hover:text-triklAccentBlue/50"
                        >
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
