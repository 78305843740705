import React from "react";

const BackgroundTemplate8 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2563_1485"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask> */}
      <g mask="url(#mask0_2563_1485)">
        <path
          d="M293.957 -18.3451C272.154 17.4102 264.299 59.9494 271.895 101.134C279.491 142.318 302.006 179.255 335.131 204.879C368.255 230.503 409.665 243.014 451.437 240.019C493.208 237.023 532.409 218.731 561.539 188.643L540.46 168.236C516.352 193.137 483.909 208.276 449.338 210.755C414.767 213.234 380.496 202.88 353.082 181.673C325.667 160.467 307.034 129.897 300.747 95.8119C294.46 61.7273 300.961 26.5213 319.006 -3.07027L293.957 -18.3451Z"
          //   fill="#666666"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <path
          d="M508.537 686.112C497.955 663.398 481.358 644.014 460.546 630.06C439.733 616.105 415.499 608.113 390.468 606.949C365.438 605.785 340.566 611.493 318.548 623.455C296.53 635.418 278.206 653.178 265.562 674.811L285.994 686.754C296.459 668.85 311.624 654.152 329.847 644.251C348.069 634.351 368.653 629.627 389.369 630.59C410.084 631.554 430.141 638.168 447.366 649.717C464.591 661.266 478.326 677.308 487.084 696.106L508.537 686.112Z"
          fill={colorPallet?.primary}
        />
        <path
          d="M-5.10705 380.248C31.2183 374.254 64.1753 355.392 87.7448 327.109C111.314 298.826 123.924 263.008 123.27 226.197C122.616 189.386 108.743 154.039 84.1842 126.61C59.6252 99.1819 26.0193 81.5027 -10.4959 76.8016L-13.872 103.025C16.3485 106.915 44.1612 121.547 64.4866 144.247C84.8119 166.948 96.2936 196.202 96.8346 226.667C97.3756 257.132 86.9399 286.775 67.4334 310.183C47.9269 333.591 20.6513 349.2 -9.4121 354.162L-5.10705 380.248Z"
          //   fill="#666666"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <path
          d="M547.331 329.751C533.395 340.727 522.005 354.595 513.948 370.399C505.891 386.203 501.358 403.567 500.661 421.292C499.964 439.018 503.12 456.684 509.911 473.072C516.703 489.46 526.968 504.179 539.999 516.216L554.346 500.683C543.562 490.722 535.066 478.539 529.445 464.976C523.825 451.414 521.213 436.793 521.789 422.123C522.366 407.453 526.118 393.083 532.786 380.003C539.455 366.923 548.881 355.446 560.414 346.362L547.331 329.751Z"
          //   fill="#7B32FF"
          fill={colorPallet?.primary}
        />
      </g>
    </svg>
  );
};

export default BackgroundTemplate8;
