import React from "react";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import BackgroundTemplate2 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate2";
import BackgroundTemplate1 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate1";
import BackgroundTemplate3 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate3";
import BackgroundTemplate4 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate4";
import BackgroundTemplate5 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate5";
import BackgroundTemplate6 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate6";
import BackgroundTemplate7 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate7";
import BackgroundTemplate8 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate8";
import BackgroundTemplate9 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate9";
import BackgroundTemplate10 from "../../../../../assets/carouselTemplates/CarouselAssets/BackgroundTemplate10";

// v2 templates
import V2BackgroundTemplate1 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate1";
import V2BackgroundTemplate2 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate2";
import V2BackgroundTemplate3 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate3";
import V2BackgroundTemplate4 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate4";
import V2BackgroundTemplate5 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate5";
import V2BackgroundTemplate6 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate6";
import V2BackgroundTemplate7 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate7";
import V2BackgroundTemplate8 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate8";
import V2BackgroundTemplate9 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate9";
import V2BackgroundTemplate10 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate10";
import V2BackgroundTemplate11 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate11";
import V2BackgroundTemplate12 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate12";
import V2BackgroundTemplate13 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate13";
import V2BackgroundTemplate14 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate14";
import V2BackgroundTemplate15 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate15";
import V2BackgroundTemplate16 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate16";
import V2BackgroundTemplate17 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate17";
import V2BackgroundTemplate18 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate18";
import V2BackgroundTemplate19 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate19";
import V2BackgroundTemplate20 from "../../../../../assets/carouselTemplates/CarouselAssets/V2BackgroundTemplate20";

const BackgroundDesignSelector = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  const renderBackgroundDesign = (type) => {
    switch (type) {
      case "template1":
        return (
          <BackgroundTemplate1 colorPallet={carouselStates.colorPalette} />
          // <div
          // // className="relative"
          // // style={{
          // //   width: "480px",
          // //   height: "600px",
          // // }}
          // >
          //   <BackgroundTemplate1 colorPallet={carouselStates.colorPalette} />
          // </div>
        );
      case "template2":
        return (
          <div>
            <BackgroundTemplate2 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template3":
        return (
          <div>
            <BackgroundTemplate3 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template4":
        return (
          <div>
            <BackgroundTemplate4 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template5":
        return (
          <div>
            <BackgroundTemplate5 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template6":
        return (
          <div>
            <BackgroundTemplate6 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template7":
        return (
          <div>
            <BackgroundTemplate7 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template8":
        return (
          <div>
            <BackgroundTemplate8 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template9":
        return (
          <div>
            <BackgroundTemplate9 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template10":
        return (
          <div>
            <BackgroundTemplate10 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template11":
        return (
          <div>
            <V2BackgroundTemplate1 colorPallet={carouselStates.colorPalette} />
          </div>
        );

      case "template12":
        return (
          <div>
            <V2BackgroundTemplate2 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template13":
        return (
          <div>
            <V2BackgroundTemplate3 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template14":
        return (
          <div>
            <V2BackgroundTemplate4 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template15":
        return (
          <div>
            <V2BackgroundTemplate5 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template16":
        return (
          <div>
            <V2BackgroundTemplate6 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template17":
        return (
          <div>
            <V2BackgroundTemplate7 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template18":
        return (
          <div>
            <V2BackgroundTemplate8 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template19":
        return (
          <div>
            <V2BackgroundTemplate9 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template20":
        return (
          <div>
            <V2BackgroundTemplate10 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template21":
        return (
          <div>
            <V2BackgroundTemplate11 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template22":
        return (
          <div>
            <V2BackgroundTemplate12 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      // case "template23":
      //   return (
      //     <div>
      //       <V2BackgroundTemplate13 colorPallet={carouselStates.colorPalette} />
      //     </div>
      //   );
      // case "template24":
      //   return (
      //     <div>
      //       <V2BackgroundTemplate14 colorPallet={carouselStates.colorPalette} />
      //     </div>
      //   );
      case "template23":
        return (
          <div>
            <V2BackgroundTemplate15 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template24":
        return (
          <div>
            <V2BackgroundTemplate16 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template25":
        return (
          <div>
            <V2BackgroundTemplate17 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      case "template26":
        return (
          <div>
            <V2BackgroundTemplate18 colorPallet={carouselStates.colorPalette} />
          </div>
        );
      // case "template29":
      //   return (
      //     <div>
      //       <V2BackgroundTemplate19 colorPallet={carouselStates.colorPalette} />
      //     </div>
      //   );
      case "template27":
        return (
          <div>
            <V2BackgroundTemplate20 colorPallet={carouselStates.colorPalette} />
          </div>
        );

      default:
        return <></>;
    }
  };
  return (
    <div>
      {renderBackgroundDesign(carouselStates?.backgroundDesign?.type?.value)}
    </div>
  );
};

export default BackgroundDesignSelector;
