import React from "react";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import AddPostToScheduler from "./AddPostToScheduler";
import { useOverallStepStore } from "../../ZustandStores/LinkedInZustandStore";

const PostScheduler = () => {
  const { overallStep, setOverallStep } = useOverallStepStore();

  return (
    <>
      <button
        className="w-full py-2 font-semibold transition-all duration-500 border-r border-gray-300 hover:bg-triklAccentBlue hover:border-triklAccentBlue hover:text-white"
        onClick={() => {
          setOverallStep(overallStep - 1);
        }}
      >
        Back
      </button>
      {/* Date Time */}

      <div className="flex items-center justify-center w-full gap-5 pt-10 pb-5">
        <AddPostToScheduler />
      </div>
    </>
  );
};

export default PostScheduler;
