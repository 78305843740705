import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialTexts = [
  {
    id: 0,
    type: "introduction",
    title: "Curious about the latest B2B sales funnels?",
    titleActive: true,
    description: "my description is here 1",
    descriptionActive: true,
    invisible: false,
  },
  {
    id: 1,
    type: "content",
    title: "Service Providers, have you seen these trending ads?",
    titleActive: true,
    description: "my description is here 2",
    descriptionActive: true,
    invisible: false,
  },
  {
    id: 2,
    type: "content",
    title: "I dissected a top-performing B2B funnel making 24 Cr in sales.",
    titleActive: true,
    description: "my description is here 3",
    descriptionActive: true,
    invisible: false,
  },
  {
    id: 3,
    type: "content",
    title: "Want the breakdown of ads, landing pages, summits, and more?",
    titleActive: true,
    description: "my description is here 4",
    descriptionActive: true,
    invisible: false,
  },
  {
    id: 4,
    type: "content",
    title: "Comment 'B2B' for a free copy and let's connect for more insights!",
    titleActive: true,
    description: "my description is here 5",
    descriptionActive: true,
    invisible: false,
  },
];

const userMediaFilesStore = create((set) => ({
  userMediaFiles: {
    images: [],
    videos: [],
    documents: [],
  },
  setUserMediaFiles: (newState) =>
    set((state) => ({
      userMediaFiles: {
        ...state.userMediaFiles,
        ...newState,
      },
    })),
}));

const useTriggerUploadStore = create((set) => ({
  isUploadMedia: false,
  setIsUploadMedia: (topic) => set({ isUploadMedia: topic }),
}));

// Carousel settings store :

const useCarouselSettingsStore = create(
  persist(
    (set) => ({
      carouselStates: {
        backgroundIndex: 0,
        gradient: {
          color1: "#6F42C1",
          color2: "#F3F3F3",
          angle: 0,
        },
        currentPage: 0,
        loading: false,
        pdfUrl: "",
        texts: initialTexts,
        editedTexts: initialTexts,
        isCreatingPdf: false,
        isUseAsMedia: false,
        isUploadingPdf: false,
        pageNumberVisible: false,
        removeWatermark: true,
        showPreview: false,
        headshotData: {
          visible: true,
          image: "",
          imageVisible: false,
          name: "Your Name",
          nameVisible: true,
          profileLink: "linkedIn handle",
          profileLinkVisible: true,
        },
        backgroundDesign: {
          type: { label: "Template 1", value: "template1" },
          active: true,
        },
        colorPalette: {
          primary: "#6D1D54",
          secondary: "#A63E6D",
          background: "#FAF7F7",
        },
      },
      setCarouselStates: (newState) =>
        set((state) => ({
          carouselStates: {
            ...state.carouselStates,
            ...newState,
          },
        })),
    }),
    {
      name: "carousel-settings", // unique name for the localStorage key
      getStorage: () => localStorage, // specify the storage
    }
  )
);

export { userMediaFilesStore, useTriggerUploadStore, useCarouselSettingsStore };
