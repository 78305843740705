import React, { useEffect, useRef, useState } from "react";

import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../../../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../../../context/userAuthContext";
import { girlImage } from "../../../../../assets/onBoardingPage";

import axios from "axios";

// import {  } from "@ant-design/icons";
import { Input, Tag, Tooltip, Button } from "antd";

import { updateUserOrganizations } from "../../../function/LinkedInFunctions";
import { RobotOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

import { UserDataRefreshStore } from "../../../ZustandStores/LinkedInZustandStore";
import OnBoardingLoader from "../../loadingScreens/OnBoardingLoader";
import { useNavigate } from "react-router-dom";
const UserProfileDetails = () => {
  const { user } = useUserAuth();
  const { userLinkedInData, setUserLinkedInData } = useUserLinkedInStore();
  const { userMainData } = useUserMainDataStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();

  let navigate = useNavigate();
  // Update User Organization Details
  const [userOrganizationsArray, setUserOrganizationsArray] = useState([]);

  const [userFormDetails, setUserFormDetails] = useState({
    personalVoiceText: userMainData?.userVoice?.personalVoiceText || "",
    updatedOrganizations: [],
    postTopics: userLinkedInData?.postTopics || [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [profileScraperRunning, setProfileScraperRunning] = useState(false);
  // get linkedIn profile Details
  const userScrappedLinkedInDetails = async () => {
    setProfileScraperRunning(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/get-linkedIn-profile`,
        {
          userId: user?.uid,
          profileLink: userLinkedInData?.linkedInProfileUrl,
        }
      );
      // After the response redirect the user to onboarding page
      if (response?.data?.success) {
        navigate("/user/onboarding");
        setProfileScraperRunning(false);
      }
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
      setProfileScraperRunning(false);
    }
  };

  const handleUpdateProfile = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/updateUserDetails`,
        {
          userId: user?.uid,
          userDetails: userFormDetails,
        }
      );
      toast.success("Profile updated successfully");
      setIsLoading(false);
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
      setIsLoading(false);
    }
  };

  // Get User LinkedIn Profile Data
  const userLinkedInProfileData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/get-user-linkedIn`,
        {
          userId: user?.uid,
        }
      );

      setUserLinkedInData(response.data?.user);
    } catch (error) {
      // toast.error("oops! something went wrong. Please try again.");
    }
  };

  // get user Orgnaization details

  const fetchOrganizations = async () => {
    if (userLinkedInData?.organizations?.length > 0) {
      // Map each organization to a fetch promise
      const fetchOrganization = userLinkedInData.organizations.map(
        (organization) =>
          axios
            .post(
              `${process.env.REACT_APP_SERVERURL}/organization/v2/getOrganizationDetails`,
              {
                userId: user?.uid,
                organizationId: organization.organizationId,
              }
            )
            .then((response) => response.data?.orgData)
            .catch((error) => {
              toast.error("oops! something went wrong. Please try again.");
              return null;
            })
      );

      Promise.all(fetchOrganization).then((results) => {
        // Filter out any null results due to errors
        const validResults = results.filter((result) => result !== null);
        // Update state with new data appended
        setUserOrganizationsArray((prev) => [...prev, ...validResults]);
      });
    }
  };

  const handleBrandVoiceChange = (organizationId, newBrandVoice) => {
    setUserFormDetails((prev) => {
      const updatedChangedOrganizationDetails =
        prev.updatedOrganizations.filter(
          (org) => org.organizationId !== organizationId
        );
      updatedChangedOrganizationDetails.push({
        organizationId,
        organizationBrandVoice: newBrandVoice,
      });
      return {
        ...prev,
        updatedOrganizations: updatedChangedOrganizationDetails,
      };
    });
  };

  // Update the user Organizations
  const handleUserOrganizationdata = async () => {
    try {
      await updateUserOrganizations(user?.uid);
      setUserDataRefresh(!userDataRefresh);
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [userLinkedInData?.organizations]);

  // Get Organizations Details
  useEffect(() => {
    userLinkedInProfileData();
    // updateUserOrganizations(user?.uid);
    handleUserOrganizationdata();
  }, [user?.uid]);

  useEffect(() => {
    if (userMainData?.userVoice?.personalVoiceText) {
      setUserFormDetails((prevState) => ({
        ...prevState,
        personalVoiceText: userMainData.userVoice.personalVoiceText,
        postTopics: userLinkedInData?.postTopics,
      }));
    }
  }, [userMainData]);

  //  Display loader while scraping the profile data
  if (profileScraperRunning) {
    return (
      <div className="">
        <div className="absolute top-0 z-[150] right-0 w-screen h-screen">
          <OnBoardingLoader />
        </div>
      </div>
    );
  }
  //  display the user profile details
  return (
    <>
      <section className="flex flex-col md:flex-row">
        <main className="flex items-center justify-center w-full md:w-3/5 ">
          <div className="flex flex-col items-center w-full max-h-screen p-5 py-32 overflow-y-scroll ">
            {/* User Basic profile details */}
            <div className="w-3/4 ">
              <div className="flex flex-col items-center py-6 bg-white rounded-md">
                {/* <div className="flex flex-col items-center w-3/4 py-2 bg-white rounded-md"> */}
                <h1 className="flex text-xl font-bold md:text-2xl">
                  {userMainData?.name}'s Profile
                </h1>
                <p className="pt-4 pb-10 text-base md:text-lg text-midGray">
                  Below are your details, modify here and save!
                </p>

                <div className="flex flex-col items-start w-full px-4 ">
                  <label className="pb-1 font-semibold text-md">
                    User Name
                  </label>
                  <input
                    type="text"
                    placeholder="userName"
                    value={userMainData?.name}
                    disabled
                    className=" rounded-md px-2 py-1 md:px-3.5 md:py-3 w-full text-midGray border mb-4 bg-white placeholder:text-midGray "
                  />
                  <label className="pb-1 font-semibold text-md">
                    User Email
                  </label>
                  <input
                    type="text"
                    placeholder="user@email.com"
                    value={userMainData?.email}
                    disabled
                    className=" rounded-md px-2 py-1 md:px-3.5 md:py-3 w-full text-midGray border mb-4 bg-white placeholder:text-midGray "
                  />
                  <label className="pb-1 font-semibold text-md">
                    Your Personal Voice
                  </label>
                  <textarea
                    type="text"
                    placeholder="Vision for your profile"
                    value={userFormDetails.personalVoiceText}
                    onChange={(e) => {
                      setUserFormDetails({
                        ...userFormDetails,
                        personalVoiceText: e.target.value,
                      });
                    }}
                    className=" rounded-md px-3 py-2 md:px-3.5 md:py-3 w-full  border mb-4 bg-white placeholder:text-midGray "
                    rows={4}
                  />
                  {userLinkedInData?.postTopics?.length > 0 && (
                    <PostTopics
                      userFormDetails={userFormDetails}
                      setUserFormDetails={setUserFormDetails}
                    />
                  )}
                  {/* IF THE PROFILE DATA IS NOT SCRAPED THE DISPLAY THE BUTTON TO SCRAPE THE DATA */}
                  {/* {userLinkedInData?.additionalDetails?.location?.length ===
                    0 && (
                    <Button
                      icon={<RobotOutlined />}
                      type="default"
                      className="w-full mt-2 bg-white text-linkedInPeach border-linkedInPeach "
                      onClick={() => {
                        userScrappedLinkedInDetails();
                      }}
                    >
                      Get LinkedIn Details
                    </Button>
                  )} */}

                  {/* LOGIN WITH LINKEDIN BUTTON */}
                  {!userLinkedInData?.linkedInUserId?.length > 0 ? (
                    <Button
                      type="primary"
                      className="w-full my-4 font-medium bg-white border-2 "
                      onClick={() => {
                        if (user) {
                          user.getIdToken().then((idToken) => {
                            const linkedInAuthUrl = `${process.env.REACT_APP_SERVERURL}/linkedIn/auth?firebaseToken=${idToken}`;
                            window.location.href = linkedInAuthUrl;
                          });
                        }
                      }}
                    >
                      Connect Linkedin
                    </Button>
                  ) : (
                    <></>
                    // Disconnect button //
                    // <Button
                    //   type="primary"
                    //   className="w-full my-4 font-medium bg-white border-2 "
                    //   onClick={async () => {
                    //     try {
                    //       const auth = getAuth();
                    //       const user = auth.currentUser;

                    //       if (user) {
                    //         const idToken = await getIdToken(user);

                    //         const response = await axios.post(
                    //           `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/logout`,
                    //           {},
                    //           {
                    //             headers: {
                    //               Authorization: `Bearer ${idToken}`,
                    //             },
                    //             withCredentials: true,
                    //           }
                    //         );

                    //         toast.success(
                    //           "Successfully disconnected LinkedIn account"
                    //         );
                    //         window.location.reload();
                    //       }
                    //     } catch (error) {
                    //       // Handle network errors
                    //       toast.error(
                    //         "Oops! Our hamsters stopped running. Give it another go! 🐹"
                    //       );
                    //       console.error("Network error:", error);
                    //     }
                    //   }}
                    // >
                    //   Disconnect Linkedin
                    // </Button>
                  )}
                  {!userLinkedInData?.organizations?.length > 0 && (
                    <Button
                      type="default"
                      className="w-full mt-2 mb-3 font-medium bg-white border-linkedInPeach text-linkedInPeach"
                      loading={isLoading}
                      onClick={() => {
                        handleUpdateProfile();
                      }}
                    >
                      Update Account
                    </Button>
                  )}
                </div>
              </div>
              {/* User Tone and styles */}

              {/* Organization pages  */}
              {userLinkedInData?.organizations?.length > 0 && (
                <h5 className="w-full mt-5 text-lg font-semibold text-left ">
                  Your LinkedIn pages
                </h5>
              )}
              <div className="w-full mt-3 bg-white">
                {/* organizations */}
                {userLinkedInData?.organizations?.length > 0 &&
                  userLinkedInData?.organizations?.map(
                    (organization, index) => {
                      // displaying the value of brandVoice for  matching organization
                      const userOrganization = userOrganizationsArray.find(
                        (org) =>
                          String(org.organizationId) ===
                          String(organization.organizationId)
                      );

                      return (
                        <section
                          key={index}
                          className="relative flex flex-col items-start w-full p-4 mt-2 rounded-lg border- "
                        >
                          <label className="pb-1 font-semibold text-left text-md">
                            LinkedIn Page Name
                          </label>
                          <input
                            readOnly
                            type="text"
                            placeholder="Enter Your LinkedIn's Page Name"
                            className="rounded-md px-2 py-0.5  md:px-3.5 md:py-2 w-full text-midGray border mb-4 border-midGray read-only:border-midGray"
                            value={organization.organizationName}
                          />
                          <label className="pb-1 font-semibold text-left text-md">
                            Your Role
                          </label>
                          <input
                            readOnly
                            type="text"
                            className="rounded-md px-3.5 py-2 w-full mb-4 text-midGray border border-midGray"
                            placeholder="Your Role"
                            value={organization.userRole}
                          />
                          <label className="pb-1 font-semibold text-left text-md">
                            Brand Voice
                          </label>

                          <textarea
                            type="text"
                            placeholder="Brand Voice"
                            className="rounded-md h-10 md:h-20 px-2 py-0.5  md:px-3.5 md:py-2  text-gray-900 border border-midGray w-full mb-4 "
                            defaultValue={
                              userOrganization
                                ? userOrganization.organizationBrandVoice
                                : ""
                            }
                            onChange={(e) =>
                              handleBrandVoiceChange(
                                organization.organizationId,
                                e.target.value
                              )
                            }
                          />
                        </section>
                      );
                    }
                  )}
                {userLinkedInData?.organizations?.length > 0 && (
                  <div className="p-3 ">
                    <Button
                      type="default"
                      className="w-full mt-2 mb-3 font-medium bg-white border-linkedInPeach text-linkedInPeach"
                      // className="w-full p-3 mt-2 mb-6 font-medium bg-white border-linkedInPeach text-linkedInPeach"
                      loading={isLoading}
                      size="large"
                      onClick={() => {
                        handleUpdateProfile();
                      }}
                    >
                      Update Account
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>

        <aside className="flex justify-end flex-grow h-screen">
          <img src={girlImage} alt="girlImage" className="object-cover " />
        </aside>
      </section>
    </>
  );
};

export default UserProfileDetails;

const PostTopics = ({ userFormDetails, setUserFormDetails }) => {
  const [postTopics, setPostTopics] = useState(
    userFormDetails?.postTopics || []
  );
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  // Effect to update postTopics when userFormDetails changes
  useEffect(() => {
    setPostTopics(userFormDetails?.postTopics || []);
  }, [userFormDetails]);

  const handleClose = (removedTopic) => {
    const newTopics = postTopics.filter((topic) => topic !== removedTopic);
    setPostTopics(newTopics);
    setUserFormDetails((prev) => ({ ...prev, postTopics: newTopics }));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !postTopics.includes(inputValue)) {
      const newTopics = [...postTopics, inputValue];
      setPostTopics(newTopics);
      setUserFormDetails((prev) => ({ ...prev, postTopics: newTopics }));
    }
    setInputVisible(false);
    setInputValue("");
  };

  const tagPlusStyle = {
    height: 22,
    background: "#f0f0f0",
    borderStyle: "dashed",
  };

  return (
    <div className="flex flex-col items-start justify-start w-full text-left ">
      <label className="pb-3 font-semibold text-left text-md">
        Post Tones you might like
      </label>
      <div className="flex flex-wrap items-center w-full">
        {postTopics.map((topic, index) => (
          <Tag
            key={index}
            closable
            onClose={() => handleClose(topic)}
            className="p-2 mb-2 mr-2 text-[14px] border-triklAccentBlue"
            style={{ userSelect: "none" }}
          >
            {topic.length > 20 ? (
              <Tooltip title={topic}>
                <span>{`${topic.slice(0, 20)}...`}</span>
              </Tooltip>
            ) : (
              <span>{topic}</span>
            )}
          </Tag>
        ))}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            className="p-2 mb-2 mr-2 text-md "
            style={{
              width: 78,
              // height: 22,
              marginInlineEnd: 8,
              verticalAlign: "top",
            }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag
            style={tagPlusStyle}
            icon={<PlusOutlined />}
            className="mb-2 mr-2 cursor-pointer text-triklAccentBlue text-md "
            onClick={showInput}
          >
            New Tone
          </Tag>
        )}
      </div>
    </div>
  );
};
