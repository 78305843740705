import { GoogleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserAuth } from "../../../../context/userAuthContext";

const GoogleLogin = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  let navigate = useNavigate();
  const { googleSignIn, logIn } = useUserAuth();

  // Google login
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn()
        .then(async (res) => {
          setIsLoading(true);

          await axios
            .post(
              `${process.env.REACT_APP_SERVERURL}/userMain/create-user`,
              {
                name: res.user?.displayName,
                email: res.user.email,
                userId: res.user.uid,
              },
              {
                withCredentials: true,
              }
            )
            .then((res) => {
              setIsLoading(false);

              toast.success("Logged in successfully");
              navigate("/user/onboarding");
            });
        })
        .catch((err) => {
          toast.error(
            "Oops! Our hamsters stopped running. Give it another go! 🐹"
          );
        });
    } catch (error) {
      alert(` ${error}`);
    }
  };

  return (
    <button
      // icon={}
      onClick={handleGoogleSignIn}
      className="flex items-center justify-center w-full gap-3 py-2 font-semibold transition-all duration-300 ease-in-out border rounded-md hover:bg-transparent border-tNewButtonsAndActiveElement hover:text-tNewButtonsAndActiveElement bg-tNewButtonsAndActiveElement text-tNewBackgroundOrInactive"
    >
      <GoogleOutlined className="text-xl" /> Continue With Google
    </button>
  );
};

export default GoogleLogin;
