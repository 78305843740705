import { Disclosure } from "@headlessui/react";
import randomColor from "randomcolor";
import React from "react";
import { BiChevronDown } from "react-icons/bi";

const TopWordsInPostSide = ({ topWordArray }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center justify-center w-full gap-3 py-4 font-semibold rounded-md bg-lightGrey hover:bg-[#8beaff] hover:shadow-md hover:shadow-triklBlueDark/20">
            <span>Most discussed topics in your group</span>
            <BiChevronDown
              className={`text-3xl text-triklBlueDark/80 ${
                open ? "rotate-180 transform" : ""
              }`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
            <div className="flex flex-wrap gap-3 py-5 text-sm">
              {/* top words array */}
              {topWordArray.map((eachword, key) => {
                return (
                  <div
                    className="box-border flex items-center gap-1 px-3 py-1 text-black rounded-lg border-triklBlueDark/50"
                    key={key}
                    style={{
                      background: randomColor({
                        luminosity: "bright",
                        format: "rgba",
                        alpha: "0.1",
                      }),
                    }}
                  >
                    <span>{eachword.text}</span>
                    <span className="text-xs text-gray-500">
                      ({eachword.value})
                    </span>
                  </div>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TopWordsInPostSide;
