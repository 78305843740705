import React, { useEffect, useState } from "react";
import { BglightCircle } from "../../../../assets/background";
import { Button, Checkbox, Col, ConfigProvider, Form, Input, Row } from "antd";
import { triklPurple } from "../../../../assets/Trikl_logo";
import { Link, NavLink, useNavigate } from "react-router-dom";

import GoogleLogin from "./GoogleLogin";
import { useUserAuth } from "../../../../context/userAuthContext";
import { toast } from "react-toastify";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const { user, signIn } = useUserAuth();
  let navigate = useNavigate();

  // firebase user sign in function
  const handleEmailSignIn = async (values) => {
    const { email, password } = values;

    if (email === "" || password === "") {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      setIsLoading(true);
      await signIn(email, password);

      // navigate to onboarding or dashboard page
      navigate("/linkedin/content-creation");
    } catch (error) {
      if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/invalid-login-credentials" ||
        error.code === "auth/user-not-found"
      ) {
        toast.error("Invalid user credentials");
      } else {
        toast.error(` ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.uid) {
      navigate("/linkedin/content-creation");
    }
  }, [user]);

  return (
    <div className="h-full bg-white bg-top bg-no-repeat">
      <div className="">
        <div className="absolute flex justify-center">
          <img className="" src={BglightCircle} alt="" />
        </div>
        <Row justify="center">
          <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
            <div className="flex justify-center mt-6">
              <img src={triklPurple} alt="Trikl" className="w-28 h-28" />
            </div>
            <div className="bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
              <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
                <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
                  Sign In
                </h2>
              </div>
              <div className="px-10 pt-8 pb-6">
                <Form
                  name="login"
                  layout="vertical"
                  form={form}
                  onFinish={handleEmailSignIn}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                        type: "email",
                      },
                    ]}
                    label="Email Address"
                    className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                  >
                    <Input placeholder="name@example.com" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                  <div className="flex flex-wrap items-center justify-between gap-[10px]">
                    {/* <Checkbox
                        // onChange={onChange}
                        // checked={state.checked}
                        className="text-xs text-light dark:text-white60"
                      >
                        Keep me logged in
                      </Checkbox> */}
                    <NavLink
                      className=" text-triklAccentBlue hover:text-triklAccentBlue/50 text-13"
                      to="/user/forgotPassword"
                    >
                      Forgot password?
                    </NavLink>
                  </div>
                  <Form.Item>
                    <button
                      // className="w-full h-12 p-0 my-6 text-sm font-medium"
                      className="flex items-center justify-center w-full gap-3 py-2 transition-all duration-300 ease-in-out bg-transparent border rounded-md border-tNewButtonsAndActiveElement text-tNewButtonsAndActiveElement hover:bg-tNewButtonsAndActiveElement hover:text-tNewBackgroundOrInactive"
                      htmltype="submit"
                      type="primary"
                      size="large"
                      loading={isLoading}
                    >
                      {isLoading ? "Loading..." : "Sign In"}
                    </button>
                  </Form.Item>

                  <p className="mb-4">
                    <span className="">or</span>
                  </p>

                  <GoogleLogin />

                  {/* <Button icon =  */}
                  {/* <Button
                      icon={<GoogleOutlined />}
                      // type="primary"
                      className="bg-lightGrey"
                    >
                      Sign In With Google
                    </Button> */}

                  {/* <ul className="flex items-center justify-center mb-0">
                <li className="px-1.5 pt-3 pb-2.5">
                  <Link
                    to="#"
                    className="flex items-center justify-center h-12 px-4 rounded-md google-social group bg-google-plus-transparent hover:bg-google-plus text-google-plus hover:text-white"
                  >
                    <ReactSVG
                      className="[&>div>svg>path]:fill-google-plus group-hover:[&>div>svg>path]:fill-white"
                      src={require(`../../../../static/img/icon/google-plus.svg`).default}
                    />
                  </Link>
                </li>
                <li className="px-1.5 pt-3 pb-2.5">
                  <Link
                    to="#"
                    className="flex items-center justify-center h-12 px-4 rounded-md facebook-social bg-facebook-transparent hover:bg-facebook text-facebook hover:text-white"
                  >
                    <UilFacebook />
                  </Link>
                </li>
                <li className="px-1.5 pt-3 pb-2.5">
                  <Link
                    to="#"
                    className="flex items-center justify-center h-12 px-4 rounded-md twitter-social bg-twitter-transparent hover:bg-twitter text-twitter hover:text-white"
                  >
                    <UilTwitter />
                  </Link>
                </li>
                <li className="px-1.5 pt-3 pb-2.5">
                  <Link
                    to="#"
                    className="flex items-center justify-center h-12 px-4 rounded-md github-social bg-github-transparent hover:bg-github text-github hover:text-white"
                  >
                    <UilGithub />
                  </Link>
                </li>
              </ul> */}

                  {/* <div className="flex flex-wrap justify-center">
                <Link
                  to="#"
                  className="inline-flex items-center bg-gray-100 dark:bg-white10 text-gray-500 dark:text-white87 h-12 px-6 m-1.5 font-medium rounded-md"
                  onClick={() => lock.show()}
                >
                  Sign In with Auth0
                </Link>
              </div> */}
                </Form>
              </div>
              <div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
                <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                  Don`t have an account? &nbsp;
                  <Link
                    to="/user/signup"
                    className="ltr:ml-1.5 rtl:mr-1.5 text-info hover:text-triklAccentBlue"
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignIn;
