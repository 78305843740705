import React, { useState } from "react";
import axios from "axios";
import { logoWithText } from "../../../assets/Trikl_logo";
import { linkedinImage } from "../../../assets/profileAnalysis";
import { Spin } from "antd";

const ProfileApp = () => {
  // states to handle toggles:
  const [isloading, setIsloading] = useState(false);
  const [change, setChange] = useState(true);
  const [profileData, setProfileData] = useState({});

  // state to handle file:
  const [file, setFile] = useState(null);

  // Funtion to upload file:
  const handleUploadToAssistant = async () => {
    setIsloading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assistantId", "asst_EqJ3suvvIacHODINqAb9Uth7");

    try {
      const userProfileData = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/create-thread`,
        formData
      );

      // Converting string response to json
      const jsonsStringResponse = userProfileData?.data?.formatedResponse;

      const jsonString = jsonsStringResponse
        .replace(/^```json\s*/, "") // Remove the initial ```json and any space/newline
        .replace(/```[\s\S]*$/, ""); // Remove the trailing ``` and any space/newline

      // conveting to json object
      const readableJson = JSON.parse(jsonString);

      setProfileData(readableJson);
      setIsloading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsloading(false);
    }
  };

  console.log("ProfileData  >>>>>", profileData);

  return (
    <>
      <div className="h-screen mt-3 overflow-y-scroll text-left bg-gray-100">
        <div className="container min-h-screen p-4 pb-32 mx-auto text-gray-800 bg-gray-100">
          <header className="flex flex-col items-center justify-around gap-4 p-10 my-4 overflow-hidden text-3xl font-semibold text-center bg-white shadow-lg md:flex-row rounded-xl">
            <div className="text-5xl font-bold text-triklBlueDark">
              <img src={logoWithText} className="h-12 w-max" alt="TRIKL Logo" />
            </div>
            <div className="text-xl text-triklAccentBlue md:text-3xl">
              LinkedIn Profile Analyzer
            </div>
          </header>

          {change ? (
            <div className="flex items-center justify-center pt-32 ">
              <div className="flex flex-col items-center justify-center w-full gap-10 p-16 bg-white shadow-md md:w-2/3 rounded-xl">
                <label className="flex flex-col items-center w-full">
                  <input
                    id="fileInput"
                    type="file"
                    accept=".pdf, .txt"
                    className="hidden"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                  <span className="w-full px-4 py-2 text-center rounded cursor-pointer">
                    {file?.name}
                  </span>
                  {!file && (
                    <span className="w-full px-4 py-2 text-center text-white bg-gray-500 rounded cursor-pointer">
                      Upload file
                    </span>
                  )}
                </label>

                {file && (
                  <button
                    onClick={() => {
                      setChange(false);
                      setIsloading(true);
                      handleUploadToAssistant();
                    }}
                    className="w-full px-4 py-2 text-center text-white rounded cursor-pointer bg-triklAccentBlue"
                  >
                    Upload
                  </button>
                )}

                <img src={linkedinImage} className="" alt="Linkedin " />
              </div>
            </div>
          ) : isloading ? (
            <div className="flex flex-col items-center justify-center pt-20 pr-4">
              <div className="flex items-center justify-center w-full py-10">
                <Spin size="large" />
              </div>
              <div className="mb-5 -mt-6 text-xl">
                Our AI is analyzing your profile by comparing it with others on
                LinkedIn... please wait!
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <ProfileCard data={profileData?.basic_info} />
              <OverallProfileScore
                score={profileData?.recommendations?.score}
                recommendation={profileData?.recommendations?.overall}
              />

              <Summary {...profileData?.summary} />
              <TopSkills top_skills={profileData?.skills} />

              <Education
                institutions={profileData?.education?.institutions}
                score={profileData?.education?.score}
                comments={profileData?.education?.comments}
                recommendations={profileData?.education?.recommendations}
              />
              {/* <Certifications
                certifications={profileData?.skills?.certifications}
                score={profileData?.skills?.score}
                comments={profileData?.skills?.comments}
                recommendations={profileData?.skills?.recommendations}
              /> */}

              <Experience
                mostRecent={profileData?.experience?.most_recent}
                previousRoles={profileData?.experience?.previous_roles}
                score={profileData?.experience?.score}
                comments={profileData?.experience?.comments}
                recommendations={profileData?.experience?.recommendations}
              />
            </div>
          )}

          {/* Loom video embedding  */}
          <section className="items-center w-full py-20">
            <h2 className="text-5xl font-semibold text-center">
              Watch This 2 Minute Explainer &#128071;
            </h2>

            <div className="relative items-center pb-[64.90384615384616%] bg-red-100 m-20">
              <iframe
                src="https://www.loom.com/embed/511f9e8fb3c74397a732a66b0ffb3d48?sid=9f342336-b157-41ff-b106-ef67d6ea1889"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                className="absolute w-full h-full"
              ></iframe>
            </div>
          </section>

          <section className="flex flex-col items-center justify-center w-full font-semibold">
            <div className="pb-5 text-4xl text-center">
              Want to schedule{" "}
              <span className="text-triklAccentBlue">
                7 LinkedIn Posts in 30 minutes
              </span>
              ?
            </div>
            <a
              href="https://app.trikl.ai/"
              target="_blank"
              className="p-8 text-xl text-white transition-all duration-200 ease-in-out rounded-full cursor-pointer bg-triklAccentBlue"
            >
              Yes! Show Me How
            </a>
          </section>
        </div>
        <footer className="w-full pb-1 font-semibold text-center">
          © TRIKL 2023
        </footer>
      </div>
    </>
  );
};

export default ProfileApp;

const ProfileCard = ({ data }) => {
  return (
    <div className="p-4 bg-white rounded-md shadow-lg">
      <h2 className="mb-2 text-3xl font-bold text-triklAccentBlue">
        {data?.name}
      </h2>
      <p className="mt-1 font-medium leading-tight text-gray-900 text-md">
        {data?.headline}
      </p>
      <p className="mt-2 text-sm text-gray-600">{data?.current_role}</p>
      <p className="mt-2 text-sm text-gray-600">{data?.location}</p>
    </div>
  );
};

const OverallProfileScore = ({ score, recommendation }) => (
  <div className="col-span-1 p-4 bg-white rounded-md shadow-lg md:col-span-2">
    <h2 className="mb-2 text-2xl font-bold">Overall Profile Score</h2>
    <ScoreDisplay score={score} />

    <SubHeader param={"Overall Recommendation:"} />
    <p className="text-triklBlueDark text-md">{recommendation}</p>
  </div>
);

const Summary = ({
  content,
  personality,
  score,
  comments,
  recommendations,
}) => (
  <div className="p-4 bg-white rounded-md shadow-lg">
    <h2 className="mb-2 text-2xl font-bold">Summary</h2>
    <ScoreDisplay score={score} />

    <SubHeader param={"Your Summary:"} />
    <p className="mb-3 text-gray-700 text-md">{content}</p>

    <SubHeader param={"Personality:"} />
    <p className="text-sm text-gray-600">{personality}</p>

    <SubHeader param={"Evaluation:"} />
    <p className="text-sm text-gray-600">{comments}</p>

    <SubHeader param={"Recommendation:"} />
    <p className="text-sm text-gray-600">{recommendations}</p>
  </div>
);

const TopSkills = ({ top_skills }) => {
  const { score, comments, recommendations, languages } = top_skills;

  return (
    <div className="p-4 bg-white rounded-md shadow-lg">
      <h2 className="mb-2 text-2xl font-bold">Top Skills</h2>
      {score && <ScoreDisplay score={score} />}

      <ul className="list-disc list-inside">
        {top_skills?.top_skills?.map((skill) => (
          <li key={skill} className="text-gray-700 text-md">
            {skill}
          </li>
        ))}
      </ul>

      <h2 className="pt-4 mb-2 text-base font-bold">Languages</h2>
      <ul className="list-disc list-inside">
        {languages?.map((language) => (
          <li key={language} className="text-gray-700 text-md">
            {language}
          </li>
        ))}
      </ul>

      <SubHeader param={"Evaluation:"} />
      {comments && <p className="text-sm text-gray-600">{comments}</p>}

      <SubHeader param={"Recommendation:"} />
      {recommendations && (
        <p className="text-sm text-gray-600">{recommendations}</p>
      )}
    </div>
  );
};

const Certifications = ({
  certifications,
  score,
  comments,
  recommendations,
}) => (
  <div className="p-4 bg-white rounded-md shadow-lg">
    <h2 className="mb-2 text-2xl font-bold">Certifications</h2>
    <ScoreDisplay score={score} />

    <ul className="list-disc list-inside">
      {certifications?.map((certification, index) => (
        <li key={index} className="text-gray-700 text-md">
          {certification}
        </li>
      ))}
    </ul>

    {/* <SubHeader param={"Evaluation:"} />
    <p className="text-sm text-gray-600">{comments}</p>

    <SubHeader param={"Recommendation:"} />
    <p className="text-sm text-gray-600">{recommendations}</p> */}
  </div>
);

const Experience = ({
  mostRecent,
  previousRoles,
  score,
  comments,
  recommendations,
}) => (
  <div className="col-span-1 p-4 bg-white rounded-md shadow-lg md:col-span-2">
    <h2 className="mb-2 text-2xl font-bold">Experience</h2>
    <ScoreDisplay score={score} />

    <div>
      <h3 className="text-lg font-semibold">Most Recent</h3>
      <p className="text-gray-700 text-md">{`${mostRecent?.title} at ${mostRecent?.company} (${mostRecent?.duration})`}</p>
      <p className="text-sm text-gray-600">{mostRecent?.description}</p>
    </div>
    <div>
      <h3 className="mt-4 text-lg font-semibold">Previous Roles</h3>

      <ul className="list-disc list-inside">
        {previousRoles?.title?.map((title, index) => (
          <li key={title} className="text-gray-700 text-md">
            {`${title} at ${previousRoles?.company[index]} (${previousRoles?.duration[index]})`}
          </li>
        ))}
      </ul>
    </div>

    <SubHeader param={"Evaluation:"} />
    <p className="text-sm text-gray-600">{comments}</p>

    <SubHeader param={"Recommendation:"} />
    <p className="text-sm text-gray-600">{recommendations}</p>
  </div>
);

const Education = ({ institutions, score, comments, recommendations }) => (
  <div className="p-4 bg-white rounded-md shadow-lg">
    <h2 className="mb-2 text-2xl font-bold">Education</h2>
    <ScoreDisplay score={score} />
    <ul className="list-disc list-inside">
      {institutions?.map((institution) => (
        <li key={institution?.name} className="text-gray-700 text-md">
          {institution?.name} - {institution?.degree}
        </li>
      ))}
    </ul>

    <SubHeader param={"Evaluation:"} />
    <p className="text-sm text-gray-600">{comments}</p>

    <SubHeader param={"Recommendation:"} />
    <p className="text-sm text-gray-600">{recommendations}</p>
  </div>
);

const ScoreDisplay = ({ score }) => (
  <div className="flex items-center pb-4 mb-4 border-b border-gray-300">
    {[...Array(5)].map((_, i) => (
      <span
        key={i}
        className={`text-lg ${i < score ? "text-yellow-400" : "text-gray-300"}`}
      >
        ★
      </span>
    ))}
    <span className="ml-2 text-3xl font-semibold text-gray-600">
      <span
        className={`${score && score > 3 ? "text-green-600" : "text-red-500"}`}
      >
        {score}
      </span>

      <span className="text-lg"> / 5</span>
    </span>
  </div>
);

const SubHeader = ({ param }) => {
  return <div className="pt-3 pb-1 font-semibold text-black">{param}</div>;
};
