import React from "react";
import { Route } from "react-router-dom";
import OnBoardingForm from "../authentication/OnBoardingForm";
import ProtectedRoute from "../../authentication/ProtectedRoute";
import SignIn from "../components/userAuthentication/SignIn";
import SignUp from "../components/userAuthentication/SignUp";
import ForgotPassword from "../components/userAuthentication/ForgotPassword";

import UserProfileDetails from "../components/v2Components/userProfile/UserProfileDetails";
import CanvasNavbar from "../components/CanvasNavbar";
import LogOut from "../components/userAuthentication/LogOut";
import ResetPassword from "../components/userAuthentication/ResetPassword";
import EmailVerification from "../components/userAuthentication/EmailVerification";

const UserRoutes = () => {
  return (
    <>
      {/* userLogin */}
      <Route path="/user/signin" element={<SignIn />} />
      <Route path="/user/signup" element={<SignUp />} />
      <Route path="/user/forgotPassword" element={<ForgotPassword />} />
      <Route path="/user/verify/callback" element={<EmailVerification />} />
      <Route path="/user/passwordReset" element={<ResetPassword />} />
      <Route
        path="/user/logout"
        element={
          <ProtectedRoute>
            <LogOut />
          </ProtectedRoute>
        }
      />

      {/* onboarding form */}
      <Route
        path="/user/onboarding"
        element={
          <ProtectedRoute>
            <OnBoardingForm />
          </ProtectedRoute>
        }
      />

      <Route
        path="/user/profile"
        element={
          <ProtectedRoute>
            <CanvasNavbar>
              <UserProfileDetails />
            </CanvasNavbar>
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default UserRoutes;
