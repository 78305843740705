//// Latest One

import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from "@mui/material";
import axios from "axios";
import {
  UserDataRefreshStore,
  useRefreshSchedulerStore,
  useShowEditComponentStore,
} from "../../ZustandStores/LinkedInZustandStore";
import EditPostInScheduler from "./EditPostInScheduler";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { LoadingSpinnerLarge } from "../../../components/widgets/LoadingSpinner";
import { updateUserOrganizations } from "../../function/LinkedInFunctions";
import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";
const localizer = momentLocalizer(moment);

const Scheduler = () => {
  return (
    <div className="container flex flex-col w-full h-auto max-h-screen min-h-screen px-10 pt-20 pb-48 mx-auto overflow-y-scroll">
      <header className="pb-4 text-xl font-semibold text-black border-b border-gray-300 font-spaceGrotesk">
        Your Posts
      </header>
      <section className="flex flex-col items-center justify-center">
        <CalForScheduledPosts />
      </section>
    </div>
  );
};

const CustomEvent = ({ event }) => (
  <Tooltip
    title={
      <>
        <div className="font-semibold">{event.title}</div>
        <sub className="py-2 text-xs">
          {moment(event.start).format("D MMM 'YY / h:mm a")}
        </sub>
      </>
    }
  >
    <header>{event.title}</header>
  </Tooltip>
);

const CalForScheduledPosts = () => {
  // Context
  const { userData } = useLinkedInUserContext();
  //Zustand
  const { showEditComponent, setShowEditComponent } =
    useShowEditComponentStore();
  const { refreshScheduler } = useRefreshSchedulerStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userMainData } = useUserMainDataStore();
  // LOCAL STATE MANAGEMENT
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({ title: "" });
  const [isSchedulerLoading, setIsSchedulerLoading] = useState(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowEditComponent(!showEditComponent);
  };
  useEffect(() => {
    fetchCalenderData();
  }, [refreshScheduler, userMainData?.userId]);

  //  Get the scheduled posts for the user
  const fetchCalenderData = async () => {
    setIsSchedulerLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/get-cal-posts?userId=${userMainData?.userId}`
      );
      if (response?.data?.calPosts?.length > 0) {
        setScheduledPosts(response.data.calPosts);
      }
      setIsSchedulerLoading(false);
    } catch (err) {
      console.error("Error fetching scheduled posts:", err);
      setIsSchedulerLoading(false);
    }
  };

  // Update user organizations
  const fetchOrganization = async () => {
    await updateUserOrganizations(userData?.userId);
    setUserDataRefresh(!userDataRefresh);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <div className="w-full">
      {isSchedulerLoading ? (
        <>
          <LoadingSpinnerLarge />
        </>
      ) : (
        <>
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={scheduledPosts}
            style={{ height: "90vh", paddingBottom: "4rem" }}
            components={{ event: CustomEvent }}
            onSelectEvent={handleEventClick}
            messages={{ agenda: "List" }}
            // views={["month", "week", "agenda"]}
            views={["month", "agenda"]}
            dayPropGetter={(date) => {
              const backgroundColor =
                date.getDate() === new Date().getDate() ? "#F5EAFA" : "inherit";
              const style = {
                backgroundColor,
              };
              return {
                className:
                  date.getDate() === new Date().getDate() ? "rbc-today" : "",
                style,
              };
            }}
            eventPropGetter={(event) => {
              let backgroundColor;

              switch (event.state) {
                case "Posted":
                  backgroundColor = "#179E17";
                  break;
                case "Scheduled":
                  backgroundColor = "#6F42C1";
                  break;
                case "Error":
                  backgroundColor = "#FF0000";
                  break;
                default:
                  backgroundColor = "#0066FF";
              }

              // const backgroundColor =
              //   event.state === "Posted"
              //     ? "#179E17"
              //     : event.state === "Scheduled"
              //     ? "#6F42C1"
              //     : "#0066FF";
              //     event.state === "Error"
              //     ? "#FF0000"

              const style = {
                backgroundColor,
              };
              return {
                style,
              };
            }}
          />
          <div className="flex flex-col items-center justify-between">
            {showEditComponent && (
              <EditPostInScheduler
                selectedEvent={selectedEvent}
                setScheduledPosts={setScheduledPosts}
                scheduledPosts={scheduledPosts}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Scheduler;
