import React, { useState, useEffect } from "react";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const [file, setFile] = useState();
  const fetchAndConvertPDF = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = await blobToURL(blob);
      setFile(url);
    } catch (error) {
      console.error("Error fetching and converting PDF file: ", error);
    }
  };
  useEffect(() => {
    fetchAndConvertPDF(fileUrl);
    return () => {
      setFile(null);
    };
  }, [fileUrl]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  function blobToURL(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }
  // Handlers to change page
  const goToPrevPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };
  const goToNextPage = () => {
    setCurrentPage(currentPage < numPages ? currentPage + 1 : numPages);
  };
  return file ? (
    <Document
      as="div"
      className="relative"
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <section className="relative">
        <button
          onClick={goToPrevPage}
          className="absolute left-0 z-50 px-2 ml-1 text-lg text-transparent text-white transition-all duration-300 ease-in-out -translate-y-1/2 rounded-full aspect-square stroke-black top-1/2 hover:bg-black/50 bg-black/5"
        >
          <MdOutlineNavigateBefore />
        </button>

        <Page width={510} pageNumber={currentPage} renderTextLayer={false} />

        <button
          onClick={goToNextPage}
          className="absolute right-0 z-50 px-2 mr-1 text-lg text-transparent text-white transition-all duration-300 ease-in-out -translate-y-1/2 rounded-full aspect-square stroke-black top-1/2 hover:bg-black/50 bg-black/5"
        >
          <MdOutlineNavigateNext />
        </button>
      </section>

      <div className="w-full px-2 py-1 text-center text-transparent text-white transition-all duration-300 ease-in-out stroke-black bg-black/70">
        {currentPage}
      </div>
    </Document>
  ) : (
    <p className="flex items-center justify-center gap-2">Loading PDF...</p>
  );
};
export default PdfViewer;
