import {
  Button,
  Card,
  Checkbox,
  ColorPicker,
  Form,
  Input,
  Menu,
  Segmented,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import "../../../../linkedIn/linkedin.css";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useUserAuth } from "../../../../../context/userAuthContext";
import { toast } from "react-toastify";

const CarouselCustomizationArea = () => {
  const items = [
    { label: "Content", key: "0" },
    { label: "Filters", key: "1" },
    { label: "Theme", key: "2" },
    { label: "Preview", key: "3" },
  ];
  const { user } = useUserAuth();
  const [selectedMenuItem, setSelectedMenuItem] = useState(items[0].key);
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  //Function to select the template as media
  // const handleSelectTemplate = async () => {};

  //  Menu click handler
  const onMenuClick = ({ key }) => {
    setSelectedMenuItem(key);

    // For Showing the preview section
    if (key == 3) {
      setCarouselStates({
        ...carouselStates,
        showPreview: true,
      });
    }
  };

  const handleCarouselDownload = () => {
    setCarouselStates({
      ...carouselStates,
      isCreatingPdf: true,
    });

    setSelectedMenuItem(items[0].key);
  };

  const handleCarouselFinalize = () => {
    if (!user?.uid) {
      toast.error("Please Login to save it in your media");
      // setCreatingCarousel(false);
      return;
    } else {
      setCarouselStates({
        ...carouselStates,
        isUploadingPdf: true,
      });

      setSelectedMenuItem(items[0].key);
    }
  };

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      // bodyStyle={{ padding: 0, paddingBottom: "80px" }}
      className="relative w-full text-left bg-white"
    >
      <Menu
        className="font-semibold rounded-md text-triklAccentBlue"
        onClick={onMenuClick}
        selectedKeys={[selectedMenuItem]}
        mode="horizontal"
        items={items}
      />

      {/* Options after selected menu item */}
      <div className="min-h-full px-5 mt-5">
        {selectedMenuItem == 0 && <SlideContentEdit />}
        {selectedMenuItem == 1 && <CarouselFilters />}
        {selectedMenuItem == 2 && <CarouselTheme />}
      </div>

      {/* Download and select for Post Buttons */}
      <div
        // className="absolute bottom-0 left-0 z-50 flex items-center w-full gap-4 p-4 bg-white rounded-md"
        className="flex items-center w-full gap-4 p-4 bg-white rounded-md"
      >
        <Button
          type="primary"
          size="large"
          block
          onClick={handleCarouselFinalize}
        >
          Select & Continue To Post
        </Button>
        <Button size="large" onClick={handleCarouselDownload}>
          <DownloadOutlined />
        </Button>
      </div>
    </Card>
  );
};

export default CarouselCustomizationArea;
// Changing the content of the carousel slides
const SlideContentEdit = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    const updatedTexts = [...carouselStates.editedTexts];
    updatedTexts[carouselStates.currentPage].title = newTitle;
    setCarouselStates({ ...carouselStates, editedTexts: updatedTexts });
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    const updatedTexts = [...carouselStates.editedTexts];
    updatedTexts[carouselStates.currentPage].description = newDescription;
    setCarouselStates({ ...carouselStates, editedTexts: updatedTexts });
  };

  const toggleActiveStatus = (key, value) => {
    const updatedTexts = [...carouselStates.editedTexts];
    updatedTexts[carouselStates.currentPage][key] = value;
    setCarouselStates({ ...carouselStates, editedTexts: updatedTexts });
  };

  const currentTitle =
    carouselStates.editedTexts[carouselStates?.currentPage]?.title || "";
  const currentDescription =
    carouselStates.editedTexts[carouselStates?.currentPage]?.description || "";
  const titleActive =
    carouselStates.editedTexts[carouselStates?.currentPage]?.titleActive ||
    false;
  const descriptionActive =
    carouselStates.editedTexts[carouselStates?.currentPage]
      ?.descriptionActive || false;

  return (
    <div className="flex flex-col gap-2">
      <h4 className="text-2xl font-semibold"> Edit Content</h4>
      <Form layout="vertical">
        {/* Title */}
        <div className="flex items-center justify-between my-1">
          <p>Title</p>
          <Switch
            size="small"
            checked={titleActive}
            className="bg-gray-300 active:bg-triklAccentBlue"
            onChange={(checked) => toggleActiveStatus("titleActive", checked)}
          />
        </div>
        <Input
          size="large"
          placeholder="Title"
          value={currentTitle}
          onChange={handleTitleChange}
        />

        {/* Description */}
        <div className="flex items-center justify-between my-1 mt-4">
          <p>Subtitle</p>
          <Switch
            className="bg-gray-300"
            size="small"
            style={{
              backgroundColor: descriptionActive
                ? "bg-triklAccentBlue"
                : "bg-gray-500",
            }}
            checked={descriptionActive}
            onChange={(checked) =>
              toggleActiveStatus("descriptionActive", checked)
            }
          />
        </div>
        <Input
          size="large"
          value={currentDescription}
          onChange={handleDescriptionChange}
          placeholder="Description"
        />
      </Form>
    </div>
  );
};

// To change the headshot
const CarouselFilters = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();
  const handleHeadshotChange = (e) => {
    const newHeadshot = e.target.value;
    setCarouselStates({
      ...carouselStates,
      headshotData: {
        ...carouselStates.headshotData,
        image: newHeadshot,
      },
    });
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setCarouselStates({
      ...carouselStates,
      headshotData: {
        ...carouselStates.headshotData,
        name: newName,
      },
    });
  };

  const handleProfileLinkChange = (e) => {
    const newProfileLink = e.target.value;
    setCarouselStates({
      ...carouselStates,
      headshotData: {
        ...carouselStates.headshotData,
        profileLink: newProfileLink,
      },
    });
  };

  // Change the visibility of the headshot elements
  const handleVisibilityChange = (key, value) => {
    setCarouselStates({
      ...carouselStates,
      headshotData: {
        ...carouselStates.headshotData,
        [key]: value,
      },
    });
  };

  const handlePageNumberVisibility = (key, value) => {
    setCarouselStates({
      ...carouselStates,
      [key]: value,
    });
  };

  //For headshot upload
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = (info) => {
    const file = info.file.originFileObj;
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageUrl(e.target.result);
      setCarouselStates({
        ...carouselStates,
        headshotData: {
          ...carouselStates.headshotData,
          image: imageUrl,
        },
      });
      // carouselStates?.headshotData?.image;
    };

    reader.readAsDataURL(file);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");

      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      toast.error("Image must smaller than 2MB!");

      return Upload.LIST_IGNORE;
    }
    return true;
  };

  return (
    <div>
      <h4 className="text-2xl font-semibold">Settings</h4>
      <Form layout="vertical">
        {/* Name */}
        <div className="flex items-center justify-between my-1 mt-3">
          <p>Name</p>
          <Switch
            className="bg-gray-300"
            size="small"
            checked={carouselStates.headshotData.nameVisible}
            onChange={(checked) =>
              handleVisibilityChange("nameVisible", checked)
            }
          />
        </div>
        <Input
          placeholder="Name"
          size="middle"
          value={carouselStates.headshotData.name}
          onChange={handleNameChange}
        />

        {/* LinkedIn handle */}
        <div className="flex items-center justify-between my-1 mt-3">
          <p>LinkedIn Handle</p>
          <Switch
            className="bg-gray-300"
            size="small"
            checked={carouselStates.headshotData.profileLinkVisible}
            onChange={(checked) =>
              handleVisibilityChange("profileLinkVisible", checked)
            }
          />
        </div>

        <Input
          placeholder="LinkedIn Handle"
          size="middle"
          value={carouselStates.headshotData.profileLink}
          onChange={handleProfileLinkChange}
        />

        {/* Headshot */}
        <div className="flex items-center justify-between my-1">
          <p>Headshot</p>
          <Switch
            className="bg-gray-300"
            size="small"
            checked={carouselStates.headshotData.imageVisible}
            onChange={(checked) =>
              handleVisibilityChange("imageVisible", checked)
            }
          />
        </div>

        <Upload
          name="headshot"
          listType="picture"
          showUploadList={false}
          beforeUpload={beforeUpload} // Prevent automatic upload
          onChange={handleImageUpload}
        >
          <Button
            icon={<UploadOutlined />}
            className="w-full p-2 h-max"
          ></Button>
        </Upload>
        {/* OR
          <Input
            size="middle"
            placeholder="Headshot"
            value={carouselStates.headshotData.image}
            onChange={handleHeadshotChange}
          /> */}

        {/* Remove page number */}
        <div className="flex items-center justify-end gap-1 my-1 mt-3">
          <p> Page Number Visible </p>

          {/* true false toggle */}
          <Switch
            size="small"
            className="bg-gray-300"
            checked={carouselStates.pageNumberVisible}
            onChange={(checked) =>
              handlePageNumberVisibility("pageNumberVisible", checked)
            }
          />
        </div>
      </Form>
    </div>
  );
};

// To Change the carousel Theme i.e. background element, color palette etc.

const CarouselTheme = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();
  const [customPallet, setCustomPallet] = useState(false);

  const handleBackgroundDesignChange = (value) => {
    setCarouselStates({
      ...carouselStates,
      backgroundDesign: {
        ...carouselStates.backgroundDesign,
        type: { value: value },
      },
    });
  };

  // Define the colorPallet array with actual color values
  const colorPalette = [
    {
      primary: "#002D62",
      secondary: "#6C7A89",
      background: "#D4E4F7",
    },
    {
      primary: "#264653",
      secondary: "#2A9D8F",
      background: "#E9C46A",
    },
    {
      primary: "#006D77",
      secondary: "#83C5BE",
      background: "#EDF6F9",
    },
    {
      primary: "#3D405B",
      secondary: "#F4F1DE",
      background: "#E07A5F",
    },
    {
      primary: "#1D3557",
      secondary: "#457B9D",
      background: "#A8DADC",
    },
    {
      primary: "#3F88C5",
      secondary: "#44BBA4",
      background: "#E7ECEF",
    },
    {
      primary: "#4A4E69",
      secondary: "#9A8C98",
      background: "#F2E9E4",
    },
    {
      primary: "#0B3D91",
      secondary: "#1E6091",
      background: "#FAFAFA",
    },
    {
      primary: "#3A0CA3",
      secondary: "#7209B7",
      background: "#F9E1FD",
    },
    {
      primary: "#4C5C68",
      secondary: "#9DA9A0",
      background: "#F2F4F3",
    },
    {
      primary: "#12355B",
      secondary: "#20639B",
      background: "#F1F1F1",
    },
    {
      primary: "#481977",
      secondary: "#224815",
      background: "#F4FFEB",
    },
    {
      primary: "#771919",
      secondary: "#DF7070",
      background: "#FFEBFD",
    },
    {
      primary: "#C172A9",
      secondary: "#338374",
      background: "#EBF6FF",
    },
    {
      primary: "#A67AD1",
      secondary: "#D946D3",
      background: "#FFF9EB",
    },
    {
      primary: "#022B26",
      secondary: "#2B69B1",
      background: "#DEFFF7",
    },
    {
      primary: "#6D1D54",
      secondary: "#A63E6D",
      background: "#FAF7F7",
    },
    {
      primary: "#B32824",
      secondary: "#52656F",
      background: "#F8F9FA",
    },
    {
      primary: "#3C1E4F",
      secondary: "#9658A1",
      background: "#F7F2FA",
    },
    {
      primary: "#e94f37",
      secondary: "#393e41",
      background: "#f6f7eb",
    },
    {
      primary: "#9381ff",
      secondary: "#b8b8ff",
      background: "#f8f7ff",
    },
    {
      primary: "#207bff",
      secondary: "#4ea5ff",
      background: "#f5f7fa",
    },
    {
      primary: "#4c5454",
      secondary: "#ff715b",
      background: "#ffffff",
    },
    {
      primary: "#2e3532",
      secondary: "#8b2635",
      background: "#e0e2db",
    },
    {
      primary: "#329721",
      secondary: "#74BE80",
      background: "#FFFFFF",
    },
    {
      primary: "#c5283d",
      secondary: "#e9724c",
      background: "#FCE9C3",
    },
    {
      primary: "#ff6b6b",
      secondary: "#4ecdc4",
      background: "#ffffff",
    },
    {
      primary: "#3d405b",
      secondary: "#e07a5f",
      background: "#ffffff",
    },
    {
      primary: "#4c5454",
      secondary: "#610EF5",
      background: "#ffffff",
    },
    {
      primary: "#6f42c1",
      secondary: "#937400",
      background: "#ffffff",
    },
  ];

  const handleColorChange = (color) => {
    setCarouselStates({
      ...carouselStates,
      colorPalette: {
        primary: color.primary,
        secondary: color.secondary,
        background: color.background,
      },
    });
  };

  return (
    <div className="flex flex-col gap-2 text-left">
      <h4 className="text-2xl font-semibold"> Theme</h4>

      {/* SELECT THE BACKGROUND DESIGN */}
      <p>Select Background Template</p>
      <div className="flex max-w-md overflow-scroll custom-h-scrollbar">
        <Segmented
          defaultValue={carouselStates?.backgroundDesign?.type}
          onChange={handleBackgroundDesignChange}
          options={[
            { label: "Template 1", value: "template1" },
            { label: "Template 2", value: "template2" },
            { label: "Template 3", value: "template3" },
            { label: "Template 4", value: "template4" },
            { label: "Template 5", value: "template5" },
            { label: "Template 6", value: "template6" },
            { label: "Template 7", value: "template7" },
            { label: "Template 8", value: "template8" },
            { label: "Template 9", value: "template9" },
            { label: "Template 10", value: "template10" },
            { label: "Template 11", value: "template11" },
            { label: "Template 12", value: "template12" },
            { label: "Template 13", value: "template13" },
            { label: "Template 14", value: "template14" },
            { label: "Template 15", value: "template15" },
            { label: "Template 16", value: "template16" },
            { label: "Template 17", value: "template17" },
            { label: "Template 18", value: "template18" },
            { label: "Template 19", value: "template19" },
            { label: "Template 20", value: "template20" },
            { label: "Template 21", value: "template21" },
            { label: "Template 22", value: "template22" },
            // { label: "Template 23", value: "template23" },
            { label: "Template 24", value: "template24" },
            { label: "Template 25", value: "template25" },
            { label: "Template 26", value: "template26" },
            { label: "Template 27", value: "template27" },
            { label: "None", value: "None" },
          ]}
        />
      </div>

      {/* Color Palette selection */}
      <div className="flex items-center justify-between my-1 mt-3">
        <p className="mt-3 text-xl font-semibold">Color Palettes</p>
      </div>
      {/* Custom Pallet Checkbox */}
      <Checkbox
        // className="font-semibold"
        onChange={(e) => setCustomPallet(e.target.checked)}
      >
        Use Custom Colors
      </Checkbox>

      <section className="flex flex-col gap-3 ">
        {customPallet ? (
          <>
            {/* Option to change the colours  */}
            {/* <div className="flex gap-3"> */}
            <div className="flex flex-wrap justify-around gap-3 p-4 border rounded">
              <div className="flex flex-col gap-2 ">
                <p>Primary</p>
                <ColorPicker
                  defaultValue={carouselStates.colorPalette.primary}
                  showText
                  onChangeComplete={(color) => {
                    setCarouselStates({
                      ...carouselStates,
                      colorPalette: {
                        ...carouselStates.colorPalette,
                        primary: color.toHexString(),
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <p>Secondary</p>
                <ColorPicker
                  defaultValue={carouselStates.colorPalette.secondary}
                  showText
                  onChangeComplete={(color) => {
                    setCarouselStates({
                      ...carouselStates,
                      colorPalette: {
                        ...carouselStates.colorPalette,
                        secondary: color.toHexString(),
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <p>Background</p>
                <ColorPicker
                  defaultValue={carouselStates.colorPalette.background}
                  showText
                  onChangeComplete={(color) => {
                    setCarouselStates({
                      ...carouselStates,
                      colorPalette: {
                        ...carouselStates.colorPalette,
                        background: color.toHexString(),
                      },
                    });
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-wrap justify-around gap-3 p-4 border rounded">
            {/* Colour pallets to be displayed */}
            {colorPalette.map((color, index) => (
              <div
                key={index}
                className="flex items-center py-2 pl-2 pr-1 bg-gray-200 rounded-full cursor-pointer"
                onClick={() => handleColorChange(color)}
              >
                <div
                  className="w-5 h-5 rounded-full "
                  style={{ backgroundColor: color.primary }}
                ></div>
                <div
                  className="w-5 h-5 -translate-x-1 rounded-full "
                  style={{ backgroundColor: color.secondary }}
                ></div>
                <div
                  className="w-5 h-5 -translate-x-2 rounded-full "
                  style={{ backgroundColor: color.background }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};
