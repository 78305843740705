import React from "react";
const BackgroundTemplate4 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <svg width="535" height="665" viewBox="0 0 535 665" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      {/* <mask
        id="mask0_2478_1742"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask> */}
      {/* <g mask="url(#mask0_2478_1742)"> */}
      <circle
        cx="343.5"
        cy="126.5"
        r="25"
        // stroke="#4A89C1"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <circle
        cx="241.5"
        cy="537.5"
        r="25"
        // stroke="#4A89C1"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      {/* <mask
          id="mask1_2478_1742"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="355"
          y="0"
          width="141"
          height="56"
        >
          <rect x="355" width="141" height="56" fill="#D9D9D9" />
          <rect
            x="355.5"
            y="0.5"
            width="140"
            height="55"
            stroke="#4A89C1"
            stroke-opacity="0.15"
          />
        </mask> */}
      <g mask="url(#mask1_2478_1742)">
        <circle
          cx="426"
          cy="-6"
          r="55"
          //   stroke="#4A89C1"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.07"
          stroke-width="6"
        />
      </g>
      {/* <mask
          id="mask2_2478_1742"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="464"
          y="179"
          width="71"
          height="486"
        >
          <rect x="464" y="179" width="71" height="486" fill="#D9D9D9" />
        </mask> */}
      <g mask="url(#mask2_2478_1742)">
        <path
          d="M664 665L464 665L464 279C464 223.771 508.772 179 564 179V179C619.228 179 664 223.771 664 279L664 665Z"
          //   fill="#4A89C1"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
      </g>
      <circle
        cx="449"
        cy="569"
        r="43.125"
        // stroke="#2C4868"
        stroke={colorPallet?.primary}
        stroke-width="5.75"
      />
      {/* <mask
          id="mask3_2478_1742"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="71"
          height="162"
        >
          <rect width="71" height="162" fill="#D9D9D9" />
        </mask> */}
      <g mask="url(#mask3_2478_1742)">
        <path
          d="M-93 0H71V74C71 119.287 34.2873 156 -11 156V156C-56.2873 156 -93 119.287 -93 74V0Z"
          //   fill="#4A89C1"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
      </g>
      {/* </g> */}
    </svg>
  );
};

export default BackgroundTemplate4;
