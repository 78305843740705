import React from "react";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { successConfetti } from "../../../../assets/onBoardingPage";

const PostSuccessfulModal = ({ isOpen, onClose, action }) => {
  const PostLink = `https://www.linkedin.com/feed/update/urn:li:share:${action.shareId}/`;
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        title="Posted Successfully"
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        footer={null}
      >
        <div className="flex flex-col items-center justify-center">
          <Lottie
            as="div"
            className="w-64 h-64 py-10"
            animationData={successConfetti}
          />
          <p className="text-xl font-semibold text-linkedInPeach">
            Hooray! {action.name} Successfully
          </p>

          <div className="flex justify-center mt-3 gap-14">
            <Button
              type="primary"
              size="medium"
              onClick={() => window.open(PostLink, "_blank")}
            >
              View Post
            </Button>
            <Button
              type="default"
              size="medium"
              onClick={() => {
                navigate("/linkedin/content-creation");
              }}
            >
              Go to Dashboard
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PostSuccessfulModal;
