import { Button, Modal } from "antd";
import { useUserAuth } from "../../../../context/userAuthContext";
import Lottie from "lottie-react";
import { LinkedInDisconnected } from "../../../../assets/LotteAnimations";

// show connect linkedin modal
const ConnectLinkedInModal = ({ connectModalOpen, connectModalClose }) => {
  const { user } = useUserAuth();
  return (
    <Modal
      centered
      open={connectModalOpen}
      onOk={connectModalClose}
      onCancel={connectModalClose}
      maskClosable={false}
      width={"60vw"}
      height={"80vh"}
      footer={null}
    >
      <main className="flex flex-col items-center justify-center gap-4 p-5 text-2xl font-semibold">
        <h3>Your LinkedIn Session Got Expired</h3>

        <Lottie
          as="div"
          className="w-64 h-64 py-10"
          animationData={LinkedInDisconnected}
        />
        <Button
          type="primary"
          size="large"
          className="w-full my-4 font-medium bg-white border-2 "
          onClick={() => {
            if (user) {
              user.getIdToken().then((idToken) => {
                const linkedInAuthUrl = `${process.env.REACT_APP_SERVERURL}/linkedIn/auth?firebaseToken=${idToken}`;
                window.location.href = linkedInAuthUrl;
              });
            }
          }}
        >
          Connect Linkedin
        </Button>
      </main>
    </Modal>
  );
};

export default ConnectLinkedInModal;
