import React from "react";

const BackgroundTemplate5 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2478_1711"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      > */}
      {/* <rect width="535" height="665" /> */}
      {/* </mask> */}
      {/* <g mask="url(#mask0_2478_1711)"> */}
      <rect
        x="502.055"
        y="632"
        width="33"
        height="33"
        transform="rotate(179.904 502.055 632)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="469.055"
        y="665"
        width="33"
        height="33"
        transform="rotate(179.904 469.055 665)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="469.055"
        y="599"
        width="33"
        height="33"
        transform="rotate(179.904 469.055 599)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="403.055"
        y="533"
        width="33"
        height="33"
        transform="rotate(179.904 403.055 533)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="435.554"
        y="631.501"
        width="32"
        height="32"
        transform="rotate(179.904 435.554 631.501)"
        // stroke="#9C4598"
        stroke={colorPallet?.secondary}
      />
      <rect
        x="110.554"
        y="131.501"
        width="32"
        height="32"
        transform="rotate(179.904 110.554 131.501)"
        // stroke="#9C4598"
        stroke={colorPallet?.secondary}
      />
      <rect
        x="502.055"
        y="566"
        width="33"
        height="33"
        transform="rotate(179.904 502.055 566)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="144.055"
        y="33"
        width="33"
        height="33"
        transform="rotate(179.904 144.055 33)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="177.055"
        y="66"
        width="33"
        height="33"
        transform="rotate(179.904 177.055 66)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="535"
        y="215"
        width="33"
        height="33"
        transform="rotate(179.904 535 215)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="210.055"
        y="33"
        width="33"
        height="33"
        transform="rotate(179.904 210.055 33)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="242.554"
        y="65.5008"
        width="32"
        height="32"
        transform="rotate(179.904 242.554 65.5008)"
        // stroke="#9C4598"
        stroke={colorPallet?.secondary}
      />
      <rect
        x="501.554"
        y="181.501"
        width="32"
        height="32"
        transform="rotate(179.904 501.554 181.501)"
        // stroke="#9C4598"
        stroke={colorPallet?.secondary}
      />
      <rect
        x="535.055"
        y="599"
        width="33"
        height="33"
        transform="rotate(179.904 535.055 599)"
        // fill="#9C4598"
        fill={colorPallet?.secondary}
        fill-opacity="0.07"
      />
      <rect
        x="534.554"
        y="664.501"
        width="32"
        height="32"
        transform="rotate(179.904 534.554 664.501)"
        // stroke="#9C4598"
        stroke={colorPallet?.secondary}
      />
      {/* </g> */}
    </svg>
  );
};

export default BackgroundTemplate5;
