import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Modal from "react-modal";

const root = ReactDOM.createRoot(document.getElementById("root"));

Modal.setAppElement("#root"); // Replace #root with the ID of your root element

root.render(<App />);
