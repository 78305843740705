import axios from "axios";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function useFetchLinkedInData() {
  const queryClient = useQueryClient();

  return useQuery(
    ["LinkedInUserData"], // queryKey is now an array
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERURL}/linkedin/login/success`,
        {
          withCredentials: true,
        }
      );
      return response.data; // Return the fetched data
    },
    {
      onError: (error) => {
        toast.error("Oops! Something went wrong!");
      },
      onSuccess: (data) => {
        queryClient.setQueryData(["LinkedInUserData"], data); // queryKey is now an array
      },
    }
  );
}

// uploading to cloudinary
const uploadPreset = "trikl-images";

// convert ArrayBuffer to imageUrl using cloudinary
const uploadImageToCloudinary = async (buffer, onUploadProgress) => {
  try {
    const formData = new FormData();
    formData.append("file", new Blob([buffer]));
    formData.append("upload_preset", uploadPreset);

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
    };

    const response = await axios.post(
      process.env.REACT_APP_CLOUDINARY_URL,
      formData,
      config
    );

    const imageUrl = response.data.url;
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image to Cloudinary:", error);
    throw error;
  }
};

const handleUrlValidation = (string) => {
  const res = string.match(
    /^(https?:\/\/)?([\w@]([-\w]*[\w@])*\.)+[\w]{2,}(\/[-\w@%_.~+]*[\w@]\/?)*(\?[;&\w@%_.~+=-]*)?(\#[\-\w@_]*)?$/i
  );
  return res !== null;
};

// Function to get the date in DD/MM/YYYY format:
const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  return `${day}/${month}/${year}`;
};

//  Function for getting the updated Images:
const getUserImageData = async (
  userId,
  setUserImagesArray,
  setUserMediaFiles
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVERURL}/userMedia/v2/getAllMedia/?userId=${userId}`
    );
    // Only store if mediaType is image then store it in a variable and store it in setUserImagesArray

    if (response.data && response.data.userMedia) {
      // Filter to get only the media where mediaType is 'image'
      const filteredImages = response.data.userMedia.filter(
        (media) => media.mediaType === "image"
      );

      //-------
      const allMediaFiles = response.data.userMedia;
      const images = allMediaFiles
        .filter((media) => media.mediaType === "image")
        .reverse();

      const videos = allMediaFiles
        .filter((media) => media.mediaType === "video")
        .reverse();

      const documents = allMediaFiles
        .filter((media) => media.mediaType === "document")
        .reverse();

      // Update the Zustand store
      setUserMediaFiles({
        images: images,
        videos: videos,
        documents: documents,
      });
      // Store the filtered images in state, reversed if needed
      setUserImagesArray(filteredImages.reverse());
    }

    // setUserImagesArray(res.data.userMedia.reverse());
  } catch (error) {
    toast.error("oops! something went wrong. Please try again.");
  }
};

// Uploading Generated/Uploaded Images function :
const storeGeneratedOrUploadedImg = async (
  userId,
  userInput,
  imgURL,
  imgType,
  setUserImagesArray,
  mediaType,
  setUserMediaFiles
) => {
  const createdOn = getCurrentDate();

  try {
    const details = {
      userId,
      medias: [
        {
          mediaType: mediaType,
          source: imgType || "uploads",
          prompts: userInput || "",
          mediaURL: imgURL || "",
          createdOn: createdOn,
        },
      ],
    };

    const response = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userMedia/v2/storeMedia`,
      details
    );

    if (response.status === 201) {
      getUserImageData(userId, setUserImagesArray, setUserMediaFiles); // Get the updated images

      toast.success("Media stored successfully!");
    } else {
      toast.error("Error storing image!");
    }
  } catch (error) {
    toast.error("Error storing image!");
  }
};

// Uploading assets:
const storeAssetImg = async (userId, assetURL) => {
  try {
    const dets = {
      userId,
      assetImg: [
        {
          assetURL,
        },
      ],
    };

    const response = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userMedia/v2/storeAsset`,
      dets
    );
  } catch (error) {
    toast.error("Error in storing asset image");
  }
};

// upload normal image to cloudinary without progress bar
const uploadImageToCloudinaryWithoutProgress = async (buffer) => {
  try {
    const formData = new FormData();
    formData.append("file", new Blob([buffer]));
    formData.append("upload_preset", uploadPreset);
    const response = await axios.post(
      process.env.REACT_APP_CLOUDINARY_URL,
      formData
    );
    const imageUrl = response.data.url;
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image to Cloudinary:", error);
    throw error;
  }
};

const assistantDetailsById = async (assistantIdParam) => {
  try {
    if (!assistantIdParam) return "";
    const userDetails = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/get-assistant-files`,
      {
        assistantId: assistantIdParam,
      }
    );
    return userDetails.data.existingFiles;
  } catch (err) {
    // toast.error("oops! something went wrong. Please try again.");
  }
};

const updateUserOrganizations = async (userId) => {
  if (!userId) return;

  try {
    await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/updateUserOrganizations`,
      {
        userId: userId,
      },
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    // toast.error("oops! something went wrong. Please try again.");
  }
};

// ---------------------------------------------------------------------------------------------------

export {
  uploadImageToCloudinary,
  handleUrlValidation,
  storeGeneratedOrUploadedImg,
  getUserImageData,
  uploadImageToCloudinaryWithoutProgress,
  updateUserOrganizations,
  assistantDetailsById,
};
