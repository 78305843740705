import React from "react";
import {
  ImageLoaderVideo,
  LoadingWriter,
  WritingLoaderVideo,
  imageCreation,
} from "../../../assets/Canvas";
import {
  useAIRecommendationClickStore,
  useFinalContentStore,
  useIsAiPromptRunningStore,
  useIsImageGenerationLoadingStore,
  useSelectedMenuItemStore,
} from "../ZustandStores/LinkedInZustandStore";
import { Button, Card } from "antd";
import { useUserLinkedInStore } from "../ZustandStores/UserLinkedInStore";
import UserTemplatePost from "./UserTemplatePost";
import { useImagePromptStore } from "../ZustandStores/ImageGenerationStore";

const DefaultLanding = () => {
  const { isPromptRunning } = useIsAiPromptRunningStore();
  const { setSelectedMenuItem } = useSelectedMenuItemStore();

  return (
    <div className="w-full px-16 pt-10 mt-10 ">
      <Card className="flex flex-col items-center justify-center p-0 pb-8 border-none">
        {!isPromptRunning ? (
          <>
            <section className="flex flex-col items-center justify-center">
              <div className="">
                <p className="mb-8 text-lg text-tNewButtonsAndActiveElement">
                  Choose a topic to create a LinkedIn post as you usually do.
                </p>

                <UserTemplatePost />
                <p className="my-2">or</p>
              </div>

              <Button
                size="large"
                className="w-72"
                onClick={() => setSelectedMenuItem(0)}
              >
                Create Post From Scratch
              </Button>
            </section>

            <div className="flex justify-center">
              <video
                style={{ height: "300px" }}
                src={LoadingWriter}
                autoPlay
                loop
                muted
                controls={false}
              />
            </div>
          </>
        ) : (
          <>
            <figure className="">
              <video
                style={{ height: "300px" }}
                src={WritingLoaderVideo}
                autoPlay
                loop
                muted
                controls={false}
              />
            </figure>
          </>
        )}
      </Card>
    </div>
  );
};

const DefaultImagesLanding = () => {
  const { isImageGenerationLoading } = useIsImageGenerationLoadingStore();
  const { setIsAIRecommendationClick } = useAIRecommendationClickStore();

  const { isImagePromptLoading } = useImagePromptStore();

  return (
    <div className="px-16 py-6 bg-white">
      <Card className="flex flex-col items-center justify-center p-0 border-none ">
        {!isImageGenerationLoading ? (
          <>
            <div>
              <video
                style={{ height: "300px" }}
                src={imageCreation}
                autoPlay
                loop
                muted
                controls={false}
              />
            </div>

            <Button
              loading={isImagePromptLoading}
              type="primary"
              size="large"
              className="w-full mt-5 bg-triklAccentBlue"
              onClick={() => {
                setIsAIRecommendationClick(true);
              }}
            >
              Get Recommendation from AI
            </Button>
          </>
        ) : (
          <>
            <div>
              <video
                style={{ height: "300px" }}
                src={ImageLoaderVideo}
                autoPlay
                loop
                muted
                controls={false}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

const DefaultReviewSection = () => {
  return (
    <div className="px-16 py-6 mt-6 bg-white">
      <Card className="flex flex-col items-center justify-center p-0 border-none ">
        <div>
          <video
            style={{ height: "300px" }}
            src={WritingLoaderVideo}
            autoPlay
            loop
            muted
            controls={false}
          />
        </div>
        <p className="text-lg">Write some content to see here</p>
      </Card>
    </div>
  );
};

export { DefaultLanding, DefaultImagesLanding, DefaultReviewSection };

// Create a replacement component for Landing that will be used in the Canvas component
// Animation remains the same
// Card 1 - My Usual Post
// Icon
// Description - It will automatically create a post in your style about a topic that you generally write on LinkedIn. You'll get an option to edit the post before publishing.

// Card 2 - Create from Scratch
// Icon
// Description - It will guide you to create a post from scratch. You'll get an option to edit the post before publishing.

// FUNCTION to find a random topic for post creation
// get all user topics
// select a random topic

// get all question templates
// select a random template

// merge topic and question

// set that as the post content to be sent to AI
