import { React, useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Wysiwyg from "../../Dashboard/subPages/contentCreation/Wysiwyg";
import StepperComponent from "../../components/widgets/StepperComponent";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FinalizePost from "./summaryComponents/FinalizePost";
import { useLinkedInUserContext } from "../../../context/linkedInUserContext";
import PostScheduler from "./schedulerComponents/PostScheduler";
import {
  UserDataRefreshStore,
  useAiResponseArrayStore,
  useAiResponseStore,
  useFinalContentStore,
  useIsAiPromptRunningStore,
  useOverallStepStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
  useUserTypeStore,
} from "../ZustandStores/LinkedInZustandStore";

import { toast } from "react-toastify";
import {
  convertHtmlToFormattedSavedDrafts,
  convertHtmlToFormattedText,
} from "../../function/ConvertHtmlToFormattedText";
import { BeatLoader } from "react-spinners";
import { DefaultLanding } from "./DefaultLanding";
import { updateUserOrganizations } from "../function/LinkedInFunctions";
import ContentOptions from "./editorComponents/ContentOptions";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../ZustandStores/UserLinkedInStore";
import MediaFileUpload from "./imageComponents/MediaFileUpload";
import RenderMedias from "./imageComponents/RenderMedias";
import { Button, Modal, notification } from "antd";
import CarouselCreation from "./mediaComponents/CarouselCreation";
import { useUserAuth } from "../../../context/userAuthContext";
import { uselinkedInReconnectModal } from "../ZustandStores/tokenExpiryStore";

const MainContentArea = () => {
  // ZUSTAND
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { aiResponseArray } = useAiResponseArrayStore();
  const { aiResponse } = useAiResponseStore();
  const { selectedDraftId, setSelectedDraftId } = useSavedDraftIdStore();

  const { selectedScheduledPostId, setSelectedScheduledPostId } =
    useScheduledPostIdStore();
  const { linkedInReconnectModalVisible, setLinkedInReconnectModalVisible } =
    uselinkedInReconnectModal();
  const { isPromptRunning } = useIsAiPromptRunningStore();
  const { userMainData } = useUserMainDataStore();
  const { userLinkedInData } = useUserLinkedInStore();

  const [selectedOrganization, setSelectedOrganization] = useState();
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  let { userData } = useLinkedInUserContext();
  const { overallStep, setOverallStep } = useOverallStepStore();
  // User LinkedIn object details

  // useEffect(() => {
  //   // if (userData?.companyId?.length <= 0) {
  //   //   navigate("/linkedin/onboarding");
  //   // }
  // }, [userData, navigate]);

  const HandleFinalize = async () => {
    if (
      finalContent.finalText.length === 0 &&
      finalContent.finalMediaUrl.length === 0
    ) {
      toast.error(
        "Please finalize the text and image before proceeding to the next step"
      );
      return;
    }
    setOverallStep(overallStep + 1);
    if (overallStep === 1) {
      toast.success("Media finalized");
    } else if (overallStep === 2) {
      toast.success(finalContent.finalMediaUrl.length > 0 && "Media finalized");
    } else if (overallStep === 4) {
      toast.success("step 4");
    }
  };

  // Increment stepperCount when aiResponse is received
  if (aiResponse && overallStep === 0) {
    setOverallStep(overallStep + 1);
  }
  const handleSavingToDraft = async (postText) => {
    setIsLoadingSave(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/save-to-drafts`,
        {
          draftId: selectedDraftId,
          mediaUrl: finalContent.finalMediaUrl,
          textContent: convertHtmlToFormattedText(postText),
          mediaType: finalContent.finalMediaType,
          userId: userMainData?.userId,
        }
      );
      // clearing the final content data after scheduling and going back to step1
      setFinalContent({
        ...finalContent,
        finalText: "",
        finalMediaUrl: "",
        finalTone: "",
        finalOrganizationId: 0,
        finalPostType: "individual",
        finalCharacterLimit: 0,
        additionalPersonalization: [],
      });
      setSelectedDraftId("");
      setSelectedScheduledPostId("");
      setOverallStep(1);
      toast.success("Saved to draft");
      setIsLoadingSave(false);
    } catch (error) {
      // console.error("Error saving draft:", error);
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
      setIsLoadingSave(false);
    }
  };

  // switch conditioned components
  const renderContentBasedOnStepper = () => {
    switch (overallStep) {
      // first landing content page
      case 0:
        if (finalContent.finalText.length > 0) {
          setOverallStep(1);
        }

        return <DefaultLanding />;

      // text content creation area
      case 1:
        return (
          <>
            {isPromptRunning ? (
              <DefaultLanding />
            ) : (
              <main className="w-full">
                <div
                  className={`w-full  py-2 font-semibold  transition-all duration-500 border-b  hover:font-bold cursor-pointer ${
                    finalContent.finalText.length > 3000
                      ? "bg-red-600"
                      : finalContent.finalText.length > 0
                      ? "bg-triklAccentBlue text-white"
                      : "bg-lightGrey text-gray-400"
                  }`}
                >
                  {finalContent.finalText?.length > 3000 ? (
                    <p>
                      Text size must be less than 3000
                      <span className="mx-2 text-gray-100">
                        (-{finalContent.finalText.length - 3000})
                      </span>
                    </p>
                  ) : (
                    <p
                      onClick={() =>
                        finalContent.finalText.length ? HandleFinalize() : ""
                      }
                    >
                      Finalize Text & Continue to Image Generation
                    </p>
                  )}
                </div>

                {/* Editor and ai text improver */}

                <section className="flex items-start justify-center w-full gap-2">
                  <div className="w-11/12 p-8">
                    {aiResponseArray?.length > 0 && <ContentOptions />}
                    <Wysiwyg />
                  </div>
                </section>
              </main>
            )}
          </>
        );

      // image creation section
      case 2:
        return (
          <>
            <div className="flex justify-around w-full gap-0 font-semibold transition-all duration-500 border-b border-gray-300 text-triklAccentBlue">
              <button
                onClick={HandleFinalize}
                className="w-full py-2 font-semibold text-white transition-all duration-500 border-b hover:font-bold bg-triklAccentBlue disabled:text-gray-400 disabled:bg-transparent"
                // disabled={!finalContent.finalMediaUrl.length > 0}
              >
                {!finalContent.finalMediaUrl
                  ? "SKIP MEDIA SELECTION"
                  : "Finalize Media & Continue to Publishing"}
              </button>
            </div>

            <div className="flex flex-col items-center w-2/3 px-8 pt-8 pb-16">
              <RenderMedias />
            </div>
          </>
        );

      //final review section
      case 3:
        return (
          <>
            <div className="flex justify-around w-full gap-0 font-semibold transition-all duration-500 border-b border-gray-300 text-triklAccentBlue">
              <button
                className="w-1/3 py-2 transition-all duration-500 border-r border-gray-300 hover:bg-triklAccentBlue hover:border-triklAccentBlue hover:text-white"
                onClick={() => {
                  if (userLinkedInData.refreshTokenExpired) {
                    setLinkedInReconnectModalVisible(true);
                  } else {
                    HandleFinalize();
                  }
                }}
              >
                {selectedScheduledPostId ? "Reshedule" : "Schedule"}
              </button>
              <button
                className="w-1/3 py-2 transition-all duration-500 border-r border-gray-300 hover:bg-triklAccentBlue hover:border-triklAccentBlue hover:text-white"
                onClick={() => {
                  if (userLinkedInData.refreshTokenExpired) {
                    setLinkedInReconnectModalVisible(true);
                  } else {
                    setIsModalOpen(true);
                  }
                }}
              >
                Post
              </button>

              <SendPostModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                setIsLoadingPost={setIsLoadingPost}
                isLoadingPost={isLoadingPost}
              />

              {!isLoadingSave ? (
                <button
                  className="w-1/3 py-2 transition-all duration-500 border-gray-300 hover:bg-triklAccentBlue hover:border-triklAccentBlue hover:text-white"
                  onClick={() => {
                    handleSavingToDraft(finalContent.finalText);
                  }}
                >
                  Save
                </button>
              ) : (
                <div className="w-1/3 py-2 transition-all duration-500 border-gray-300 bg-triklAccentBlue ">
                  <BeatLoader color="#fff" size={8} />
                </div>
              )}
            </div>
            <div className="w-1/2">
              <FinalizePost />
            </div>
          </>
        );
      case 4:
        return (
          <>
            <PostScheduler />
          </>
        );
      case 5:
        return (
          <>
            <MediaFileUpload />
          </>
        );
      // case 6:
      //   return (
      //     <>
      //       <CarouselCreation />
      //     </>
      //   );

      default:
        return null;
    }
  };

  // useEffect(() => {
  //   if (userData?.companyId?.length <= 0) {
  //     navigate("/linkedin/onboarding");
  //   }
  // }, [userData, navigate]);

  return (
    <div className="flex flex-col w-full h-auto max-h-screen min-h-screen pt-24 pb-48 overflow-y-scroll border-r border-gray-300 lg:w-2/3">
      <header className="pb-8 border-b border-gray-300">
        {/* stepper functionality */}
        <StepperComponent />
      </header>

      {/* main area */}
      <section className="flex flex-col items-center justify-center">
        {renderContentBasedOnStepper()}
      </section>
    </div>
  );
};

export default MainContentArea;

const SendPostModal = ({
  isOpen,
  setIsOpen,
  setIsLoadingPost,
  isLoadingPost,
}) => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userData } = useLinkedInUserContext();
  const { userType, setUserType } = useUserTypeStore();
  const { setOverallStep } = useOverallStepStore();
  const { setSelectedDraftId } = useSavedDraftIdStore();
  const { setSelectedScheduledPostId } = useScheduledPostIdStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userLinkedInData } = useUserLinkedInStore();
  const navigate = useNavigate();

  const newArray = [
    {
      organizationId: userData?.userId,
      organizationName: "My personal Account",
      userType: "individual",
    },
    ...(userLinkedInData?.organizations?.map((org) => ({
      ...org,
      userType: "organization",
    })) || []),
  ];

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // post now using linkedIn
  const handleShareLinkedIn = async () => {
    const key = "connectAccountNotification"; // Unique key for the notification
    if (userLinkedInData?.linkedInUserId == "") {
      // return toast.info("Please connect your LinkedIn account to post");

      notification.info({
        key, // Assign a unique key to the notification
        placement: "bottomRight",
        message: "Please connect your LinkedIn account to post",
        description: (
          <Button
            type="primary"
            onClick={() => {
              navigate("/user/profile");
              notification.destroy(key);
            }}
          >
            Connect Account
          </Button>
        ),
        duration: 2,
      });

      return;
    }
    setIsLoadingPost(true);
    const formattedContent = convertHtmlToFormattedText(finalContent.finalText);

    try {
      if (userType === "individual") {
        // Form details
        const Data = {
          userId: userLinkedInData?.userId,
          content: formattedContent,
          mediaUrl: finalContent.finalMediaUrl,
          mediaType: finalContent.finalMediaType,
          // organizationId: "0",
        };

        const res = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/self-post`,
          Data,
          {
            withCredentials: true,
          }
        );
      } else {
        const Data = {
          userId: userLinkedInData?.userId,
          organizationId: finalContent.finalOrganizationId,
          content: formattedContent,
          mediaUrl: finalContent.finalMediaUrl,
          mediaType: finalContent.finalMediaType,
        };

        const res = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/post-in-community`,
          Data,
          {
            withCredentials: true,
          }
        );
      }

      // clearing the final content data after scheduling and going back to step1
      setFinalContent({
        ...finalContent,
        finalText: "",
        finalMediaUrl: "",
        finalMediaType: "",
        finalTone: "",
        finalOrganizationId: 0,
        finalPostType: "individual",
        finalCharacterLimit: 0,
        additionalPersonalization: [],
      });

      setSelectedDraftId("");
      setSelectedScheduledPostId("");
      setOverallStep(1);
      toast.success("Successfully posted over Linkedin");

      setIsLoadingPost(false);
      closeModal();
    } catch (error) {
      console.log("error.response.status", error.response.status);

      if (error.response.status === 401) {
        toast.error("Please connect your LinkedIn account to post");
        // setShowLinkedInConnectModal(true);
        setIsLoadingPost(false);
        return;
      }

      if (error.response.status === 403) {
        toast.error("Access denied. Please make a payment!");
        setIsLoadingPost(false);
        return;
      }
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
      setIsLoadingPost(false);
      closeModal();
    }
  };

  // ----------------------- Handling Select Tag -----------------------
  const handleSelect = (eachOrganizationId) => {
    const selectedOption = newArray.find(
      (org) => org.organizationId == eachOrganizationId
    );

    if (selectedOption) {
      setFinalContent({
        ...finalContent,
        finalPostType: selectedOption.userType,
        finalOrganizationId: selectedOption.organizationId,
      });
      setUserType(selectedOption.userType);
    } else {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  // Update user organizations
  const fetchOrganization = async () => {
    await updateUserOrganizations(userData?.userId);
    setUserDataRefresh(!userDataRefresh);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          <div className="fixed inset-0 ">
            <div
              className="flex items-center justify-center h-screen"
              // className="flex items-center justify-center min-h-full p-4 text-center"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-400"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className=" m-auto bg-linkedInBase overflow-y-auto rounded-lg flex justify-center min-w-[50vw] min-h-[50vh] max-w-[96vw] max-h-[96vh]">
                  <main className="relative flex flex-col justify-center p-3 md:items-center">
                    <Dialog.Title
                      as="h1"
                      className="pb-8 text-2xl font-medium leading-6 text-triklAccentBlue font-spaceGrotesk"
                    >
                      Are you sure want to post it now ?
                    </Dialog.Title>
                    <div className="flex flex-col gap-5">
                      <div className="flex items-center gap-4">
                        <p className="text-lg font-semibold text-triklBlueDark">
                          Post from
                        </p>
                        <div className="w-60">
                          <select
                            className="p-2 text-center bg-white border rounded-lg shadow-sm cursor-pointer text-triklBlueDark focus:outline-none "
                            onChange={(e) => {
                              handleSelect(e.target.value);
                            }}
                          >
                            {newArray.map((eachOrganization, key) => (
                              <option
                                value={eachOrganization.organizationId}
                                key={key}
                              >
                                {eachOrganization.organizationName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="flex gap-2 mt-16">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium transition-all duration-300 ease-in-out border border-transparent rounded-md text-triklAccentBlue bg-triklAccentBlue/20 hover:bg-triklAccentBlue hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-triklAccentBlue/50 focus-visible:ring-offset-2"
                          onClick={() => handleShareLinkedIn()}
                          disabled={isLoadingPost}
                        >
                          {isLoadingPost ? (
                            <BeatLoader color="#fff" size={8} />
                          ) : (
                            "Post"
                          )}
                        </button>

                        <button
                          disabled={isLoadingPost}
                          onClick={() => {
                            closeModal();
                          }}
                          className="px-4 py-2 font-semibold text-white rounded-lg bg-triklRed"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </main>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
