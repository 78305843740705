import React, { useEffect, useState, Fragment } from "react";
import AssistantChat from "./AssistantChat";
import AssistantSidebar from "./AssistantSidebar";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import {
  useActiveThreadIdStore,
  useAssistantDataStore,
  useAssistantIdStore,
  useThreadMsgArr,
} from "./AssistantZustandStore";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import aiAssistantIllustration from "../../../../assets/aiAssistantIllustration.svg";
import { assistantDetailsById } from "../../function/LinkedInFunctions";
import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";
import { Button, Checkbox, Form, Input, Layout, message, Upload } from "antd";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import {
  CloseOutlined,
  InboxOutlined,
  PlusOutlined,
  StarOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { CalendarCreated } from "../../../../assets/calendar";

const Assistant = () => {
  // Zustand Stores
  const { setMessageArray } = useThreadMsgArr();
  const { activeThreadId, setActiveThreadId } = useActiveThreadIdStore();
  const { activeAssistantId, setActiveAssistantId } = useAssistantIdStore();
  const { setAssistantFilesArray } = useAssistantDataStore();
  const { userMainData } = useUserMainDataStore();

  // LocalState
  const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);

  const handleFetchThread = async (threadIdParam) => {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/fetch-messages`,
      {
        threadIdParam,
      }
    );
    // updating the messgaes array
    setMessageArray(res.data.messages.reverse());
  };

  const handleFetchAssistantDetails = async (activeAssistantId) => {
    const res = await assistantDetailsById(activeAssistantId);
    setAssistantFilesArray(res);
  };

  useEffect(() => {
    if (activeAssistantId) {
      handleFetchAssistantDetails(activeAssistantId);
    }
  }, [activeAssistantId]); // Ensure assistant details are fetched when activeAssistantId changes

  // DEFAULT ASSISTANT and THREAD SELECTION
  useEffect(() => {
    if (userMainData) {
      const firstAssistantId =
        userMainData?.userModels?.assistants[0]?.assistantId;
      setActiveAssistantId(firstAssistantId);
      if (firstAssistantId) {
        const firstThreadId =
          userMainData?.userModels?.assistants[0]?.threads[0]?.threadId;
        setActiveThreadId(firstThreadId);
      }
    }
  }, [userMainData]); // Set default assistant and thread IDs when userMainData is available

  useEffect(() => {
    if (activeThreadId) {
      handleFetchThread(activeThreadId);
    }
  }, [activeThreadId]); // Fetch thread messages when activeThreadId changes

  // useEffect(() => {
  //   handleFetchAssistantDetails(activeAssistantId);
  // }, [activeAssistantId]);

  // DEFAULT ASSISTANT and THREAD SELECTION
  // useEffect(() => {
  //   setActiveAssistantId(userMainData?.userModels?.assistants[0]?.assistantId);
  //   // setActiveThreadId(
  //   //   userMainData?.userModels?.assistants[0]?.threads[0]?.threadId
  //   // );
  // }, [userMainData]);

  return (
    <div>
      <AssistantModal
        isOpen={isAssistantModalOpen}
        setIsOpen={setIsAssistantModalOpen}
        userMainData={userMainData}
      />
      {!userMainData?.userModels?.assistants[0] ? (
        <div className="flex items-center justify-center h-screen pt-32 text-center bg-linkedInBase">
          <button
            className="flex items-center justify-center w-1/2 gap-1 py-2 text-white transition-all duration-300 ease-in-out border border-white rounded-lg bg-triklAccentBlue hover:bg-triklAccentBlue hover:text-white hover:font-semibold"
            onClick={() => {
              setIsAssistantModalOpen(true);
            }}
          >
            Get Started with your assistant today!
          </button>
        </div>
      ) : (
        <Layout className="h-full font-inter">
          <AssistantSidebar />
          <AssistantChat handleFetchThread={handleFetchThread} />
        </Layout>
      )}
    </div>
  );
};

export default Assistant;

// MODAL to Create a New Assistant
const AssistantModal = ({ isOpen, setIsOpen, userMainData }) => {
  const [input, setInput] = useState({ name: "", instruction: "" });
  const [showAssistantCreatedGif, setShowAssistantCreatedGif] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false); // Loading state
  const [form] = Form.useForm();

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Handle form submit - Call to backend API to create a new assistant against active user
  const handleSubmit = async (e) => {
    // e.preventDefault();

    setButtonLoading(true);
    const newAssistantData = {
      userId: userMainData.userId,
      assistantName: input.name,
      assistantInstruction: input.instruction,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/create-assistant`,
        {
          newAssistantData: newAssistantData,
        }
      );

      setShowAssistantCreatedGif(true);

      setTimeout(() => {
        setShowAssistantCreatedGif(false);
        closeModal(); //  close the modal after GIF has been displayed
        setButtonLoading(false);
        window.location.reload();
      }, 4000);
    } catch (error) {
      toast.error(
        "oops! something went wrong. Please try again after some time."
      );
    }
  };

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-400"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/30 font-inter" />
          </Transition.Child>

          {showAssistantCreatedGif ? (
            <div className="fixed inset-0 flex items-center justify-center">
              <AssistantCreatedGif />
            </div>
          ) : (
            <div className="fixed inset-0 flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-400"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex justify-center px-10 m-auto overflow-y-auto bg-white rounded-lg max-h-[90vh] items-start overflow-scroll custom-scrollbar">
                  {/* min-w-[50vw] min-h-[50vh] max-w-[96vw] max-h-[96vh] */}
                  <main className="flex flex-col justify-center p-3 py-8 md:items-center">
                    <Dialog.Title className="pb-8">
                      <div
                        as="h1"
                        className="pt-8 pb-4 text-2xl font-medium leading-6 text-triklAccentBlue"
                      >
                        Create your AI Assistant
                      </div>
                      <div className="text-sm text-gray-500">
                        Safely upload all files and add instructions
                        <br />
                        to customize AI for your brand.
                      </div>
                    </Dialog.Title>

                    {/* FORM to create assistant */}
                    <Form
                      name="basic"
                      form={form}
                      labelCol={{
                        span: 8,
                      }}
                      style={{
                        minWidth: 600,
                        maxWidth: 600,
                      }}
                      // initialValues={{
                      //   remember: true,
                      // }}
                      layout="vertical"
                      // autoComplete="off"
                      onFinish={handleSubmit}
                    >
                      <Form.Item
                        label="Name Your Assistant"
                        name="assistantName"
                        rules={[
                          {
                            required: true,
                            message: "Please name your AI Assistant!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          value={input.name}
                          onChange={(e) =>
                            setInput((prevInput) => ({
                              ...prevInput,
                              name: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label="Custom Instructions"
                        name="customInstructions"
                        rules={[
                          {
                            required: false,
                            message: "Add custom instructions to your AI",
                          },
                        ]}
                      >
                        <TextArea
                          type="text"
                          value={input.instruction}
                          onChange={(e) =>
                            setInput((prevInput) => ({
                              ...prevInput,
                              instruction: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={buttonLoading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </main>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          )}
        </Dialog>
      </Transition>
    </>
  );
};

const AssistantCreatedGif = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-white">
      <Lottie as="div" className="" animationData={CalendarCreated} />
      <p className="pb-10 text-xl font-semibold text-triklAccentBlue">
        Hooray! Your Assistant is ready
      </p>
    </div>
  );
};

//
//
//
//
// old form for AI CUSTOMIZATION
// {/* <div className="gap-5 ">
//                       <form
//                         // className="flex flex-col gap-5 m-5"
//                         onSubmit={handleSubmit}
//                       >
//                         <div>
//                           <label
//                           // className="pr-3"
//                           >
//                             Assistant Name
//                           </label>
//                           <input
//                             type="text"
//                             value={input.name}
//                             onChange={(e) =>
//                               setInput((prevInput) => ({
//                                 ...prevInput,
//                                 name: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                         <div>
//                           <label> Assistant Instruction </label>
//                           <input
//                             type="text"
//                             value={input.instruction}
//                             onChange={(e) =>
//                               setInput((prevInput) => ({
//                                 ...prevInput,
//                                 instruction: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                         <button type="submit">Submit</button>
//                       </form>
//                       <div
//                       // className="flex gap-2 mt-16"
//                       >
//                         {/* <button
//                           type="button"
//                           className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium transition-all duration-300 ease-in-out border border-transparent rounded-md text-triklAccentBlue bg-triklAccentBlue/20 hover:bg-triklAccentBlue hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-triklAccentBlue/50 focus-visible:ring-offset-2"
//                           onClick={() => handleShareLinkedIn()}
//                           disabled={isLoadingPost}
//                         >
//                           {isLoadingPost ? (
//                             <BeatLoader color="#fff" size={8} />
//                           ) : (
//                             "Post"
//                           )}
//                         </button> */}

//                         {/* <img src={aiAssistantIllustration} alt="" /> */}

//                         <Button
//                           type="text"
//                           onClick={() => {
//                             closeModal();
//                           }}
//                           shape="circle"
//                           icon={<CloseOutlined />}
//                           className="absolute right-4 top-4"
//                         />
//                       </div>
//                     </div> */}
//
//
//
//
//

// {/* <Transition show={isOpen} as={Fragment}>
//         <Dialog as="div" className="relative z-10 " onClose={closeModal}>
//           <Dialog.Overlay className="fixed inset-0 bg-black/30" />
//           <div className="fixed inset-0 ">
//             <div
//               className="flex items-center justify-center h-screen"
//               // className="flex items-center justify-center min-h-full p-4 text-center"
//             >
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0 scale-95"
//                 enterTo="opacity-100 scale-100"
//                 leave="ease-in duration-400"
//                 leaveFrom="opacity-100 scale-100"
//                 leaveTo="opacity-0 scale-95"
//               >
//                 <Dialog.Panel className=" m-auto bg-linkedInBase overflow-y-auto rounded-lg flex justify-center min-w-[50vw] min-h-[50vh] max-w-[96vw] max-h-[96vh]">
//                   <main className="relative flex flex-col justify-center p-3 md:items-center">
//                     <Dialog.Title
//                       as="h1"
//                       className="pb-8 text-2xl font-medium leading-6 text-triklAccentBlue font-spaceGrotesk"
//                     >
//                       Create your custom Assistant
//                     </Dialog.Title>
//                     <div className="gap-5 ">
//                       <form
//                         className="flex flex-col gap-5 m-5"
//                         onSubmit={handleSubmit}
//                       >
//                         <div>
//                           <label className="pr-3"> Assistant Name </label>
//                           <input
//                             type="text"
//                             value={input.name}
//                             onChange={(e) =>
//                               setInput((prevInput) => ({
//                                 ...prevInput,
//                                 name: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                         <div>
//                           <label> Assistant Instruction </label>
//                           <input
//                             type="text"
//                             value={input.instruction}
//                             onChange={(e) =>
//                               setInput((prevInput) => ({
//                                 ...prevInput,
//                                 instruction: e.target.value,
//                               }))
//                             }
//                           />
//                         </div>
//                         <button type="submit">Submit</button>
//                       </form>
//                       <div className="flex gap-2 mt-16">
//                         {/* <button
//                           type="button"
//                           className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium transition-all duration-300 ease-in-out border border-transparent rounded-md text-triklAccentBlue bg-triklAccentBlue/20 hover:bg-triklAccentBlue hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-triklAccentBlue/50 focus-visible:ring-offset-2"
//                           onClick={() => handleShareLinkedIn()}
//                           disabled={isLoadingPost}
//                         >
//                           {isLoadingPost ? (
//                             <BeatLoader color="#fff" size={8} />
//                           ) : (
//                             "Post"
//                           )}
//                         </button> */}

//                         <button
//                           // disabled={isLoadingPost}
//                           onClick={() => {
//                             closeModal();
//                           }}
//                           className="px-4 py-2 font-semibold text-white rounded-lg bg-triklRed"
//                         >
//                           Cancel
//                         </button>
//                       </div>
//                     </div>
//                   </main>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog> */}
