import React, { useState } from "react";
import { ImEarth } from "react-icons/im";
import Avatar from "boring-avatars";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { FaRegComment } from "react-icons/fa";
import { AiOutlineLike } from "react-icons/ai";
import { BiRepost } from "react-icons/bi";
import { IoIosSend } from "react-icons/io";
import likeCommShare from "../../../../assets/Canvas/likeCommShare.svg";

// ZUSTAND
import { useFinalContentStore } from "../../ZustandStores/LinkedInZustandStore";

import {
  useUserMainDataStore,
  userAttachedFilesStore,
} from "../../ZustandStores/UserLinkedInStore";
import PdfViewer from "./PdfViewer";

const FinalizePost = () => {
  let { userData } = useLinkedInUserContext();
  const { finalContent } = useFinalContentStore();
  const [showFullText, setShowFullText] = useState(false);
  const { userMainData } = useUserMainDataStore();

  const displayedText = showFullText
    ? finalContent.finalText
    : finalContent.finalText.split(" ").slice(0, 22).join(" ") + " ";

  const markdownToHtml = (text) => {
    text = text.replace(
      /<span style="background-color: [^;]+;">(.*?)<\/span>/g,
      "$1"
    ); // Remove background color from spans
    text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    return text;
  };

  const htmlContent = markdownToHtml(displayedText);

  return (
    <>
      <section className="flex items-center justify-center h-auto gap-3 py-5 font-inter">
        <main className="text-gray-400 bg-white border rounded-md shadow-lg">
          {/* header */}
          <header className="flex items-center gap-2 p-1 px-5 pt-5">
            <Avatar
              size={50}
              name="Maria Mitchell"
              variant="marble"
              colors={["#F5EAFA", "#6F42C1", "#B38EFF", "#6F42C1", "#D7BFFF"]}
            />
            <aside className="text-start">
              <h4 className="font-semibold text-[14px] text-[#191919] ">
                {userData?.organizationName || userMainData?.name}
              </h4>
              <div className="text-[12px] text-[#666666]">followers</div>
              <p className="flex items-center gap-1 text-[#666666] text-[12px]">
                <span>16h. </span> <ImEarth className="w-3 h-3" />
              </p>
            </aside>
          </header>

          {/* text section */}
          <div
            // className="py-2 text-[14px] text-black text-justify px-5"
            className="px-5 text-black text-left text-[0.9rem] py-2"
            // style={textWidth}
          >
            <span as="span" dangerouslySetInnerHTML={{ __html: htmlContent }} />

            {/* {displayedText} */}
            {finalContent?.finalText.split(" ").length > 22 && (
              <button
                as="span"
                onClick={() => {
                  setShowFullText(!showFullText);
                }}
                className="text-[#4e3d3d] text-right w-full py-2"
              >
                {!showFullText ? "...see more" : "...see less"}
              </button>
            )}
          </div>

          {/* Display the type of media selected */}

          {finalContent?.finalMediaUrl &&
            finalContent?.finalMediaType === "video" && (
              <>
                {/* // Display the video */}
                <video controls src={finalContent?.finalMediaUrl}>
                  Your browser does not support the video tag.
                </video>
              </>
            )}
          {finalContent?.finalMediaUrl &&
            finalContent?.finalMediaType === "document" && (
              <>
                <PdfViewer fileUrl={finalContent?.finalMediaUrl} />
              </>
            )}

          {/* image section */}
          {finalContent?.finalMediaUrl &&
            finalContent?.finalMediaType === "image" && (
              <img
                src={finalContent.finalMediaUrl}
                alt="Final_TRIKL_image"
                className="w-full"
              />
            )}

          {/* likes  comments and other components  */}

          {/* interactions */}
          <div className="flex flex-row items-center text-xs justify-between py-4 px-5 text-[12px] text-[#666666]">
            <div className="flex gap-2">
              <img
                src={likeCommShare}
                className="object-fit "
                alt="likeCommentImage"
              />
              <div>Jax and 326 others</div>
            </div>
            <aside>73 comments . 37 reposts</aside>
          </div>

          {/* interactions */}
          <section className="flex items-center py-4 text-sm border-t border-gray-300 justify-evenly">
            <div className="flex items-center gap-1">
              <AiOutlineLike className="text-xl" /> Like
            </div>
            <div className="flex items-center gap-1">
              <FaRegComment className="text-xl" /> Comment
            </div>
            <div className="flex items-center gap-1">
              <BiRepost className="text-xl" /> Repost
            </div>
            <div className="flex items-center gap-1">
              <IoIosSend className="text-xl" /> Send
            </div>
          </section>
        </main>
      </section>
    </>
  );
};

export default FinalizePost;
