import React, { useRef, useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import { Spin } from "antd";
import Avatar from "boring-avatars";
import {
  useCarouselSettingsStore,
  userMediaFilesStore,
} from "../../../ZustandStores/UserMediaStore";
import {
  useCreatedImages,
  useFinalContentStore,
  useOverallStepStore,
} from "../../../ZustandStores/LinkedInZustandStore";
import { useUserMainDataStore } from "../../../ZustandStores/UserLinkedInStore";
import axios from "axios";
import { toast } from "react-toastify";
import { storeGeneratedOrUploadedImg } from "../../../function/LinkedInFunctions";
import BackgroundDesignSelector from "./BackgroundDesignSelector";

const CarouselMain = ({ setIsDrawerOpen, setSelectedMediaType }) => {
  // GLOBAL STATES
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();
  const { setUserMediaFiles } = userMediaFilesStore();
  const { setUserImagesArray } = useCreatedImages();
  const { setOverallStep } = useOverallStepStore();
  const { userMainData } = useUserMainDataStore();

  const markdownRefs = useRef(
    carouselStates?.editedTexts?.map(() => React.createRef())
  );

  // Local states
  const [creatingCarousel, setCreatingCarousel] = useState(false);

  // // CONVERT THE HTML ELEMENTS TO PDF
  const handleConvertToPDF = async () => {
    setCarouselStates(true);
    const doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: [2400, 3000],
    });

    try {
      await document.fonts.ready; // Ensure all fonts are loaded

      for (let i = 0; i < markdownRefs.current.length; i++) {
        const element = markdownRefs.current[i].current;
        if (!element) continue;

        // Temporarily adjust styles to ensure visibility
        const originalDisplay = element.style.display;
        const originalPosition = element.style.position;
        element.style.display = "block";
        element.style.position = "relative"; // Ensure it's not out of flow

        await new Promise((resolve) => setTimeout(resolve, 300)); // Allow styles to apply

        try {
          await htmlToImage
            .toPng(element, {
              // pixelRatio: window.devicePixelRatio, // Set the scale to device pixel ratio
              pixelRatio: 3, // Set the scale to device pixel ratio
              useCORS: true, // Use CORS for loading images
            })
            .then((imgData) => {
              if (i > 0) {
                doc.addPage();
              }
              // doc.addImage(imgData, "PNG", 0, 0, 1200, 1500);
              doc.addImage(imgData, "PNG", 0, 0, 2400, 3000);
            });
        } catch (canvasError) {
          console.error(
            `Error generating canvas for element ${i}:`,
            canvasError
          );
          continue; // Skip to the next element
        } finally {
          // Restore original styles
          element.style.display = originalDisplay;
          element.style.position = originalPosition;
        }
      }

      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      return pdfUrl;
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setCarouselStates(false);
    }
  };

  // Function to use Carousel as Media
  // ** Step 1 get the PDF blob
  // ** Step 2 Call backend API "v2/upload-media" to store the file in AWS and get the URL
  // ** Step 3 Set the PFD as active media URL
  const uploadCarousel = async () => {
    setCreatingCarousel(true);
    try {
      const pdfBlobUrl = await handleConvertToPDF();

      if (!pdfBlobUrl) {
        toast.error("Failed to generate PDF, please try again! ");
        console.error("Failed to generate PDF.");
        return;
      }
      const responseBlob = await fetch(pdfBlobUrl);
      const pdfBlob = await responseBlob.blob();

      const timestamp = new Date()?.toISOString()?.replace(/[-:.]/g, "");

      const formData = new FormData();
      formData.append("fileType", "document");
      const file = new File([pdfBlob], `trikl-carousel-${timestamp}.pdf`, {
        type: "application/pdf",
      });
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/upload-media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const mediaUrl = response?.data?.mediaUrl;

      setFinalContent({
        ...finalContent,
        finalMediaUrl: mediaUrl,
        finalMediaType: "document",
      });

      const imgURL = mediaUrl;
      const userInput = "";
      const userId = userMainData.userId;
      const imgType = "uploads";
      const mediaType = "document";
      await storeGeneratedOrUploadedImg(
        userId,
        userInput,
        imgURL,
        imgType,
        setUserImagesArray,
        mediaType,
        setUserMediaFiles
      );

      // if (
      //   finalContent?.finalText?.length > 0 ||
      //   finalContent?.finalMediaUrl?.length > 0
      // ) {
      //   setOverallStep(3);
      // } else {
      //   toast.info("Please select text or any media to proceed");
      // }
    } catch (error) {
      console.error("Error uploading carousel", error);
      toast.error("Something went wrong, please try again! ");
    } finally {
      setCreatingCarousel(false);
      setCarouselStates({ ...carouselStates, isUploadingPdf: false });
      setIsDrawerOpen(false);
      setSelectedMediaType("Images");
    }
  };

  // Download the carousel as a PDF
  const fetchData = async () => {
    setCreatingCarousel(true);
    try {
      if (carouselStates?.isCreatingPdf) {
        const pdfLink = await handleConvertToPDF();
        const a = document.createElement("a");
        a.href = pdfLink;
        a.download = "trikl-carousel.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();

        setCarouselStates({
          ...carouselStates,
          isCreatingPdf: false,
        });
      }
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setCreatingCarousel(false);
    }
  };

  // UPLOAD THE CAROUSEL TO THE SERVER
  useEffect(() => {
    fetchData();
    if (carouselStates?.isUploadingPdf) {
      uploadCarousel();
    }
  }, [carouselStates?.isUploadingPdf]);

  useEffect(() => {
    fetchData();
  }, [carouselStates?.isCreatingPdf]);

  // ---------

  return (
    <div className="relative items-center rounded-md">
      <Spin spinning={creatingCarousel} fullscreen />

      {/* SHOWING CAROUSEL SLIDES  */}
      {carouselStates?.editedTexts?.map((text, index) => (
        <div
          key={index}
          ref={markdownRefs.current[index]}
          id="finalCarousel"
          className={`flex relative flex-col text-left p-10 leading-relaxed border rounded shadow-sm ${
            index === carouselStates?.currentPage ? "block" : "hidden"
          }
          h-[600px] w-[480px]  pb-16
          `}
          style={{
            // width: "480px",
            // height: "600px",
            backgroundColor: `${carouselStates.colorPalette.background}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Just the template excluding text */}
          <BackgroundDesignSelector />

          <div className="flex flex-col items-start justify-center h-full gap-2 ">
            {/*Slide number */}
            {index > 0 && carouselStates.pageNumberVisible && (
              <div className="absolute bottom-10 right-10">
                <p
                  style={{
                    color: carouselStates.colorPalette.primary,
                  }}
                >
                  {index} / {carouselStates?.editedTexts.length - 1}
                </p>
              </div>
            )}

            {/* Title  */}
            {carouselStates?.editedTexts[index].titleActive && (
              <p
                className="text-4xl font-bold "
                // style={{ color: carouselStates.colorPalette.primary }}
                style={{
                  color: carouselStates.colorPalette.primary,
                  letterSpacing: "0.5px", // To ensure the same stays true when converting to PDF
                  wordSpacing: "1px", // To ensure the same stays true when converting to PDF
                  lineHeight: "1.5", // To ensure the same stays true when converting to PDF
                }}
              >
                {carouselStates?.editedTexts[index].title}
              </p>
            )}
            {/* Description */}
            {carouselStates?.editedTexts[index].descriptionActive && (
              <p
                className="text-lg"
                style={{ color: carouselStates.colorPalette.secondary }}
              >
                {carouselStates?.editedTexts[index].description}
              </p>
            )}
          </div>
          {/* Show the headshot */}
          {carouselStates?.headshotData?.visible && (
            <div className="absolute z-10 flex items-center justify-start gap-2 w-max bottom-8 left-8 ">
              {/* Check if image is visible is enabled */}
              {carouselStates?.headshotData?.imageVisible && (
                <>
                  {carouselStates?.headshotData?.image ? (
                    <img
                      src={carouselStates?.headshotData?.image}
                      className="w-10 rounded-full aspect-square"
                      alt="your image"
                    />
                  ) : (
                    <Avatar size={40} variant="beam" />
                  )}
                </>
              )}

              {/* Showing Name and profile link */}
              <div className="flex flex-col ">
                {carouselStates?.headshotData?.nameVisible && (
                  <h5
                    className="font-semibold "
                    style={{
                      color: carouselStates.colorPalette.primary,
                    }}
                  >
                    {carouselStates?.headshotData?.name}
                  </h5>
                )}
                {carouselStates?.headshotData?.profileLinkVisible && (
                  <p
                    className="text-xs"
                    style={{
                      color: carouselStates.colorPalette.secondary,
                    }}
                  >
                    @{carouselStates?.headshotData?.profileLink}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CarouselMain;

//  Change the carousel setting store to contain the values for storing primary & secondary colour
// Also make a dynamic asset adding component which would change the background filter on the slide
// based on the colour selected by the user change the colour of the asset
// Change the title, subtitle of slide from the sidebar
// Hide or unhide the components of the slide

// Add more pages to the slide and navigate through them

// Filters :
// Adding headshots, Name, links
// Theme:
// Change the background of the slide
// color pallet for the carousel

// Preview:
// Show the carousel preview
// Download :
// Download the carousel
