import React from "react";
import TopWordsInPostSide from "./PostSideBarElements/TopWordsInPostSide";
import AIPromptTemplates from "./PostSideBarElements/AIPromptTemplates";

const MainPostSide = ({
  chatId,
  platformName,
  setInputValue,
  aiRecommendation,
  scrollToBottom,
  topWordArray,
  communityTag,
}) => {
  // DEFINING PROPS

  const aIPromptTemplatesProps = {
    aiRecommendation,
    setInputValue,
    scrollToBottom,
  };

  return (
    <aside className="w-full h-screen px-5 pt-5 mt-12 overflow-y-scroll border-gray-500 md:h-screen md:w-1/3 md:border-l pb-36">
      <div className="flex flex-col gap-5">
        <TopWordsInPostSide topWordArray={topWordArray} />
        <AIPromptTemplates {...aIPromptTemplatesProps} />
      </div>
    </aside>
  );
};

export default MainPostSide;
