import React, { useState, memo, useEffect, useRef } from "react";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import {
  useCanvasSideStepStore,
  useFinalizedTopicFromStore,
  useNewsLoadingStore,
  useCurrentPostTypeStore,
  useContentUrlStore,
  useFinalContentStore,
} from "../../ZustandStores/LinkedInZustandStore";
import { handleUrlValidation } from "../../function/LinkedInFunctions";
import { toast } from "react-toastify";
import axios from "axios";
import { useCalendarContentGenStore } from "../../ZustandStores/ContentCalendarStore";
import { AiOutlineAudio, AiOutlineStop } from "react-icons/ai";
import Lottie from "lottie-react";
import { RecordingIcon } from "../../../../assets/Canvas";
import { MdDeleteOutline } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import { UploadOutlined } from "@ant-design/icons";
import { BeatLoader } from "react-spinners";
import { Button, Modal } from "antd";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import Avatar from "boring-avatars";
import { useNavigate } from "react-router-dom";
import TemplateSelectionModal from "../postTemplateSelection/TrendingSelectionModal";

const TopicSelection = ({
  ManualData,
  setManualData,
  setSelectedTopic,
  selectedTopic,
}) => {
  // ZUSTAND STORE CALLS
  const { userData } = useLinkedInUserContext();
  const { setIsLoadingNAT } = useNewsLoadingStore();
  const { finalizedTopic, setFinalizedTopic } = useFinalizedTopicFromStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { currentPostType } = useCurrentPostTypeStore();
  const [userTags, setUserTags] = useState(userData?.userTags || []);
  const [currentInput, setCurrentInput] = useState("");
  const [clickedItem, setClickedItem] = useState(null);

  const handleSubmit = async () => {
    if (currentInput && !userTags.includes(currentInput)) {
      const updatedTags = [...userTags, currentInput];
      setUserTags(updatedTags);
      await handleUpdateUserTags(updatedTags);
    }
    setCurrentInput("");
    setFinalizedTopic(selectedTopic);
    setCanvasSideStep(3);
    setIsLoadingNAT(true);
  };
  // remove tag from userTags
  const handleRemoveTag = async (tagToRemove) => {
    const updatedTags = userTags.filter((tag) => tag !== tagToRemove);
    setUserTags(updatedTags);

    await handleUpdateUserTags(updatedTags);
  };

  // on button click or enter key press submit the topic
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleClick = (item) => {
    setClickedItem(item);
    setSelectedTopic(item);
  };

  // update user tags
  const handleUpdateUserTags = async (updatedTags) => {
    try {
      const updatedTagsArray = axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/updateUserTags`,
        {
          userId: userData?.userId,
          tags: updatedTags,
        }
      );
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  const renderContentBasedOnStepper = () => {
    if (
      currentPostType === "Latest News" ||
      currentPostType === "Manually Search News"
    ) {
      return (
        <>
          {/* Company topics selected */}
          <div className="flex flex-wrap gap-4 pb-4">
            {userTags?.map((item, index) => (
              <div
                className={`group relative flex gap-1 p-2 rounded-md ${
                  selectedTopic === item
                    ? "bg-lightBlue text-triklAccentBlue"
                    : "bg-lightGrey"
                }`}
                key={`${item}-${index}`}
              >
                <button onClick={() => handleClick(item)}>{item}</button>
                <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100">
                  <IoCloseCircle
                    className="text-red-500 cursor-pointer"
                    onClick={() => handleRemoveTag(item)}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Manual data section */}
          <main className="flex items-center justify-start gap-4 pr-2 text-gray-500">
            <div className="py-3 min-w-max">or search manually</div>
            <input
              type="text"
              placeholder="Enter any topic like ai, fashion, etc."
              value={currentInput}
              onChange={(e) => {
                setSelectedTopic(e.target.value);
                setCurrentInput(e.target.value);
              }}
              onKeyDown={handleKeyPress}
              className="block w-full px-4 py-2 text-sm border rounded-md outline-none"
            />
          </main>

          <div className="flex w-full pt-8">
            <button
              // disabled={finalizedTopic === ""}
              className="object-center px-4 py-1 font-semibold border rounded-lg text-triklAccentBlue w-fit border-triklAccentBlue"
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </>
      );
    } else if (currentPostType === "Create From Link") {
      return (
        <>
          <UrlToContent />
        </>
      );
    } else if (currentPostType === "Manually Add Data") {
      return (
        <>
          <ManuallyAddData />
        </>
      );
    } else if (currentPostType === "Create From Audio") {
      return (
        <>
          <AudioToContent />
        </>
      );
    }
    // else if (currentPostType === "Inspiration profiles") {
    //   return (
    //     <>
    //       <InspirationProfiles />
    //     </>
    //   );
    // }
  };

  return (
    <div className="pt-4">
      {/* Manual data */}
      {renderContentBasedOnStepper()}
      {/* footer buttons */}
    </div>
  );
};

////////////////////////////////////

const ManualDataModule = ({ setSelectedTopic, handleSubmit }) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Prevent the default action to avoid form submission or any other unwanted behavior
      event.preventDefault();

      handleSubmit();
    }
  };

  return (
    <main className="flex items-center justify-start gap-4 pr-2 text-gray-500">
      <div className="py-3 min-w-max">or search manually</div>
      <input
        type="text"
        placeholder="Enter any topic like ai, fashion, etc."
        onChange={(e) => {
          setSelectedTopic(e.target.value);
        }}
        onKeyDown={handleKeyPress}
        className="block w-full px-4 py-2 text-sm border rounded-md outline-none"
      />
    </main>
  );
};

///
const UrlToContent = () => {
  const { setCurrentUrl } = useContentUrlStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const [isValidUrl, setIsValidUrl] = useState(true); // State to track URL validity

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setCurrentUrl(inputValue);

    // Validate the URL using the handleUrlValidation function
    const isValid = handleUrlValidation(inputValue);
    setIsValidUrl(isValid);
  };
  // handleUrlValidation
  return (
    <>
      <input
        type="text"
        placeholder="Paste link here..."
        onChange={handleInputChange}
        className="block w-full px-4 py-3 text-sm border rounded-lg outline-none"
      />
      <div className="flex w-full pt-4">
        <button
          className={`object-center px-4 py-2 font-semibold border rounded-lg ${
            isValidUrl
              ? "text-triklAccentBlue w-fit border-triklAccentBlue"
              : "text-darkGrey border-darkGrey bg-gray-100"
          }  w-fit`}
          onClick={() => {
            setCanvasSideStep(4);
          }}
          disabled={!isValidUrl}
        >
          Continue
        </button>
      </div>
    </>
  );
};

//
const ManuallyAddData = () => {
  const { userLinkedInData } = useUserLinkedInStore();
  const { setCurrentUrl } = useContentUrlStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { calendarContent, setCalendarContent } = useCalendarContentGenStore();
  const [textAreaContent, setTextAreaContent] = useState("");

  // Effect to set initial textarea content if calendarContent.textContent has any text
  useEffect(() => {
    if (calendarContent.textContent && calendarContent.textContent.length > 0) {
      setTextAreaContent(calendarContent.textContent);
    }
  }, [calendarContent.textContent]); // Depend on calendarContent.textContent for updates

  // Handler for textarea changes to update both local state and potentially some global state or URL
  const handleTextAreaChange = (event) => {
    setTextAreaContent(event.target.value);
    // Assuming you want to keep the global state or URL in sync with the textarea
    setCurrentUrl(event.target.value);
  };

  return (
    <>
      <textarea
        placeholder="Add what comes to your mind..."
        onChange={handleTextAreaChange}
        value={textAreaContent} // Controlled component
        rows={5}
        className="block w-full px-4 py-3 text-sm border rounded-lg outline-none"
      />

      <div className="flex w-full pt-4">
        <button
          className="object-center px-4 py-2 font-semibold border rounded-lg text-triklAccentBlue w-fit border-triklAccentBlue"
          onClick={() => {
            setCanvasSideStep(4);
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
};

/// Create content from audio
const AudioToContent = () => {
  const inputFileRef = useRef();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { currentUrl, setCurrentUrl } = useContentUrlStore();
  const audioChunks = [];
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadedFile, setUploadedFile] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isGeneratingTxt, setIsGeneratingTxt] = useState(false);
  const [isTranscript, setIsTranscript] = useState(false);
  const [openTranscriptModal, setOpenTranscriptModal] = useState(false);

  // Handling the file upload
  const handleFileUpload = async (e) => {
    inputFileRef.current.click();
    try {
      e.preventDefault();

      const audioFile = e.target.files[0];
      if (!audioFile) {
        toast.error("select an audio file first!");
        return;
      }

      setUploadedFile(audioFile);
      if (audioFile) {
        setFileUrl(URL.createObjectURL(audioFile));
      }
    } catch (error) {
      console.error("error >>>", error);
    }
  };

  // Handle Recording
  const handleRecording = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        // Starting the stream:
        const newMediaRecorder = new MediaRecorder(stream);
        newMediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };
        newMediaRecorder.start(1000);
        // Check if the browser supports MP4 format for MediaRecorder
        let options = {};
        if (MediaRecorder.isTypeSupported("audio/mp4")) {
          options = { mimeType: "audio/mp4" };
        }
        newMediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/mp4" }); // Ensure correct MIME type is set

          setUploadedFile(audioBlob);
          const newAudioUrl = URL.createObjectURL(audioBlob);
          setFileUrl(newAudioUrl);
          audioChunks.length = 0; // Reset the chunks array for a new recording session
          // Stop each track on the stream to close it properly
          stream.getTracks().forEach((track) => track.stop());
        };
        setMediaRecorder(newMediaRecorder);
      } catch (error) {
        setIsRecording(!isRecording); // To toggle lottie animation:
        toast.error("Something went wrong! Please try again");
      }
    } else {
      mediaRecorder.stop(); // If the button is already clicked, stop the recording
    }
    setIsRecording(!isRecording); // To toggle lottie animation:
  };

  // Handle file delete
  const handleDeleteFile = async () => {
    setUploadedFile(null);
    setFileUrl(null);
    setCanvasSideStep(2);
  };

  // Convert uploaded file to playable audio
  const getFileUrl = () => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile);
    }
    return fileUrl;
  };

  //handle continue button
  const handleContinueClick = async (uploadedFile) => {
    try {
      setIsGeneratingTxt(true);

      const formData = new FormData();
      formData.append("audioFile", uploadedFile, "audio.mp4");

      const audioTranscriptResponse = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/audio/get-text`,
        formData
      );

      if (!audioTranscriptResponse) {
        return;
      }
      setCurrentUrl(audioTranscriptResponse.data.transcript);
      setIsTranscript(true);
      setCanvasSideStep(4);
    } catch (error) {
      toast.error("something went wrong, please try again!");
    } finally {
      setIsGeneratingTxt(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full gap-3">
      <input
        type="file"
        accept="audio/mpeg, audio/wav, audio/ogg, audio/mp4"
        ref={inputFileRef}
        className="mb-4"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />

      {!fileUrl ? (
        !isRecording ? (
          <div className="flex items-center justify-center w-full gap-2 my-2">
            <Button
              className="bg-triklAccentBlue text-white  border-triklAccentBlue box-border rounded-md border-[1px]  px-8 py-1 font-semibold items-center justify-center"
              onClick={() =>
                inputFileRef.current && inputFileRef.current.click()
              }
              icon={<UploadOutlined />}
            >
              Upload file
            </Button>
            <Button
              className="flex items-center justify-center"
              type="default"
              onClick={handleRecording}
              icon={<AiOutlineAudio />}
            >
              Record audio
            </Button>
          </div>
        ) : (
          <button
            as="div"
            className="flex flex-col items-center justify-around w-full my-2"
            onClick={() => {
              handleRecording();
            }}
          >
            <Lottie
              as="div"
              className="h-40 p-3 aspect-square"
              animationData={RecordingIcon}
            />
            <h1 className="w-full text-xs text-triklRed">(Click to stop)</h1>
          </button>
        )
      ) : (
        <div className="flex items-center justify-center w-full gap-2 my-2">
          <audio controls src={getFileUrl()}>
            Your browser does not support the <code>audio</code> element.
          </audio>
          <button
            className="p-1 text-2xl text-triklRed"
            onClick={handleDeleteFile}
          >
            <MdDeleteOutline />
          </button>
        </div>
      )}

      {/* Continue button */}
      <div className="flex w-full pt-4">
        {isGeneratingTxt ? (
          <div className="py-2 font-semibold text-center text-white rounded-md px-7 bg-triklAccentBlue font-spaceGrotesk">
            <BeatLoader color="#fff" size={8} />
          </div>
        ) : (
          <button
            disabled={fileUrl === null && isGeneratingTxt}
            className={`object-center px-4 py-2 font-semibold border rounded-lg  ${
              !isGeneratingTxt
                ? "text-triklAccentBlue border-triklAccentBlue"
                : " text-gray-500 border-gray-600"
            }  w-fit`}
            onClick={async () => {
              await handleContinueClick(uploadedFile);
            }}
          >
            Continue
          </button>
        )}
        {isTranscript && (
          <button
            className="px-3 text-black"
            onClick={() => setOpenTranscriptModal(true)}
            disabled={currentUrl === "" && isGeneratingTxt}
          >
            Show Transcript
          </button>
        )}
      </div>

      <TranscriptModal
        isOpen={openTranscriptModal}
        onClose={() => setOpenTranscriptModal(false)}
        transcript={currentUrl}
      />
    </div>
  );
};

// Inspiration profiles
const InspirationProfiles = () => {
  let navigate = useNavigate();
  const { userLinkedInData } = useUserLinkedInStore();
  const { setFinalContent, finalContent } = useFinalContentStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedProfileIndex, setSelectedProfileIndex] = useState();

  const handleProfileSelection = (profile, index) => {
    setSelectedProfileIndex(index);
    setSelectedProfile(profile);
    setFinalContent({
      ...finalContent,
      finalInspirationProfile: "Koushik Sherugar",

      // finalInspirationProfile: profile?.profile_name,
    });
  };

  return (
    <div className="border rounded-md ">
      {!userLinkedInData?.inspiration_Profiles?.length > 0 ? (
        <div className="px-8 py-16 h-30">
          <p
            className="text-xl font-semibold"
            onClick={() => {
              setSelectedProfile("Added");
            }}
          >
            Select Your Inspiration
          </p>
          <p
            className="mt-2 font-semibold underline text-triklAccentBlue"
            onClick={() => {
              navigate("/linkedin/inspiration");
            }}
          >
            Add inspiration
          </p>
        </div>
      ) : (
        <div className="p-4 ">
          <h4 className="my-2 text-lg font-semibold text-left">
            Your Inspirational Profiles
          </h4>
          <div className="flex flex-wrap items-center justify-start gap-3">
            {userLinkedInData?.inspiration_Profiles?.map((profile, index) => (
              <>
                <div
                  className={`
                  flex flex-col items-center justify-center w-32 gap-2 p-2 ${
                    selectedProfileIndex === index &&
                    "bg-tNewPrimaryColor text-triklAccentBlue font-semibold border-triklAccentBlue"
                  } border rounded-md cursor-pointer h-28 
                  `}
                  key={index}
                  onClick={() => {
                    handleProfileSelection(profile, index);
                  }}
                >
                  <Avatar
                    size={50}
                    name="Maria Mitchell"
                    variant="marble"
                    colors={[
                      "#F5EAFA",
                      "#6F42C1",
                      "#B38EFF",
                      "#6F42C1",
                      "#D7BFFF",
                    ]}
                  />

                  <p>
                    {profile?.profile_name?.length > 10
                      ? `${profile.profile_name.substring(0, 10)}...`
                      : profile.profile_name}
                  </p>
                </div>
              </>
            ))}
          </div>

          <Button
            disabled={!selectedProfile}
            onClick={() => {
              setCanvasSideStep(4);
            }}
            type="default"
            size="large"
            className="w-full mt-4 border text-triklAccentBlue border-triklAccentBlue"
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};
export default TopicSelection;

const TranscriptModal = ({ isOpen, onClose, transcript }) => {
  return (
    <>
      <Modal
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        footer={null}
      >
        {/* <p>Hello world</p> */}
        <main className="flex flex-col items-center justify-center w-full h-full text-left">
          <h1 className="text-lg"> Your Transcript </h1>
          <p className="p-6"> {transcript} </p>
        </main>
      </Modal>
    </>
  );
};
