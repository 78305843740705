import { Transition } from "@headlessui/react";
import Modal from "react-modal";
import React, { Fragment } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PropagateLoader } from "react-spinners";

const ProductTour = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
    // localStorage.setItem("userTourModalOpen", false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        className="absolute z-30 flex items-center justify-center w-screen h-screen mt-4 bg-black/50 backdrop-blur-sm"
      >
        <Transition appear show={true} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <main
              className="static flex px-10 bg-white rounded-md "
              // className="flex flex-row"
            >
              <div
                className="absolute cursor-pointer text-gray-500/50 hover:text-triklRed top-5 right-5"
                onClick={handleClose}
              >
                <AiOutlineCloseCircle className="hidden w-8 h-8 md:block" />
              </div>

              <main className="flex flex-col items-center justify-center w-full h-full p-4 text-lg text-left">
                <span className="mb-6 text-lg font-bold text-triklAccentBlue">
                  Get Started Using TRIKL.
                </span>

                <iframe
                  src="https://www.loom.com/embed/ac8094f534cf48978430869b8a94461a?hideEmbedTopBar=true&t=20s"
                  frameBorder="0"
                  allowFullScreen
                  height={500}
                  width={800}
                ></iframe>

                <button
                  className="px-4 py-2 mt-4 text-sm font-semibold text-white rounded-md bg-triklAccentBlue hover:bg-triklAccentBlueDark"
                  onClick={handleClose}
                >
                  Close
                </button>
              </main>
            </main>
          </Transition.Child>
        </Transition>
      </Modal>
    </>
  );
};

export default ProductTour;
