const V2BackgroundTemplate8 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3492)">
        <path
          d="M339.5 557.246C210.451 660.427 55 636.412 4 605.625L0 603.426V665H535V464.885C528 461.22 460.5 460.5 339.5 557.246Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.15"
        />
        <path
          d="M269.625 523.526L269.629 523.525C312.17 513.937 345.426 478.955 352.838 436.12L352.838 436.119C353.506 432.248 353.858 428.293 354.205 424.39C354.328 423.014 354.45 421.646 354.585 420.289C355.967 448.815 366.363 473.64 386.752 494.02C407.079 514.397 431.825 524.847 460.256 526.283C451.218 526.713 442.286 528.077 433.521 530.657L433.513 530.66C405.751 539.059 384.445 555.811 369.57 580.633L369.569 580.634C359.994 596.668 355.2 614.125 354.56 632.739C354.167 623.353 352.976 614.044 350.026 605.015C337.086 565.032 310.164 539.554 269.41 529.106L269.406 529.105C264.495 527.867 259.323 527.373 254.244 526.889C252.912 526.762 251.587 526.635 250.275 526.496C251.383 526.35 252.489 526.208 253.592 526.067C259.029 525.37 264.4 524.682 269.625 523.526ZM354.518 419.035C354.518 419.035 354.519 419.034 354.521 419.034L354.518 419.035Z"
          stroke={colorPallet?.secondary}
          stroke-width="2"
        />
        <path
          d="M0 112.222C10.8065 115.899 107.293 109.864 301.811 19.5541C312.36 14.3739 341.177 4.01328 379 0H0V112.222Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.15"
        />
        <path
          d="M502.228 429.4C500.158 429.722 498.103 430.176 496.067 430.782L496.06 430.784C483.895 434.503 474.553 441.929 468.039 452.914L468.038 452.915C464.902 458.224 462.936 463.883 462.026 469.84C461.712 467.774 461.256 465.724 460.602 463.702C460.602 463.702 460.602 463.702 460.602 463.701L459.65 464.009M502.228 429.4L417.377 428.987C417.773 429.028 418.174 429.066 418.578 429.105C419.573 429.201 420.587 429.299 421.598 429.428C420.514 429.589 419.422 429.731 418.331 429.872C418.017 429.913 417.703 429.953 417.39 429.995C417.781 430.034 418.174 430.072 418.566 430.11C420.752 430.321 422.939 430.531 425.033 431.065C442.542 435.602 454.09 446.646 459.65 464.009M502.228 429.4C492.083 427.825 483.124 423.331 475.566 415.674L502.228 429.4ZM459.65 464.009C460.769 467.469 461.298 471.032 461.522 474.643L459.65 464.009Z"
          stroke={colorPallet?.secondary}
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate8;
