import React from "react";
import axios from "axios";
import { useFinalContentStore } from "../../ZustandStores/LinkedInZustandStore";

const ShareOnLinkedIn = () => {
  const { finalContent } = useFinalContentStore();

  // const handleShareLinkedIn = async () => {
  //   // Open a blank tab
  //   let newTab = window.open("", "_blank");

  //   try {
  //     const newentry = await axios.post(
  //       `${process.env.REACT_APP_SERVERURL}/share/post`,
  //       {
  //         text: finalContent.finalText,
  //         image: finalContent.finalMediaUrl,
  //       }
  //     );

  //     const newPath = `${window.location.origin}/linkedin/share/${newentry.data._id}`;
  //     // Update the new tab's URL
  //     if (window.confirm("Do you want to open a new tab?")) {
  //       newTab.location.href = newPath;
  //     }
  //   } catch (error) {
  //     console.error("Error sharing on LinkedIn:", error);
  //     newTab.close();
  //   }
  // };

  const handleShareLinkedIn = () => {
    // Prompt the user first for immediate action
    if (window.confirm("Do you want to open a new tab ?")) {
      // Open a new tab with a placeholder or loading page
      let newTab = window.open("", "_blank");
      axios
        .post(`${process.env.REACT_APP_SERVERURL}/share/post`, {
          text: finalContent.finalText,
          image: finalContent.finalMediaUrl,
        })
        .then((newentry) => {
          const newPath = `${window.location.origin}/linkedin/share/${newentry.data._id}`;
          // Update the new tab's URL
          newTab.location.href = newPath;
        })
        .catch((error) => {
          console.error("Error sharing on LinkedIn:", error);
          newTab.close();
        });
    }
  };

  return (
    <>
      <button
        className="flex items-center justify-center w-full p-4 overflow-hidden text-base font-semibold text-triklAccentBlue disabled:text-triklAccentBlue/70"
        onClick={() => handleShareLinkedIn()}
      >
        Share
      </button>
    </>
  );
};

export default ShareOnLinkedIn;
