import React from "react";
import { BglightCircle } from "../../../../assets/background";
import { Button, Col, Form, Input, Row } from "antd";
import { triklPurple } from "../../../../assets/Trikl_logo";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../../context/userAuthContext";
import { toast } from "react-toastify";
import { confirmPasswordReset, getAuth } from "firebase/auth";

const ResetPassword = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [isLoading, setIsLoading] = React.useState(false);
  const { resetPassword } = useUserAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = ({ password, confirmPassword }) => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const auth = getAuth();
    const oobCode = new URLSearchParams(window.location.search).get("oobCode");
    setIsLoading(true);

    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        toast.success("Password reset successfully");
        navigate("/user/signin");
      })
      .catch((error) => {
        console.error("Error resetting password", error);
        toast.error("Failed to reset password");
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="min-h-screen bg-white bg-no-repeat top bg-">
        <div className="">
          <div className="absolute flex justify-center">
            <img className="" src={BglightCircle} alt="" />
          </div>

          <Row justify="center">
            <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
              <div className="flex justify-center mt-6">
                <img src={triklPurple} alt="Trikl" className="w-28 h-28" />
              </div>
              <div className="bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
                <div className="px-10 pt-8 pb-6">
                  <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                      name="password"
                      label="New Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your new password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm New Password"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your new password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="mt-3"
                        type="primary"
                        htmltype="submit"
                        loading={isLoading}
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="p-6 text-center bg-section dark:bg-white10 rounded-b-md">
                  <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                    Return to &nbsp;
                    <Link
                      to="/user/signin"
                      className="ltr:ml-1.5 rtl:mr-1.5 text-info text-triklAccentBlue hover:text-triklAccentBlue/50"
                    >
                      Sign In
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
