import React, { useEffect, useRef, useState } from "react";
import {
  BiDownload,
  BiImageAdd,
  BiLayerMinus,
  BiLayerPlus,
} from "react-icons/bi";
import { Tooltip } from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";
import { uploadImageToCloudinary } from "../../function/LinkedInFunctions";
import { BsFillLayersFill, BsLayersHalf } from "react-icons/bs";
import { MdTextFormat } from "react-icons/md";
import { fabric } from "fabric";
import { toast } from "react-toastify";
import { fontFamilyData } from "./CanvasEditorData";
import { useCanvasImageUrlStore } from "../../ZustandStores/CanvasEditorStore";

const FabricCanvas = ({ finalImageURL }) => {
  // React hooks
  const [canvas, setCanvas] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const canvasRef = useRef(null);

  //Zustand
  const { setCanvasImageUrl } = useCanvasImageUrlStore();

  //************************** functions to handle canvas changes ************************/

  // Function to download canvas as an image
  const downloadCanvas = () => {
    try {
      const dataURL = canvas.toDataURL({
        format: "png",
        quality: 1,
      });

      const a = document.createElement("a");
      a.href = dataURL;
      a.download = "canvas_image.png";
      a.click();
    } catch (error) {
      toast.error("Error in Downloading an Image!");
    }
  };

  // To delete the selected Object:
  const deleteSelectedObject = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.remove(activeObject);
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  };

  // MAPPING THE BUTTONS
  const buttonData = [
    { text: "Add Text", icon: <MdTextFormat />, action: "" },
    {
      text: "Add Logo",
      icon: <BiImageAdd />,
      action: "",
    },
    {
      text: "Layers",
      icon: <BsLayersHalf />,
      action: "",
    },
    {
      text: "Delete Selected",
      icon: <AiOutlineDelete className="text-triklRed" />,
      action: deleteSelectedObject,
    },
    {
      text: "Download Canvas",
      icon: <BiDownload className="text-triklAccentBlue" />,
      action: downloadCanvas,
    },
  ];

  // Function to handle toolbar button clicks
  const handleButtonClick = (button) => {
    // If the button's component is already selected, deselect it. Otherwise, select it.
    if (
      selectedComponent === "Add Text" ||
      selectedComponent === "Add Logo" ||
      selectedComponent === "Layers"
    ) {
      setSelectedComponent(null);
    } else {
      setSelectedComponent(button.text);
      // Call the button's action if it's a function
      if (typeof button.action === "function") {
        button.action();
      }
    }
  };

  // TO SET THE CANVAS MAIN COMPONENT
  useEffect(() => {
    try {
      // launch a new canvas
      let newCanvas;

      //Initial settings
      newCanvas = new fabric.Canvas(canvasRef?.current, {
        backgroundColor: "aliceblue",
        preserveObjectStacking: true,
        enableRetinaScaling: true,
        devicePixelRatio: 2,
      });

      // Load the image from finalImageURL
      fabric.Image.fromURL(
        finalImageURL,
        (img) => {
          // Calculate scaling factors for width and height
          const scaleFactorWidth = Math.min(512, img.width) / img.width;
          const scaleFactorHeight = Math.min(512, img.height) / img.height;

          // Use the smaller scaling factor to maintain aspect ratio
          const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

          // Set the canvas size
          newCanvas.setWidth(img.width * scaleFactor);
          newCanvas.setHeight(img.height * scaleFactor);

          // Scale the image to fit inside the canvas without distortion
          img.scale(scaleFactor);

          // Add the image to the canvas
          newCanvas.add(img);

          // Set the canvas state
          setCanvas(newCanvas);
        },
        { crossOrigin: "Anonymous" }
      );
    } catch (error) {
      console.error("Error initializing canvas:", error);
    }
  }, []);

  // TO HANDLE THE CANVAS MODIFICATIONS
  useEffect(() => {
    try {
      if (canvas) {
        // actions when canvas is modified
        const handleCanvasModified = () => {
          // setCanvasData(canvas.toJSON());
          const dataURL = canvas.toDataURL({
            format: "png",
            quality: 1,
            multiplier: 2, // or higher for better quality
          });

          setCanvasImageUrl(dataURL);
        };

        canvas.on("object:modified", handleCanvasModified);
        canvas.on("object:added", handleCanvasModified);
        canvas.on("object:removed", handleCanvasModified);
        return () => {
          canvas.off("object:modified", handleCanvasModified);
          canvas.off("object:added", handleCanvasModified);
          canvas.off("object:removed", handleCanvasModified);
        };
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again!");
    }
  }, [canvas]);

  return (
    <div className="relative mt-10">
      {/* Editor components */}
      <section className="static">
        <div className="flex flex-wrap justify-between gap-3 ">
          {/* Main buttons */}
          {buttonData.map((eachButton, index) => {
            return (
              <Tooltip title={eachButton.text} placement="bottom" key={index}>
                <div
                  onClick={() => handleButtonClick(eachButton)}
                  key={index}
                  className="flex items-center p-3 transition-all duration-500 ease-in-out cursor-pointer hover:bg-gray-300"
                >
                  <span>{eachButton.icon}</span>
                </div>
              </Tooltip>
            );
          })}
        </div>
        {/* Other setting button */}
        <div className="absolute px-4 py-1 rounded-md -top-12">
          <RenderSubcomponents
            selectedComponent={selectedComponent}
            canvas={canvas}
            setCanvasImageUrl={setCanvasImageUrl}
          />
        </div>
      </section>
      {/* Canvas Section */}
      <canvas className="pb-4 my-4" ref={canvasRef} />
    </div>
  );
};

export default FabricCanvas;

// Image Upload functionality
const AssetUpload = ({ addAssetImageParam }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        try {
          const imageUrl = await uploadImageToCloudinary(
            reader.result,
            setUploadProgress
          );
          addAssetImageParam(imageUrl);
        } catch (error) {
          console.error("Error uploading the image:", error);
        }
      };
    }
  };

  return (
    <Tooltip title="Upload Asset" placement="bottom">
      <div className="flex items-center gap-2 px-6 py-1 rounded-md">
        <label
          htmlFor="fileInput"
          className="font-semibold cursor-pointer hover:text-triklAccentBlue"
        >
          Choose Asset
        </label>
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          accept=".png"
          className="hidden"
        />
        {uploadProgress > 0 && uploadProgress < 100 && (
          <p>Uploading: {uploadProgress}%</p>
        )}
        {uploadProgress === 100 && <p>Upload complete!</p>}
      </div>
    </Tooltip>
  );
};

// TO DISPLAY THE SUBCOMPONENTS
const RenderSubcomponents = ({
  selectedComponent,
  canvas,
  setCanvasImageUrl,
}) => {
  const [fontFamily, setFontFamily] = useState("sans");
  const [color, setColor] = useState("#000000");

  // Function to add text to the canvas
  const addTextToCanvas = () => {
    if (!canvas) return; // Checking if canvas is available

    try {
      const text = new fabric.Textbox("Your text here", {
        left: 100,
        top: 100,
        fontFamily: fontFamily,
        fill: color,
      });

      canvas.add(text);
    } catch (error) {
      toast.error("Something went wrong, Please try again!");
    }
  };

  // Changing the layer of the Objects:
  const bringTotalFront = async () => {
    const objSelected = await canvas.getActiveObject();
    if (objSelected) canvas.bringToFront(objSelected);
    canvas.renderAll();
  };
  const bringOneStepFront = async () => {
    const objSelected = await canvas.getActiveObject();

    if (objSelected) canvas.bringForward(objSelected);
    canvas.renderAll();
  };
  const goTotallyBack = async () => {
    const objSelected = await canvas.getActiveObject();

    if (objSelected) canvas.sendToBack(objSelected);
    canvas.renderAll();
  };
  const goOneStepBack = async () => {
    const objSelected = await canvas.getActiveObject();

    if (objSelected) canvas.sendBackwards(objSelected);
    canvas.renderAll();
  };

  // Function to add an image to the canvas
  const addAssetImage = (assetImage) => {
    if (!canvas) return;
    new fabric.Image.fromURL(
      assetImage,
      (img) => {
        img.scale(0.2);
        canvas.add(img);
        canvas.renderAll();
      },
      { crossOrigin: "Anonymous" }
    );
  };

  // Layer postion Data
  const LayerData = [
    {
      text: "Bring Totally Front",
      icon: <BsLayersHalf />,
      action: bringTotalFront,
    },
    {
      text: "Go Totally Back",
      icon: <BsFillLayersFill />,
      action: goTotallyBack,
    },
    {
      text: "Bring OneStep Front",
      icon: <BiLayerPlus />,
      action: bringOneStepFront,
    },

    {
      text: "Go OneStep Back",
      icon: <BiLayerMinus />,
      action: goOneStepBack,
    },
  ];

  switch (selectedComponent) {
    case "Add Text":
      // Selecting the text style

      return (
        <div
          className="flex items-center gap-2 px-4 py-1 bg-white rounded-md"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          {/* add text button */}
          <button
            className="p-1 text-xs font-semibold rounded-md hover:text-triklAccentBlue"
            onClick={() => {
              addTextToCanvas();
            }}
          >
            Add Text
          </button>
          <p className="px-2 opacity-50">|</p>
          {/* FONT FAMILY */}
          <Tooltip title=" Text Font Family" placement="bottom">
            <select
              className="w-20 border rounded "
              value={fontFamily}
              onChange={(e) => {
                setFontFamily(e.target.value);
                const selectedObject = canvas.getActiveObject();

                if (selectedObject && selectedObject.type === "textbox") {
                  selectedObject.set({ fontFamily: e.target.value });
                  canvas.renderAll();
                  const dataURL = canvas.toDataURL({
                    format: "png",
                    quality: 1,
                    multiplier: 2, // or higher for better quality
                  });

                  setCanvasImageUrl(dataURL);
                }
              }}
            >
              {fontFamilyData.map((eachFont, index) => (
                <option value={eachFont.name} key={index}>
                  {eachFont.name}
                </option>
              ))}
            </select>
          </Tooltip>
          <p className="px-2 opacity-50">|</p>
          {/* </div> */}

          {/* COLOR */}
          <Tooltip title=" text color" placement="bottom">
            <div>
              <input
                id="text-color-input"
                className=""
                type="color"
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                  const selectedObject = canvas.getActiveObject();

                  if (selectedObject && selectedObject.type === "textbox") {
                    selectedObject.set({ fill: e.target.value });
                    canvas.renderAll();
                    const dataURL = canvas.toDataURL({
                      format: "png",
                      quality: 1,
                      multiplier: 2, // or higher for better quality
                    });

                    setCanvasImageUrl(dataURL);
                  }
                }}
              />
            </div>
          </Tooltip>
        </div>
      );
    case "Layers":
      return (
        <div
          className="flex gap-2 p-1 bg-white rounded-md shadow-md"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          {LayerData.map((eachButton, index) => {
            return (
              <Tooltip title={eachButton.text} placement="bottom" key={index}>
                <div
                  onClick={() => {
                    // To work only if there's function as an action
                    if (typeof eachButton.action === "function") {
                      eachButton.action();
                    }
                  }}
                  key={index}
                  className="flex items-center p-2 rounded-md cursor-pointer hover:bg-slate-300 "
                >
                  <span> {eachButton.icon}</span>
                </div>
              </Tooltip>
            );
          })}
        </div>
      );
    case "Add Logo":
      return (
        <div
          className="gap-2 p-1 bg-white rounded-md"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <AssetUpload addAssetImageParam={addAssetImage} />
        </div>
      );
    default:
      return null;
  }
};
