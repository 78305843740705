// store.js
import { create } from "zustand";

//USER LOGIN TYPE
const useUserTypeStore = create((set) => ({
  userType: "individual",
  setUserType: (type) => set({ userType: type }),
}));

// OVERALL STEP OF CONTENT CREATION
const useOverallStepStore = create((set) => ({
  overallStep: 0,
  setOverallStep: (topic) => set({ overallStep: topic }),
}));

// CANVAS SIDEBAR STEP
const useCanvasSideStepStore = create((set) => ({
  canvasSideStep: 0,
  setCanvasSideStep: (topic) => set({ canvasSideStep: topic }),
}));

// PLAN ALLOWANCE TEXT AND IMAGE LIMIT
const useUsageLimitTrackingStore = create((set) => ({
  userUsageData: null,
  setUserUsageData: (topic) => set({ userUsageData: topic }),
}));

// FINALIZED TOPIC SELECTION
const useFinalizedTopicFromStore = create((set) => ({
  finalizedTopic: "",
  setFinalizedTopic: (topic) => set({ finalizedTopic: topic }),
}));

// FINALIZED IMAGE CREATION PROMPT
const useFinalImgCreationUniqNameStore = create((set) => ({
  finalizedImgUniqName: "",
  setFinalizedImgUniqName: (topic) => set({ finalizedImgUniqName: topic }),
}));

//  AI RESPONSE FROM PROMPT
const useAiResponseStore = create((set) => ({
  aiResponse: "",
  setAiResponse: (response) => set({ aiResponse: response }),
}));

// NEWS ARTICLES AND SOCIAL TRENDS, WHEN API IS BEING CALLED
const useNewsLoadingStore = create((set) => ({
  isLoadingNAT: false,
  setIsLoadingNAT: (loadingState) => set({ isLoadingNAT: loadingState }),
}));

// NEW NEWS DATA STORE
// const useNewNewsDataStore = create((set) => ({
//   newNewsData: [],
//   setNewNewsData: (data) => set({ newNewsData: data }),
//   clearNewNewsData: () => set({ newNewsData: [] }), // New function to clear the array
// }));

// AI PROMPT RUNNING
const useIsAiPromptRunningStore = create((set) => ({
  isPromptRunning: false,
  setIsPromptRunning: (loadingState) => set({ isPromptRunning: loadingState }),
}));

const useIsGenerateContentDisabled = create((set) => ({
  isGenAIButtonDis: false,
  setIsGenAIButtonDis: (loadingState) =>
    set({ isGenAIButtonDis: loadingState }),
}));

const useWhatsSelectedStore = create((set) => ({
  whatsSelected: {
    tabIndex: 0,
    itemIndex: 10000,
    title: "",
    description: "",
    // url: "",
    allData: {},
  },
  setWhatsSelected: (newState) =>
    set((state) => ({
      whatsSelected: {
        ...state.whatsSelected,
        ...newState,
      },
    })),
}));

// Multiple news selection Array
const useMultipleNewsSelected = create((set) => ({
  newsArray: [],
  addNews: (newsItem) =>
    set((state) => ({ newsArray: [...state.newsArray, newsItem] })),
  removeNews: (newsItem) =>
    set((state) => ({
      newsArray: state.newsArray.filter((item) => item !== newsItem),
    })),
}));

// opening saved draft for edit
const useSavedDraftStore = create((set) => ({
  savedDraftContent: {
    imageUrl: "",
    textContent: "",
    stepCount: 0,
  },
  setSavedDraftContent: (newState) =>
    set((state) => ({
      savedDraftContent: {
        ...state.savedDraftContent,
        ...newState,
      },
    })),
}));

// IMAGES DATA TO SHOW - ALL THE GENERATED IMAGES
const useCreatedImages = create((set) => ({
  userImagesArray: [],
  setUserImagesArray: (newArray) => set(() => ({ userImagesArray: newArray })),
}));

// UPDATED FINALIZED CONTENT ZUSTAND WITH ARRAY FOR STORING ADDITIONAL PERSONALIZATION
const useFinalContentStore = create((set) => ({
  finalContent: JSON.parse(localStorage.getItem("finalContent")) || {
    // finalDraftId: "",
    finalText: "",
    finalMediaUrl: "",
    finalMediaType: "",
    finalOrganizationId: 0,
    finalPostType: "individual",
    finalCharacterLimit: 0,
    finalTone: "",
    finalOrganizationBrandVoiceId: "",
    finalCategory: "",
    finalInspirationProfile: "",
    additionalPersonalization: [
      // {none: true,
      // personalVoice: false,
      // brandVoice: false,
      // }
    ],
  },
  setFinalContent: (newState) => {
    set((state) => {
      const updatedFinalContent = {
        ...state.finalContent,
        ...newState,
      };
      localStorage.setItem("finalContent", JSON.stringify(updatedFinalContent));
      return { finalContent: updatedFinalContent };
    });
  },
  addPersonalizationItem: (item) => {
    set((state) => {
      const updatedFinalContent = {
        ...state.finalContent,
        additionalPersonalization: Array.isArray(
          state.finalContent.additionalPersonalization
        )
          ? [...state.finalContent.additionalPersonalization, item]
          : [item],
      };

      localStorage.setItem("finalContent", JSON.stringify(updatedFinalContent));
      return { finalContent: updatedFinalContent };
    });
  },
  updatePersonalizationItem: (index, item) => {
    set((state) => {
      const updatedArray = [...state.finalContent.additionalPersonalization];
      updatedArray[index] = item;
      const updatedFinalContent = {
        ...state.finalContent,
        additionalPersonalization: updatedArray,
      };
      localStorage.setItem("finalContent", JSON.stringify(updatedFinalContent));
      return { finalContent: updatedFinalContent };
    });
  },
}));

// AI Response Array content store
const useAiResponseArrayStore = create((set) => ({
  aiResponseArray: [],
  setAiResponseArray: (response) => set({ aiResponseArray: response }),
}));

const useSelectedImgStore = create((set) => ({
  imageSelection: "",
  setImageSelection: (value) => set({ imageSelection: value }),
}));

const useAIPromptRecommedationStore = create((set) => ({
  aiPromptRecommedation: ["linkedin post by a business"],
  setAIPromptRecommedation: (value) => set({ aiPromptRecommedation: value }),
}));

// Saved Draft Id for edit
const useSavedDraftIdStore = create((set) => ({
  selectedDraftId: localStorage.getItem("draftId") || "",
  setSelectedDraftId: (id) => {
    localStorage.setItem("draftId", id);
    set({ selectedDraftId: id });
  },
}));

// Scheduled Post Id for edit
const useScheduledPostIdStore = create((set) => ({
  selectedScheduledPostId: localStorage.getItem("scheduledPostId") || "",
  setSelectedScheduledPostId: (id) => {
    localStorage.setItem("scheduledPostId", id);
    set({ selectedScheduledPostId: id });
  },
}));

// SELECT BETWEEN WHETHER TO CREATE AI IMAGE OF UPLOAD AN IMAGE
const useGenOrUploadStore = create((set) => ({
  isGenImgAI: 0,
  setIsGenImgAI: (topic) => set({ isGenImgAI: topic }),
}));

const useIsImageGenerationLoadingStore = create((set) => ({
  isImageGenerationLoading: false,
  setIsImageGenerationLoading: (loadingState) =>
    set({ isImageGenerationLoading: loadingState }),
}));

const useAIRecommendationClickStore = create((set) => ({
  isAIRecommendationClick: false,
  setIsAIRecommendationClick: (prev) => set({ isAIRecommendationClick: prev }),
}));

// Calendar Edit Open Close
const useShowEditComponentStore = create((set) => ({
  showEditComponent: false,
  setShowEditComponent: (prev) => set({ showEditComponent: prev }),
}));

// refresh the scheduler to display new schedules
const useRefreshSchedulerStore = create((set) => ({
  refreshScheduler: false,
  toggleRefresh: () =>
    set((state) => ({ refreshScheduler: !state.refreshScheduler })),
}));

// currentPostType

const useCurrentPostTypeStore = create((set) => ({
  currentPostType: "Latest News",
  setCurrentPostType: (topic) => set({ currentPostType: topic }),
}));

const useContentUrlStore = create((set) => ({
  currentUrl: "",
  setCurrentUrl: (value) => set({ currentUrl: value }),
}));

// REFRESH USER DATA
const UserDataRefreshStore = create((set) => ({
  userDataRefresh: false,
  setUserDataRefresh: (prev) => set({ userDataRefresh: prev }),
}));

// CREATE FROM CLICK
const useIsCreateFromClickStore = create((set) => ({
  isCreateFromClick: false,
  setIsCreateFromClick: (prev) => set({ isCreateFromClick: prev }),
}));

// SELECT THE MENU FOR LANDING PAGE
const useSelectedMenuItemStore = create((set) => ({
  selectedMenuItem: null,
  setSelectedMenuItem: (item) => set({ selectedMenuItem: item }),
}));

// ALL EXPORTS BELOW THIS
export {
  useUserTypeStore,
  useOverallStepStore,
  useCanvasSideStepStore,
  useFinalizedTopicFromStore,
  useNewsLoadingStore,
  useWhatsSelectedStore,
  useMultipleNewsSelected,
  useAiResponseStore,
  useIsAiPromptRunningStore,
  useIsGenerateContentDisabled,
  useSavedDraftStore,
  useSavedDraftIdStore,
  useFinalContentStore,
  useAiResponseArrayStore,
  // IMAGE ----------------
  useSelectedImgStore,
  useGenOrUploadStore,
  useIsImageGenerationLoadingStore,
  useAIRecommendationClickStore,
  useAIPromptRecommedationStore,
  useShowEditComponentStore,
  useCreatedImages,
  useFinalImgCreationUniqNameStore,
  useRefreshSchedulerStore,
  useCurrentPostTypeStore,
  useContentUrlStore,
  UserDataRefreshStore,
  useUsageLimitTrackingStore,
  useIsCreateFromClickStore,

  // -------------
  useSelectedMenuItemStore,

  // SCHEDULED POST
  useScheduledPostIdStore,
};
