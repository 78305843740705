const V2BackgroundTemplate17 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3568)">
        <path
          d="M57 0L57 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M117 0L117 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M177 0L177 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M237 0L237 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M297 0L297 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M357 0L357 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M417 0L417 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M477 0L477 665"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 62L535 62"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 122L535 122"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 182L535 182"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 242L535 242"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 302L535 302"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 362L535 362"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 422L535 422"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 482L535 482"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 542L535 542"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <path
          d="M0 602L535 602"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.1"
        />
        <rect
          x="299"
          y="483"
          width="236"
          height="60"
          fill={colorPallet?.secondary}
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate17;
