// v2
const convertHtmlToFormattedText = (html) => {
  let output = html

    .replace(/<\/p>\s*<p>/g, "\n") // Replace </p><p> with a single \n to separate paragraphs
    .replace(/<p><br\s*\/?><\/p>/g, "\n\n") // Handle <p><br></p> sequences to convert them into two \n
    .replace(/<br\s*\/?>/g, "") // Replace <br> or <br/> with \n
    .replace(/<\/?p>/g, "") // Remove remaining individual <p> and </p> tags
    .replace(/&nbsp;/g, " ") // Remove space html code with " "
    .replace(/<\/?span[^>]*>/g, "") // Remove span tags with any attributes
    .replace(/<\/?[^>]+(>|$)/g, "") // Remove any remaining HTML tags
    .replace(/<\/?div[^>]*>/g, "") // Remove div tags with any attributes
    .replace(/<\/?strong>/g, "") // Remove strong tags
    .replace(/<\/?em>/g, "") // Remove em tags
    .replace(/<\/?b>/g, "") // Remove b tags
    .replace(/<\/?i>/g, "") // Remove i tags
    .replace(/<\/?u>/g, "") // Remove u tags
    .replace(/<\/?a[^>]*>/g, "") // Remove a tags with any attributes
    .replace(/&gt;/g, ">") // Decode &gt; to >
    .replace(/&lt;/g, "<") // Decode &lt; to <
    .replace(/&amp;/g, "&"); // Decode &amp; to &

  return output;
};

// const convertHtmlToFormattedText = (html) => {
//   // Step 1: Replace </p><p> with a single \n to separate paragraphs
//   let output = html.replace(/<\/p>\s*<p>/g, "\n");

//   // Step 2: Specifically handle <p><br></p> sequences to convert them into a single \n
//   output = output.replace(/<p><br\s*\/?><\/p>/g, "\n\n");

//   // Step 3: Replace <br> or <br/> with \n
//   output = output.replace(/<br\s*\/?>/g, "");

//   // Step 4: Now, safely remove remaining individual <p> and </p> tags
//   output = output.replace(/<\/?p>/g, "");

//   return output;
// };

function convertTextToHtml(text) {
  // Split the text by newlines
  const lines = text.split("\n");
  // Filter out empty lines
  const filteredLines = lines.filter((line) => line.trim() !== "");

  // Wrap each line in a <p> tag
  const htmlLines = filteredLines.map((line) => `<p>${line.trim()}</p>`);

  // Join the lines back into a single HTML string
  const htmlString = htmlLines.join("");

  return htmlString;
}

// // v2  for saved drafts
// const convertHtmlToFormattedSavedDrafts = (html) => {
//   html = html
//     .replace(/<\/p><p>/g, "\n") // Replace </p><p> with a single newline
//     .replace(/<p><br\s*\/?><\/p>/g, "\n\n") // Replace <p><br></p> with two newlines
//     .replace(/<span style="background-color: [^;]+;">(.*?)<\/span>/g, "$1") // Remove background color from spans
//     .replace("&gt;", ">")
//     .replace("&lt;", "<")
//     .replace("&gt;&gt;&gt;", ">>>")
//     .replace("&lt;&lt;&lt;", "<<<")
//     .replace(/<\/?[^>]+(>|$)/g, ""); // Remove all other HTML tags to get the final text

//   return html;
// };

// const convertHtmlToFormattedSavedDrafts = (html) => {
//   // 1. Replace </p><p> with a single newline
//   html = html.replace(/<\/p><p>/g, "\n");
//   // 2. Replace <p><br></p> with two newlines
//   html = html.replace(/<p><br\s*\/?><\/p>/g, "\n\n");

//   html = html.replace("&gt;", ">");
//   html = html.replace("&gt;&gt;&gt;", ">>>");
//   html = html.replace("&lt;&lt;&lt;", "<<<");

//   // 3. Remove all other HTML tags to get the final text
//   let output = html.replace(/<\/?[^>]+(>|$)/g, "");

//   return output;
// };

// ---------- v2 for onboarding post parsing

const convertHtmlToFormattedTextOnboarding = (htmlContent) => {
  let formattedText = htmlContent.replace(/<br\s*\/?>/gi, "\n");
  // Handle &nbsp; - convert to space
  formattedText = formattedText.replace(/&nbsp;/gi, " ");

  // Handle &amp; - convert to &
  formattedText = formattedText.replace(/&amp;/gi, "&");

  formattedText = formattedText.replace(/<em>(.*?)<\/em>/gi, "_$1_");
  formattedText = formattedText.replace(/<strong>(.*?)<\/strong>/gi, "**$1**");
  // Remove any remaining HTML tags
  formattedText = formattedText.replace(/<\/?[^>]+(>|$)/g, "");

  return formattedText;
};

// Parse the post content from database to HTML for onboarding editor
const parseFormattedTextToHtmlOnboarding = (formattedText) => {
  if (
    !formattedText ||
    formattedText == undefined ||
    formattedText?.length === 0
  ) {
    return "";
  }

  let htmlContent = formattedText.replace(/\n/g, "<br>");

  htmlContent = htmlContent.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  htmlContent = htmlContent.replace(/_(.*?)_/g, "<em>$1</em>");

  return htmlContent;
};

// Convert \n to <br/>
const displayLinebrakeContent = (content) => {
  return content.replace(/\n/g, "<br/>");
};

export {
  convertHtmlToFormattedText,
  convertTextToHtml,
  // convertHtmlToFormattedSavedDrafts,
  // ------ onboardingPostParsing
  convertHtmlToFormattedTextOnboarding,
  parseFormattedTextToHtmlOnboarding,
  displayLinebrakeContent,
};
