import { create } from "zustand";

// Loader  displayed when the whole app is loading
const useMainLoaderStore = create((set) => ({
  isMainLoaderRunning: true,
  setIsMainLoaderRunning: (newState) => {
    set(() => ({ isMainLoaderRunning: newState }));
  },
}));

export { useMainLoaderStore };
