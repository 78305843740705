import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoMdAttach } from "react-icons/io";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import {
  useActiveThreadIdStore,
  useAssistantDataStore,
  useAssistantIdStore,
  useThreadDataStore,
} from "./AssistantZustandStore";
import { LoadingSpinner } from "../../../components/widgets/LoadingSpinner";
import { assistantDetailsById } from "../../function/LinkedInFunctions";
import Sider from "antd/es/layout/Sider";
import { Button, message, Tag, Upload } from "antd";
import {
  CloudUploadOutlined,
  EditOutlined,
  FileAddOutlined,
  InboxOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { UserDataRefreshStore } from "../../ZustandStores/LinkedInZustandStore";

const AssistantSidebar = () => {
  const { userData } = useLinkedInUserContext();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { activeThreadId, setActiveThreadId } = useActiveThreadIdStore();
  const { activeAssistantId, setActiveAssistantId } = useAssistantIdStore();
  const { threadData, setThreadData } = useThreadDataStore();

  //  handle assistant change and update it in zustandstore
  const handleChange = (e) => {
    setActiveAssistantId(e.target.value);
  };

  const updateThreadsList = () => {
    if (userData?.userModels.assistants) {
      const selectedAssistant = userData.userModels.assistants.find(
        (assistant) => assistant.assistantId === activeAssistantId
      );
      if (selectedAssistant && selectedAssistant.threads) {
        setThreadData(selectedAssistant.threads);
      } else {
        setThreadData([]); // Clear threadData if no threads found
      }
    }
  };

  useEffect(() => {
    updateThreadsList();
  }, [activeThreadId]);

  useEffect(() => {
    updateThreadsList();
  }, [activeAssistantId, userData]);

  return (
    <Sider
      theme="light"
      width={250}
      className="h-screen px-4 text-xs text-left bg-white font-inter overflow-clip"
    >
      <section className="relative flex flex-col justify-between h-auto pt-10 my-20 overflow-scroll custom-scrollbar">
        {/* GROUPING FOR BETTER LAYOUT */}
        <section className="w-full px-2">
          {/* Name of Assistant */}
          {/* <header className="pb-4 text-lg font-semibold">
            {userData?.userModels.assistants[0].assistantName}
          </header> */}
          <select
            id="simple-dropdown"
            onChange={handleChange}
            className="block w-full px-4 py-2 mt-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          >
            {userData?.userModels.assistants.map((assistant, key) => {
              return (
                <option key={key} value={assistant.assistantId}>
                  {assistant.assistantName}
                </option>
              );
            })}
          </select>

          <Button
            className="w-full mt-4"
            icon={<EditOutlined />}
            onClick={() => setActiveThreadId("")}
          >
            New Chat
          </Button>
        </section>

        {/* All threads */}
        <section className="h-[60vh] px-2 overflow-scroll custom-bar">
          <div className="my-4 font-bold text-gray-400 border-b border-b-gray-200">
            History
          </div>
          <div className="flex flex-col">
            {threadData.map((eachThread, index) => (
              <button
                className={`py-2 text-left hover:text-tNewButtonsAndActiveElementDarkest active:text-triklAccentBlue
                  ${
                    eachThread.threadId === activeThreadId
                      ? "text-tNewButtonsAndActiveElement font-semibold"
                      : "text-gray-500"
                  }
                  `}
                key={index}
                onClick={() => setActiveThreadId(eachThread.threadId)}
              >
                {eachThread.threadName?.length > 50
                  ? eachThread.threadName.slice(0, 50) + "..."
                  : eachThread.threadName}
              </button>
            ))}
          </div>
        </section>

        {/* File upload section */}
        <FileSection />
      </section>
    </Sider>
  );
};

export default AssistantSidebar;

const FileSection = () => {
  // Zustand
  const { activeAssistantId } = useAssistantIdStore();
  const { assistantFilesArray, setAssistantFilesArray } =
    useAssistantDataStore();
  // upload the file to assistant
  const handleUploadToAssistant = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assistantId", activeAssistantId);

    try {
      //Step 1 - upload the file to the vector store associated with the user assistant
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/assitant-file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      //Step 2 - Fetch all the files associated with the assistant
      const assistantFiles = await assistantDetailsById(activeAssistantId);
      setAssistantFilesArray(assistantFiles);
      return { success: true };
    } catch (error) {
      // console.error("Error uploading file:", error);
      return { success: false, error };
    }
  };

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      // const isPNG = file.type === ('image/png' || '');
      const isPDForTXT =
        file.type === "application/pdf" || file.type === "text/plain";

      if (!isPDForTXT) {
        message.error(`${file.name} is not a pdf or txt file`);
      }
      return isPDForTXT || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      const result = await handleUploadToAssistant(file);
      if (result.success) {
        onSuccess(null, file);
        message.success(`${file.name} file uploaded successfully`);
      } else {
        onError(result.error);
        message.error(`${file.name} file upload failed.`);
      }
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  useEffect(() => {
    const fetchAssistantFiles = async () => {
      try {
        const assistantFiles = await assistantDetailsById(activeAssistantId);
        setAssistantFilesArray(assistantFiles);
      } catch (error) {
        console.error("Error fetching assistant files:", error);
      }
    };

    if (activeAssistantId) {
      fetchAssistantFiles();
    }
  }, [activeAssistantId]);

  return (
    <>
      <Upload {...props} className="flex flex-col w-full p-2 h-max">
        <Button icon={<UploadOutlined />} className="w-full py-4 h-max">
          Add Your Files
        </Button>
      </Upload>

      {assistantFilesArray && (
        <div className="pl-2 overflow-scroll text-gray-500 custom-scrollbar max-h-20">
          {assistantFilesArray.map((item, index) => (
            <p key={index}>{item.fileName}</p>
          ))}
        </div>
      )}
    </>
  );
};
