import { applyActionCode, getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmailVerification = () => {
  const navigate = useNavigate();

  // USER EMAIL VERIFICATION

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mode = params.get("mode");
    const oobCode = params.get("oobCode");

    if (mode === "verifyEmail" && oobCode) {
      const auth = getAuth();

      applyActionCode(auth, oobCode)
        .then(() => {
          // toast.success("Email verified successfully");
          navigate("/linkedin/content-creation", { replace: true });
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error verifying email", error);
          toast.error("Error verifying email");
          navigate("/linkedin/content-creation", { replace: true });
        });
    } else if (mode === "resetPassword" && oobCode) {
      navigate(`/user/passwordReset?oobCode=${oobCode}`, { replace: true });
    }
  }, [navigate]);

  return null;
};

export default EmailVerification;
