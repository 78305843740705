import React from "react";

const BackgroundTemplate9 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2563_1473"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="white" />
      </mask> */}
      <g mask="url(#mask0_2563_1473)">
        <path
          d="M0 240.898C77.9088 173.977 233.007 101.045 292.285 326.448C351.5 551.609 485.615 511.115 553 460.291"
          //   stroke="#C08F6A"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.07"
          stroke-width="20"
        />
        <path
          d="M335 -9.43537C357.667 -11.7687 426.485 -4.94319 466.5 73.5646C494.5 128.5 542.667 134.898 549 120.565"
          //   stroke="#784118"
          stroke={colorPallet?.primary}
          stroke-width="19"
        />
        <path
          d="M-15 556.316C20.6 534.693 103.435 506.136 180.517 603.436C257.599 700.736 317.078 705.632 341 700.736"
          //   stroke="#C08F6A"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.07"
          stroke-width="19"
        />
      </g>
    </svg>
  );
};

export default BackgroundTemplate9;
