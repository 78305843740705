import React, { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import {
  LinkedInUserContext,
  useLinkedInUserContext,
} from "../../../context/linkedInUserContext";
import {
  triklPurple,
  triklPurpleWithoutText,
} from "../../../assets/Trikl_logo";
import {
  UserDataRefreshStore,
  useUsageLimitTrackingStore,
} from "../ZustandStores/LinkedInZustandStore";
import PagesSidebar from "./PagesSidebar";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../context/userAuthContext";
import { Alert, Button, ConfigProvider, Space } from "antd";

import { useUserOrganizationsStore } from "../ZustandStores/OrganizationStore";
import { useMainLoaderStore } from "../ZustandStores/AppLoaderStore";
import MainAppLoader from "./loadingScreens/MainAppLoader";
import ConnectLinkedInModal from "./modalsComponents/ConnectLinkedInModal";
import { uselinkedInReconnectModal } from "../ZustandStores/tokenExpiryStore";

const CanvasNavbar = ({ children }) => {
  // ZUSTAND
  const { userDataRefresh } = UserDataRefreshStore();
  const { userUsageData, setUserUsageData } = useUsageLimitTrackingStore();
  const { userLinkedInData, setUserLinkedInData } = useUserLinkedInStore();
  const { userMainData, setUserMainData } = useUserMainDataStore();
  const { organizationsData, setOrganizationsData } =
    useUserOrganizationsStore();
  const { isMainLoaderRunning, setIsMainLoaderRunning } = useMainLoaderStore();
  // Context
  const { user, resendVerificationEmail } = useUserAuth();

  // LOCAL STATE MANAGEMENT
  // user Details Store

  const [profileOpen, setProfileOpen] = useState(false);
  // useState for mobile page:
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { linkedInReconnectModalVisible, setLinkedInReconnectModalVisible } =
    uselinkedInReconnectModal();
  let navigate = useNavigate();
  const transitionRef = useRef(null);

  // function to fetch user usage data
  async function fetchUsageData() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/get-usage-data`,
        { userId: user?.uid }
      );
      setUserUsageData(response.data);
    } catch (error) {
      console.error("Failed to fetch user usage data:", error);
    } finally {
      // Set a timeout to delay setting the loading state to false
      setTimeout(() => {}, 1000); // Adjust time as needed
    }
  }

  // Fetch user profile data
  const userMainDetails = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/getUserDetails`,
        {
          params: { userId: user?.uid },
        }
      );
      setUserMainData(data.data.user);
      if (data.data.user) {
        setIsMainLoaderRunning(false);
      }
    } catch (error) {
      // toast.error("oops! something went wrong. Please try again.");
      setIsMainLoaderRunning(false);
    }
  };

  // TO FETCH THE USER DETAILS IN LINKEDIN SCHEMA:

  // Fetch user profile data
  const userLinkedinProfileData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/get-user-linkedIn`,
        {
          userId: user?.uid,
        }
      );
      setUserLinkedInData(response?.data?.user);
    } catch (error) {
      // toast.error("oops! something went wrong. Please try again.");
    }
  };
  // TO FETCH THE USER ORGANIZATION DETAILS
  const userOrganizationDetails = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/organization/v2/getAllUserOrganizationsDetails`,
        {
          userId: user?.uid,
        }
      );

      setOrganizationsData(response?.data?.orgData);
    } catch (error) {
      // toast.error("oops! something went wrong. Please try again.");
    }
  };

  // Show connect linkedIn modal if user LinkedIn refresh token is expired
  const showLinkedInReconnectModal = () => {
    // Show the modal
    if (
      userLinkedInData?.linkedInUserId?.length > 0 &&
      userLinkedInData?.refreshTokenExpired
    ) {
      setLinkedInReconnectModalVisible(true);
    }
  };
  useEffect(() => {
    // Check if the user object is not empty

    userMainDetails();
    userLinkedinProfileData();
    userOrganizationDetails();
    fetchUsageData();
    showLinkedInReconnectModal();
  }, [user, userDataRefresh]); // Dependencies

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the Transition component
      if (
        transitionRef.current &&
        !transitionRef.current.contains(event.target)
      ) {
        setProfileOpen(false); // Close the Transition component
      }
    };

    // Add the click event listener to the document body
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Redirect user to onboarding page if they haven't filled the onboarding form

  useEffect(() => {
    if (
      user &&
      (userMainData?.description === "" ||
        userLinkedInData?.linkedInProfileUrl === "")
    ) {
      navigate("/user/onboarding");
    }
  }, [userMainData, user]);

  return (
    <LinkedInUserContext.Provider
      value={{
        userData: userMainData,
      }}
    >
      {isMainLoaderRunning ? (
        <MainAppLoader />
      ) : (
        <>
          <div className="relative flex flex-col">
            <nav className="fixed top-0 z-50 flex flex-col items-center justify-center w-full bg-white border-b-2 border-gray-200">
              <div className="w-full">
                {!user?.emailVerified && (
                  <Alert
                    message={`We have sent a verification email to ${user?.email}. Please verify it.`}
                    type="warning"
                    action={
                      <Space>
                        <Button
                          type="text"
                          size="small"
                          ghost
                          onClick={resendVerificationEmail}
                        >
                          Resend Email
                        </Button>
                      </Space>
                    }
                  />
                )}
              </div>

              {/* <main className="container flex justify-between px-2 py-2 mx-auto"> */}
              <main className="flex flex-row justify-between w-full px-2 py-2 md:justify-center">
                <div className="flex justify-between w-[60vw] px-2  md:w-auto">
                  <button
                    className="md:hidden"
                    onClick={() => setSideBarOpen(!sideBarOpen)}
                  >
                    {" "}
                    &#9776;{" "}
                  </button>
                  <a
                    as="div"
                    className="flex items-center gap-2 text-center"
                    href="/linkedin/content-creation"
                  >
                    <img
                      src={triklPurpleWithoutText}
                      className="object-contain h-8"
                      alt="TRIKL's Logo"
                    />
                    <p className="text-lg font-semibold font-poppins text-triklBlueDark">
                      TRIKL
                    </p>
                    <div className="pl-3 font-medium">|</div>
                    <div className="pl-3 font-medium text-triklAccentBlue">
                      {userMainData?.name}
                    </div>
                  </a>
                  <div className="flex items-center justify-center gap-3"></div>
                </div>

                {/* sidebar right section */}

                <div
                  className={`fixed top-0 left-0 h-screen bg-white w-0 ${
                    sideBarOpen ? "w-56" : "w-0"
                  } transition-all duration-300 ease-in-out overflow-x-hidden md:overflow-visible md:w-4/6  md:h-auto md:relative md:flex md:items-center md:justify-end md:gap-5  `}
                >
                  <button
                    className="block px-4 py-1 m-4 text-xl font-bold rounded-lg bg-red-500/25 md:hidden lg:hidden"
                    onClick={() => setSideBarOpen(!sideBarOpen)}
                  >
                    {sideBarOpen ? "X" : null}
                  </button>

                  <PlanDetails />

                  {/* --------------- navbar Buttons/icons ----------------- */}

                  {/* Profile Section */}
                  <div className="relative ">
                    <button
                      type="button"
                      onClick={() => {
                        setProfileOpen(!profileOpen);
                      }}
                      className="w-10 h-10 mt-5 overflow-hidden border-2 rounded-full md:w-8 md:h-8 md:mt-0 border-triklAccentBlue"
                    >
                      {user?.photoURL ? (
                        <img src={user?.photoURL} alt="profile image" />
                      ) : (
                        <PersonIcon className="overflow-hidden text-white rounded-full bg-triklAccentBlue" />
                      )}
                    </button>

                    <Transition
                      ref={transitionRef}
                      show={profileOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="absolute w-48 mt-1 bg-white border border-gray-200 divide-y divide-gray-200 rounded-md shadow-md md:top-2 md:-left-20 right-2">
                        <div className="flex items-center p-2 space-x-2">
                          <PersonIcon />
                          <div className="font-medium">
                            {/* {userMainData?.name} */}
                            {userMainData?.name?.length > 15
                              ? `${userMainData.name.slice(0, 15)}...`
                              : userMainData?.name}
                          </div>
                        </div>

                        <div className="flex flex-col w-full p-2 px-5 space-y-3 text-left">
                          <a
                            href="/user/profile"
                            className="transition hover:text-triklAccentBlue"
                          >
                            My Profile
                          </a>

                          {/* Subscription Page */}
                          <a
                            href="https://billing.stripe.com/p/login/cN2cPmgxtdhd9oI5kk"
                            className="transition hover:text-triklAccentBlue"
                          >
                            My Plan
                          </a>

                          {/* DISPLAY REMAINING CREDITS */}
                          <div className="w-full pt-4 border-t border-gray-300">
                            {userUsageData?.textLimit}
                            <span className="text-xs">
                              {" / "}
                              {Math.max(
                                userUsageData?.planAllowance?.textlimit,
                                0
                              )}
                              {" Words generated"}
                            </span>
                          </div>

                          <div className="w-max">
                            {userUsageData?.imageLimit}
                            <span className="text-xs">
                              {" / "}
                              {Math.max(
                                userUsageData?.planAllowance?.imagelimit,
                                0
                              )}
                              {" Images generated"}
                            </span>
                          </div>
                        </div>

                        <div className="p-2">
                          <button
                            className="flex items-center pl-1 space-x-2 transition hover:text-triklAccentBlue"
                            // onClick={handleLogout}
                            onClick={() => {
                              navigate("/user/logout");
                            }}
                          >
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                              ></path>
                            </svg>
                            Log Out
                          </button>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
              </main>
            </nav>
            <div className="flex h-screen max-h-screen ">
              <PagesSidebar />
              <div className="w-full overflow-y-scroll">{children}</div>
            </div>
          </div>
          <ConnectLinkedInModal
            connectModalOpen={linkedInReconnectModalVisible}
            connectModalClose={() => {
              setLinkedInReconnectModalVisible(false);
            }}
          />
        </>
      )}
    </LinkedInUserContext.Provider>
  );
};

export default CanvasNavbar;

const PlanDetails = () => {
  const { userData } = useLinkedInUserContext();
  const { user } = useUserAuth();

  function calculateDaysBetweenDates(timestamp, isoDateString) {
    // Convert both dates to Date objects
    const date1 = new Date(timestamp);
    const date2 = new Date(isoDateString);

    // Calculate difference in milliseconds
    const diffInTime = date2.getTime() - date1.getTime();

    // Convert time difference to days
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    return Math.ceil(diffInDays); // Use Math.ceil to round up to the nearest whole number
  }

  let daysRemaining;
  {
    userData?.subscription?.trial_end_time
      ? (daysRemaining = calculateDaysBetweenDates(
          Date.now(),
          userData?.subscription?.trial_end_time
        ))
      : (daysRemaining = calculateDaysBetweenDates(
          Date.now(),
          userData?.subscription?.curr_end_time
        ));
  }

  const isPaidUser = userData?.payment?.status;

  return (
    <div className="w-max">
      {isPaidUser ? (
        <div className="text-xs text-triklAccentBlue">
          <span className="capitalize">{userData?.subscription?.tiertype}</span>{" "}
          Plan Active
        </div>
      ) : (
        <div className="pl-5 text-xs text-amber-700 md:pl-0">
          {"Trial Ends in "}
          {daysRemaining}
          {" Days"}
        </div>
      )}
    </div>
  );
};
