import React, { useEffect, useState } from "react";
import { BglightCircle } from "../../../../assets/background";
import { Button, Checkbox, Col, Row } from "antd";
import { triklPurple } from "../../../../assets/Trikl_logo";
import { Link, useNavigate } from "react-router-dom";

import { useUserAuth } from "../../../../context/userAuthContext";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../../ZustandStores/UserLinkedInStore";
const LogOut = () => {
  const { user, logOut } = useUserAuth();
  const { userMainData } = useUserMainDataStore();
  const { userLinkedInData } = useUserLinkedInStore();

  return (
    <div>
      <div className="h-screen bg-white bg-top bg-no-repeat ">
        <div className="">
          <div className="absolute flex justify-center">
            <img className="" src={BglightCircle} alt="" />
          </div>
          <Row justify="center">
            <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
              <div className="flex justify-center mt-6">
                <img src={triklPurple} alt="Trikl" className="w-28 h-28" />
              </div>

              <div className="bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
                <div className="px-5 py-4 text-left border-b border-gray-200 dark:border-white10">
                  <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
                    Hi, {userMainData?.name || user?.displayName || user?.email}
                    👋
                  </h2>
                </div>
                <p className="mt-3 text-md ">
                  With TRIKL by your side, content creation becomes a piece of
                  cake.
                </p>
                <div className="px-10 pt-8 pb-6">
                  <Button
                    className="w-full h-12 p-0 my-6 text-sm font-medium"
                    htmltype="submit"
                    type="primary"
                    size="large"
                    onClick={() => {
                      logOut();
                    }}
                  >
                    Log Out
                  </Button>
                </div>
                <div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
                  <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                    Go back to &nbsp;
                    <Link
                      to="/linkedin/content-creation"
                      className="ltr:ml-1.5 rtl:mr-1.5 text-info hover:text-primary text-linkedInPeach"
                    >
                      Dashboard
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
