import axios from "axios";
import { toast } from "react-toastify";

////////////// IMAGE GENERATION - Stream code //////////////
const getAIRecoJSONPrompt = async (
  userId,
  PromptTextParam,
  selectedImgModel
) => {
  // GET AI recommedation

  try {
    const aiResFromPrompt = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/linkedIn-rephrase`,
      {
        advancedPrompt: PromptTextParam,
        purpose: "aiReco",
        modelName: selectedImgModel,
        userId: userId,
      }
    );

    // AI RESPONSE AS STRING

    const aiResAsStr = aiResFromPrompt.data.content;

    // Convert string to array
    const aiResConvToArr = JSON.parse("[" + aiResAsStr + "]");

    return aiResConvToArr[0];
  } catch (error) {
    if (error.response.status === 429) {
      toast.error(
        "Text limit reached, check your profile page for more details 🐹"
      );

      return;
    }
    if (error.response.status === 403) {
      toast.error("Access denied. Please make a payment!");

      return;
    }
    toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
  }
};

const toneData = [
  { name: "Formal", icon: "👍" },
  { name: "Celebration", icon: "👏🏻" },
  { name: "Support", icon: "🫶🏻" },
  { name: "Emotional", icon: "💕" },
  { name: "Insightful", icon: "💡" },
  { name: "Funny", icon: "🤣" },
  { name: "Shocking", icon: "😳" },
  { name: "Controversial", icon: "🧐" },
  { name: "Inspirational", icon: "🤩" },
  { name: "Announcement", icon: "📢" },
];

const CategoryData = [
  { name: "Learnings", uniqueName: "learnings", icon: "📚" },
  { name: "Celebrate", uniqueName: "celebrate", icon: "🎉" },
  { name: "Share Tips", uniqueName: "tips", icon: "🪄" },
  { name: "Myth Buster", uniqueName: "myth", icon: "💀" },
  { name: "Tools Curation", uniqueName: "tools", icon: "🧰" },
  { name: "Expertise", uniqueName: "expertise", icon: "🧑🏼‍🔬" },
  { name: "Mistakes", uniqueName: "mistakes", icon: "❌" },
  { name: "Experience", uniqueName: "experience", icon: "😎" },
  { name: "Stories", uniqueName: "stories", icon: "📖" },
  { name: "General", uniqueName: "general", icon: "😃" },
  { name: "None", uniqueName: "none", icon: "🚫" },
];

const personalizationPrompts = [
  { name: "Bullet Points", uniqueName: "smallBulletPoints", icon: "👉" },
  { name: "Remove Emojis", uniqueName: "noEmoji", icon: "🛑" },
];

const wordLimitData = [
  { name: "one liner", icon: "🗣️", characterSize: 75 },
  { name: "short", icon: "✍️", characterSize: 300 },
  { name: "medium", icon: "📄", characterSize: 850 },
  { name: "long", icon: "🧾", characterSize: 1400 },
];

export {
  toneData,
  wordLimitData,
  CategoryData,
  personalizationPrompts,
  getAIRecoJSONPrompt,
};
