import React, { useState } from "react";
import { useUserAuth } from "../../../context/userAuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useUserMainDataStore } from "../../linkedIn/ZustandStores/UserLinkedInStore";
import { UserDataRefreshStore } from "../../linkedIn/ZustandStores/LinkedInZustandStore";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FiCopy } from "react-icons/fi";
import { FiRefreshCcw } from "react-icons/fi";

const TextSuggestionPopup = ({
  popUpInfo,
  setPopUpInfo,
  setReplaceHighlightedText,
}) => {
  const { user } = useUserAuth();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userMainData } = useUserMainDataStore();

  const [regenerating, setRegenerating] = useState(false);

  // Rephrase the sentence with the the grammer to be updated
  const rephraseSentence = async (selectedText) => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }
    setRegenerating(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/text-improvement`,
        {
          content: `"""${selectedText}"""`,
          contentType: popUpInfo.type,
          userId: userMainData?.userId,
        }
      );

      // If the response is 429, it means the user has reached the text limit
      if (res.status === 429) {
        toast.error(
          "Text limit reached, check your profile page for more details 🐹"
        );
        return;
      }
      setUserDataRefresh(!userDataRefresh);
      setPopUpInfo({
        ...popUpInfo,
        tbReplaceText: res.data.content,
      });
      setRegenerating(false);
    } catch (error) {
      setRegenerating(false);
    }
  };

  if (!popUpInfo.visible) return null;
  return (
    <div
      className="absolute z-50 flex flex-col p-4 bg-white border rounded shadow-lg w-[389px]"
      style={{
        left: `${popUpInfo.position.x}px`,
        top: `${popUpInfo.position.y}px`,
      }}
    >
      {/* Show sentence */}
      <div className="relative flex items-center gap-3">
        <div
          className="w-6 h-6 rounded-full"
          style={{ backgroundColor: popUpInfo.highlightColor }}
        ></div>
        <h2 className="text-lg font-semibold">{popUpInfo.type}</h2>
        <p
          className="absolute right-1 top-1"
          onClick={() => {
            setPopUpInfo({ ...popUpInfo, visible: false });
          }}
        >
          <CloseOutlined className="text-gray-500" />
        </p>
      </div>
      <p className="py-4 text-lg text-left text-tNewTextgray">
        {popUpInfo?.tbReplaceText?.length > 0
          ? popUpInfo?.tbReplaceText
          : popUpInfo.text}
      </p>

      {popUpInfo?.tbReplaceText?.length > 0 ? (
        <div className="flex justify-between ">
          <Button
            loading={regenerating}
            type="primary"
            size="large"
            className="p-2 font-semibold w-28"
            onClick={() => {
              setReplaceHighlightedText(true);
              //   setPopUpInfo({ ...popUpInfo, visible: false, tbReplaceText: "" });
              //   setRegeneratedText("");
            }}
          >
            Replace
          </Button>

          <div className="flex items-center gap-4 text-2xl text-midGray">
            <FiCopy
              onClick={() => {
                navigator.clipboard.writeText(popUpInfo.tbReplaceText);
                toast.success("Copied to clipboard!");
              }}
            />
            <FiRefreshCcw
              onClick={() => {
                rephraseSentence(popUpInfo.text);
              }}
            />
          </div>
        </div>
      ) : (
        <Button
          loading={regenerating}
          type="primary"
          size="large"
          className="w-32 p-2 font-semibold"
          onClick={() => {
            rephraseSentence(popUpInfo.text);
          }}
        >
          Fix with AI
        </Button>
      )}
    </div>
  );
};

export default TextSuggestionPopup;
