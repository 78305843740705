import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { LoadingSpinnerWhite } from "../../../components/widgets/LoadingSpinner";
import { ConfigProvider, Modal, Radio, Segmented, Select } from "antd";
import { UserDataRefreshStore } from "../../ZustandStores/LinkedInZustandStore";

import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import { useUserOrganizationsStore } from "../../ZustandStores/OrganizationStore";
const PersonalizeModal = ({ isOpen, onClose, selectedValue, userData }) => {
  // MAIN VOICE TYPES
  const MainVoiceTypes = [
    {
      label: "personalVoice",
      value: "Personal Voice",
      key: 0,
    },
    {
      label: "brandVoice",
      value: "Brand Voice",
      key: 1,
    },
  ];

  // User Data:
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userLinkedInData } = useUserLinkedInStore();
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [mainVoiceType, setMainVoiceType] = useState(selectedValue);
  const [isVoiceSuggestionsLoading, setIsVoiceSuggestionsLoading] =
    useState(false);
  const [selectedVoiceType, setSelectedVoiceType] = useState([]);
  // Response from AI
  const [voiceDataArray, setVoiceDataArray] = useState([]);
  const [BrandVoiceDataArray, setBrandVoiceDataArray] = useState([]);
  // custom input state
  const [inputValue, setInputValue] = useState("");
  const [customInputArray, setCustomInputArray] = useState([]);

  // User CurrentStep Store
  const [currentStep, setCurrentStep] = useState(1);

  // INITIAL STATE
  const [userVoiceData, setUserVoiceData] = useState({
    headline: userLinkedInData?.headline || "",
    voiceType: mainVoiceType?.name || "",
    voiceText: "",
    keyChar: [],
  });
  const [value, setValue] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  // const { userMainData } = useUserMainDataStore();
  let voiceTypes = [];

  // VOICE TYPES
  const personalvoiceTypes = [
    {
      title: "The Networker",
      info: "This person is all about making connections. They're likely to have a very high number of connections and are active in various groups, constantly engaging with content and looking to expand their professional network.",
    },
    {
      title: "The Job Seeker",
      info: "Actively looking for new opportunities, this user's activity is geared towards making themselves attractive to potential employers. Their profile is meticulously updated, and they're keen on engaging with company pages and job listings.",
    },
    {
      title: "The Thought Leader",
      info: "Often sharing insightful posts, articles, and engaging in meaningful conversations about industry trends, this user is viewed as an expert in their field. They contribute valuable content and are often sought after for their opinions.",
    },
    {
      title: "The Content Creator",
      info: "Similar to thought leaders but more focused on creating original content like articles, videos, and infographics. They use LinkedIn as a platform to showcase their work and engage with their audience.",
    },
    {
      title: "The Recruiter",
      info: "Focused on finding the right candidates for job openings, recruiters are active in searching for profiles that match their criteria, posting job ads, and reaching out to potential candidates.",
    },
    {
      title: "The Salesperson",
      info: "Utilizing LinkedIn to identify and connect with potential leads, the salesperson is keen on expanding their network with strategic connections that could turn into customers. They're often sharing product updates, company news, and industry insights.",
    },
    {
      title: "The Company Evangelist",
      info: "Employees who are passionate about where they work and share a lot of content related to their company. They celebrate company achievements, share job openings, and post about company culture.",
    },
    {
      title: "The Casual User",
      info: "Perhaps the least active of all types, this user logs in occasionally, maybe to check on updates from their network or read some articles. They're not actively networking or looking for jobs but like to stay informed.",
    },
    {
      title: "The Mentor",
      info: "Experienced professionals who enjoy sharing their knowledge with others. They might post advice, offer to help others in their career paths, and engage with those seeking guidance or feedback.",
    },
    {
      title: "The Event Promoter",
      info: "Users who frequently post about webinars, conferences, and other professional events. They're all about bringing people together for networking and learning opportunities.",
    },
  ];

  const brandVoiceTypes = [
    {
      title: "Industry Leader",
      info: "These are the giants in their fields, well-known brands that use LinkedIn to share their insights, company news, and industry trends. They're often seen as thought leaders and have a substantial following.",
    },
    {
      title: "Start-Up",
      info: "Energetic and innovative, start-ups use LinkedIn to gain visibility, attract talent, and network with potential investors. Their content often includes company milestones, product launches, and team highlights.",
    },
    {
      title: "SME (Small and Medium Enterprises)",
      info: "These companies use LinkedIn to level the playing field against larger competitors, showcasing their expertise, customer service, and niche offerings. They often share success stories, customer testimonials, and engage actively with their network.",
    },
    {
      title: "B2B Service Provider",
      info: "Specializing in services for other businesses, these companies use LinkedIn for lead generation, sharing case studies, whitepapers, and industry insights to attract and engage potential clients.",
    },
    {
      title: "Tech Innovator",
      info: "Companies at the forefront of technology, whether in software, hardware, or biotech, use LinkedIn to share their latest innovations, research, and collaborations. They aim to attract talent and keep their audience informed about cutting-edge developments.",
    },
    {
      title: "Non-Profit Organization",
      info: "These entities use LinkedIn to raise awareness about their causes, share success stories, attract volunteers, and engage with potential donors. Their content often focuses on impact, community involvement, and ways to support their mission.",
    },
    {
      title: "Educational Institution",
      info: "Universities, colleges, and online education platforms use LinkedIn to attract students, share research and achievements, and network with alumni. They post about their programs, faculty, and student success stories.",
    },
    {
      title: "Event Organizer",
      info: "Companies that organize professional events, conferences, and webinars use LinkedIn to promote upcoming events, share highlights from past events, and engage with attendees and speakers.",
    },
    {
      title: "Content Publisher",
      info: "Media companies and content platforms use LinkedIn to share articles, reports, and videos. They aim to engage professionals with relevant news, insights, and discussions on current affairs and industry trends.",
    },
    {
      title: "Recruitment Firm",
      info: "Specializing in talent acquisition, these companies are active in posting job openings, career advice, and industry trends affecting employment. They aim to connect with both job seekers and employers to fill positions effectively.",
    },
  ];

  // FUNCTIONS

  // //setting the initial tab as per user selection
  useEffect(() => {
    setMainVoiceType(
      selectedValue
        ? selectedValue
        : { icon: "👨‍💻", name: "Personal Voice", uniqueName: "personalVoice" }
    );
  }, [selectedValue]);

  //update-userVoice
  const updateUserVoice = async () => {
    setIsLoading(true);

    try {
      if (mainVoiceType?.uniqueName === "personalVoice") {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userMain/v2/update-userVoice`,
          {
            userId: userData?.userId,
            purpose: mainVoiceType?.uniqueName,
            content: userVoiceData,
          }
        );
      } else {
        const updatedOrganizationVoice = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/organization/v2/updateOrganizationVoice`,
          {
            userId: userLinkedInData?.userId,
            organizationId: selectedOrganization.organizationId,
            brandVoice: userVoiceData.voiceText,
          }
        );
      }
      if (organizationsData.length > 0) {
        setSelectedOption({
          value: organizationsData[0]?._id,
          label: organizationsData[0]?.organizationName,
        });
      }

      setIsLoading(false);
      toast.success("updated successfully");
      setCurrentStep(1);
      setUserVoiceData({
        headline: userData?.headline || "",
        voiceType: mainVoiceType?.uniqueName || "",
        voiceText: "",
      });

      setVoiceDataArray([]);
      setSelectedVoiceType([]);
      setUserDataRefresh(!userDataRefresh);
      onClose(true);
    } catch (error) {
      toast.error("Error in updating brand voice");
      setIsLoading(false);
    }
  };

  const getVoicePromptSuggestions = async () => {
    setIsVoiceSuggestionsLoading(true);
    try {
      if (mainVoiceType?.uniqueName === "brandVoice" && !selectedOrganization) {
        toast.error("You need to have organizations to create brand voice.");
        setIsVoiceSuggestionsLoading(false);
        return;
      }
      const ResponseArray = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/linkedIn-voiceSuggestion`,
        {
          userId: userData?.userId,
          userHeadline: userData?.headline,
          selectedVoicesArray: selectedVoiceType,
          purpose: mainVoiceType?.uniqueName,
        }
      );

      const jsonStringResponse = ResponseArray?.data?.content;

      // Clean the JSON string by removing the leading and trailing markers
      const cleanJsonString = jsonStringResponse
        .replace(/^```json\n/, "")
        .replace(/\n```$/, "");

      // Parse the cleaned JSON string to convert it into a JavaScript array
      const voiceDataArray = JSON.parse(cleanJsonString);

      if (mainVoiceType?.uniqueName === "brandVoice") {
        setBrandVoiceDataArray(voiceDataArray);
      } else if (mainVoiceType?.uniqueName === "personalVoice") {
        setVoiceDataArray(voiceDataArray);
      }

      setIsVoiceSuggestionsLoading(false);
      setCurrentStep(2);
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
      setIsVoiceSuggestionsLoading(false);
    }
  };

  const handleSelection = (voice) => {
    setSelectedVoiceType((prev) => {
      if (prev?.includes(voice)) {
        // Deselect if already selected
        return prev?.filter((item) => item !== voice);
      } else {
        if (prev?.length < 3) {
          return [...prev, voice];
        } else {
          toast.info("You can select up to 3 personas only.");
          return prev;
        }
      }
    });
  };

  const handleSelectionChange = (value) => {
    setMainVoiceType(
      value === "Brand Voice"
        ? { icon: "💼", name: "Brand Voice", uniqueName: "brandVoice" }
        : { icon: "👨‍💻", name: "Personal Voice", uniqueName: "personalVoice" }
    );
  };

  const handleSubmit = () => {
    if (
      inputValue.trim() !== "" &&
      !selectedVoiceType.includes(inputValue.trim())
    ) {
      if (selectedVoiceType?.length < 3) {
        const newCustomInput = {
          title: inputValue.trim(),
          info: "Custom Voice",
        };
        setCustomInputArray((prev) => [...prev, newCustomInput]);
        setSelectedVoiceType((prev) => [...prev, inputValue.trim()]);

        // Clear the input field after adding
        setInputValue("");
      } else {
        // If 3 items are already selected, show a notification to the user
        toast.info("You can select up to 3 personas only.");
      }
    }
  };

  // ORGANIZATION SELECTION ------------------------

  const { organizationsData } = useUserOrganizationsStore();

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    const selectedOrg = organizationsData.find((org) => {
      return org?._id === selectedOption;
    });

    setSelectedOrganization(selectedOrg);
  };

  const options = organizationsData.map((org) => ({
    value: org?._id,
    label: org?.organizationName,
  }));

  useEffect(() => {
    if (organizationsData?.length > 0 && !selectedOption) {
      const defaultOrganization = organizationsData[0];
      setSelectedOrganization(defaultOrganization); // Set the first organization as selected
      setSelectedOption({
        value: defaultOrganization?._id,
        label: defaultOrganization?.organizationName,
      });
    }
  }, [organizationsData, setSelectedOrganization, selectedOrganization]);

  return (
    <>
      <Modal
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        width={"80vw"}
        footer={null}
      >
        <main className="flex flex-col items-center justify-center pb-14">
          <h2 className="pt-4 pb-8 text-4xl">
            Create Your
            {mainVoiceType?.uniqueName === "brandVoice" ? "Brand" : "Personal"}
            Voice
          </h2>
          <Segmented
            options={MainVoiceTypes?.map((each) => each?.value)}
            onChange={(value) => {
              setSelectedVoiceType([]);
              handleSelectionChange(value);
            }}
            value={mainVoiceType?.name}
            size="large"
          />

          {(selectedOrganization?.organizationBrandVoice.length > 0 &&
            mainVoiceType?.uniqueName === "brandVoice") ||
          (userData?.userVoice?.personalVoiceText &&
            userData?.userVoice?.personalVoiceText?.length > 0 &&
            mainVoiceType?.uniqueName === "personalVoice") ? (
            <>
              {mainVoiceType?.uniqueName === "brandVoice" &&
                selectedOrganization?.organizationBrandVoice.length > 0 && (
                  <div className="flex items-center justify-center w-2/3 gap-3 my-4 ">
                    {organizationsData?.length > 0 ? (
                      <>
                        <p className="font-semibold ">
                          Select your organization
                        </p>
                        <Select
                          id="simple-dropdown"
                          className="flex w-fit"
                          value={selectedOption}
                          onChange={handleChange}
                          options={options}
                        />
                      </>
                    ) : (
                      <>
                        <h2>You do not have any linkedIn organizations</h2>
                      </>
                    )}
                  </div>
                )}
              <ShowExisitingUserVoice
                mainPostType={mainVoiceType?.uniqueName}
                userVoiceData={userVoiceData}
                setUserVoiceData={setUserVoiceData}
                updateUserVoiceFunction={updateUserVoice}
                userData={userData}
                selectedOrganization={selectedOrganization}
              />
            </>
          ) : (
            <div className="flex flex-col items-center w-full gap-3 my-6 ">
              <div className="w-full">
                <div className="flex items-center justify-center w-1/4 gap-2 text-gray-500">
                  <button
                    className={`${
                      (mainVoiceType?.uniqueName === "brandVoice" &&
                        currentStep === 1) ||
                      (mainVoiceType?.uniqueName === "personalVoice" &&
                        currentStep === 1)
                        ? "text-gray-500"
                        : "text-triklAccentBlue"
                    }`}
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                  >
                    <MdOutlineNavigateBefore />
                  </button>
                  <p className="flex gap-2">
                    STEP
                    <span className="text-triklAccentBlue">{currentStep}</span>
                  </p>

                  <button
                    className={`${
                      (mainVoiceType?.uniqueName === "brandVoice" &&
                        BrandVoiceDataArray?.length > 0 &&
                        currentStep === 1) ||
                      (mainVoiceType?.uniqueName === "personalVoice" &&
                        voiceDataArray?.length > 0 &&
                        currentStep === 1)
                        ? "text-triklAccentBlue"
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      if (
                        mainVoiceType?.uniqueName === "brandVoice" &&
                        BrandVoiceDataArray?.length > 0
                      ) {
                        setCurrentStep(2);
                      } else if (
                        mainVoiceType?.uniqueName === "personalVoice" &&
                        voiceDataArray?.length > 0
                      ) {
                        setCurrentStep(2);
                      }
                    }}
                  >
                    <MdOutlineNavigateNext />
                  </button>
                </div>
              </div>
              {/* //---------------------------------- */}
              {currentStep === 1 ? (
                <>
                  {/* Condition for handling 'brandVoice' type and ensuring there are organizations to select from. */}
                  {mainVoiceType?.uniqueName === "brandVoice" &&
                  organizationsData.length > 0 ? (
                    <div className="flex items-center justify-center w-2/3 gap-3">
                      <p className="font-semibold">Select your organization</p>
                      <Select
                        id="simple-dropdown"
                        className="flex w-fit"
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                      />
                    </div>
                  ) : (
                    mainVoiceType?.uniqueName === "brandVoice" && (
                      <h2 className="text-center">
                        You do not have any LinkedIn organizations
                      </h2>
                    )
                  )}

                  {/* Section for Step-1: Persona selection */}
                  <section className="flex flex-col w-3/4 gap-3 my-6">
                    <h2 className="pb-6 text-2xl">
                      Select 3 personas that you like! [{" "}
                      <span className="font-semibold text-triklAccentBlue">
                        {selectedVoiceType?.length} / 3
                      </span>{" "}
                      selected ]
                    </h2>
                    <div className="flex flex-wrap gap-3 pb-6">
                      {mainVoiceType?.uniqueName === "personalVoice"
                        ? [...personalvoiceTypes, ...customInputArray].map(
                            (each, index) => (
                              <button
                                disabled={
                                  selectedVoiceType?.length >= 3 &&
                                  isVoiceSuggestionsLoading
                                }
                                className={`flex rounded-md items-center gap-2 px-4 py-2 transition-all duration-300 ease-in-out border border-gray-300 w-max ${
                                  selectedVoiceType?.includes(each?.title)
                                    ? "border-triklAccentBlue bg-triklAccentBlue text-white"
                                    : ""
                                }`}
                                key={index}
                                onClick={() => handleSelection(each?.title)}
                              >
                                <p>{each?.title}</p>
                                <IoInformationCircleOutline
                                  data-tooltip-id={`personalizeModalTooltip-${index}`}
                                  data-tooltip-content={
                                    each?.info || "Custom Voice"
                                  }
                                  data-tooltip-place="top"
                                />
                                <Tooltip
                                  id={`personalizeModalTooltip-${index}`}
                                  style={{
                                    fontSize: "0.8rem",
                                    maxWidth: "250px",
                                    whiteSpace: "normal",
                                    textAlign: "left",
                                  }}
                                />
                              </button>
                            )
                          )
                        : [...brandVoiceTypes, ...customInputArray].map(
                            (each, index) => (
                              <button
                                disabled={
                                  selectedVoiceType?.length >= 3 &&
                                  isVoiceSuggestionsLoading
                                }
                                className={`flex rounded-md items-center gap-2 px-4 py-2 transition-all duration-300 ease-in-out border border-gray-300 w-max ${
                                  selectedVoiceType?.includes(each?.title)
                                    ? "border-triklBlueDark bg-triklBlueDark text-white"
                                    : ""
                                }`}
                                key={index}
                                onClick={() => handleSelection(each?.title)}
                              >
                                <p>{each?.title}</p>
                                <IoInformationCircleOutline
                                  data-tooltip-id={`personalizeModalTooltip-${index}`}
                                  data-tooltip-content={
                                    each?.info || "Custom Voice"
                                  }
                                  data-tooltip-place="top"
                                />
                                <Tooltip
                                  id={`personalizeModalTooltip-${index}`}
                                  style={{
                                    fontSize: "0.8rem",
                                    maxWidth: "250px",
                                    whiteSpace: "normal",
                                    textAlign: "left",
                                  }}
                                />
                              </button>
                            )
                          )}
                    </div>
                    {/* Input box for adding a custom persona, only if no brand/personal voice text already exists. */}
                    {((mainVoiceType?.uniqueName === "personalVoice" &&
                      userData?.userVoice?.personalVoiceText?.length === 0) ||
                      (mainVoiceType?.uniqueName === "brandVoice" &&
                        selectedOrganization?.organizationBrandVoice?.length ===
                          0)) && (
                      <div className="flex">
                        <input
                          type="text"
                          placeholder="Add custom voice"
                          className="w-32 px-2 py-1 border border-gray-300 rounded-md"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button
                          onClick={handleSubmit}
                          className="px-4 py-2 ml-2 bg-white border rounded-md border-triklBlueDark hover:text-white text-triklBlueDark hover:bg-triklAccentBlue"
                        >
                          Add
                        </button>
                      </div>
                    )}
                    <button
                      type="primary"
                      disabled={
                        selectedVoiceType?.length < 3 ||
                        isVoiceSuggestionsLoading
                      }
                      size="large"
                      onClick={() => {
                        getVoicePromptSuggestions();
                      }}
                      className={`text-lg ${
                        isVoiceSuggestionsLoading
                          ? "bg-triklAccentBlue"
                          : "bg-white"
                      } text-triklAccentBlue w-full hover:bg-triklAccentBlue hover:text-white border-triklAccentBlue box-border rounded-md border-[1px] mt-6 py-2 flex items-center justify-center disabled:text-white disabled:border-gray-400 disabled:bg-gray-400 transition-all duration-300 ease-in-out`}
                    >
                      {isVoiceSuggestionsLoading ? (
                        <LoadingSpinnerWhite />
                      ) : (
                        "Create Voice"
                      )}
                    </button>
                  </section>
                </>
              ) : (
                <>
                  {/* Step-2: Brand voice selection and editing */}
                  {voiceDataArray.length > 0 ||
                  BrandVoiceDataArray.length > 0 ? (
                    <section className="flex flex-col w-3/4 gap-3">
                      <h2 className="pb-6 text-2xl">
                        Select/Edit your voice. (You can only choose one)
                      </h2>
                      <div className="flex gap-5">
                        {(mainVoiceType?.uniqueName === "brandVoice"
                          ? BrandVoiceDataArray
                          : voiceDataArray
                        ).map((each, index) => (
                          <div
                            onClick={() => {
                              const selectedVoiceText = each.voiceText;
                              setUserVoiceData({
                                ...userVoiceData,
                                voiceText: selectedVoiceText,
                              });
                              setValue(selectedVoiceText);
                            }}
                            key={index}
                            className={`flex flex-col p-2 border-2 rounded-md ${
                              value === each.voiceText
                                ? "border-triklAccentBlue"
                                : "border-gray-300"
                            }`}
                          >
                            <Radio.Group
                              onChange={(e) => {
                                const selectedVoiceText = e.target.value;
                                setUserVoiceData({
                                  ...userVoiceData,
                                  voiceText: selectedVoiceText,
                                });
                                setValue(selectedVoiceText);
                              }}
                              value={value}
                              className="w-full"
                            >
                              <Radio
                                value={each.voiceText}
                                className="font-semibold"
                              >
                                {`Voice ${index + 1}`}
                              </Radio>
                            </Radio.Group>
                            <hr className="px-6 mt-2" />
                            <div className="flex flex-col items-center p-3 my-2 rounded-md max-w-30 min-w-16">
                              <p
                                contentEditable
                                onBlur={(e) => {
                                  e.currentTarget.style.border = "none";
                                  setUserVoiceData({
                                    ...userVoiceData,
                                    voiceText: e.target.innerText,
                                  });
                                }}
                                suppressContentEditableWarning={true}
                                style={{
                                  outline: "none",
                                }}
                              >
                                {each?.voiceText}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <button
                        type="primary"
                        disabled={userVoiceData.voiceText === ""}
                        size="large"
                        onClick={() => {
                          updateUserVoice();
                        }}
                        className={`text-lg ${
                          isLoading ? "bg-triklAccentBlue" : "bg-white"
                        } text-triklAccentBlue w-full hover:bg-triklAccentBlue hover:text-white border-triklAccentBlue box-border rounded-md border-[1px] mt-6 py-2 flex items-center justify-center disabled:text-white disabled:border-gray-400 disabled:bg-gray-400 transition-all duration-300 ease-in-out`}
                      >
                        {isLoading ? <LoadingSpinnerWhite /> : "Update"}
                      </button>
                    </section>
                  ) : (
                    <h2 className="text-center">
                      No voice data available for editing
                    </h2>
                  )}
                </>
              )}
            </div>
          )}
        </main>
      </Modal>
    </>
  );
};

export default PersonalizeModal;

const ShowExisitingUserVoice = ({
  mainPostType,
  userVoiceData,
  setUserVoiceData,
  updateUserVoiceFunction,
  userData,
  selectedOrganization,
}) => {
  let DisplayData;
  if (mainPostType === "brandVoice") {
    DisplayData = selectedOrganization?.organizationBrandVoice;
  } else if (mainPostType === "personalVoice") {
    DisplayData = userData?.userVoice?.personalVoiceText;
  }

  return (
    <div className="flex flex-col w-3/4 gap-3">
      <div
        className={`flex flex-col p-2  mt-10 border-2  rounded-md border-gray-300 hover:border-triklAccentBlue`}
      >
        <div
          className={`my-2 flex flex-col items-center p-3 rounded-md max-w-30 min-w-16`}
        >
          <p
            contentEditable
            onBlur={(e) => {
              e.currentTarget.style.border = "none";
              setUserVoiceData({
                ...userVoiceData,
                voiceText: e.target.innerText,
              });
            }}
            suppressContentEditableWarning={true}
            style={{
              outline: "none",
            }}
          >
            {DisplayData}
          </p>
        </div>
      </div>
      <button
        type="primary"
        size="large"
        onClick={() => {
          updateUserVoiceFunction();
        }}
        className={`text-lg text-triklAccentBlue w-full hover:bg-triklAccentBlue hover:text-white border-triklAccentBlue box-border 
              rounded-md border-[1px] mt-6 py-2 flex items-center justify-center transition-all duration-300 ease-in-out`}
      >
        Update Voice
      </button>
    </div>
  );
};
