import React from "react";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import ChatAiIcon from "../../../../assets/chatAiicons.gif";
const ContentChatArea = ({ myDivRef, chatArray }) => {
  // FUNCTIONS
  const handleCopyClick = (aiResponseCopied) => {
    navigator.clipboard.writeText(aiResponseCopied);
    toast.success("Post copied successfully!", {
      pauseOnFocusLoss: false,
    });
  };

  // RENDER
  return (
    <section
      className="w-full h-full"
      style={{
        overflowY: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <header className="flex flex-col items-center justify-center w-screen px-2 pt-2 mx-auto md:w-2/3 md:px-0">
        <img width={100} src={ChatAiIcon} alt="AI chat icon - TRIKL" />
        <h2 className="pb-2 text-2xl font-semibold text-triklBlueDark">
          Supercharge Your Community With AI
        </h2>
        <div className="">
          TRIKL works with ChatGPT to analyse your community and creates content
          that's most relevant to your members. All with a single click.
        </div>

        <div className="pt-4 pb-8 text-xs text-gray-500">
          Let's learn together. TRIKL is powered by ChatGPT, so surprises and
          mistakes are possible. Make sure to check the facts, and share
          feedback so we can learn and improve!
        </div>
      </header>

      {/* ALL CHATS GO HERE */}
      <article
        className="items-end w-5/6 pb-8 mx-auto text-left"
        ref={myDivRef}
      >
        {/* Msg by ai */}
        {chatArray.map((eachChatMsg, key) => (
          <section
            className={`flex ${
              eachChatMsg.chatType === "assistant"
                ? "flex-row"
                : "flex-row-reverse"
            }  gap-3 drop-shadow-md mb-6`}
            key={key}
          >
            <header className="mt-1">
              <img
                className="rounded-full"
                src={
                  eachChatMsg.chatType === "assistant"
                    ? eachChatMsg.chatUsrImg
                    : eachChatMsg.chatUsrImg
                  // "user.photoURL"
                }
                alt={eachChatMsg.chatUsrName}
                width={30}
              />
            </header>

            <article
              className="flex items-center justify-start gap-2 cursor-pointer"
              onClick={() => {
                if (eachChatMsg.chatType === "assistant") {
                  handleCopyClick(eachChatMsg.chatMsg);
                }
              }}
            >
              <div
                className={`max-w-lg rounded-lg px-6 py-4 ${
                  eachChatMsg.chatType === "assistant"
                    ? "bg-white"
                    : "bg-[#8beaff]"
                }`}
              >
                {eachChatMsg.chatMsg}
              </div>
              {eachChatMsg.chatType === "assistant" && (
                // COPY SECTION
                <section className="flex items-center gap-1 text-xl text-gray-500/60 hover:text-triklAccentBlue">
                  <BiCopy />
                </section>
              )}
            </article>
          </section>
        ))}
      </article>
    </section>
  );
};

export default ContentChatArea;
