const V2BackgroundTemplate12 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3519)">
        <rect
          x="290"
          y="-52"
          width="277"
          height="277"
          rx="50"
          fill={colorPallet?.secondary}
          fill-opacity="0.1"
        />
        <rect
          x="273"
          y="-64"
          width="275"
          height="275"
          rx="49"
          stroke="black"
          stroke-width="2"
        />
        <rect
          x="-70"
          y="590"
          width="277"
          height="277"
          rx="50"
          fill={colorPallet?.secondary}
          fill-opacity="0.1"
        />
        <rect
          x="-54"
          y="606"
          width="275"
          height="275"
          rx="49"
          stroke="black"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate12;
