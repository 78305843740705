import React, { useEffect, useState } from "react";
import "./onboardingLoader.css";
import SnakeGame from "./LoadingGame";

const OnBoardingLoader = () => {
  const LoaderContent = [
    "Evaluating Your Goals",
    "Analyzing Your Topic Preferences",
    "Curating Topics For You",
    "Training AI For You",
    "Applying Your LinkedIn Preferences",
    "Understanding Interests",
    "Noting Languages Spoken",
    "Studying Influencers Posting Style",
    "Assessing Engagement Levels",
    "Creating Your Personal Voice",
    "Drafting Suggestions",
    "Building Profile",
    "Writing Recommendation",
    "Generating Posts for You",
    "Generating Images",
    "Preparing Output",
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [seconds, setSeconds] = useState(157);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % LoaderContent.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [seconds]);

  return (
    <div className="flex items-center justify-around min-h-screen space-y-4 text-lg text-white bg-linkedInPeach">
      <div className="loader-container">
        <div className="loader-animation">
          {LoaderContent.map((content, index) => (
            <div
              key={index}
              className={`loader-step ${
                index === LoaderContent.length / 2 ? "loader-step-active" : ""
              }`}
            >
              {content}
            </div>
          ))}
        </div>
      </div>

      <div>
        <p className="pb-2 text-lg">Till then... feed this Snake!</p>
        <SnakeGame />
        <p className="pt-2 text-sm">
          Use Arrow Keys To Move. Don't Hit The Walls
        </p>
      </div>

      <div className="absolute bottom-10">
        <p className="text-6xl">{`${Math.floor(seconds / 60)}:${
          seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
        }`}</p>
        <p className="pt-2 text-sm">This usually takes 2-5 minutes</p>
      </div>
    </div>
  );
};

export default OnBoardingLoader;
