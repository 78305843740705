import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Spin,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { BglightCircle } from "../../../../assets/background";
import { triklPurple } from "../../../../assets/Trikl_logo";
import GoogleLogin from "./GoogleLogin";
import { useUserAuth } from "../../../../context/userAuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";
import { LoadingOutlined } from "@ant-design/icons";

const SignUp = () => {
  // Added: State for loading screen
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [form] = Form.useForm();

  const { user, signUp, logOut } = useUserAuth();
  const { userMainData } = useUserMainDataStore();
  let navigate = useNavigate();

  const handleEmailSignUp = async (values) => {
    const { email, password, agreeTerms, name } = values;
    // Extract domain from email
    const emailDomain = email.split("@")[1];

    if (!agreeTerms) {
      toast.error("You must agree to the terms and conditions to continue.");
      return;
    }

    // // Check if the domain is disposable or not
    // try {
    //   const domainCheckRes = await axios.get(
    //     `https://api.mailcheck.ai/domain/${emailDomain}`
    //   );

    //   if (domainCheckRes?.data?.disposable) {
    //     toast.error("Disposable email addresses are not allowed.");
    //     setIsLoading(false);
    //     return;
    //   }
    // } catch (error) {
    //   toast.error("Failed to verify email domain.");
    //   setIsLoading(false);
    //   return;
    // }

    setIsLoading(true);

    try {
      const res = await signUp(email, password, name);

      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/create-user`,
        {
          name: name,
          email: res.user?.email,
          userId: res.user.uid,
        }
      );
      toast.success("logged in successfully");
      navigate("/user/onboarding");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("User already exists");
      } else if (error.code === "auth/weak-password") {
        toast.error("Password should be at least 6 characters");
      } else {
        toast.error("Error signing up");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.uid) {
      setUserLoggedIn(true);
    }

    // Simulate loading time before showing the page
    const timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 2000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, [user]);

  if (isPageLoading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen bg-white bg-top bg-no-repeat">
      <div className="">
        <div className="absolute flex justify-center">
          <img className="" src={BglightCircle} alt="" />
        </div>

        <Row justify="center">
          <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
            <div className="flex justify-center mt-3">
              <img src={triklPurple} alt="Trikl" className="w-28 h-28" />
            </div>
            <div className="mb-0 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
              {userLoggedIn ? (
                <>
                  <div className="px-5 py-4 text-left border-b border-gray-200 dark:border-white10">
                    <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
                      Hi, {userMainData?.name || user?.displayName} 👋
                    </h2>
                  </div>
                  <p className="mt-3 text-md ">
                    With TRIKL by your side, content creation becomes a piece of
                    cake.
                  </p>
                  <div className="px-10 pt-8 pb-6">
                    <Button
                      className="w-full h-12 p-0 my-6 text-sm font-medium"
                      htmltype="submit"
                      type="primary"
                      size="large"
                      onClick={() => {
                        navigate("/linkedin/content-creation");
                      }}
                    >
                      Go To Dashboard
                    </Button>
                  </div>
                  <div className="p-6 space-x-2 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
                    <span
                      onClick={async () => {
                        await logOut();
                        window.location.reload();
                      }}
                      className="ml-1 font-medium cursor-pointer text-linkedInPeach"
                    >
                      sign out
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
                    <h2 className="mb-0 text-lg font-semibold text-dark dark:text-white87">
                      Create your TRIKL account
                    </h2>
                  </div>

                  <div className="px-10 pt-6 pb-0">
                    <Form
                      form={form}
                      name="register"
                      onFinish={handleEmailSignUp}
                      layout="vertical"
                    >
                      <Form.Item
                        label="Name"
                        name="name"
                        className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Full name!",
                          },
                        ]}
                      >
                        <Input placeholder="Full name" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email Address"
                        className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                            type: "email",
                          },
                        ]}
                      >
                        <Input placeholder="name@example.com" />
                      </Form.Item>
                      <Form.Item
                        label="Password"
                        name="password"
                        className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                      <div className="flex items-center text-sm ">
                        <Form.Item name="agreeTerms" valuePropName="checked">
                          <Checkbox className="cursor-default">
                            I agree to the &nbsp;
                            <a
                              href="https://trikl.ai/terms-conditions/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline cursor-pointer text-linkedInPeach hover:text-linkedInPeach/50 "
                            >
                              Terms of Service
                            </a>
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <Form.Item>
                        <button
                          loading={isLoading}
                          disabled={isLoading}
                          // className="w-full h-12 p-0 text-sm font-medium "
                          className="flex items-center justify-center w-full gap-3 py-2 transition-all duration-300 ease-in-out bg-transparent border rounded-md border-tNewButtonsAndActiveElement text-tNewButtonsAndActiveElement hover:bg-tNewButtonsAndActiveElement hover:text-tNewBackgroundOrInactive"
                          htmltype="submit"
                          // type="primary"
                          size="large"
                        >
                          Create Account
                        </button>
                      </Form.Item>

                      <p className="mb-5">
                        <span className="">or</span>
                      </p>

                      <GoogleLogin />
                    </Form>
                  </div>
                  <div className="p-3 my-6 text-center bg-gray-100 rounded-md dark:bg-white10">
                    <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                      Already have an account? &nbsp;
                      <Link
                        to="/user/signin"
                        className="ltr:ml-1.5 rtl:mr-1.5 text-info font-semibold text-tNewButtonsAndActiveElement hover:text-tNewButtonsAndActiveElementDarkest"
                      >
                        Sign In
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignUp;

// Added: Loader component
const Loader = () => (
  <div className="flex items-center justify-center min-h-screen">
    <Spin size="large" />
  </div>
);
