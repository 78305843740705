import React, { useEffect } from "react";
import { useAIRecommendationClickStore } from "../../ZustandStores/LinkedInZustandStore";
import { useState } from "react";
import MediaCreation from "./MediaCreation";
import MediaFileUpload from "./MediaFileUpload";
import { ConfigProvider, Menu } from "antd";
import SavedMediaDisplay from "./SavedMediaDisplay";

const ImgSidebar = () => {
  // MENU ITEMS
  const items = [
    { label: "Generate AI Media", key: "0" },
    { label: "Upload Media", key: "1" },
    { label: "Saved Media", key: "2" },
  ];

  const { isAIRecommendationClick, setIsAIRecommendationClick } =
    useAIRecommendationClickStore();
  const [selectedMenuItem, setSelectedMenuItem] = useState(items[0].key);

  // Auto click
  useEffect(() => {
    if (isAIRecommendationClick) {
      setSelectedMenuItem(items[0].key);
    }
  }, [isAIRecommendationClick]);

  const onClick = ({ key }) => {
    setSelectedMenuItem(key);
  };

  return (
    <main className="flex flex-col w-full max-h-screen min-h-screen gap-5 py-24 overflow-y-scroll bg-white">
      <h2 className="w-full px-4 text-lg font-bold text-center">
        Your wish, Our AI's command
      </h2>
      <Menu
        className="w-full bg-white text-triklAccentBlue"
        onClick={onClick}
        selectedKeys={[selectedMenuItem]}
        mode="horizontal"
        items={items}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      />

      {/* GENERATE AI IMAGE SECTION */}
      {selectedMenuItem == 0 && <MediaCreation />}
      {/* DISPLAY UPLOAD MEDIA SECTION */}
      {selectedMenuItem == 1 && <MediaFileUpload />}
      {/* SAVED MEDIA FILES */}
      {selectedMenuItem == 2 && <SavedMediaDisplay />}
    </main>
  );
};

export default ImgSidebar;
