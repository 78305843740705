import React, { useRef, useState } from "react";
import axios from "axios";
import { triklLogo } from "../../../../assets/Trikl_logo";
import ContentChatArea from "./ContentChatArea";
import ContentWriteArea from "./ContentWriteArea";
import MainPostSide from "../../../Dashboard/subPages/postCreation/MainPostSide";
import { LoadingSpinnerSmall } from "../../../components/widgets/LoadingSpinner";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { Button } from "antd";
const moment = require("moment");

const ShortContentCreator = () => {
  let { userData } = useLinkedInUserContext();

  const topWordArray = [
    { text: "zeeve", value: 11 },
    { text: "web3", value: 9 },
    { text: "blockchain", value: 9 },
    { text: "event", value: 8 },
    { text: "crypto", value: 8 },
    { text: "contest", value: 7 },
    { text: "growth", value: 6 },
    { text: "trading", value: 6 },
    { text: "nvm", value: 5 },
    { text: "trading", value: 3 },
    { text: "node4", value: 3 },
  ];

  const chatId = "1549123679";
  const approvedGroup = true;
  const platformName = "telegram";
  const groupDescription =
    "Zeeve is a reliable and secure blockchain infrastructure management platform used by thousands of blockchain startups, enterprises, and web3 developers to deploy, scale, monitor, and manage their blockchain nodes and networks. The platform offers enterprise-grade security, deployment options (bring your own cloud or use blockchain managed service), high performance, real-time data analytics, 24/7 resource monitoring, and CI/CD automation. It supports all major blockchain protocols, offers managed and hybrid deployment options, advanced analytics and real-time monitoring, and pluggable services for rapid development. Zeeve aims to simplify the process for both enterprises and blockchain startups to build decentralized apps, deploy blockchain networks, monitor and grow by providing powerful developer tools, resources, and support.";
  const communityTag = ["communityTag"];
  // ------
  const path = window.location.pathname;

  const topWords = topWordArray.map((item) => item.text).join(", ");

  const [inputValue, setInputValue] = useState("");
  const timestamp = moment().format("YYYYMMDDHHmmss");

  let chatArray = [
    {
      chatUsrName: "TRIKL",
      chatUsrImg: triklLogo,
      chatMsg:
        "Let's get started with your first post. Type something or select a template!",
      chatType: "assistant",
    },
  ];

  const special_words = [
    "Empathy",
    "Gratitude",
    "Encouragement",
    "Appreciation",
    "Support",
    "Validation",
    "Inspiration",
    "Positivity",
    "Humor",
    "Love",
    "Hope",
    "Acknowledgment",
    "Understanding",
    "Curiosity",
    "Friendship",
    "Respect",
    "Joy",
    "Patience",
    "Trust",
    "Compassion",
  ];

  const getUniqueWord = () => {
    const uniqueIndex = Math.floor(Math.random() * special_words.length);
    const uniqueWord = special_words[uniqueIndex];
    special_words.splice(uniqueIndex, 1); // Remove the selected word from the array
    return uniqueWord;
  };

  // post input to openai API
  const aiRecommendation = async (preDefinedText, isTemplate) => {
    scrollToBottom();
    const RandomTone = getUniqueWord();
    let aiInput;
    chatArray.push({
      chatUsrName: "TRIKL",
      chatUsrImg: triklLogo,
      chatMsg: preDefinedText,
      chatType: "user",
    });

    // Add loader
    chatArray.push({
      chatUsrName: "loading",
      chatUsrImg: triklLogo,
      chatMsg: <LoadingSpinnerSmall />,
      chatType: "assistant",
    });

    if (!isTemplate) {
      aiInput = preDefinedText;
    } else {
      aiInput = `You're an experienced community engagement manager on ${platformName}. ${preDefinedText} in less than 150 characters for a group that loves to talk about the following: ${topWords}. Tone should be ${RandomTone} about these topics. Make it relatable to the group's audience. Don't explain. Don't refuse to answer. Don't write disclaimers.You can do your own research around the mentioned topics and internet. Add Emojis if applicable. Your GOAL is to maximize engagement in the ${platformName} group. `;
      if (!groupDescription.length == "") {
        aiInput += `To know more about the topic you can refer this about the group: ${groupDescription}} timestamp`;
      }
      aiInput += `[Timestamp: ${timestamp}]`;
    }
    // input to openAI API
    //Only if group is approved

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/openai/advanced-ai-recommendation`,
        {
          advancedPrompt: aiInput,
        }
      );
      const formattedResponse = res?.data?.content.replace(": ", "");
      chatArray.pop();
      chatArray.push({
        chatUsrName: "TRIKL",
        chatUsrImg: triklLogo,
        chatMsg: formattedResponse,
        chatType: "assistant",
      });
      scrollToBottom();
    } catch (err) {
      if (err.response.status === 500 || 503) {
        alert("Something went wrong. Please try again.");
        window.location.reload();
      }
    }
  };

  // CHAT SCROLL TO LATEST MESSAGE
  const myDivRef = useRef(null);
  function scrollToBottom() {
    if (
      !path.includes("/blogs") &&
      !path.includes("/image") &&
      !path.includes("/ask-ai")
    ) {
      myDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  // PROPS TO PASS
  const chatAreaProps = { myDivRef, chatArray };

  const writeChatAreaProps = {
    inputValue,
    setInputValue,
    aiRecommendation,
    scrollToBottom,
    approvedGroup,
  };

  const mainPostSideProps = {
    chatId,
    platformName,
    setInputValue,
    aiRecommendation,
    scrollToBottom,
    topWordArray,
    communityTag,
  };

  return (
    <>
      {userData?.companyName !== "Zeeve" ? (
        <>
          <div className="flex flex-col items-stretch justify-center w-full max-h-screen overflow-hidden md:flex-row">
            <main className="flex flex-col w-full h-screen py-10 mt-8 md:w-2/3 md:border-r">
              <>
                <ContentChatArea {...chatAreaProps} />
                <ContentWriteArea {...writeChatAreaProps} />
              </>
            </main>
            <MainPostSide {...mainPostSideProps} />
          </div>
        </>
      ) : (
        <section className="flex flex-col items-center justify-center h-screen gap-3 ">
          <h3 className="text-3xl font-bold">
            You Do Not Have Access To This Page
          </h3>
          <Button
            type="primary"
            href="/linkedin/content-creation"
            className="text-white bg-triklAccentBlue"
            size="large"
          >
            Go to Dashboard
          </Button>
        </section>
      )}
    </>
  );
};

export default ShortContentCreator;
