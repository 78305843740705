import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  useFinalContentStore,
  useOverallStepStore,
} from "../../linkedIn/ZustandStores/LinkedInZustandStore";
import { toast } from "react-toastify";

const StepperComponent = () => {
  const { overallStep, setOverallStep } = useOverallStepStore();
  const { finalContent } = useFinalContentStore();

  const handleStepClick = (key) => {
    if (
      key + 1 === 3 &&
      !finalContent?.finalText?.length > 0 &&
      !finalContent?.finalMediaUrl?.length > 0
    ) {
      toast.info("Please add text and media to proceed");
      return;
    }
    setOverallStep(key + 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={overallStep - 1} alternativeLabel>
        {["Create Content", "Select Media", "Review & Finalize"].map(
          (label, key) => (
            <Step
              onClick={() => handleStepClick(key)}
              key={key}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#6F42C1", // Just text label (COMPLETED)
                  // color: "",
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#F5EAFA", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "#6F42C1", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "black", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "#6F42C1", // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel
                sx={{
                  color: "green",
                  "&.Mui-active": { color: "red" },
                  "&.Mui-completed": { color: "green" },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;
