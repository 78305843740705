import { useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../../../context/userAuthContext";
import ProfileDetailsDrawer from "./ProfileDetailsDrawer";
import axios from "axios";
import { Button, Card, Spin } from "antd";
import { CiBookmark } from "react-icons/ci";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { IoIosSend, IoMdArrowRoundForward } from "react-icons/io";
import { AiOutlineClockCircle, AiOutlineLike } from "react-icons/ai";
import {
  useUserLinkedInAPIStore,
  useUserLinkedInStore,
  useUserMainDataStore,
  userLinkedInDataRefreshStore,
} from "../../ZustandStores/UserLinkedInStore";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import AddToScheduler from "../schedulerComponents/AddToScheduler";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { IoCaretDown } from "react-icons/io5";
import {
  // convertHtmlToFormattedSavedDrafts,
  convertHtmlToFormattedTextOnboarding,
  parseFormattedTextToHtmlOnboarding,
} from "../../../function/ConvertHtmlToFormattedText";
import PostSuccessfulModal from "./PostSuccessfulModal";

const DisplayGeneratedPosts = ({ generatedPosts, setGeneratedPosts }) => {
  const initialLoad = useRef(true);
  const { user } = useUserAuth();
  const { userLinkedInData, setUserLinkedInData } = useUserLinkedInStore();

  const [selectedPost, setSelectedPost] = useState(
    generatedPosts?.length > 0 ? generatedPosts[0] : {}
  );
  const [savedPosts, setSavedPosts] = useState([]); // To track all the bookmarked posts
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const [displayScheduler, setDisplayScheduler] = useState(false);
  const [postingOnLinkedIn, setPostingOnLinkedIn] = useState(false);

  // POST SUCCESSFUL MODAL DISPLAY
  const [postSuccessModal, setPostSuccessModal] = useState(false);
  const [postSuccessAction, setPostSuccessAction] = useState({
    name: "",
    shareId: "",
  });

  // Function to get the date in DD/MM/YYYY format:
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // // close the profile drawer
  // const closeDrawer = () => {
  //   setIsDrawerOpen(false);
  // };

  // fetch user linkedIn details
  const fetchUser = async () => {
    const userId = encodeURIComponent(user?.uid ?? "");
    const url = `${process.env.REACT_APP_SERVERURL}/userLinkedin/login/success?userId=${userId}`;

    try {
      const data = await axios.get(url, {
        withCredentials: true,
      });

      setUserLinkedInData(data.data.user);
      return data.data.user;
    } catch (error) {}
  };

  // Update the content of the selected post with HTML content
  const updatePostContent = async (htmlContent) => {
    setSelectedPost((prev) => ({ ...prev, content: htmlContent }));

    // Update the content in the generatedPosts array
    const updatedPosts = generatedPosts.map((post) =>
      post.title === selectedPost?.title
        ? { ...post, content: htmlContent }
        : post
    );

    setGeneratedPosts(updatedPosts);

    // update the content in the database
    if (selectedPost) {
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/updatePostContent`,
          {
            userId: user?.uid,
            title: selectedPost?.title,
            content: convertHtmlToFormattedTextOnboarding(htmlContent),
          }
        );
      } catch (error) {
        toast.error("oops! something went wrong. Please try again.");
      }
    }
  };

  // save the posts to drafts
  const savePostToDrafts = async (post) => {
    try {
      await savePost(post);
      // Save the image to the database
      await saveImage(post);
      setSavedPosts([...savedPosts, post]);
      toast.success("Post saved to drafts successfully");
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  // Function to save a post to drafts
  async function savePost(post) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/save-to-drafts`,

        {
          userId: user?.uid,
          draftId: "",
          mediaUrl: post.image,
          textContent: post.content,
          mediaType: "image",
        }
      );
    } catch (error) {
      // Throw the error to be caught by the caller
      toast.error("oops! something went wrong. Please try again.");
    }
  }
  //   {
  //     userId: user?.uid,
  //     postData: {
  //       content: post.content,
  //       image: post.image,
  //     },
  //   }
  // );
  // Function to save image
  async function saveImage(post) {
    const createdOn = getCurrentDate();

    const details = {
      userId: user?.uid,
      medias: [
        {
          mediaType: "image",
          source: "onboarding",
          prompts: "",
          mediaURL: post?.image || "",
          createdOn: createdOn,
        },
      ],
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMedia/v2/storeMedia`,
        details
      );
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  }

  // Function to check if the user is logged in and post on LinkedIn
  const handlePostOnLinkedIn = async () => {
    if (userLinkedInData?.linkedInUserId) {
      setPostingOnLinkedIn(true);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/self-post`,
          {
            userId: userLinkedInData?.userId,
            mediaUrl: selectedPost?.image,
            content: convertHtmlToFormattedTextOnboarding(
              selectedPost?.content
            ),
            mediaType: "image",
          },
          {
            withCredentials: true,
          }
        );
        setPostingOnLinkedIn(false);

        setPostSuccessAction({ name: "Posted", shareId: res?.data?.shareId });

        setPostSuccessModal(true);
      } catch (error) {
        setPostingOnLinkedIn(false);

        toast.error("Post failed");
      }
    } else {
      // Display LinkedIn Login if the user is not logged in
      if (user) {
        user.getIdToken().then((idToken) => {
          const linkedInAuthUrl = `${process.env.REACT_APP_SERVERURL}/linkedIn/auth?firebaseToken=${idToken}?isOnboarding=true`;
          window.location.href = linkedInAuthUrl;
        });
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, [user]);

  // Select the first post and even if the user generated Posts get's updated don't select the first one
  useEffect(() => {
    if (initialLoad.current) {
      setSelectedPost(generatedPosts[0]);
      initialLoad.current = false;
    }
  }, [generatedPosts]);

  return (
    <>
      <Spin spinning={postingOnLinkedIn} fullscreen />
      <PostSuccessfulModal
        isOpen={postSuccessModal}
        onClose={() => setPostSuccessModal(false)}
        action={postSuccessAction}
      />
      <section className="container flex items-start h-screen gap-8 p-32 mx-auto">
        {/* LEFT SECTION */}
        <div className="w-2/5">
          <button className="flex items-center justify-end invisible w-full gap-1 my-1 text-xs">
            <p>spacer</p>
          </button>

          <Card>
            <p className="pt-2 pb-5 text-3xl font-semibold text-left">
              <span className="text-sm">We created</span>
              <br />3 Posts You Might Like
            </p>
            {/* display posts button */}
            {generatedPosts?.map((post, index) => (
              <button
                key={index}
                className={`${
                  selectedPost?.title === post?.title
                    ? "bg-linkedInPeach/10 text-linkedInPeach border-linkedInPeach"
                    : " bg-triklLightGrey text-darkGrey border-lightGrey"
                } p-2 w-full  rounded-md flex items-center border justify-between my-3 text-sm font-normal cursor-pointer `}
                onClick={() => {
                  setSelectedPost(post);
                }}
              >
                <p className="px-2 py-2 text-left">
                  {index + 1 + ".  "}
                  {post?.title?.length > 35
                    ? post?.title?.slice(0, 35) + "..."
                    : post?.title}
                </p>
                {/* save to drafts */}
                <button
                  disabled={savedPosts.includes(post)}
                  className="pr-2"
                  onClick={() => {
                    savePostToDrafts(post);
                  }}
                >
                  {savedPosts.includes(post) ? (
                    <BsBookmarkCheckFill />
                  ) : (
                    <CiBookmark />
                  )}
                  {/* <CiBookmark /> */}
                </button>
              </button>
            ))}
            {/* Section for post and schedule */}
            <section className="flex items-center gap-2 mt-10 juss">
              <Button
                loading={postingOnLinkedIn}
                className="flex items-center justify-center gap-10 font-semibold rounded-full"
                type="primary"
                size="large"
                onClick={() => {
                  handlePostOnLinkedIn();
                }}
              >
                <span className="text-sm">
                  {userLinkedInData?.linkedInUserId
                    ? "Post On LinkedIn"
                    : "Connect LinkedIn & Post"}
                  {/* Post On LinkedIn */}
                </span>
                <IoMdArrowRoundForward />
              </Button>
              <Button
                type="text"
                onClick={() => {
                  if (userLinkedInData?.linkedInUserId) {
                    setDisplayScheduler(true);
                  } else {
                    // toast.info("Please login to LinkedIn to schedule posts");
                    if (user) {
                      user.getIdToken().then((idToken) => {
                        const linkedInAuthUrl = `${process.env.REACT_APP_SERVERURL}/linkedIn/auth?firebaseToken=${idToken}?isOnboarding=true`;
                        window.location.href = linkedInAuthUrl;
                      });
                    }
                  }
                }}
                icon={
                  <AiOutlineClockCircle className="w-6 h-6 text-darkGrey" />
                }
                shape="circle"
                size="middle"
              />
            </section>

            {/* <div
              as="button"
              onClick={() => {
                // userProfileData();
                setIsDrawerOpen(true);
              }}
              className="flex items-center gap-3 pt-2 pl-4 cursor-pointer text-darkGrey"
            >
              <p>See your Profile Analysis</p>
            </div> */}
            <p
              className="mt-10 cursor-pointer text-normal text-linkedInPeach"
              onClick={() => {
                navigate("/linkedin/content-creation");
              }}
            >
              Go to Dashboard
            </p>
            {/* <ProfileDetailsDrawer
              isDrawerOpen={isDrawerOpen}
              closeDrawer={closeDrawer}
            /> */}
          </Card>
        </div>

        {/* RIGHT POST PREVIEW */}
        <section
          className="w-3/5  overflow-y-scroll h-[80vh] pb-16"
          style={{
            maskImage:
              "linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%)",
            WebkitMaskImage:
              "linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%)",
          }}
        >
          <div className="w-4/5 pl-6">
            <button className="flex items-center justify-end w-full gap-1 my-1 text-xs">
              <p className="italic">Click on Post to Edit</p>
            </button>

            {/* main content starts */}
            <Card
              className="flex flex-col items-center justify-center "
              bodyStyle={{ padding: 0 }}
            >
              {/* User Details */}
              <div className="flex items-center gap-3 p-6">
                <img
                  src={
                    "https://images.unsplash.com/photo-1579546929518-9e396f3cc809"
                  }
                  alt="profile-pic"
                  className="w-10 h-10 rounded-full"
                />
                {/* TEXT */}
                <div>
                  <p className="text-sm font-semibold text-left">Sample Post</p>
                  <p className="text-xs text-left text-darkGrey">
                    your followers
                  </p>
                </div>
              </div>

              {/* Editable Post Content Area */}
              <div
                contentEditable
                className="p-2 px-6 mb-6 text-sm font-normal text-left"
                onBlur={(e) => updatePostContent(e.target.innerHTML)}
                dangerouslySetInnerHTML={{
                  __html: parseFormattedTextToHtmlOnboarding(
                    selectedPost?.content
                  ),
                }}
              ></div>

              <img src={selectedPost?.image} alt="post-image" />

              {/* LinkedIn post Card footer */}
              <div className="flex flex-row items-center justify-between w-full p-5 text-[#666666] border-t border-gray-300">
                {/* profile icon */}
                <div className="flex items-center justify-center gap-2">
                  <div className="text-xl">
                    <img
                      // src={userLinkedInData?.profilePhoto}

                      src={
                        "https://images.unsplash.com/photo-1579546929518-9e396f3cc809"
                      }
                      className="w-4 h-4 rounded-full"
                    />
                  </div>
                  <div>
                    <IoCaretDown />
                  </div>
                </div>

                <div className="flex items-center justify-center gap-2">
                  <div className="text-xl">
                    <AiOutlineLike />
                  </div>
                  <div>Like</div>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <div className="text-xl">
                    <FaRegComment />
                  </div>
                  <div>Comment</div>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <div className="text-xl">
                    <BiRepost />
                  </div>
                  <div>Post</div>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <div className="text-xl">
                    <IoIosSend />
                  </div>
                  <div>Send</div>
                </div>
              </div>
            </Card>
          </div>
        </section>
      </section>

      {/* <div className="grid items-center justify-center w-full h-screen grid-cols-12 gap-4 bg-red-300"></div> */}
      {userLinkedInData?.linkedInUserId ? (
        <AddToScheduler
          isOpen={displayScheduler}
          onClose={() => setDisplayScheduler(false)}
          postContent={selectedPost}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DisplayGeneratedPosts;
