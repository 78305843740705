import React, { useState, useEffect } from "react";
import "../../linkedin.css";
import { BiCopy } from "react-icons/bi";
import {
  useFinalContentStore,
  useCreatedImages,
  useOverallStepStore,
  useIsImageGenerationLoadingStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
} from "../../ZustandStores/LinkedInZustandStore";

import { toast } from "react-toastify";
import ImageModal from "./ImageModal";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import axios from "axios";
import { DefaultImagesLanding } from "../DefaultLanding";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";

import { FiEdit3 } from "react-icons/fi";
import { userMediaFilesStore } from "../../ZustandStores/UserMediaStore";
import PdfViewer from "../summaryComponents/PdfViewer";

const RenderMedias = () => {
  // ZUSTAND
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { isImageGenerationLoading } = useIsImageGenerationLoadingStore();
  const { overallStep, setOverallStep } = useOverallStepStore();
  const { setUserImagesArray } = useCreatedImages();
  const { userLinkedInData } = useUserLinkedInStore();

  const { userMediaFiles, setUserMediaFiles } = userMediaFilesStore();
  const { selectedDraftId } = useSavedDraftIdStore();
  const { selectedScheduledPostId, setSelectedScheduledPostId } =
    useScheduledPostIdStore();
  const [selectedImgPrompt, setSelectedImgPrompt] = useState(" ");
  const [isEditImage, setIsEditImage] = useState(false);

  const [currentMediaType, setCurrentMediaType] = useState("images");
  // ----------------------------
  const [selectedMediaTypeArray, setSelectedMediaTypeArray] = useState(
    userMediaFiles?.images || []
  );
  // User Data:
  let { userData } = useLinkedInUserContext();

  const userId = userData?.userId;

  // Get user media files
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERURL}/userMedia/v2/getAllMedia/?userId=${userId}`
      );

      if (response.data && response.data.userMedia) {
        const allMediaFiles = response.data.userMedia;
        const images = allMediaFiles
          .filter((media) => media.mediaType === "image")
          .reverse();

        const videos = allMediaFiles
          .filter((media) => media.mediaType === "video")
          .reverse();

        const documents = allMediaFiles
          .filter((media) => media.mediaType === "document")
          .reverse();

        // Update the Zustand store
        setUserMediaFiles({
          images: images,
          videos: videos,
          documents: documents,
        });
        setUserImagesArray(images);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [finalContent.finalMediaUrl]);

  // userEffect to populate userMedia to the mediaBar
  useEffect(() => {
    if (currentMediaType === "images") {
      setSelectedMediaTypeArray(userMediaFiles?.images);
    } else if (currentMediaType === "videos") {
      setSelectedMediaTypeArray(userMediaFiles?.videos);
    } else if (currentMediaType === "documents") {
      setSelectedMediaTypeArray(userMediaFiles?.documents);
    }
  }, [currentMediaType, userMediaFiles]);

  // useEffect to check selected saved drafts
  useEffect(() => {
    if (selectedDraftId) {
      userLinkedInData?.savedDrafts?.map((draft) => {
        if (draft._id === selectedDraftId) {
          setFinalContent({
            finalMediaUrl: draft?.mediaUrl,
            finalMediaType: draft?.mediaType,
          });
        }
      });
    } else if (selectedScheduledPostId) {
      userLinkedInData?.scheduledPosts?.map((scheduledPost) => {
        if (scheduledPost._id === selectedScheduledPostId) {
          setFinalContent({
            finalMediaUrl: scheduledPost?.mediaUrl,
            finalMediaType: scheduledPost?.mediaType,
          });
        }
      });
    }
  }, []);

  return (
    <div className={`flex flex-col`}>
      {/* TOOLBAR FOR IMAGE EDITING */}
      {finalContent?.finalMediaUrl?.length > 0 && (
        <button
          className="p-2 border rounded-md text-md text-linkedInPeach border-linkedInPeach"
          onClick={() => {
            setFinalContent({
              ...finalContent,
              finalMediaUrl: "",
              finalMediaType: "",
            });
            if (
              finalContent?.finalText?.length > 0 ||
              finalContent?.finalMediaUrl?.length > 0
            ) {
              setOverallStep(overallStep + 1);
            } else {
              toast.info("Please select text or any media to proceed");
            }
          }}
        >
          Skip Media Selection
        </button>
      )}

      {/* DISPLAY TEXT BASED ON MEDIA TYPE */}

      {/* To open the edit image modal */}
      {finalContent.finalMediaUrl && finalContent.finalMediaType == "image" && (
        <div
          onClick={() => {
            setIsEditImage(true);
          }}
          className="flex justify-end w-full mt-1 text-left"
        >
          <p className="flex items-center gap-2 cursor-pointer">
            <span>
              <FiEdit3 />
            </span>
            Edit Image
          </p>
        </div>
      )}
      <ImageModal setIsEditImage={setIsEditImage} isEditImage={isEditImage} />
      {/* SELECTED IMAGE */}
      {isImageGenerationLoading ? (
        <DefaultImagesLanding />
      ) : (
        <>
          <div className="pt-2">
            {finalContent?.finalMediaUrl?.length > 0 &&
            finalContent?.finalMediaType?.length > 0 ? (
              <>
                {finalContent.finalMediaUrl &&
                  finalContent.finalMediaType == "image" && (
                    <img
                      as="div"
                      className="object-contain h-full aspect-square"
                      src={finalContent.finalMediaUrl}
                      alt="Image created for LinkedIn on TRIKL"
                    />
                  )}
                {finalContent.finalMediaUrl &&
                  finalContent.finalMediaType == "video" && (
                    <video
                      className="w-full h-full "
                      src={finalContent.finalMediaUrl}
                      alt="video on TRIKL"
                      controls
                    />
                  )}
                {finalContent.finalMediaUrl &&
                  finalContent.finalMediaType == "document" && (
                    <PdfViewer fileUrl={finalContent?.finalMediaUrl} />
                  )}
              </>
            ) : (
              <DefaultImgCreationScreen />
            )}
          </div>
          {finalContent.finalMediaUrl &&
            finalContent.finalMediaType == "image" && (
              <div className="flex items-center justify-start gap-2 pt-2 pb-4 text-sm text-left text-gray-500">
                <span className="text-xs">Prompt:</span>
                <span className="font-semibold">{selectedImgPrompt}</span>
                <button
                  onClick={() => {
                    navigator.clipboard
                      .writeText(selectedImgPrompt)
                      .then(() => {
                        toast.success("Prompt copied successfully");
                      })
                      .catch((err) => {
                        toast.error("Failed to copy prompt");
                      });
                  }}
                >
                  <BiCopy />
                </button>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default RenderMedias;

const DefaultImgCreationScreen = () => {
  return <DefaultImagesLanding />;
};
