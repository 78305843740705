import React from "react";

const BackgroundTemplate3 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2478_1772"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask> */}
      {/* <g mask="url(#mask0_2478_1772)"> */}
      <path
        d="M319.241 101.399L337.662 204.557L239.115 168.931L319.241 101.399Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <path
        d="M136.459 191.55L147.207 191.591L141.869 182.262L136.459 191.55Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M25.4591 45.5501L36.2073 45.5914L30.8689 36.2625L25.4591 45.5501Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M77.0698 560.472L68.32 566.714L78.1009 571.171L77.0698 560.472Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M382.101 662.395L380.736 673.056L390.651 668.908L382.101 662.395Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M485.039 83.9772L474.363 85.2243L480.781 93.8462L485.039 83.9772Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M400.441 247.368L389.777 248.712L396.273 257.276L400.441 247.368Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M451.454 483.533L461.742 486.645L459.294 476.18L451.454 483.533Z"
        fill={colorPallet?.primary}
      />
      <path
        d="M510.36 607.07L574.36 690.044L470.503 703.983L510.36 607.07Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <path
        d="M309.424 518.209L320.505 622.41L224.724 579.906L309.424 518.209Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <path
        d="M-71.217 495.021L33.4786 490.597L-15.0383 583.478L-71.217 495.021Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <path
        d="M596.135 283.153L507.297 338.729L503.586 234.005L596.135 283.153Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      <path
        d="M94.0955 107.682L60.1722 8.61521L162.928 28.7702L94.0955 107.682Z"
        stroke={colorPallet?.secondary}
        stroke-opacity="0.07"
        stroke-width="5"
      />
      {/* </g> */}
    </svg>
  );
};

export default BackgroundTemplate3;
