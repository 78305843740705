import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useCanvasSideStepStore,
  useFinalContentStore,
  useFinalizedTopicFromStore,
  useWhatsSelectedStore,
} from "../../ZustandStores/LinkedInZustandStore";
import "../../linkedin.css";
import { toast } from "react-toastify";
import { Disclosure } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { ButtonsOutlineGrey } from "../../../components/Buttons";

const NewsContent = () => {
  const { finalizedTopic } = useFinalizedTopicFromStore();
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for tracking loading
  const requestBody = { keyword: `${finalizedTopic}` };

  const getNewsFunc = async (reqBodyParam) => {
    let newsDataArray = [];
    newsDataArray = await handleSearch(reqBodyParam);
    // const newsAPIResponse = await axios.post(
    //   `${process.env.REACT_APP_SERVERURL}/newsArticle/get-new-news`,
    //   reqBodyParam
    // );
    // if (newsAPIResponse.data.data.length > 0) {
    //   newsDataArray = newsAPIResponse.data.data;
    // } else if (newsAPIResponse.data.data.length === 0) {
    //   newsDataArray = await handleSearch(reqBodyParam);
    // }

    const uniqueNewsData = newsDataArray?.filter(
      (news, index, self) =>
        index === self.findIndex((t) => t.title === news.title)
    );
    setNewsData(uniqueNewsData);
    return uniqueNewsData;
  };

  const handleSearch = async (reqBodyParam) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/websearch/web-search`,
        { searchQuery: reqBodyParam?.keyword }
      );

      return response.data;
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  // append news from google custom search api
  const appendNewsData = async () => {
    const additionalNewsData = await handleSearch(requestBody);
    // Process additionalNewsData similarly to getNewsFunc's processing, if needed
    additionalNewsData &&
      setNewsData((prev) => [...prev, ...additionalNewsData]);
  };

  useEffect(() => {
    setIsLoading(true); // Set loading to true before fetching
    getNewsFunc(requestBody).finally(() => setIsLoading(false)); // Set loading to false after fetching
  }, [finalizedTopic]);

  return (
    <div>
      <Disclosure
        defaultOpen
        className="pb-4 mx-4 bg-white border border-gray-300 rounded-md "
        as="div"
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between w-full p-4">
              <div className="items-start font-semibold text-left">
                Relevant news on {finalizedTopic}
              </div>

              <BiChevronDown
                className={`text-3xl text-triklBlueDark/80 ${
                  open ? "rotate-180 transform" : ""
                }`}
              />
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="pb-10 pl-4 overflow-scroll rounded-lg h-96 custom-v-scrollbar">
                <NewsHeader
                  isLoading={isLoading}
                  newsDataArray={newsData}
                  onSeeMore={appendNewsData}
                />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default NewsContent;

const NewsHeader = ({ isLoading, newsDataArray, onSeeMore }) => {
  // Zustand
  const { whatsSelected, setWhatsSelected } = useWhatsSelectedStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { finalContent, setFinalContent } = useFinalContentStore();

  const [isSelected, setIsSelected] = useState(null);
  const today = new Date();

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${day} / ${month < 10 ? "0" + month : month} / ${year}`;
  }

  const calculateDaysBetween = (dateString) => {
    const targetDate = new Date(dateString);
    const today = new Date();

    // Set hours to 0 for both dates to ensure the difference is calculated accurately from the start of the day
    targetDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = today - targetDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
  };

  const handleNewsSelection = (contentParam, indexParam) => {
    setCanvasSideStep(4);
    setIsSelected(contentParam);
    setWhatsSelected({
      ...whatsSelected,
      itemIndex: indexParam,
      allData: contentParam,
      title: contentParam.title,
      description: contentParam.description,
      url: contentParam.url,
      content: contentParam.content,
    });
    setFinalContent({
      ...finalContent,
      finalTone: "",
      finalCharacterLimit: 0,
      additionalPersonalization: [],
    });
  };

  const cropURLToBase = (fullURL) => {
    const url = new URL(fullURL);
    return url.host.replace(/^www\./, "");
  };
  return (
    <>
      {isLoading && <h2>Loading...</h2>}
      {!isLoading && newsDataArray?.length === 0 && (
        <div className="text-lg font-semibold text-triklBlueDark">
          No news found, please try some other keyword
        </div>
      )}
      {!isLoading && newsDataArray?.length > 0 && (
        <>
          {newsDataArray?.map((eachContent, eachIndex) => (
            <div
              key={eachIndex}
              className={`relative flex flex-col mt-2 rounded-lg text-black ${
                isSelected === eachContent
                  ? "bg-triklDarkAccentBlue/60"
                  : "bg-lightGrey hover:bg-triklDarkAccentBlue/30"
              }`}
            >
              <button
                as="div"
                className="w-full h-full p-4 text-left rounded-lg pb-7"
                onClick={() => {
                  handleNewsSelection(eachContent, eachIndex);
                }}
              >
                {eachContent.title}
              </button>

              <aside className="absolute right-0 flex items-center justify-between w-full gap-3 px-4 text-xs text-right text-gray-500 bottom-2">
                <a
                  href={eachContent?.url}
                  target="_blank"
                  className="hover:text-triklAccentBlue hover:underline"
                >
                  {cropURLToBase(eachContent?.url)}
                </a>

                {/* {calculateDaysBetween(eachContent?.published_at) > 0 ? (
                  <p>
                    {calculateDaysBetween(eachContent?.published_at)} days ago
                  </p>
                ) : (
                  <>
                   { calculateDaysBetween(eachContent?.published_at)= "" ? <p>"Date Not Available"</p>: <p> "Today"</p> }</>
                )} */}
                <p>
                  {calculateDaysBetween(eachContent?.published_at) > 0
                    ? `${calculateDaysBetween(
                        eachContent?.published_at
                      )} days ago`
                    : eachContent?.published_at
                    ? "Today"
                    : "Date Not Available"}
                </p>
              </aside>
            </div>
          ))}

          {/* {newsDataArray?.length <= 3 && ( */}
          <ButtonsOutlineGrey
            title="Find More"
            clickAction={onSeeMore}
            classParam="border bg-white border-gray-400 rounded-md py-1 mt-3 w-full hover:bg-triklAccentBlue hover:text-white"
          />
          {/* )} */}
        </>
      )}
    </>
  );
};
