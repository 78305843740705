import React, { useState } from "react";
import {
  useCanvasSideStepStore,
  useContentUrlStore,
  useFinalContentStore,
  useWhatsSelectedStore,
} from "../../ZustandStores/LinkedInZustandStore";
import "../../linkedin.css";
import {
  CategoryData,
  personalizationPrompts,
  toneData,
  wordLimitData,
} from "../../../../assets/textPromptAndOptions/allTextPrompts";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import PersonalizeModal from "./PersonalizeModal";
import { EditOutlined } from "@ant-design/icons";
import { useUserOrganizationsStore } from "../../ZustandStores/OrganizationStore";
import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";

// FOR SELECTING TYPE OF POST

const CategoryMenu = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { whatsSelected } = useWhatsSelectedStore();
  const { setCurrentUrl } = useContentUrlStore();
  const handleSelection = (selectedValue) => {
    setFinalContent({
      ...finalContent,
      finalCategory: selectedValue.uniqueName,
    });
    // setCanvasSideStep(5);
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <p className="pb-2 font-semibold text-left">Select Post Type </p>
      <section className="flex flex-wrap gap-3">
        {CategoryData?.map((each, index) => (
          <button
            key={index}
            onClick={() => handleSelection(each)}
            className={`
            flex items-center gap-2 px-2 py-1 border border-gray-300 rounded-md w-max
            transition-all duration-300 ease-in-out
            ${
              (whatsSelected.title || setCurrentUrl) &&
              finalContent.finalCategory === each.uniqueName &&
              "bg-triklPeachLight border-triklAccentBlue"
            }
            `}
          >
            <div className="">{each.icon}</div>
            <div className="">{each.name}</div>
          </button>
        ))}
      </section>
    </div>
  );
};

// FOR SELECTING TONE OF THE POST (MANDATORY)
const ToneMenu = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { whatsSelected } = useWhatsSelectedStore();
  const { setCurrentUrl } = useContentUrlStore();
  const handleSelection = (selectedValue) => {
    setFinalContent({
      ...finalContent,
      finalTone: selectedValue.name,
    });
    setCanvasSideStep(5);
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <p className="pb-2 font-semibold text-left">Select Tone</p>

      <section className="flex flex-wrap gap-3">
        {toneData?.map((each, index) => (
          <button
            key={index}
            onClick={() => handleSelection(each)}
            className={`
            flex items-center gap-2 px-2 py-1 border border-gray-300 rounded-md w-max
            transition-all duration-300 ease-in-out
            ${
              (whatsSelected.title || setCurrentUrl) &&
              finalContent.finalTone === each.name &&
              "bg-triklPeachLight border-triklAccentBlue"
            }
            `}
          >
            <div className="">{each.icon}</div>
            <div className="">{each.name}</div>
          </button>
        ))}
      </section>
    </div>
  );
};

// FOR SELECTING POST SIZE (MANDATORY)
const LengthMenu = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { whatsSelected } = useWhatsSelectedStore();
  const { setCurrentUrl } = useContentUrlStore();

  const handlePostLenSelection = (selectedValue) => {
    setFinalContent({
      ...finalContent,
      finalCharacterLimit: selectedValue.characterSize,
    });
    setCanvasSideStep(6);
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <p className="pb-2 font-semibold text-left">Select Length</p>

      <section className="flex flex-wrap gap-3">
        {wordLimitData?.map((each, index) => (
          <button
            key={index}
            onClick={() => handlePostLenSelection(each)}
            className={`
            flex items-center gap-2 px-2 py-1 border border-gray-300 rounded-md w-max
            transition-all duration-300 ease-in-out
            ${
              (whatsSelected.title || setCurrentUrl) &&
              finalContent.finalCharacterLimit === each.characterSize &&
              "bg-triklPeachLight border-triklAccentBlue"
            }
            `}
          >
            <div>{each.icon}</div>
            <div>{each.name}</div>
          </button>
        ))}
      </section>
    </div>
  );
};

const UserVoiceComponent = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userMainData } = useUserMainDataStore();
  const { organizationsData } = useUserOrganizationsStore();
  const [selectedVoice, setSelectedVoice] = useState();
  const [openPersonalizeModal, setOpenPersonalizeModal] = useState(false);

  const [showOrganization, setShowOrganization] = useState(false);

  const personalVoiceTextLength =
    userMainData?.userVoice?.personalVoiceText?.length || 0;
  const isBrandVoiceAvailable = organizationsData?.some(
    (org) => org.organizationBrandVoice !== ""
  );

  const voiceData = [
    { name: "none", uniqueName: "none", icon: "🔇" },
    { name: "Personal Voice", uniqueName: "personalVoice", icon: "👨‍💻" },
    { name: "Brand Voice", uniqueName: "brandVoice", icon: "💼" },
  ];

  const handlePostVoiceSelection = (selectedValue) => {
    if (!selectedValue.uniqueName === "none") {
      setSelectedVoice(selectedValue);
    }

    if (
      (organizationsData?.length == 0 || !isBrandVoiceAvailable) &&
      selectedValue.uniqueName === "brandVoice"
    ) {
      setOpenPersonalizeModal(true);
      return;
    } else if (
      personalVoiceTextLength == 0 &&
      selectedValue.uniqueName === "personalVoice"
    ) {
      setOpenPersonalizeModal(true);

      return;
    }
    let updatedArray = [...finalContent.additionalPersonalization];

    // Handling "none" selection
    if (selectedValue.uniqueName === "none") {
      // Remove "personalVoice" and "brandVoice" if they exist
      updatedArray = updatedArray.filter(
        (item) => item !== "personalVoice" && item !== "brandVoice"
      );
      // Add "none" to the array
      updatedArray.push(selectedValue.uniqueName);
    } else if (
      selectedValue.uniqueName === "personalVoice" ||
      selectedValue.uniqueName === "brandVoice"
    ) {
      // If "personalVoice" or "brandVoice" is selected, first remove "none" if it exists
      updatedArray = updatedArray.filter((item) => item !== "none");

      // Check if the selected voice is already in the array
      if (updatedArray.includes(selectedValue.uniqueName)) {
        // If it is, remove it
        updatedArray = updatedArray.filter(
          (item) => item !== selectedValue.uniqueName
        );
      } else {
        // If it's not, add the selected voice and remove the opposite
        updatedArray.push(selectedValue.uniqueName);
        const oppositeVoice =
          selectedValue.uniqueName === "personalVoice"
            ? "brandVoice"
            : "personalVoice";
        updatedArray = updatedArray.filter((item) => item !== oppositeVoice);
      }
    }
    if (selectedValue.uniqueName === "brandVoice") {
      setShowOrganization(true);
    } else {
      setShowOrganization(false);
    }

    // Update the state or variable that holds the array
    setFinalContent({
      ...finalContent,
      additionalPersonalization: updatedArray,
      finalOrganizationBrandVoiceId:
        selectedValue.uniqueName !== "brandVoice"
          ? ""
          : finalContent.finalOrganizationBrandVoiceId,
    });

    // setFinalContent({
    //   ...finalContent,
    //   finalOrganizationBrandVoiceId: "",
    // });
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <div className="flex justify-between">
        <p className="pb-2 font-semibold text-left">
          Add Your Voice
          {/* <span className="font-normal">&#40;can select multiple&#41;</span> */}
        </p>
        <EditOutlined
          className="-mt-2 "
          size={5}
          onClick={() => setOpenPersonalizeModal(true)}
        />
      </div>

      <section className="flex flex-wrap ">
        <PersonalizeModal
          isOpen={openPersonalizeModal}
          onClose={() => setOpenPersonalizeModal(false)}
          userData={userMainData}
          selectedValue={selectedVoice}
        />
        {/* Add a window.alert for user clicking on the */}
        {voiceData?.map((each, index) => (
          <button
            key={index}
            onClick={() => {
              handlePostVoiceSelection(each);
            }}
            className={`
            flex items-center gap-2 px-2 py-1 my-1 border border-gray-300 rounded-md w-max
            transition-all duration-300 ease-in-out mr-3

            ${
              finalContent.additionalPersonalization &&
              finalContent.additionalPersonalization.includes(each.uniqueName)
                ? "bg-triklPeachLight border-triklAccentBlue"
                : ""
            }
            `}
          >
            <div>{each.icon}</div>
            <div>{each.name}</div>
          </button>
        ))}
        {/* display organizations */}

        {/* if organizationsData.length > 0  and organizationBrandVoice is not empty string then print that organization name */}
        {showOrganization && (
          <>
            {organizationsData?.map(
              (each, index) =>
                each.organizationBrandVoice !== "" && (
                  <button
                    className={`px-2 py-1 m-1 border border-gray-300 rounded-md w-max
                    transition-all duration-300 ease-in-out ${
                      each.organizationId ===
                      finalContent?.finalOrganizationBrandVoiceId
                        ? "text-linkedInPeach border-linkedInPeach  "
                        : ""
                    }`}
                    key={index}
                    onClick={() => {
                      setFinalContent({
                        ...finalContent,
                        finalOrganizationBrandVoiceId: each.organizationId,
                      });
                    }}
                  >
                    {each.organizationName}
                  </button>
                )
            )}
          </>
        )}
      </section>
    </div>
  );
};

// FOR SELECTING POST SIZE (MANDATORY)
const OptionalPersonalMultiSelectMenu = () => {
  const {
    finalContent,
    addPersonalizationItem,
    updatePersonalizationItem,
    setFinalContent,
  } = useFinalContentStore();
  // const { setCanvasSideStep } = useCanvasSideStepStore();
  // const { whatsSelected } = useWhatsSelectedStore();

  const handlePostLenSelection = (selectedValue) => {
    if (
      finalContent.additionalPersonalization?.includes(selectedValue.uniqueName)
    ) {
      // Remove the item if it's already selected
      const updatedArray = finalContent.additionalPersonalization.filter(
        (item) => item !== selectedValue.uniqueName
      );
      setFinalContent({
        ...finalContent,
        additionalPersonalization: updatedArray,
      });

      // setFinalContent({
      //   ...finalContent,
      //   finalOrganizationBrandVoiceId: ,
      // });
    } else {
      // Add the item if it's not selected
      addPersonalizationItem(selectedValue.uniqueName);
    }
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <p className="pb-2 font-semibold text-left">
        Personalize{" "}
        <span className="font-normal">&#40;can select multiple&#41;</span>
      </p>

      {/* <UserVoiceComponent /> */}

      <section className="flex flex-wrap gap-3">
        {personalizationPrompts?.map((each, index) => (
          <button
            key={index}
            onClick={() => {
              handlePostLenSelection(each);
            }}
            className={`
            flex items-center gap-2 px-2 py-1 border border-gray-300 rounded-md w-max
            transition-all duration-300 ease-in-out
            ${
              finalContent.additionalPersonalization &&
              finalContent.additionalPersonalization.includes(each.uniqueName)
                ? "bg-triklPeachLight border-triklAccentBlue"
                : ""
            }
            `}
          >
            <div>{each.icon}</div>
            <div>{each.name}</div>
          </button>
        ))}
      </section>
    </div>
  );
};

/////////////////

export {
  CategoryMenu,
  ToneMenu,
  LengthMenu,
  OptionalPersonalMultiSelectMenu,
  UserVoiceComponent,
};

/////////////////

// OLD CODE

// const CategorySelectionCard = () => {
//   const { finalContent, setFinalContent } = useFinalContentStore();
//   const { canvasSideStep, setCanvasSideStep } = useCanvasSideStepStore();
//   const { whatsSelected } = useWhatsSelectedStore();

//   const handleSelection = (selectedValue) => {
//     if (cardType === "tone") {
//       setFinalContent({
//         ...finalContent,
//         finalTone: selectedValue.name,
//       });
//       setCanvasSideStep(5);
//     } else if (cardType === "wordLimit") {
//       setFinalContent({
//         ...finalContent,
//         finalCharacterLimit: selectedValue.characterSize,
//       });
//       setCanvasSideStep(6);
//     }
//   };

//   const isSelected = (type, content, item) => {
//     if (!whatsSelected.title) return false;
//     if (type === "tone" && content?.finalTone === item.name) return true;
//     if (
//       type === "wordLimit" &&
//       content?.finalCharacterLimit === item.characterSize
//     )
//       return true;
//     return false;
//   };

//   return (
//     <div
//       className="p-4 mx-4 bg-white border border-gray-300 rounded-md"
//       // className="flex flex-col gap-2 p-2"
//     >
//       <p className="pb-2 font-semibold text-left">Select the {cardType}:</p>

//       {/* <section className="flex items-center gap-2 overflow-scroll text-sm rounded-md custom-h-scrollbar"> */}
//       <section className="flex flex-wrap gap-3">

//         {canvasSideStep >= 4 &&
//           toneData?.map((eachTone, indexTone) => (
//             <ToneMenu eachTone={eachTone} indexTone={indexTone} />
//           ))}

//         {canvasSideStep >= 5 &&
//           wordLimitData?.map((eachLen, indexLen) => (
//             <LengthMenu eachLen={eachLen} indexLen={indexLen} />
//           ))}
//       </section>
//     </div>
//   );
// };

// export default CategorySelectionCard;
