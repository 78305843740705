import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLinkedInUserContext } from "../../../context/linkedInUserContext";
import TextArea from "antd/es/input/TextArea";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import { CalendarDataRefreshStore } from "../ZustandStores/ContentCalendarStore";
import PersonalizeModal from "../components/editorComponents/PersonalizeModal";
import { toast } from "react-toastify";
import { CalendarCreated } from "../../../assets/calendar";
import Lottie from "lottie-react";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../context/userAuthContext";
import { useUserOrganizationsStore } from "../ZustandStores/OrganizationStore";

const CreateCalenderModal = ({ isOpen, onClose }) => {
  const { calendarDataRefresh, setCalendarDataRefresh } =
    CalendarDataRefreshStore();
  const { userMainData } = useUserMainDataStore();

  const { organizationsData } = useUserOrganizationsStore();
  const { userLinkedInData } = useUserLinkedInStore();
  let { userData } = useLinkedInUserContext();
  const { user } = useUserAuth();
  const [selectedVoice, setSelectedVoice] = useState("personalVoiceText");
  const [isCreatingCalendar, setIsCreatingCalendar] = useState(false);
  const [selectedVoiceModal, setSelectedVoiceModal] = useState();
  const [showCalendarCreatedGif, setShowCalendarCreatedGif] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState({
    name: "",
    about: "",
    id: 0,
  });

  const [openPersonalizeModal, setOpenPersonalizeModal] = useState(false);

  const [formData, setFormData] = useState({
    calendarName: "",
    createfor: selectedVoice || "",
    personalVoiceText: userData?.userVoice?.personalVoiceText || "",
    brandVoiceText: selectedOrganization?.about || "",
    about: userData?.headline || selectedOrganization?.about || "",
  });

  const voiceButtons = [
    {
      name: "Personal",
      value: "personalVoiceText",
    },
    {
      name: "Brand",
      value: "brandVoiceText",
    },
  ];

  const handleTextAreaChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  // Handler for clicking a menu item
  const handleMenuClick = (e) => {
    const organization = organizationsData.find((org) => org._id === e.key);
    setSelectedOrganization({
      name: organization.organizationName,
      about: userData.headline,
      id: organization.organizationId,
      brandVoiceText: organization.organizationBrandVoice,
    });
    setFormData({ ...formData, about: organization.organizationBrandVoice });
  };

  // Preparing the menu items from the organizations data
  const menuItems = organizationsData?.map((org) => ({
    key: org._id,
    label: org.organizationName,
  }));

  // Dropdown menu setup
  const menu = <Menu items={menuItems} onClick={handleMenuClick} />;

  // Complete the API call
  const handleCreateCalendar = async () => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }

    setIsCreatingCalendar(true);
    try {
      if (formData.calendarName === "") {
        toast.error("Calendar name cannot be empty");
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/contentCalendar/create-calendar`,
          {
            userId: userMainData?.userId,
            calendarName: formData.calendarName,
            voiceType: selectedVoice,
            about: formData.about,
            organizationId: selectedOrganization.id,
            Description:
              selectedVoice === "personalVoiceText"
                ? formData.personalVoiceText
                : formData.about,
          },
          {
            withCredentials: true,
          }
        );
        setCalendarDataRefresh(!calendarDataRefresh);

        // Clear the form and close the modal

        setFormData({
          ...formData,
          calendarName: "",
        });

        setSelectedOrganization({ name: "", about: "", id: 0 });
        // show GIF
        setShowCalendarCreatedGif(true);

        setTimeout(() => {
          setShowCalendarCreatedGif(false);
          onClose(true); //  close the modal after GIF has been displayed
        }, 3000);

        setIsCreatingCalendar(false);
      }
      setIsCreatingCalendar(false);
    } catch (error) {
      setIsCreatingCalendar(false);
      toast.error("Error creating calendar. Please try again.");
    }
  };

  // Get initial data for the form
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      personalVoiceText: userData?.userVoice?.personalVoiceText || "",
      brandVoiceText: userData?.userVoice?.brandVoiceText || "",
      about:
        selectedVoice === "brandVoiceText" && organizationsData?.length > 0
          ? organizationsData[0]?.organizationBrandVoice
          : userMainData?.description ||
            userData?.headline ||
            selectedOrganization?.about ||
            "",
    }));

    if (organizationsData?.length > 0) {
      const defaultOrg = organizationsData[0];
      setSelectedOrganization({
        name: defaultOrg.organizationName,
        about: defaultOrg.organizationBrandVoice,
        id: defaultOrg.organizationId,
        brandVoiceText: defaultOrg.organizationBrandVoice,
      });
    }
  }, [userData, selectedVoice]);

  // Default user organization selection
  useEffect(() => {
    if (organizationsData?.length > 0) {
      const defaultOrg = organizationsData[0];
      setSelectedOrganization({
        name: defaultOrg.organizationName,
        about: defaultOrg.organizationBrandVoice,
        id: defaultOrg.organizationId,
      });
    } else {
      setSelectedOrganization({ name: "", about: "", id: 0 });
    }
  }, [userData, isOpen]);

  return (
    <Modal
      title="Create a new Calendar"
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={isCreatingCalendar} fullscreen />
      <PersonalizeModal
        isOpen={openPersonalizeModal}
        onClose={() => setOpenPersonalizeModal(false)}
        selectedValue={selectedVoiceModal}
        userData={userData}
      />

      {/* show Calendar created GIF */}
      {showCalendarCreatedGif === true ? (
        <CalendarCreatedGif />
      ) : (
        <>
          <main className="flex flex-col ">
            <form className="">
              {/* Name */}
              <div className="flex flex-col gap-1 mb-5">
                <Input
                  disabled={isCreatingCalendar}
                  required={true}
                  value={formData.calendarName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      calendarName: e.target.value,
                    });
                  }}
                  type="text"
                  id="calendarName"
                  placeholder="Name of calendar"
                  size="large"
                />
              </div>
              {/* create for */}
              <div className="flex flex-col gap-1 mb-5">
                <label htmlFor="createfor">Create For:</label>
                <div className="flex gap-2">
                  {voiceButtons?.map((eachButton, index) => {
                    return (
                      <Button
                        disabled={isCreatingCalendar}
                        key={index}
                        type="default"
                        onClick={() => {
                          setSelectedVoice(eachButton?.value);
                        }}
                        className={`${
                          selectedVoice == eachButton?.value
                            ? "border-linkedInPeach"
                            : ""
                        }`}
                      >
                        {eachButton?.name}
                      </Button>
                    );
                  })}
                </div>
              </div>

              {/* Display Voice text */}
              <div className="mb-5 ">
                {selectedVoice === "personalVoiceText" &&
                userData?.userVoice?.personalVoiceText?.length > 0 ? (
                  //   ||
                  // (selectedVoice === "brandVoiceText" &&
                  //   selectedOrganization?.about?.length > 0)
                  <TextArea
                    disabled={isCreatingCalendar}
                    value={
                      selectedVoice === "personalVoiceText"
                        ? formData.personalVoiceText
                        : formData.brandVoiceText
                    }
                    rows={4}
                    placeholder="Your brand voice"
                    onChange={(e) => {
                      // Update formData state with new value for the selected voice text
                      const newText = e.target.value;
                      if (selectedVoice === "personalVoiceText") {
                        setFormData({
                          ...formData,
                          personalVoiceText: newText,
                        });
                      } else if (selectedVoice === "brandVoiceText") {
                        setFormData({ ...formData, brandVoiceText: newText });
                      }
                    }}
                  />
                ) : (
                  <>
                    {selectedVoice === "personalVoiceText" ? (
                      <Button
                        type="primary"
                        className="w-full mb-8 font-medium "
                        onClick={() => {
                          setOpenPersonalizeModal(true);
                          setSelectedVoiceModal({
                            name: "Personal Voice",
                            uniqueName: "personalVoice",
                            icon: "👨‍💻",
                          });
                        }}
                      >
                        Add Personal Voice
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              {selectedVoice === "brandVoiceText" &&
              organizationsData?.length > 0 ? (
                <Dropdown overlay={menu}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Button icon={<DownOutlined />}>
                      {selectedOrganization?.name ||
                        "You don't have any organization"}
                    </Button>
                  </a>
                </Dropdown>
              ) : (
                <></>
              )}
              {selectedVoice === "brandVoiceText" &&
                selectedOrganization?.brandVoiceText?.length === 0 && (
                  <Button
                    type="primary"
                    className="w-full my-4 font-medium "
                    onClick={() => {
                      setOpenPersonalizeModal(true);
                      setSelectedVoiceModal({
                        icon: "👨",
                        name: "Brand Voice",
                        uniqueName: "brandVoice",
                      });
                    }}
                  >
                    Add Brand Voice
                  </Button>
                )}
              {/* About section */}
              <div className="flex flex-col gap-1 my-5">
                <label htmlFor="about">About:</label>
                <TextArea
                  disabled={isCreatingCalendar}
                  value={formData.about}
                  rows={4}
                  placeholder="About you or your brand"
                  onChange={(e) =>
                    handleTextAreaChange(e.target.value, "about")
                  }
                />
              </div>
              <Button
                loading={isCreatingCalendar}
                type="primary"
                className="bg-triklAccentBlue"
                onClick={() => {
                  handleCreateCalendar();
                }}
              >
                Create Calendar
              </Button>
            </form>
          </main>
        </>
      )}
    </Modal>
  );
};

export default CreateCalenderModal;

const CalendarCreatedGif = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <Lottie as="div" className="p-3 " animationData={CalendarCreated} />
      <p className="text-xl font-semibold text-triklAccentBlue">
        Hooray! Your calendar is ready
      </p>
    </div>
  );
};
