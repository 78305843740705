import React from "react";

const V2BackgroundTemplate2 = ({ colorPalette }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3457)">
        <path
          d="M-78.8398 456.908C1.19039 510.297 97.5619 533.571 193.137 522.592C288.712 511.614 377.297 467.094 443.137 396.949C508.977 326.805 547.806 235.583 552.718 139.505C557.63 43.4263 528.308 -51.2805 469.965 -127.774L418.375 -88.4253C467.405 -24.1412 492.047 55.4488 487.919 136.191C483.79 216.934 451.159 293.596 395.828 352.544C340.497 411.492 266.053 448.906 185.733 458.132C105.413 467.358 24.4238 447.799 -42.8323 402.932L-78.8398 456.908Z"
          fill={colorPalette?.secondary}
        />
        <circle
          cx="469.5"
          cy="617.5"
          r="146.5"
          fill="black"
          fill-opacity="0.03"
        />
        <path
          d="M402 112.5C402 253.609 287.609 368 146.5 368C5.39125 368 -109 253.609 -109 112.5C-109 -28.6087 5.39125 -143 146.5 -143C287.609 -143 402 -28.6087 402 112.5Z"
          fill="black"
          fill-opacity="0.03"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate2;
