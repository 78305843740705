import React, { useEffect, useState } from "react";
import CanvasNavbarWithoutAuth from "../../../app/linkedIn/components/CanvasNavWithoutAuth";
import axios from "axios";
import { useUserAuth } from "../../../context/userAuthContext";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // onboarding page redirect if user subscription or trial is not active
    if (!user) {
      navigate("/user/signup");
    }
  }, [user]);

  return (
    <div className="h-full bg-linkedInBase">
      <CanvasNavbarWithoutAuth />

      {/* Pricing section */}
      <h1 className="pt-32 text-5xl font-semibold">
        Free trial ended?
        <span className="text-triklAccentBlue"> Subscribe now! </span>
      </h1>

      {user?.email && (
        <main className="w-full py-12 text-white bg-linkedInBase">
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            customer-email={user?.email}
          ></stripe-pricing-table>
        </main>
      )}
    </div>
  );
};

export default Pricing;
