import { Spin } from "antd";
import React from "react";
import { BeatLoader, PacmanLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <Spin size="large" />
    </div>
  );
};

const LoadingSpinnerWhite = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <Spin size="large" />
    </div>
  );
};

const LoadingSpinnerLarge = () => {
  return (
    <div className="flex flex-col items-center justify-center pt-20 pr-4">
      <div className="flex items-center justify-center w-full py-10">
        <Spin size="large" />
      </div>
      <div className="mb-5 -mt-6 text-triklAccentBlue">
        Loading... please wait!
      </div>
    </div>
  );
};

const LoadingSpinnerSmall = () => {
  return (
    <div className="flex items-center justify-center w-full ">
      <BeatLoader color="#6F42C1" size={8} />
    </div>
  );
};

const LoadingSpinnerWithCaption = () => {
  return (
    <div>
      <div className="flex items-center justify-center w-full py-10">
        <Spin size="large" />
      </div>
      <div className="mb-5 -mt-6 text-triklAccentBlue">
        Loading... please wait!
      </div>
    </div>
  );
};

export {
  LoadingSpinner,
  LoadingSpinnerWhite,
  LoadingSpinnerSmall,
  LoadingSpinnerWithCaption,
  LoadingSpinnerLarge,
};
