import React, { useState, useEffect } from "react";

const gridSize = 20;
const initialSnake = [{ x: 8, y: 8 }];
const initialFood = {
  x: Math.floor(Math.random() * gridSize),
  y: Math.floor(Math.random() * gridSize),
};
const directions = {
  ArrowUp: { x: 0, y: -1 },
  ArrowDown: { x: 0, y: 1 },
  ArrowLeft: { x: -1, y: 0 },
  ArrowRight: { x: 1, y: 0 },
};
const initialScore = 0;
const highScore = 370; // Assuming high score of 234 from previous games

function SnakeGame() {
  const [snake, setSnake] = useState(initialSnake);
  const [food, setFood] = useState(initialFood);
  const [direction, setDirection] = useState({ x: 1, y: 0 });
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(initialScore);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (directions[event.key]) {
        setDirection(directions[event.key]);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (gameOver) return;
    const moveSnake = setInterval(() => {
      setSnake((prevSnake) => {
        const newSnake = [...prevSnake];
        const head = {
          x: prevSnake[0].x + direction.x,
          y: prevSnake[0].y + direction.y,
        };

        // Check for collisions with walls
        if (
          head.x < 0 ||
          head.x >= gridSize ||
          head.y < 0 ||
          head.y >= gridSize
        ) {
          setGameOver(true);
          clearInterval(moveSnake);
          return prevSnake;
        }

        // Check for collisions with self
        if (
          prevSnake.some(
            (segment) => segment.x === head.x && segment.y === head.y
          )
        ) {
          setGameOver(true);
          clearInterval(moveSnake);
          return prevSnake;
        }

        // Move the snake
        newSnake.unshift(head);
        if (head.x === food.x && head.y === food.y) {
          // Eating food
          setScore((prevScore) => prevScore + 10);
          setFood({
            x: Math.floor(Math.random() * gridSize),
            y: Math.floor(Math.random() * gridSize),
          });
        } else {
          newSnake.pop(); // Remove the last part of the snake
        }
        return newSnake;
      });
    }, 200);

    return () => clearInterval(moveSnake);
  }, [direction, gameOver]);

  const retry = () => {
    setSnake(initialSnake);
    setFood(initialFood);
    setDirection({ x: 1, y: 0 });
    setGameOver(false);
    setScore(initialScore);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {gameOver && (
          <>
            <div className="text-xl text-[#ffb7b7] py-6">Game Over!</div>
            <div className="flex items-center justify-center gap-4 pb-6 text-lg text-white">
              <div className="font-semibold">Your score: {score}</div>
              <div>|</div>
              <div>High Score: {Math.max(score, highScore)}</div>
            </div>
            <button
              className="px-6 py-2 transition-all duration-300 ease-in-out bg-white border rounded-md text-tNewButtonsAndActiveElementDarkest hover:bg-[#93ffab] border-tNewButtonsAndActiveElementDarkest"
              onClick={retry}
            >
              Retry
            </button>
          </>
        )}
        {!gameOver && (
          <div>
            <div className="flex items-center justify-center gap-4 pb-6 text-lg text-white">
              <div className="font-semibold text-[#ffd500]">
                Your score: {score}
              </div>
              <div>|</div>
              <div>High Score: {Math.max(score, highScore)}</div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${gridSize}, 20px)`,
              }}
            >
              {Array.from({ length: gridSize * gridSize }).map((_, idx) => {
                const x = idx % gridSize;
                const y = Math.floor(idx / gridSize);
                const isSnake = snake.some(
                  (segment) => segment.x === x && segment.y === y
                );
                const isFood = food.x === x && food.y === y;
                return (
                  <div
                    key={idx}
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: isSnake
                        ? "#ffd500"
                        : isFood
                        ? "#f72585"
                        : "transparent",
                      border: "1px solid #30106a",
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SnakeGame;
