import { create } from "zustand";

// Image prompt generating store
const useImagePromptStore = create((set) => ({
  isImagePromptLoading: false,
  setIsImagePromptLoading: (newState) => {
    set(() => ({ isImagePromptLoading: newState }));
  },
}));

export { useImagePromptStore };
