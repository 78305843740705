import React from "react";

const BackgroundTemplate10 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2563_1507"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask> */}
      <g mask="url(#mask0_2563_1507)">
        <rect
          x="296"
          y="616.684"
          width="182.011"
          height="182.011"
          rx="8"
          transform="rotate(-43.2941 296 616.684)"
          //   fill="#4EB0B7"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="-76"
          y="78.1777"
          width="192.751"
          height="192.751"
          rx="8"
          transform="rotate(-43.2941 -76 78.1777)"
          //   fill="#4EB0B7"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="470.613"
          y="518.283"
          width="112.699"
          height="112.699"
          rx="8"
          transform="rotate(-43.2941 470.613 518.283)"
          //   fill="#4EB0B7"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="146"
          y="13.0391"
          width="77.3456"
          height="77.3456"
          rx="8"
          transform="rotate(-43.2941 146 13.0391)"
          //   fill="#2B87A4"
          fill={colorPallet?.primary}
        />
        <rect
          x="225"
          y="671.604"
          width="77.3456"
          height="77.3456"
          rx="8"
          transform="rotate(-43.2941 225 671.604)"
          //   fill="#2B87A4"
          fill={colorPallet?.primary}
        />
      </g>
    </svg>
  );
};

export default BackgroundTemplate10;
