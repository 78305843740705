import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { ImEarth } from "react-icons/im";
import { EditOutlined } from "@ant-design/icons";
import {
  useFinalContentStore,
  useOverallStepStore,
  useRefreshSchedulerStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
  useShowEditComponentStore,
} from "../../ZustandStores/LinkedInZustandStore";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { toast } from "react-toastify";
import { convertHtmlToFormattedText } from "../../../function/ConvertHtmlToFormattedText";
import { Button, DatePicker, Select } from "antd";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../../ZustandStores/UserLinkedInStore";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Avatar from "boring-avatars";
import likeCommShare from "../../../../assets/Canvas/likeCommShare.svg";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { IoIosSend } from "react-icons/io";
import PdfViewer from "../../components/summaryComponents/PdfViewer";
import "./editPostInScheduler.css";
import { useUserAuth } from "../../../../context/userAuthContext";
import { uselinkedInReconnectModal } from "../../ZustandStores/tokenExpiryStore";

// 1. If user is in "/linkedin/scheduler" page then redirect them to "/linkedin/content-creation" page by setting final Text, final Image value, [ step -0 ]

// - update date & time of scheduled post

// 2. create a store for scheduledPostId with localStorage and set the value of scheduledPostId in the store
// 3. Display image based on the image url stored with that associated scheduledPostId
// 4. on step 3 "instead of displaying the "schedule" button display the reshedule button"
// 5. default selected organization and default selected post type, date and time should be the same as the scheduled post
// 6. Update the scheduled posts data API

const EditPostInScheduler = ({
  selectedEvent,
  setScheduledPosts,
  scheduledPosts,
}) => {
  //   ZUSTAND
  const { showEditComponent, setShowEditComponent } =
    useShowEditComponentStore();
  const { setOverallStep } = useOverallStepStore();
  const { toggleRefresh } = useRefreshSchedulerStore();
  let { userData } = useLinkedInUserContext();
  const { userLinkedInData } = useUserLinkedInStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { linkedInReconnectModalVisible, setLinkedInReconnectModalVisible } =
    uselinkedInReconnectModal();
  //   LOCAL STATE MANAGEMENT
  const { user } = useUserAuth();
  const [showWarning, setShowWarning] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const timerRef = useRef(null);
  const [updatedOrganizationId, setUpdatedOrganizationId] = useState(
    selectedEvent.organizationId
  );
  const [updatedPostType, setUpdatedPostType] = useState(
    selectedEvent.postType
  );

  const [dateTime, setDateTime] = useState(dayjs(selectedEvent.start));
  const { setSelectedScheduledPostId } = useScheduledPostIdStore();
  const { setSelectedDraftId } = useSavedDraftIdStore();
  let navigate = useNavigate();

  const newArray = [
    {
      organizationId: "",
      organizationName: "My personal Account",
      userType: "Individual",
    },
    ...(userLinkedInData?.organizations?.map((org) => ({
      ...org,
      userType: "organization",
    })) || []),
  ];

  //   FUNCTIONS
  // reschedule post
  const handleScheduleUpdate = async (updatedEvent) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/update-scheduled-post`,
        {
          postId: selectedEvent._id,
          userId: userData?.userId,
          updatedData: updatedEvent,
        }
      );
      toggleRefresh();
    } catch (error) {
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
    }
  };

  //Edit an event scheduled post time
  const editEvent = async () => {
    if (selectedEvent && dateTime) {
      const updatedEvent = {
        ...selectedEvent,
        title: convertHtmlToFormattedText(selectedEvent.title),
        postType: updatedPostType,
        mediaUrl: selectedEvent.mediaUrl,
        mediaType: selectedEvent.mediaType,
        userId: userData?.userId,
        start: dateTime,
        end: dateTime,
        organizationId: updatedOrganizationId
          ? updatedOrganizationId
          : selectedEvent.organizationId,
        shareId: selectedEvent.shareId,
        state: selectedEvent.state,
      };

      // Update the scheduledPosts state
      const updatedPosts = await scheduledPosts?.map((post) =>
        post._id === updatedEvent._id ? updatedEvent : post
      );
      await setScheduledPosts(updatedPosts);

      await handleScheduleUpdate(updatedEvent);
    }
    setDateTime(null);
    setShowEditComponent(false);
  };

  const cancelDelete = () => {
    clearInterval(timerRef.current); // Clear the interval
    setShowWarning(false); // Hide the warning
    setCountdown(5); // Reset the countdown
  };

  const handlePostDelete = async (postId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedIn/v2/delete-scheduled-post`,
        {
          userId: userData?.userId,
          postId: postId,
        }
      );

      toggleRefresh();
    } catch (error) {
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
    }
  };

  // Edit the scheduled post and redirect to content creation page
  const handlePostEdit = (selectedEvent) => {
    let contentText = selectedEvent.title;

    // Replacing all the line breaks with <br> tags to format the text as per editor
    if (!contentText.includes("<br>") && !contentText.includes("<p>")) {
      contentText = contentText.replace(/\n/g, "<br>");
    }
    // Update the post contents in the Zustand store
    setFinalContent({
      ...finalContent,
      finalText: contentText,
      finalMediaUrl: selectedEvent.mediaUrl,
      finalMediaType: selectedEvent.mediaType,
      finalOrganizationId: selectedEvent.organizationId,
      finalPostType: selectedEvent.postType,
      // start
    });

    setSelectedDraftId("");
    setSelectedScheduledPostId(selectedEvent._id);
    setOverallStep(0);
    setShowEditComponent(false);
    navigate("/linkedin/content-creation");
  };

  const deleteEvent = () => {
    setShowWarning(true);

    timerRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timerRef.current);

          if (selectedEvent?._id) {
            // Update the scheduledPosts state
            const updatedPosts = scheduledPosts?.filter(
              (post) => post._id !== selectedEvent._id
            );

            // Defer the state update using setTimeout
            setTimeout(() => {
              setScheduledPosts(updatedPosts);
              handlePostDelete(selectedEvent._id);
              setOverallStep(0);
              setShowEditComponent(false);
            }, 0);

            return 5; // Reset the countdown
          }
          return prevCountdown - 1;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleSelect = (eachOrganizationId) => {
    let selectedOption;

    if (eachOrganizationId === "" || eachOrganizationId === "0") {
      selectedOption = newArray[0]; // Select the first object if organizationId is empty or "0"
    } else {
      selectedOption = newArray.find(
        (org) => org.organizationId == eachOrganizationId
      );
    }

    if (selectedOption) {
      setUpdatedPostType(selectedOption.userType);
      setUpdatedOrganizationId(selectedOption.organizationId);
    } else {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-between">
      <Transition show={showEditComponent} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowEditComponent(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-400"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-4/5 h-[75vh] m-auto  mt-[7rem] bg-linkedInBase rounded-lg flex border">
                  <main className="relative flex flex-row items-start justify-center w-full h-full gap-16 px-4 py-10 md:px-16 ">
                    {/* LEFT section with all post details and buttons */}
                    <section className="flex flex-col justify-center w-1/3 h-full gap-4 py-2">
                      <aside className="flex items-center justify-between w-full gap-2 ">
                        <p className="w-1/2">Post Type:</p>
                        <span className="w-1/2 text-base font-normal capitalize">
                          {updatedPostType}
                        </span>
                      </aside>
                      <aside className="flex items-center justify-between w-full gap-2">
                        <p className="min-w-max">Select Account: </p>
                        <Select
                          className="w-1/2"
                          defaultValue={selectedEvent.organizationId}
                          onChange={handleSelect}
                          options={newArray.map((eachOrganization, key) => ({
                            value: eachOrganization.organizationId,
                            label: eachOrganization.organizationName,
                          }))}
                        />
                      </aside>
                      {/* Date and time selection */}
                      <aside className="flex items-center justify-between w-full gap-2 py-5">
                        <p className="min-w-max">Select Date & Time:</p>
                        <DatePicker
                          allowClear={false}
                          className="w-1/2 py-[0.3rem] border text-triklAccentBlue font-semibold border-triklAccentBlue"
                          placeholder="Select date and time"
                          value={dateTime}
                          format="DD/MM/YYYY hh:mm A"
                          defaultValue={dayjs()}
                          onChange={(date, dateStr) => {
                            setDateTime(dayjs(date));
                          }}
                          showTime={{ use12Hours: true }}
                          disabledDate={(current) => {
                            return current && current < dayjs().startOf("day");
                          }}
                          disabledTime={(current) => {
                            const currentHour = dayjs().hour();
                            const currentMinute = dayjs().minute();

                            return {
                              disabledHours: () => {
                                const hours = [];
                                if (
                                  current &&
                                  current.format("YYYY-MM-DD") ===
                                    dayjs().format("YYYY-MM-DD")
                                ) {
                                  for (let i = 0; i < currentHour; i++) {
                                    hours.push(i);
                                  }
                                }
                                return hours;
                              },
                              disabledMinutes: (selectedHour) => {
                                const minutes = [];
                                if (
                                  current &&
                                  current.format("YYYY-MM-DD") ===
                                    dayjs().format("YYYY-MM-DD") &&
                                  selectedHour === currentHour
                                ) {
                                  for (let i = 0; i < currentMinute; i++) {
                                    minutes.push(i);
                                  }
                                }
                                return minutes;
                              },
                              disabledSeconds: () => [],
                            };
                          }}
                        />
                      </aside>

                      {/* Connect linkedIn Buttons */}
                      {userLinkedInData?.linkedInUserId?.length > 0 &&
                        userLinkedInData?.refreshTokenExpired && (
                          <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                              if (user) {
                                user.getIdToken().then((idToken) => {
                                  const linkedInAuthUrl = `${process.env.REACT_APP_SERVERURL}/linkedIn/auth?firebaseToken=${idToken}`;
                                  window.location.href = linkedInAuthUrl;
                                });
                              }
                            }}
                          >
                            Connect LinkedIn
                          </Button>
                        )}

                      <div className="flex items-center gap-4 mt-4">
                        <Button
                          onClick={() => {
                            if (selectedEvent?.state === "Posted") {
                              const urlPostType =
                                selectedEvent?.mediaType === "video" ||
                                selectedEvent?.mediaType === "document"
                                  ? "ugcPost"
                                  : "share";

                              window.open(
                                `https://www.linkedin.com/feed/update/urn:li:${urlPostType}:${selectedEvent.shareId}/`,
                                "_blank"
                              );
                            } else {
                              editEvent();
                            }
                          }}
                          size="large"
                          type="primary"
                        >
                          {selectedEvent?.state === "Posted"
                            ? `View Post `
                            : "Reschedule"}
                        </Button>

                        {selectedEvent?.state === "Scheduled" ||
                          (selectedEvent?.state === "Error" && (
                            <Button
                              onClick={() => {
                                handlePostEdit(selectedEvent);
                              }}
                              size="large"
                              icon={<EditOutlined />}
                            >
                              Edit Post
                            </Button>
                          ))}

                        <Button
                          onClick={deleteEvent}
                          disabled={selectedEvent?.state === "Posted"}
                          size="small"
                          type="link"
                          danger
                          className=" bg-linkedInBase border-linkedInBase"
                        >
                          Delete
                        </Button>
                      </div>
                      {showWarning && (
                        <div className="px-6 py-4 mt-5 bg-white rounded-lg text-triklRed">
                          <div className="pb-2">
                            Are you sure you want to delete? Deleting in{" "}
                            {countdown} seconds...
                          </div>
                          <button
                            className="px-4 py-2 font-semibold text-white rounded-lg bg-triklRed"
                            onClick={cancelDelete}
                          >
                            Cancel Deleting ({countdown})
                          </button>
                        </div>
                      )}
                    </section>

                    {/* RIGHT section with post visual */}
                    {/* <div className="flex pl-4 overflow-scroll items-center flex-col justify-start h-full w-[40vw]"> */}
                    <div className="relative h-full w-[40vw]">
                      <div className="flex flex-col items-center justify-start w-full h-full pl-4 overflow-scroll mask-gradient">
                        <PostSection selectedEvent={selectedEvent} />
                      </div>
                    </div>
                  </main>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default EditPostInScheduler;

const PostSection = ({ selectedEvent }) => {
  let { userData } = useLinkedInUserContext();
  const [showFullText, setShowFullText] = useState(false);
  const { userMainData } = useUserMainDataStore();
  const displayedText = showFullText
    ? selectedEvent.title
    : selectedEvent.title.split(" ").slice(0, 22).join(" ") + " ";

  const markdownToHtml = (text) => {
    // // Replacing all the line breaks with <br> tags to format the text as per editor
    // if (!contentText.includes("<br>") && !contentText.includes("<p>")) {
    //   contentText = contentText.replace(/\n/g, "<br>");
    // }

    // text = text.replace(
    //   /<span style="background-color: [^;]+;">(.*?)<\/span>/g,
    //   "$1"
    // ); // Remove background color from spans
    text = text.replace(/\n/g, "<br>");
    return text;
  };

  const htmlContent = markdownToHtml(displayedText);

  return (
    <>
      <section
        className="flex w-3/4 mb-20 bg-white rounded-md shadow-md"
        // className="flex items-center justify-center gap-3 py-5 overflow-auto font-inter"
      >
        <main
        // className="text-gray-400 bg-white border rounded-md shadow-lg"
        >
          {/* header */}
          <header className="flex items-center gap-2 p-1 px-5 pt-5">
            <Avatar
              size={50}
              name="Maria Mitchell"
              variant="marble"
              colors={["#F5EAFA", "#6F42C1", "#B38EFF", "#6F42C1", "#D7BFFF"]}
            />
            <aside className="text-start">
              <h4 className="font-semibold text-[14px] text-[#191919] ">
                {userData?.organizationName || userMainData?.name}
              </h4>
              <p className="flex items-center gap-1 text-[#666666] text-[12px]">
                <span>16h. </span> <ImEarth className="w-3 h-3" />
              </p>
            </aside>
          </header>

          {/* text section */}
          <div className="px-5 text-left font-normal text-[0.9rem] py-2">
            <span as="span" dangerouslySetInnerHTML={{ __html: htmlContent }} />

            {/* {displayedText} */}
            {selectedEvent?.title.split(" ").length > 22 && (
              <button
                as="span"
                onClick={() => {
                  setShowFullText(!showFullText);
                }}
                className="w-full py-2 font-semibold text-right text-tNewButtonsAndActiveElement"
              >
                {!showFullText ? "...see more" : "...see less"}
              </button>
            )}
          </div>

          {/* Display the type of media selected */}
          <section className="">
            {selectedEvent?.mediaUrl &&
              selectedEvent?.mediaType === "video" && (
                <>
                  {/* // Display the video */}
                  <video controls src={selectedEvent?.mediaUrl}>
                    Your browser does not support the video tag.
                  </video>
                </>
              )}
            {selectedEvent?.mediaUrl &&
              selectedEvent?.mediaType === "document" && (
                <>
                  <PdfViewer fileUrl={selectedEvent?.mediaUrl} />
                </>
              )}

            {/* image section */}
            {selectedEvent?.mediaUrl &&
              selectedEvent?.mediaType === "image" && (
                <img
                  src={selectedEvent?.mediaUrl}
                  alt="Final_TRIKL_image"
                  className="w-full"
                />
              )}
          </section>

          {/* likes  comments and other components  */}
          <div className="flex font-normal flex-row items-center text-xs justify-between py-4 px-5 text-[12px] text-[#666666]">
            <div className="flex gap-2">
              <img
                src={likeCommShare}
                className="object-fit "
                alt="likeCommentImage"
              />
              <div>Jax and 326 others</div>
            </div>
            <aside>73 comments . 37 reposts</aside>
          </div>

          {/* interactions */}
          <section className="flex items-center py-4 text-sm font-normal border-t border-gray-300 justify-evenly">
            <div className="flex items-center gap-1">
              <AiOutlineLike className="text-xl" /> Like
            </div>
            <div className="flex items-center gap-1">
              <FaRegComment className="text-xl" /> Comment
            </div>
            <div className="flex items-center gap-1">
              <BiRepost className="text-xl" /> Repost
            </div>
            <div className="flex items-center gap-1">
              <IoIosSend className="text-xl" /> Send
            </div>
          </section>
        </main>
      </section>
    </>
  );
};
