import { Disclosure } from "@headlessui/react";
import randomColor from "randomcolor";
import React from "react";
import { BiChevronDown, BiPoll } from "react-icons/bi";
import { FaRegLaughBeam, FaRegQuestionCircle } from "react-icons/fa";

const AIPromptTemplates = ({
  aiRecommendation,
  setInputValue,
  scrollToBottom,
}) => {
  return (
    <Disclosure as="div" className="mt-2">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center justify-center w-full gap-3 py-4 font-semibold rounded-md bg-lightGrey hover:bg-[#8beaff] hover:shadow-md hover:shadow-triklBlueDark/20">
            <span>Top AI Prompt Templates</span>
            <BiChevronDown
              className={`text-3xl text-triklBlueDark/80 ${
                open ? "rotate-180 transform" : ""
              }`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500">
            <main className="flex flex-col items-start gap-3 pt-4">
              <div className="pb-2 text-xs">
                All these prompts are customized specially for your community
                discussions
              </div>
              <button
                className="box-border flex items-center w-full gap-1 px-3 py-2 text-black border rounded-lg border-triklBlueDark/50"
                style={{
                  background: randomColor({
                    luminosity: "bright",
                    format: "rgba",
                    alpha: "0.1",
                  }),
                }}
                onClick={async (e) => {
                  setInputValue("Ask a thought-provoking question.");
                  await aiRecommendation(
                    "Ask a thought-provoking question.",
                    true
                  );
                  setInputValue("");
                  scrollToBottom();
                }}
              >
                <FaRegQuestionCircle />
                <span className="pl-1">Ask a question?</span>
              </button>
              <button
                className="box-border flex items-center w-full gap-1 px-3 py-2 text-black border rounded-lg border-triklBlueDark/50"
                style={{
                  background: randomColor({
                    luminosity: "bright",
                    format: "rgba",
                    alpha: "0.1",
                  }),
                }}
                onClick={async (e) => {
                  setInputValue(
                    "Ask a thought-provoking question in a poll manner with 3 simple options"
                  );
                  await aiRecommendation(
                    "Ask a thought-provoking question in a poll manner with 3 simple options",
                    true
                  );
                  setInputValue("");
                  scrollToBottom();
                }}
              >
                <BiPoll />
                <span className="pl-1">Run a poll</span>
              </button>
              <button
                className="box-border flex items-center w-full gap-1 px-3 py-2 text-black border rounded-lg border-triklBlueDark/50"
                style={{
                  background: randomColor({
                    luminosity: "bright",
                    format: "rgba",
                    alpha: "0.1",
                  }),
                }}
                onClick={async (e) => {
                  setInputValue(
                    "write a funny and relatable joke to my community."
                  );
                  await aiRecommendation(
                    "write a funny and relatable joke to my community.",
                    true
                  );
                  setInputValue("");
                  scrollToBottom();
                }}
              >
                <FaRegLaughBeam />
                <span className="pl-1">Tell a joke</span>
              </button>
            </main>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AIPromptTemplates;
