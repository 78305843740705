import { ConfigProvider, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import {
  useAiResponseArrayStore,
  useFinalContentStore,
} from "../../ZustandStores/LinkedInZustandStore";

const ContentOptions = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();

  const { aiResponseArray } = useAiResponseArrayStore();

  const [PostTypes, setPostTypes] = useState([
    { name: "Option 1", content: "" },
    { name: "Option 2", content: "" },
    { name: "Option 3", content: "" },
  ]);

  useEffect(() => {
    let parsedArray;
    try {
      parsedArray =
        typeof aiResponseArray === "string"
          ? JSON.parse(aiResponseArray)
          : aiResponseArray;
    } catch (error) {
      console.error("Error parsing aiResponseArray:", error);
      parsedArray = [];
    }

    if (parsedArray.length > 0) {
      const updatedPostTypes = PostTypes.map((post, index) => ({
        ...post,
        content: parsedArray[index]?.postContent || "",
      }));
      setPostTypes(updatedPostTypes);

      setSelectedOption(updatedPostTypes[0]?.name || "");

      setFinalContent({ finalText: updatedPostTypes[0]?.content || "" });
    }
  }, [aiResponseArray]);

  const [selectedOption, setSelectedOption] = useState(
    PostTypes[0]?.name || ""
  );

  const handleSelect = (value) => {
    const selectedOption = PostTypes.find((option) => option.name === value);
    if (selectedOption) {
      setSelectedOption(selectedOption.name);
      setFinalContent({ finalText: selectedOption.content });
    }
  };

  return (
    <div className="mb-4">
      <Segmented
        value={selectedOption.name}
        onChange={(value) => handleSelect(value)}
        options={PostTypes.map((option) => ({
          label: option.name,
          value: option.name,
        }))}
        //   block
      />
    </div>
  );
};

export default ContentOptions;
