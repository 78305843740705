import axios from "axios";
import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import IconButton from "@mui/material/IconButton";
import { FiCopy, FiRefreshCcw } from "react-icons/fi";
import { BeatLoader } from "react-spinners";

import { UserDataRefreshStore } from "../../linkedIn/ZustandStores/LinkedInZustandStore";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import { useUserMainDataStore } from "../../linkedIn/ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../context/userAuthContext";

// ZUSTAND
const TextContentOptimiser = ({
  selectedText,
  aiOptimisedText,
  setAiOptimisedText,
  setUpdateText,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rephraseInputText, setRephraseInputText] = useState("");
  const [regenerating, setRegenerating] = useState(false);
  const [replacing, setReplacing] = useState(false);
  const [hidePromptArea, setHidePromptArea] = useState(false);
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { userMainData } = useUserMainDataStore();

  const { user } = useUserAuth();

  // --------------
  const generatePost = async (rephraseInputText) => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }
    setRegenerating(true);
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/linkedIn-rephrase`,
        {
          advancedPrompt: `${rephraseInputText} the text, which is delimited by triple quotes """${selectedText}"""`,
          purpose: "rephrase",
          userId: userMainData?.userId,
        }
      );
      setUserDataRefresh(!userDataRefresh);
      setAiOptimisedText(res.data.content);
      setHidePromptArea(true);
      setIsLoading(false);
      setRegenerating(false);
    } catch (error) {
      setIsLoading(false);
      setRegenerating(false);
      setHidePromptArea(false);
    }
  };

  const handleTextReplace = () => {
    setReplacing(true);
    setUpdateText(true);
    setHidePromptArea(false);
    setReplacing(false);
    setRephraseInputText("");
  };

  // Stops click events from the form area from propagating up to the BsMagic icon
  const handleFormClick = (event) => {
    event.stopPropagation();
  };

  return (
    <section>
      <div
        className="absolute -translate-x-10 -translate-y-5 md:translate-y-0 md:-translate-x-16 w-max"
        onClick={() => setIsFormVisible(!isFormVisible)}
      >
        <Tooltip
          title="Select text to improve with AI"
          followCursor
          className="bg-red-600"
          sx={
            selectedText?.length > 1
              ? {
                  background: "#6F42C1",
                  // "linear-gradient(90deg, rgba(58,0,212,1) 0%,rgba(58,0,212,0.5)37%,rgba(253, 237, 253, 1) 100%)",
                  color: "#fff",
                }
              : {
                  bgcolor: "#ffffff",
                }
          }
        >
          <IconButton>
            <AutoFixNormalIcon />
          </IconButton>
        </Tooltip>
      </div>

      <Draggable
        enableUserSelectHack={false}
        bounds={{
          top: 0,
          left: 0,
          right: window.innerWidth,
          bottom: window.innerHeight,
        }}
      >
        <main className="absolute z-[500] w-1/2 bottom-5 left-32">
          {isFormVisible && selectedText?.length > 1 && (
            <div
              className="flex flex-col items-start justify-start gap-2 p-5 text-left bg-white border border-gray-300 rounded-lg drop-shadow-md"
              onClick={handleFormClick}
            >
              {!hidePromptArea && (
                <>
                  <textarea
                    placeholder="what change you want?"
                    className="w-full px-4 py-1 bg-gray-100 rounded-md text-triklBlueDark"
                    value={rephraseInputText}
                    onChange={(e) => {
                      setRephraseInputText(e.target.value);
                    }}
                  />

                  {isLoading ? (
                    <div className="w-full px-4 py-1 font-semibold text-center text-white rounded-md bg-triklAccentBlue font-spaceGrotesk">
                      <BeatLoader color="#fff" size={8} />
                    </div>
                  ) : (
                    <button
                      disabled={regenerating}
                      className="w-full px-4 py-1 font-semibold text-center text-white rounded-md bg-triklAccentBlue font-spaceGrotesk"
                      onClick={() => {
                        generatePost(rephraseInputText);
                      }}
                    >
                      Modify with AI
                    </button>
                  )}

                  <span className="pt-4 text-xs text-gray-500">
                    Selected Text:
                  </span>
                  <p className="overflow-scroll text-justify max-h-48">
                    {selectedText}
                  </p>
                </>
              )}

              {aiOptimisedText.length > 0 && (
                <div className="flex flex-col">
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <p className="text-gray-500">
                        Refined selected text:
                        <span className="text-black">{aiOptimisedText}</span>
                      </p>
                    </div>
                    <FiCopy
                      className="w-4 h-4 ml-4 text-gray-500 cursor-pointer hover:text-triklAccentBlue/50"
                      onClick={() => {
                        navigator.clipboard.writeText(aiOptimisedText);
                        toast.success("Copied to clipboard!");
                      }}
                    />
                  </div>

                  <div className="flex items-center justify-center w-full gap-2">
                    <button
                      disabled={regenerating}
                      className="bg-triklAccentBlue text-white w-full border-triklAccentBlue box-border 
        rounded-md border-[1px] mt-3 px-8 py-1 flex 
        font-semibold items-center justify-center"
                      onClick={() => {
                        handleTextReplace();
                      }}
                    >
                      {replacing ? (
                        <BeatLoader color="#6F42C1" size={10} />
                      ) : (
                        "Replace"
                      )}
                    </button>

                    <button
                      className="bg-white text-triklAccentBlue w-full border-triklAccentBlue box-border 
                      rounded-md border-[1px] mt-3 px-8 py-1 flex 
                      font-semibold items-center justify-center"
                      disabled={regenerating}
                      onClick={() => {
                        generatePost(rephraseInputText);
                      }}
                    >
                      {regenerating ? (
                        <BeatLoader color="#6F42C1" size={10} />
                      ) : (
                        <>
                          Regenerate
                          <FiRefreshCcw className="ml-1 text-triklAccentBlue" />
                        </>
                      )}
                    </button>
                  </div>

                  <button
                    disabled={regenerating}
                    onClick={() => {
                      setHidePromptArea(false);
                      setAiOptimisedText("");
                    }}
                    className="bg-triklAccentBlue text-white w-full border-triklAccentBlue box-border 
        rounded-md border-[1px] mt-3 px-8 py-1 flex 
        font-semibold items-center justify-center"
                  >
                    Edit the prompt
                  </button>
                </div>
              )}
            </div>
          )}
        </main>
      </Draggable>
    </section>
  );
};

export default TextContentOptimiser;
