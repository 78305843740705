import React, { useEffect, useState } from "react";
import SavedDrafts from "./editorComponents/SavedDrafts";
import TopicSelection from "./editorComponents/TopicSelection";
import { Button, Modal, Select } from "antd";

import {
  useAiResponseStore,
  useIsAiPromptRunningStore,
  useWhatsSelectedStore,
  useFinalContentStore,
  useCanvasSideStepStore,
  useCurrentPostTypeStore,
  useContentUrlStore,
  UserDataRefreshStore,
  useIsCreateFromClickStore,
  useSelectedMenuItemStore,
  useSavedDraftIdStore,
  useAiResponseArrayStore,
  useScheduledPostIdStore,
} from "../ZustandStores/LinkedInZustandStore";
import NewsContent from "./editorComponents/NewsContent";
import { toast } from "react-toastify";
import {
  CategoryMenu,
  LengthMenu,
  ToneMenu,
  UserVoiceComponent,
} from "./editorComponents/CategorySelectionCard";
import ClearUpdatedContent from "./modalsComponents/ClearUpdatedContent";
import { useLocation } from "react-router-dom";
import { useCalendarContentGenStore } from "../ZustandStores/ContentCalendarStore";
import { useUserMainDataStore } from "../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../context/userAuthContext";
import { usePostTemplateStore } from "../ZustandStores/PostTemplateStore";
import TemplateSelectionModal from "./postTemplateSelection/TrendingSelectionModal";

// ZUSTAND STORE VARIABLES

const CanvasSidebar = () => {
  // ZUSTAND
  const { canvasSideStep } = useCanvasSideStepStore();
  const { currentPostType } = useCurrentPostTypeStore();
  const { finalContent } = useFinalContentStore();
  const { userMainData } = useUserMainDataStore();
  // OTHERS
  const [isSavedDrafts, setIsSavedDrafts] = useState(false);
  const [ManualData, setManualData] = useState(false);
  const [setStep1Selected] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedAIModel, setSelectedAIModel] = useState("personalAI");
  const { calendarContent, setCalendarContent } = useCalendarContentGenStore();
  const location = useLocation();
  const recordName = location.state?.recordName;
  const { selectedMenuItem, setSelectedMenuItem } = useSelectedMenuItemStore();
  const [templateOption, setTemplateOption] = useState("Select a Template");

  useEffect(() => {
    if (recordName) {
      setSelectedMenuItem(0);
      setCalendarContent({
        ...calendarContent,
        textContent: recordName || "",
        ManualDataClick: true,
      });
    }
  }, [recordName]);

  return (
    <main className="flex flex-col max-h-screen gap-5 py-24 overflow-y-scroll h-max md:min-h-screen">
      {/* CREATE POST OR SAVED DRAFTS */}
      <Step0 setIsSavedDrafts={setIsSavedDrafts} />
      {/* CREATE POST FROM - TRENDING TOPICS OR MANUALLY ADD DATA */}
      {canvasSideStep >= 1 && (
        <Step1
          isSavedDrafts={isSavedDrafts}
          selectedMenuItem={selectedMenuItem}
          setManualData={setManualData}
          ManualData={ManualData}
          setStep1Selected={setStep1Selected}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          setSelectedAIModel={setSelectedAIModel}
        />
      )}
      {/* NEWS, ARTICLES, EVENTS */}
      {canvasSideStep >= 3 &&
        (currentPostType === "Latest News" ||
          currentPostType === "Manually Search News") && <NewsContent />}

      {/* Customixation Option & Templates */}
      {canvasSideStep >= 4 && finalContent && (
        <CustomizationOption
          templateOption={templateOption}
          setTemplateOption={setTemplateOption}
        />
      )}

      {/* Generate button */}
      {canvasSideStep >= 4 && finalContent && (
        <GenerateContent
          selectedAIModel={selectedAIModel}
          templateOption={templateOption}
        />
      )}
    </main>
  );
};

export default CanvasSidebar;

/////////////////////////////////////////////

const Step0 = ({ setIsSavedDrafts }) => {
  // Zustand Stores
  const { selectedDraftId } = useSavedDraftIdStore();

  const { selectedScheduledPostId } = useScheduledPostIdStore();
  const { setCanvasSideStep } = useCanvasSideStepStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { setIsCreateFromClick } = useIsCreateFromClickStore();
  const { selectedMenuItem, setSelectedMenuItem } = useSelectedMenuItemStore();

  // STATE
  const [isClearDraftId, setIsClearDraftId] = useState(false);

  const handleButtonClick = (index) => {
    if (index === 0) {
      setIsCreateFromClick(true);
      if (selectedDraftId.length > 0) {
        // inside;
        setIsClearDraftId(true);
        // setIsCreateFromClick(false);
      } else if (selectedScheduledPostId.length > 0) {
        setIsClearDraftId(true);
        // setIsCreateFromClick(false);
      }
    }
    setSelectedMenuItem(index);
    setUserDataRefresh(!userDataRefresh);
    index === 1 ? setIsSavedDrafts(true) : setIsSavedDrafts(false);
    setCanvasSideStep(1);
  };

  // Execute the logic based on the selectedMenuItem
  useEffect(() => {
    if (selectedMenuItem !== null) {
      //  Mimic the button click based on the selectedMenuItem value
      handleButtonClick(selectedMenuItem);
    }
  }, [selectedMenuItem]);

  // OTHERS
  const menuItems = ["Create New Post", "Saved Drafts"];

  return (
    <>
      <header className="flex w-full gap-4 px-4">
        {menuItems.map((each, index) => (
          <button
            key={index}
            onClick={() => {
              handleButtonClick(index);
            }}
            className={`w-1/2 py-2 rounded-md font-spaceGrotesk border ${
              selectedMenuItem === index
                ? "bg-white text-triklAccentBlue font-semibold border-triklAccentBlue"
                : "border-triklBlueDark text-triklBlueDark"
            }`}
          >
            {each}
          </button>
        ))}
      </header>
      {/* CLEAR SAVED DRAFTS / SCHEDULED POST MODAL */}

      <ClearUpdatedContent
        isOpen={isClearDraftId}
        onClose={() => setIsClearDraftId(false)}
        clearPostType={selectedDraftId ? "Saved Draft" : "Scheduled Post"}
      />
    </>
  );
};

/////////////////////////////////////////////

const Step1 = ({
  isSavedDrafts,
  selectedMenuItem,
  setManualData,
  ManualData,
  setSelectedTopic,
  selectedTopic,
  setSelectedAIModel,
}) => {
  return isSavedDrafts || selectedMenuItem === 1 ? (
    <SavedDrafts />
  ) : (
    <>
      <ModelSelection setSelectedAIModel={setSelectedAIModel} />
      <Step1CreateFrom
        setManualData={setManualData}
        ManualData={ManualData}
        selectedTopic={selectedTopic}
        setSelectedTopic={setSelectedTopic}
      />
    </>
  );
};

/////////////////////////////////////////////
const ModelSelection = ({ setSelectedAIModel }) => {
  const handleChange = (event) => {
    setSelectedAIModel(event);
  };

  return (
    <div className="flex items-center gap-2 py-2 mx-4">
      <label
        htmlFor="simple-dropdown"
        className="text-sm font-semibold min-w-max"
      >
        Choose an AI
      </label>

      <div className="flex-grow">
        <Select
          id="simple-dropdown"
          className="flex w-full"
          defaultValue="personalAI"
          onChange={handleChange}
          options={[
            { value: "personalAI", label: "TRIKL" },
            { value: "fourTurbo", label: "GPT-4 Advanced" },
            { value: "claude3", label: "Claude 3" },
            { value: "gemini", label: "Gemini" },
          ]}
        />
      </div>
    </div>
  );
};

/////////////////////////////////////////////

const Step1CreateFrom = ({
  setManualData,
  ManualData,
  setSelectedTopic,
  selectedTopic,
}) => {
  const { canvasSideStep, setCanvasSideStep } = useCanvasSideStepStore();
  const { calendarContent } = useCalendarContentGenStore();
  const { setCurrentPostType } = useCurrentPostTypeStore();
  const { isCreateFromClick, setIsCreateFromClick } =
    useIsCreateFromClickStore();
  const { finalContent, setFinalContent } = useFinalContentStore();

  const postTypes = [
    // { typeName: "Latest News", sideBarStep: 2 },
    // { typeName: "Create From Link", sideBarStep: 2 },
    { typeName: "Manually Add Data", sideBarStep: 2 },
    { typeName: "Create From Audio", sideBarStep: 2 },
    // { typeName: "Inspiration profiles", sideBarStep: 2 },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState(postTypes[0]); // Default selection

  // Adjust selection based on external conditions
  useEffect(() => {
    let initialSelection = postTypes[0]; // Default to "Latest News"
    if (calendarContent.ManualDataClick) {
      initialSelection = postTypes.find(
        (type) => type.typeName === "Manually Add Data"
      );
    }
    handleSelect(initialSelection.typeName);
  }, [calendarContent.ManualDataClick, isCreateFromClick]);

  const handleSelect = (selectedTypeName) => {
    const selectedOption = postTypes.find(
      (option) => option.typeName === selectedTypeName
    );
    if (!selectedOption) return;

    setSelectedMenuItem(selectedOption);
    setCanvasSideStep(selectedOption.sideBarStep);
    setCurrentPostType(selectedOption.typeName);
    setManualData(selectedOption.typeName === "Manually Add Data");

    // Reset final content state if necessary
    setFinalContent({
      ...finalContent,
      finalCategory: "",
      finalCharacterLimit: 0,
      finalTone: "",
      additionalPersonalization: [],
    });
  };

  return (
    <section className="p-4 mx-4 bg-white border border-gray-300 rounded-md">
      <header className="pb-3 font-semibold text-left">Create Post From</header>
      <div className="grid grid-cols-2 gap-2 pb-4">
        {postTypes.map((option) => (
          <button
            key={option.typeName}
            className={`${
              selectedMenuItem.typeName === option.typeName
                ? "border border-triklAccentBlue"
                : "bg-gray-200 border border-gray-200 text-black"
            } p-2 rounded-lg w-full`}
            onClick={() => handleSelect(option.typeName)}
          >
            {option.typeName}
          </button>
        ))}
      </div>
      {/* Assuming TopicSelection is another component you've created */}
      {canvasSideStep >= 2 && (
        <TopicSelection
          ManualData={ManualData}
          setManualData={setManualData}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
        />
      )}
    </section>
  );
};

/////////////////////////////////////////////
const GenerateContent = ({ selectedAIModel, templateOption }) => {
  // ZUSTAND
  const { whatsSelected } = useWhatsSelectedStore();
  const { setAiResponse } = useAiResponseStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { isPromptRunning, setIsPromptRunning } = useIsAiPromptRunningStore();
  const { currentUrl } = useContentUrlStore();
  const { currentPostType } = useCurrentPostTypeStore();
  const { canvasSideStep } = useCanvasSideStepStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { aiResponseArray, setAiResponseArray } = useAiResponseArrayStore();
  const { userMainData } = useUserMainDataStore();
  const { selectedPostTemplate, setSelectedPostTemplate } =
    usePostTemplateStore();
  const { user } = useUserAuth();

  const generatePost = async (currentPostType) => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }
    try {
      setIsPromptRunning(true);
      const isNoEmoji = await finalContent.additionalPersonalization.includes(
        "noEmoji"
      );
      const isPersonalized =
        await finalContent.additionalPersonalization.includes("personalize");

      const isBulletPoint =
        await finalContent.additionalPersonalization.includes(
          "smallBulletPoints"
        );
      const isBrandVoice =
        await finalContent.additionalPersonalization.includes("brandVoice");
      const isPersonalVoice =
        await finalContent.additionalPersonalization.includes("personalVoice");
      const organizationBrandVoiceId =
        await finalContent.finalOrganizationBrandVoiceId;
      // const isOrganization = finalContent.additionalPersonalization

      const promptVariables = {
        text: currentUrl,
        finaltone: finalContent.finalTone,
        finalCategory: finalContent.finalCategory,
        finalCharacterLimit: finalContent.finalCharacterLimit,
        isNoEmoji: isNoEmoji,
        isPersonalized: isPersonalized,
        isBulletPoint: isBulletPoint,
        isBrandVoice: isBrandVoice,
        isPersonalVoice: isPersonalVoice,
        organizationBrandVoiceId: organizationBrandVoiceId,
        createFrom: currentPostType,
        finalInspirationProfile: finalContent.finalInspirationProfile,
        postTemplateId: selectedPostTemplate,
      };

      ////////////// CALLING PROMPT BASIS WHAT KIND OF POST IS SELECTED //////////////
      // CASE 1: CREATING POST FROM ANY PUBLIC URL
      if (currentPostType === "Create From Link" && currentUrl.length > 0) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/ai-article-contents`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: userMainData?.userId,
                promptVariables: promptVariables,
                selectedAIModel: selectedAIModel,
              }),
              credentials: "include",
              // signal: controller.signal,
            }
          );

          if (response.status === 429) {
            toast.error(
              "Text limit reached, check your profile page for more details 🐹"
            );
            return;
          }

          if (!response.ok) {
            toast.error("Something went wrong, please try again! 🐹");
          }
          const reader = response.body.getReader();
          let aiAnswer = "";
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            aiAnswer += new TextDecoder().decode(value, { stream: true });
            const formattedAnswer = aiAnswer.replace(/\n/g, "<br>");
            setIsPromptRunning(false);
            setAiResponse(formattedAnswer);
            setFinalContent({
              ...finalContent,
              finalText: formattedAnswer,
            });
          }
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Fetch error:", error);
          }
        } finally {
          setIsPromptRunning(false);
        }
      } else if (
        currentPostType === "Manually Add Data" ||
        currentPostType === "Inspiration profiles" ||
        currentPostType === "Create From Audio"
      ) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/linkedIn-post`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: userMainData?.userId,
                promptVariables: promptVariables,
                selectedAIModel: selectedAIModel,
              }),
            }
          );

          if (response.status === 429) {
            toast.error(
              "Text limit reached, check your profile page for more details 🐹"
            );
            return;
          }

          if (!response.ok) {
            toast.error("Something went wrong, please try again! 🐹");
          }

          const reader = response.body.getReader();
          let aiAnswer = "";
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            aiAnswer += new TextDecoder().decode(value, { stream: true });
            const formattedAnswer = aiAnswer.replace(/\n/g, "<br>");
            setIsPromptRunning(false);
            setAiResponse(formattedAnswer);
            setFinalContent({
              ...finalContent,
              finalText: formattedAnswer,
            });
          }
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Fetch error:", error);
          }
          toast.error("Something went wrong, please try again! 🐹");
        } finally {
          setIsPromptRunning(false);
        }
      } // create post from Audio
      else if (currentPostType === "Latest News" || "Manually Search News") {
        promptVariables.text = whatsSelected.url;

        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/ai-article-contents`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: userMainData?.userId,
                promptVariables: promptVariables,
                selectedAIModel: selectedAIModel,
              }),
              // credentials: "include",
              // signal: controller.signal,
            }
          );
          if (response.status === 429) {
            toast.error(
              "Text limit reached, check your profile page for more details 🐹"
            );
            return;
          }

          if (!response.ok) {
            toast.error("Something went wrong, please try again! 🐹");
          }

          const reader = response.body.getReader();
          let aiAnswer = "";
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            aiAnswer += new TextDecoder().decode(value, { stream: true });
            const formattedAnswer = aiAnswer.replace(/\n/g, "<br>");
            setIsPromptRunning(false);
            setAiResponse(formattedAnswer);
            setFinalContent({
              ...finalContent,
              finalText: formattedAnswer,
            });
          }
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Fetch error:", error);
          }
        } finally {
          setIsPromptRunning(false);
        }
      }

      setUserDataRefresh(!userDataRefresh);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Can't access the link, please try another article 🐹");
        return;
      }

      if (error.response.status === 403) {
        toast.error("Access denied. Please make a payment!");
        return;
      }
      toast.error("Oops! Something went wrong, give it another go! 🐹");
    } finally {
      setIsPromptRunning(false);
    }
  };

  const manualOption =
    finalContent?.finalTone === "" ||
    finalContent?.finalCategory === "" ||
    finalContent?.finalCharacterLimit === 0;

  const contentLength = finalContent?.finalText.length;

  return (
    <div className="w-full px-4">
      {templateOption === "Select a Template" ? (
        <button
          as="div"
          className="w-full py-4 text-white uppercase border rounded-md font-spaceGrotesk bg-triklAccentBlue border-triklAccentBlue disabled:bg-triklAccentBlue/60 disabled:border-triklAccentBlue/50"
          disabled={isPromptRunning || !selectedPostTemplate}
          onClick={() => {
            if (finalContent?.finalText.length) {
              setIsModalOpen(true);
            } else {
              generatePost(currentPostType);
            }
          }}
        >
          {!selectedPostTemplate
            ? "Select a template"
            : finalContent?.finalText.length
            ? "Clear Editor For New Content"
            : "Generate Content"}
        </button>
      ) : contentLength || manualOption ? (
        <button
          as="div"
          className="w-full py-4 text-white uppercase border rounded-md font-spaceGrotesk bg-triklAccentBlue border-triklAccentBlue disabled:bg-triklAccentBlue/60 disabled:border-triklAccentBlue/50"
          disabled={isPromptRunning || manualOption}
          onClick={() => setIsModalOpen(true)}
        >
          {manualOption ? "Select options" : "Clear Editor For New Content"}
        </button>
      ) : (
        <button
          as="div"
          className="w-full py-4 text-white uppercase border rounded-md font-spaceGrotesk bg-triklAccentBlue border-triklAccentBlue disabled:bg-triklAccentBlue/60 disabled:border-triklAccentBlue/50"
          disabled={
            isPromptRunning ||
            manualOption ||
            contentLength > 0 ||
            selectedPostTemplate
          }
          onClick={() => {
            generatePost(currentPostType);
          }}
        >
          Generate Content
        </button>
      )}
      <PermissionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        finalContent={finalContent}
        setFinalContent={setFinalContent}
        generatePost={generatePost}
      />
    </div>
  );
};

/////////////////////////////////////////////

const PermissionModal = ({
  isOpen,
  onClose,
  finalContent,
  setFinalContent,
  generatePost,
}) => {
  const { currentPostType } = useCurrentPostTypeStore();

  return (
    <>
      <Modal
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        // width={"80vw"}
        footer={null}
      >
        {/* <p>Hello world</p> */}
        <main className="flex flex-col items-center justify-center w-full h-full gap-4 text-lg text-left">
          <span className="text-red-600"> Are you sure? </span>
          This will clear the current text and generate a new one{" "}
          <Button
            className="w-2/3 bg-triklAccentBlue"
            type="primary"
            onClick={() => {
              setFinalContent({
                ...finalContent,
                finalText: "",
              });
              onClose();
              generatePost(currentPostType);
            }}
          >
            Clear and Generate
          </Button>
        </main>
      </Modal>
    </>
  );
};

//////////////////// Option to select from templates /////////////////////////

const CustomizationOption = ({ templateOption, setTemplateOption }) => {
  const { selectedPostTemplate, setSelectedPostTemplate } =
    usePostTemplateStore();
  const CustomizationOptions = [
    { typeName: "Select a Template" },
    { typeName: "Manual Input" },
  ];

  const handleOptionSelected = (selectedTypeName) => {
    if (selectedTypeName === "Manual Input") {
      setSelectedPostTemplate("");
      setTemplateOption("Manual Input");
    } else {
      setTemplateOption("Select a Template");
    }
  };

  return (
    <div className="p-4 mx-4 bg-white border border-gray-300 rounded-md ">
      <header className="pb-3 font-semibold text-left">Select Options</header>
      <div className="flex justify-between gap-2">
        {CustomizationOptions.map((option) => (
          <button
            key={option.typeName}
            className={`${
              templateOption === option.typeName
                ? "border border-triklAccentBlue"
                : "bg-gray-200 border border-gray-200 text-black"
            } p-2 rounded-lg w-full`}
            onClick={() => handleOptionSelected(option.typeName)}
          >
            {option.typeName}
          </button>
        ))}
      </div>

      {templateOption === "Manual Input" ? (
        <div className="flex flex-col gap-5 pt-5">
          {/* Category Selection */}
          <CategoryMenu />
          {/* Tone Selection */}
          <ToneMenu />
          {/* Length Selection */}
          <LengthMenu />
          {/* Voice Selection */}
          <UserVoiceComponent />
        </div>
      ) : (
        <PostTemplates />
      )}
    </div>
  );
};

const PostTemplates = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col max-h-screen gap-5 px-4">
      <Button onClick={() => setIsOpen(true)} className="mt-5">
        Open Templates Gallery
      </Button>

      <TemplateSelectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};
