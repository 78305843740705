const V2BackgroundTemplate11 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3499)">
        <g filter="url(#filter0_f_3208_3499)">
          <circle
            cx="421.5"
            cy="77.5"
            r="118.5"
            fill={colorPallet?.secondary}
          />
        </g>
        <g filter="url(#filter1_f_3208_3499)">
          <circle
            cx="-1.5"
            cy="586.5"
            r="144.5"
            fill={colorPallet?.secondary}
          />
        </g>
        <g filter="url(#filter2_f_3208_3499)">
          <circle cx="439" cy="701" r="135.5" stroke={colorPallet?.secondary} />
        </g>
        <g filter="url(#filter3_f_3208_3499)">
          <circle cx="40" cy="71" r="124.5" stroke={colorPallet?.secondary} />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_3208_3499"
          x="143"
          y="-201"
          width="557"
          height="557"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="80"
            result="effect1_foregroundBlur_3208_3499"
          />
        </filter>
        <filter
          id="filter1_f_3208_3499"
          x="-306"
          y="282"
          width="609"
          height="609"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="80"
            result="effect1_foregroundBlur_3208_3499"
          />
        </filter>
        <filter
          id="filter2_f_3208_3499"
          x="297"
          y="559"
          width="284"
          height="284"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_3208_3499"
          />
        </filter>
        <filter
          id="filter3_f_3208_3499"
          x="-91"
          y="-60"
          width="262"
          height="262"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_3208_3499"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default V2BackgroundTemplate11;
