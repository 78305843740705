import React from "react";

const BackgroundTemplate2 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2478_1665"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2478_1665)"> */}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.7695 558.5C49.7695 589.223 47.8171 617.192 44.6208 638.128C56.1577 620.876 73.2236 599.574 93.4933 577.797C136.267 531.843 177.111 501.178 184.719 509.305C192.328 517.432 163.822 561.273 121.048 607.227C100.127 629.703 79.6681 648.521 63.3336 660.933C83.1254 657.24 110.258 654.962 140.19 654.962C200.77 654.962 249.881 664.292 249.881 675.802C249.881 687.312 200.77 696.643 140.19 696.643C110.105 696.643 82.8495 694.342 63.0323 690.615C79.4551 703.106 100.2 722.328 121.406 745.35C163.954 791.544 192.246 835.545 184.597 843.628C176.949 851.712 136.256 820.818 93.7078 774.625C73.0731 752.222 55.7914 730.335 44.3362 712.811C47.6296 733.859 49.6488 762.255 49.6488 793.5C49.6488 858.393 40.9384 911 30.1936 911C19.4487 911 10.7383 858.393 10.7383 793.5C10.7383 761.382 12.872 732.274 16.33 711.064C4.79681 728.68 -12.847 750.858 -33.9702 773.552C-76.744 819.506 -117.587 850.171 -125.196 842.044C-132.805 833.918 -104.298 790.077 -61.5245 744.123C-40.9702 722.04 -20.8618 703.488 -4.67261 691.074C-24.25 694.53 -50.4809 696.643 -79.3094 696.643C-139.89 696.643 -189 687.312 -189 675.802C-189 664.292 -139.89 654.962 -79.3094 654.962C-49.6561 654.962 -22.7511 657.197 -3.00864 660.83C-19.2809 648.327 -39.6443 629.396 -60.4515 606.806C-103 560.612 -131.292 516.612 -123.643 508.528C-115.995 500.444 -75.302 531.338 -32.7536 577.532C-12.4482 599.577 4.61037 621.122 16.0651 638.503C12.8348 617.526 10.859 589.407 10.859 558.5C10.859 493.607 19.5694 441 30.3142 441C41.0591 441 49.7695 493.607 49.7695 558.5Z"
        fill={colorPallet.secondary}
        fill-opacity="0.07"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M462.769 8.5C462.769 39.223 460.817 67.1919 457.621 88.1282C469.158 70.8762 486.224 49.5739 506.493 27.7972C549.267 -18.1568 590.111 -48.8219 597.719 -40.6952C605.328 -32.5685 576.822 11.2726 534.048 57.2266C513.127 79.7027 492.668 98.5214 476.334 110.933C496.125 107.24 523.258 104.962 553.19 104.962C613.77 104.962 662.881 114.292 662.881 125.802C662.881 137.312 613.77 146.643 553.19 146.643C523.105 146.643 495.85 144.342 476.032 140.615C492.455 153.106 513.2 172.328 534.406 195.35C576.954 241.544 605.246 285.545 597.597 293.628C589.949 301.712 549.256 270.818 506.708 224.625C486.073 202.222 468.791 180.335 457.336 162.811C460.63 183.859 462.649 212.255 462.649 243.5C462.649 308.393 453.938 361 443.194 361C432.449 361 423.738 308.393 423.738 243.5C423.738 211.382 425.872 182.274 429.33 161.064C417.797 178.68 400.153 200.858 379.03 223.552C336.256 269.506 295.413 300.171 287.804 292.044C280.195 283.918 308.702 240.077 351.476 194.123C372.03 172.04 392.138 153.488 408.327 141.074C388.75 144.53 362.519 146.643 333.691 146.643C273.11 146.643 224 137.312 224 125.802C224 114.292 273.11 104.962 333.691 104.962C363.344 104.962 390.249 107.197 409.991 110.83C393.719 98.3269 373.356 79.3959 352.548 56.806C310 10.6125 281.708 -33.3881 289.357 -41.4719C297.005 -49.5558 337.698 -18.6618 380.246 27.5318C400.552 49.5769 417.61 71.1225 429.065 88.5032C425.835 67.5264 423.859 39.4068 423.859 8.5C423.859 -56.3935 432.569 -109 443.314 -109C454.059 -109 462.769 -56.3935 462.769 8.5Z"
        // fill="#B0845C"
        fill={colorPallet.secondary}
        fill-opacity="0.07"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M216.7 58.8526C216.7 57.7283 216.628 56.7052 216.511 55.941C216.932 56.5715 217.556 57.3515 218.298 58.149C219.858 59.8245 221.347 60.9426 221.624 60.6462C221.902 60.3499 220.863 58.7515 219.303 57.0759C218.54 56.2561 217.794 55.5698 217.198 55.1172C217.92 55.2522 218.91 55.3356 220.003 55.3356C222.212 55.3356 224.003 54.9954 224.003 54.5757C224.003 54.156 222.212 53.8158 220.003 53.8158C218.907 53.8158 217.914 53.8996 217.191 54.0354C217.79 53.5799 218.546 52.8793 219.319 52.0401C220.871 50.3559 221.902 48.7516 221.623 48.4568C221.344 48.1621 219.861 49.2885 218.309 50.9727C217.557 51.7897 216.927 52.5879 216.509 53.2269C216.629 52.4595 216.703 51.424 216.703 50.2846C216.703 47.9186 216.385 46.0005 215.993 46.0005C215.602 46.0005 215.284 47.9186 215.284 50.2846C215.284 51.4564 215.362 52.5183 215.488 53.2918C215.068 52.6492 214.424 51.8399 213.653 51.0118C212.094 49.3363 210.604 48.2182 210.327 48.5145C210.05 48.8108 211.089 50.4093 212.648 52.0848C213.398 52.8901 214.131 53.5666 214.722 54.0192C214.008 53.8931 213.051 53.816 211.999 53.816C209.791 53.816 208 54.1562 208 54.5759C208 54.9956 209.791 55.3357 211.999 55.3357C213.077 55.3357 214.055 55.2548 214.774 55.1232C214.181 55.5791 213.439 56.2687 212.681 57.0914C211.13 58.7756 210.098 60.3799 210.377 60.6747C210.656 60.9694 212.14 59.843 213.691 58.1587C214.431 57.3551 215.053 56.5696 215.471 55.936C215.353 56.7008 215.281 57.7259 215.281 58.8526C215.281 61.2186 215.599 63.1367 215.991 63.1367C216.382 63.1367 216.7 61.2186 216.7 58.8526Z"
        fill={colorPallet.secondary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M135.7 172.853C135.7 171.728 135.628 170.705 135.511 169.941C135.932 170.571 136.556 171.351 137.298 172.149C138.858 173.824 140.347 174.943 140.624 174.646C140.902 174.35 139.863 172.751 138.303 171.076C137.54 170.256 136.794 169.57 136.198 169.117C136.92 169.252 137.91 169.336 139.003 169.336C141.212 169.336 143.003 168.995 143.003 168.576C143.003 168.156 141.212 167.816 139.003 167.816C137.907 167.816 136.914 167.9 136.191 168.035C136.79 167.58 137.546 166.879 138.319 166.04C139.871 164.356 140.902 162.752 140.623 162.457C140.344 162.162 138.861 163.288 137.309 164.973C136.557 165.79 135.927 166.588 135.509 167.227C135.629 166.459 135.703 165.424 135.703 164.285C135.703 161.919 135.385 160.001 134.993 160.001C134.602 160.001 134.284 161.919 134.284 164.285C134.284 165.456 134.362 166.518 134.488 167.292C134.068 166.649 133.424 165.84 132.653 165.012C131.094 163.336 129.604 162.218 129.327 162.515C129.05 162.811 130.089 164.409 131.648 166.085C132.398 166.89 133.131 167.567 133.722 168.019C133.008 167.893 132.051 167.816 130.999 167.816C128.791 167.816 127 168.156 127 168.576C127 168.996 128.791 169.336 130.999 169.336C132.077 169.336 133.055 169.255 133.774 169.123C133.181 169.579 132.439 170.269 131.681 171.091C130.13 172.776 129.098 174.38 129.377 174.675C129.656 174.969 131.14 173.843 132.691 172.159C133.431 171.355 134.053 170.57 134.471 169.936C134.353 170.701 134.281 171.726 134.281 172.853C134.281 175.219 134.599 177.137 134.991 177.137C135.382 177.137 135.7 175.219 135.7 172.853Z"
        fill={colorPallet.secondary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.69988 108.853C5.69988 107.728 5.62818 106.705 5.51088 105.941C5.9316 106.571 6.55604 107.351 7.29833 108.149C8.85789 109.824 10.3471 110.943 10.6245 110.646C10.9019 110.35 9.86254 108.751 8.30298 107.076C7.53989 106.256 6.79365 105.57 6.19797 105.117C6.91987 105.252 7.91045 105.336 9.00344 105.336C11.2122 105.336 13.0028 104.995 13.0028 104.576C13.0028 104.156 11.2122 103.816 9.00344 103.816C7.90718 103.816 6.91394 103.9 6.1915 104.035C6.7902 103.58 7.54635 102.879 8.31929 102.04C9.87063 100.356 10.9022 98.7516 10.6233 98.4568C10.3444 98.1621 8.86074 99.2885 7.3094 100.973C6.55688 101.79 5.92667 102.588 5.509 103.227C5.62911 102.459 5.70276 101.424 5.70276 100.285C5.70276 97.9186 5.38517 96.0005 4.99341 96.0005C4.60164 96.0005 4.28405 97.9186 4.28405 100.285C4.28405 101.456 4.36195 102.518 4.48817 103.292C4.06768 102.649 3.42394 101.84 2.65311 101.012C1.09355 99.3363 -0.395627 98.2182 -0.673053 98.5145C-0.950479 98.8108 0.0888991 100.409 1.64846 102.085C2.39803 102.89 3.13133 103.567 3.72166 104.019C3.00775 103.893 2.05097 103.816 0.999397 103.816C-1.20941 103.816 -3 104.156 -3 104.576C-3 104.996 -1.20941 105.336 0.999397 105.336C2.0767 105.336 3.05452 105.255 3.77362 105.123C3.18067 105.579 2.43905 106.269 1.68129 107.091C0.129943 108.776 -0.901598 110.38 -0.622726 110.675C-0.343853 110.969 1.13983 109.843 2.69117 108.159C3.43141 107.355 4.05329 106.57 4.47094 105.936C4.35319 106.701 4.28117 107.726 4.28117 108.853C4.28117 111.219 4.59876 113.137 4.99052 113.137C5.38229 113.137 5.69988 111.219 5.69988 108.853Z"
        fill={colorPallet.secondary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M250.7 543.853C250.7 542.728 250.628 541.705 250.511 540.941C250.932 541.571 251.556 542.351 252.298 543.149C253.858 544.824 255.347 545.943 255.624 545.646C255.902 545.35 254.863 543.751 253.303 542.076C252.54 541.256 251.794 540.57 251.198 540.117C251.92 540.252 252.91 540.336 254.003 540.336C256.212 540.336 258.003 539.995 258.003 539.576C258.003 539.156 256.212 538.816 254.003 538.816C252.907 538.816 251.914 538.9 251.191 539.035C251.79 538.58 252.546 537.879 253.319 537.04C254.871 535.356 255.902 533.752 255.623 533.457C255.344 533.162 253.861 534.288 252.309 535.973C251.557 536.79 250.927 537.588 250.509 538.227C250.629 537.459 250.703 536.424 250.703 535.285C250.703 532.919 250.385 531.001 249.993 531.001C249.602 531.001 249.284 532.919 249.284 535.285C249.284 536.456 249.362 537.518 249.488 538.292C249.068 537.649 248.424 536.84 247.653 536.012C246.094 534.336 244.604 533.218 244.327 533.515C244.05 533.811 245.089 535.409 246.648 537.085C247.398 537.89 248.131 538.567 248.722 539.019C248.008 538.893 247.051 538.816 245.999 538.816C243.791 538.816 242 539.156 242 539.576C242 539.996 243.791 540.336 245.999 540.336C247.077 540.336 248.055 540.255 248.774 540.123C248.181 540.579 247.439 541.269 246.681 542.091C245.13 543.776 244.098 545.38 244.377 545.675C244.656 545.969 246.14 544.843 247.691 543.159C248.431 542.355 249.053 541.57 249.471 540.936C249.353 541.701 249.281 542.726 249.281 543.853C249.281 546.219 249.599 548.137 249.991 548.137C250.382 548.137 250.7 546.219 250.7 543.853Z"
        fill={colorPallet.secondary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M494.7 461.853C494.7 460.728 494.628 459.705 494.511 458.941C494.932 459.571 495.556 460.351 496.298 461.149C497.858 462.824 499.347 463.943 499.624 463.646C499.902 463.35 498.863 461.751 497.303 460.076C496.54 459.256 495.794 458.57 495.198 458.117C495.92 458.252 496.91 458.336 498.003 458.336C500.212 458.336 502.003 457.995 502.003 457.576C502.003 457.156 500.212 456.816 498.003 456.816C496.907 456.816 495.914 456.9 495.191 457.035C495.79 456.58 496.546 455.879 497.319 455.04C498.871 453.356 499.902 451.752 499.623 451.457C499.344 451.162 497.861 452.288 496.309 453.973C495.557 454.79 494.927 455.588 494.509 456.227C494.629 455.459 494.703 454.424 494.703 453.285C494.703 450.919 494.385 449.001 493.993 449.001C493.602 449.001 493.284 450.919 493.284 453.285C493.284 454.456 493.362 455.518 493.488 456.292C493.068 455.649 492.424 454.84 491.653 454.012C490.094 452.336 488.604 451.218 488.327 451.515C488.05 451.811 489.089 453.409 490.648 455.085C491.398 455.89 492.131 456.567 492.722 457.019C492.008 456.893 491.051 456.816 489.999 456.816C487.791 456.816 486 457.156 486 457.576C486 457.996 487.791 458.336 489.999 458.336C491.077 458.336 492.055 458.255 492.774 458.123C492.181 458.579 491.439 459.269 490.681 460.091C489.13 461.776 488.098 463.38 488.377 463.675C488.656 463.969 490.14 462.843 491.691 461.159C492.431 460.355 493.053 459.57 493.471 458.936C493.353 459.701 493.281 460.726 493.281 461.853C493.281 464.219 493.599 466.137 493.991 466.137C494.382 466.137 494.7 464.219 494.7 461.853Z"
        fill={colorPallet.secondary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M433.7 630.853C433.7 629.728 433.628 628.705 433.511 627.941C433.932 628.571 434.556 629.351 435.298 630.149C436.858 631.824 438.347 632.943 438.624 632.646C438.902 632.35 437.863 630.751 436.303 629.076C435.54 628.256 434.794 627.57 434.198 627.117C434.92 627.252 435.91 627.336 437.003 627.336C439.212 627.336 441.003 626.995 441.003 626.576C441.003 626.156 439.212 625.816 437.003 625.816C435.907 625.816 434.914 625.9 434.191 626.035C434.79 625.58 435.546 624.879 436.319 624.04C437.871 622.356 438.902 620.752 438.623 620.457C438.344 620.162 436.861 621.288 435.309 622.973C434.557 623.79 433.927 624.588 433.509 625.227C433.629 624.459 433.703 623.424 433.703 622.285C433.703 619.919 433.385 618.001 432.993 618.001C432.602 618.001 432.284 619.919 432.284 622.285C432.284 623.456 432.362 624.518 432.488 625.292C432.068 624.649 431.424 623.84 430.653 623.012C429.094 621.336 427.604 620.218 427.327 620.515C427.05 620.811 428.089 622.409 429.648 624.085C430.398 624.89 431.131 625.567 431.722 626.019C431.008 625.893 430.051 625.816 428.999 625.816C426.791 625.816 425 626.156 425 626.576C425 626.996 426.791 627.336 428.999 627.336C430.077 627.336 431.055 627.255 431.774 627.123C431.181 627.579 430.439 628.269 429.681 629.091C428.13 630.776 427.098 632.38 427.377 632.675C427.656 632.969 429.14 631.843 430.691 630.159C431.431 629.355 432.053 628.57 432.471 627.936C432.353 628.701 432.281 629.726 432.281 630.853C432.281 633.219 432.599 635.137 432.991 635.137C433.382 635.137 433.7 633.219 433.7 630.853Z"
        fill={colorPallet.secondary}
      />
      {/* </g> */}
    </svg>
  );
};

export default BackgroundTemplate2;
