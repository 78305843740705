import React from "react";
import { AiOutlineSend } from "react-icons/ai";

const ContentWriteArea = ({
  inputValue,
  setInputValue,
  aiRecommendation,
  scrollToBottom,
  approvedGroup,
}) => {
  return (
    <div className="flex items-start gap-2 px-8">
      <textarea
        className="w-full p-4 rounded-lg drop-shadow-md"
        placeholder="Select template or type a message ..."
        value={inputValue}
        disabled={!approvedGroup}
        onChange={(e) => {
          setInputValue(e.target.value);
          // setisInputTextAvailable(true);
        }}
      />
      <button
        disabled={!approvedGroup}
        onClick={async (e) => {
          setInputValue(e.target.value);
          await aiRecommendation(inputValue, false);
          setInputValue("");
          scrollToBottom();
        }}
        className="w-32 text-white rounded-lg h-14 bg-triklAccentBlue"
      >
        <div className="flex justify-center gap-1 font-semibold align-middle">
          Ask
          <AiOutlineSend className="w-5 h-5" />
        </div>
      </button>
    </div>
  );
};

export default ContentWriteArea;
