import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useFinalContentStore } from "../../ZustandStores/LinkedInZustandStore";
import logoWithText from "../../../../assets/Trikl_logo/logoWithText.svg";
import { BsWhatsapp } from "react-icons/bs";

const PublicContentShowcase = () => {
  const { id: contentId } = useParams();
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [urlId, setUrlId] = useState(null);
  const [number, setNumber] = useState("");

  useEffect(() => {
    async function getContentById(param) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVERURL}/share/get/${param}`
        );

        setText(response.data.text);
        setImage(response.data.image);
        setUrlId(contentId);
      } catch (error) {
        console.error("Error fetching content: ", error);
      }
    }

    if (contentId) {
      getContentById(contentId);
    }
  }, [contentId]);

  const handleStoreWhatsAppNumbers = async (
    objectIdParam,
    whatsAppNumberParam
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/share/storeWhatsAppNumbers`,
        {
          id: objectIdParam,
          whatsAppNumber: whatsAppNumberParam,
        }
      );
    } catch (error) {
      console.error("Error storing whatsapp number: ", error);
    }
  };
  return (
    <>
      {/* <Helmet>
        <title>TRIKL - 10X Your Brand Voice</title>
        <meta name="description" content={text} />
        <meta property="og:image" content={image} />
      </Helmet> */}

      <Helmet>
        {/* Primary Meta Tags */}
        <title> TRIKL - 10x Your Brand Voice</title>
        <meta name="title" content="TRIKL - Your LinkedIn Post Is Ready" />
        <meta name="description" content={text} />

        {/* Open Graph / Facebook / WhatsApp */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={window.location.pathname + window.location.search}
        />
        <meta
          property="og:title"
          content="TRIKL - Your LinkedIn Post Is Ready"
        />
        <meta property="og:description" content={text} />
        <meta property="og:image" content={image} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={window.location.pathname + window.location.search}
        />
        <meta
          property="twitter:title"
          content="TRIKL - Your LinkedIn Post Is Ready"
        />
        <meta property="twitter:description" content={text} />
        <meta property="twitter:image" content={image} />
      </Helmet>

      <div>
        {/* DISPLAY COMPONENT */}
        <main className="flex flex-col items-center justify-start px-10 pb-32 md:px-0">
          {/* TRIKL LOGO */}
          <div className="flex justify-center w-full py-10 pb-0 md:pb-10">
            {/* <img src={logoWithText} alt="TRIKL logo" className="w-32 h-max" /> */}
          </div>
          <h1 className="pt-20 text-2xl font-semibold md:pt-10 md:text-4xl">
            Your Post is Ready!
          </h1>

          <div className="flex flex-col w-full gap-3 mt-10 md:w-1/3">
            <input
              type="Number"
              className="w-full p-4 text-lg text-center bg-white border border-gray-300 rounded-md shadow-sm min-w-max shadow-gray-400"
              value={number}
              placeholder={"Enter Whatsapp Number"}
              onChange={(event) => setNumber(event.target.value)}
            />
            <a
              href={`https://wa.me/${number}?text=https://app.trikl.ai/linkedin/share/${urlId}`}
              as="div"
              target="_blank"
              className="w-full pb-6 md:w-auto"
              onClick={(event) => {
                // event.preventDefault();
                handleStoreWhatsAppNumbers(urlId, number);
              }}
            >
              <div className="flex items-center justify-center w-full gap-3 p-4 font-semibold rounded-md min-w-max bg-triklDarkAccentBlue/30">
                <BsWhatsapp />
                <div>Share on WhatsApp</div>
              </div>
            </a>
          </div>

          {/* CONTENT CARD */}
          <section className="flex flex-col items-center justify-start w-full my-4 bg-white rounded-md shadow-md md:w-1/3 h-max">
            {/* IMAGE SECTION    */}
            <img
              as="div"
              className="w-full rounded-t-md"
              src={
                image
                  ? image
                  : "https://res.cloudinary.com/dqg7jgqvq/image/upload/v1699767047/test/zrnspeknzdtutc6slj5p.png"
              }
            />

            {/* TEXT SECTION */}
            <main
              dangerouslySetInnerHTML={{ __html: text }}
              className="p-4 text-left md:p-10"
            ></main>

            {/* CTA */}
            <a
              as="div"
              href="https://trikl.ai/"
              target="_blank"
              className="fixed bottom-0 z-50 flex items-center justify-center w-full py-1 text-sm font-semibold bg-triklDarkAccentBlue"
            >
              Know more about TRIKL
            </a>
          </section>
        </main>
      </div>
    </>
  );
};

export default PublicContentShowcase;
