import React, { useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { BsCloudUpload } from "react-icons/bs";
import { Spin } from "antd";
import {
  useFinalContentStore,
  useCreatedImages,
} from "../../ZustandStores/LinkedInZustandStore";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import {
  uploadImageToCloudinary,
  storeGeneratedOrUploadedImg,
} from "../../function/LinkedInFunctions";

import { UploadIcons } from "../../../../assets/Canvas";
import axios from "axios";
import { userMediaFilesStore } from "../../ZustandStores/UserMediaStore";
const MediaFileUpload = () => {
  // ZUSTAND
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setUserMediaFiles } = userMediaFilesStore();
  const { userImagesArray, setUserImagesArray } = useCreatedImages();

  // CONTEXT
  let { userData } = useLinkedInUserContext();

  // LOCAL STATE HANDLERS
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);

  const dropRef = useRef(null);
  // MEDIA SELECTION LOGIC
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];
    // File size validation
    let maxSize;
    switch (fileType) {
      case "image":
        maxSize = 10 * 1024 * 1024; // 10MB in bytes
        break;
      case "video":
        maxSize = 200 * 1024 * 1024; // 200MB in bytes
        break;
      case "application":
        maxSize = 100 * 1024 * 1024; // 100MB in bytes
        break;
      default:
        alert("Unsupported file type.");
        return; // Stop further execution
    }

    if (file.size > maxSize) {
      alert(`File size should not exceed ${maxSize / (1024 * 1024)}MB.`);
      return; // Stop further execution
    }

    setSelectedImage(file);

    // Handling different file types
    switch (fileType) {
      case "image":
        await handleMediaUpload(file);
        break;
      case "application":
        // Assuming pdf and ppt fall under 'application' for simplicity

        await handleMediaUpload(file);
        setSelectedImage(file);

        break;
      case "video":
        await handleMediaUpload(file);
        break;
      default:
        // Handle unknown type or reset
        setSelectedImage("");
        break;
    }
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  // UPLOAD PROGRESS
  const onUploadProgress = (progress) => {
    setUploadProgress(progress);
  };

  const handleMediaUpload = async (file) => {
    let mediaUrl = "";
    let mediaType = "";
    setUploadingMedia(true);
    const form = new FormData();
    form.append("file", file);
    form.append("userId", userData.userId);
    const fileType = file.type.split("/")[0];

    try {
      if (fileType === "image") {
        const imageBuffer = await file.arrayBuffer();
        mediaUrl = await uploadImageToCloudinary(imageBuffer, onUploadProgress);

        mediaType = "image";
        setUploadProgress(100);
        setFinalContent({
          ...finalContent,
          finalMediaUrl: mediaUrl,
          finalMediaType: "image",
        });
      } else if (["application", "video"].includes(fileType)) {
        form.append(
          "fileType",
          fileType === "application" ? "document" : "video"
        );
        const response = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userMain/v2/upload-media`,
          form
        );

        mediaUrl = response?.data?.mediaUrl;
        mediaType = fileType === "application" ? "document" : "video";
        setSelectedImage(file);
        setFinalContent({
          ...finalContent,
          finalMediaUrl: mediaUrl,
          finalMediaType: mediaType,
        });
      }

      // Storing Uploaded ImgURL & UserId
      const imgURL = mediaUrl;
      const userInput = "";
      const userId = userData.userId;
      const imgType = "uploads";
      await storeGeneratedOrUploadedImg(
        userId,
        userInput,
        imgURL,
        imgType,
        setUserImagesArray,
        mediaType,
        setUserMediaFiles
      );
      setUploadingMedia(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadingMedia(false);
    }
  };

  // const handleImageUpload = async (file) => {
  //   try {
  //     const imageBuffer = await file.arrayBuffer();
  //     const imageUrl = await uploadImageToCloudinary(
  //       imageBuffer,
  //       onUploadProgress
  //     );
  //     setUploadProgress(100);
  //     setFinalContent({
  //       ...finalContent,
  //       finalMediaUrl: imageUrl,
  //     });

  //     // Storing Uploaded ImgURL & UserId
  //     const imgURL = imageUrl;
  //     const userInput = "";
  //     const userId = userData.userId;
  //     const imgType = "uploads";

  //     await storeGeneratedOrUploadedImg(
  //       userId,
  //       userInput,
  //       imgURL,
  //       imgType,
  //       setUserImagesArray
  //     );
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   }
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    setSelectedImage(file);
    await handleMediaUpload(file);
    // setFinalContent.finalMediaUrl(file)
  };

  return (
    <main className="flex flex-col items-center w-full gap-5 pt-10">
      <Spin spinning={uploadingMedia} fullscreen />

      <h2 className="mb-2 text-lg font-bold text-left">
        Upload Image, Pdf, or Video
      </h2>

      {/* image upload */}
      <section
        className={`flex relative flex-col items-center justify-center w-full  border rounded-lg cursor-pointer ${
          isDragOver
            ? "border-blue-500 bg-blue-200"
            : "border-gray-300 bg-transparent"
        } hover:bg-white`}
      >
        {selectedImage ? (
          <>
            <BsCloudUpload className="w-10 h-10 mt-3 text-triklAccentBlue" />

            <div className="px-16 py-4 text-base font-spaceGrotesk">
              {selectedImage.name}
            </div>
            <div className="text-xs font-inter">
              size:{" "}
              {selectedImage.size / 1024 < 1024
                ? `${Math.round(selectedImage.size / 1024)} KB`
                : `${(selectedImage.size / 1024 / 1024).toFixed(2)} MB`}
            </div>
            <button
              className="absolute top-0 pt-10 text-2xl text-gray-400 right-10 hover:text-red-500"
              onClick={handleImageRemove}
            >
              <MdDelete />
            </button>
          </>
        ) : (
          <>
            <label
              className={`flex flex-col items-center justify-center w-full py-16 border rounded-lg cursor-pointer ${
                isDragOver
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-300 bg-transparent"
              } hover:bg-white`}
              ref={dropRef}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              htmlFor="dropzone-file"
            >
              <div className="flex flex-col items-center justify-center gap-3">
                <div className="text-5xl text-triklAccentBlue">
                  {/* <BsCloudUpload /> */}
                  <img src={UploadIcons} width={200} alt="upload icon" />
                </div>
                <p className="text-lg font-spaceGrotesk">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs font-inter">
                  SVG, PNG, JPG ,PDF, PPT, MP4
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleImageChange}
                accept="image/jpeg, image/png, video/mp4, .mp4, application/pdf, .pdf,.docx,.ppt"
              />
            </label>
          </>
        )}
        {/* upload button */}

        {/* attachedFile.fileType =="image" */}

        <div className={`w-full${selectedImage ? "" : "hidden"}`}>
          {selectedImage && (
            <div className="flex flex-col items-center w-full gap-5 pt-4">
              <ProgressBar uploadProgress={uploadProgress} />
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default MediaFileUpload;

const ProgressBar = ({ uploadProgress }) => {
  return (
    uploadProgress > 0 &&
    uploadProgress < 100 && (
      <div className="relative flex flex-col w-full px-10 pt-3 border-t border-gray-300">
        <div className="inline-block px-2 py-1 text-xs font-semibold uppercase rounded-full text-triklAccentBlue bg-triklAccentBlue-200">
          Uploading...
        </div>

        <div className="flex w-full text-right">
          <span className="inline-block text-xs font-semibold text-triklAccentBlue">
            {uploadProgress}%
          </span>
        </div>

        <div className="flex h-2 mb-4 overflow-hidden text-xs rounded bg-triklAccentBlue-200">
          <div
            style={{ width: `${uploadProgress}%` }}
            className="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-triklAccentBlue"
          ></div>
        </div>
      </div>
    )
  );
};
