const V2BackgroundTemplate16 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3536)">
        <path
          d="M272.044 330.607C271.63 329.957 271.334 329.188 270.861 328.596C222.305 275.545 173.749 222.435 125.193 169.384C84.5027 125.027 43.8716 80.6109 3.29975 36.1356C2.59004 35.3668 2.1169 34.1248 2.1169 33.0602C1.99861 22.0597 2.05775 11.0591 2.05775 0.0585938C27.43 0.0585938 52.8613 0.0585938 78.2927 0.0585938C78.5884 0.768306 78.825 1.53716 79.239 2.24687C90.0029 20.5811 100.826 38.8562 111.649 57.1313C161.27 141.173 210.891 225.274 260.511 309.316C264.651 316.354 268.91 323.333 273.109 330.371L273.05 330.252C272.695 330.371 272.34 330.43 271.985 330.548L272.044 330.607Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M275.123 330.725C274.945 330.607 274.768 330.489 274.59 330.371L274.531 330.193L274.59 330.075C275.064 329.424 275.537 328.774 275.951 328.064C289.79 303.579 303.57 279.153 317.41 254.668C364.842 170.508 412.275 86.4069 459.766 2.24687C460.18 1.53716 460.417 0.768306 460.772 0.0585938C485.138 0.0585938 509.505 0.0585938 533.872 0.0585938C533.872 12.7743 533.872 25.49 533.872 38.2056C533.281 38.7379 532.63 39.2702 532.157 39.8616C493.004 84.0412 453.852 128.28 414.759 172.519C368.746 224.505 322.792 276.492 276.779 328.419C276.246 329.01 275.892 329.72 275.478 330.371C275.359 330.548 275.241 330.607 275.123 330.725Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M533.872 626.853C522.457 613.901 510.983 601.007 499.569 588.055C451.841 533.94 404.172 479.824 356.503 425.708C329.948 395.605 303.392 365.442 276.837 335.338C276.305 334.747 275.891 334.037 275.418 333.387C276.778 334.274 278.139 335.102 279.44 336.048C364.25 398.858 449.12 461.667 533.931 524.477C533.872 558.602 533.872 592.727 533.872 626.853Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M273.109 330.371C270.329 321.972 267.609 313.574 264.829 305.176C230.763 203.923 196.638 102.73 162.571 1.47802C162.394 1.00488 162.216 0.531735 162.039 0.0585938C186.406 0.0585938 210.773 0.0585938 235.139 0.0585938C235.967 6.74172 236.795 13.3657 237.564 20.0488C239.398 35.8991 241.231 51.6902 243.065 67.5404C244.898 83.3906 246.731 99.2409 248.565 115.032C250.398 130.941 252.291 146.851 254.124 162.76C255.958 178.67 257.791 194.579 259.625 210.488C261.754 228.882 263.942 247.275 266.071 265.668C268.496 286.487 270.862 307.305 273.286 328.123C273.346 328.774 273.819 329.424 274.114 330.075C274.233 330.134 274.292 330.134 274.41 330.193L274.469 330.371C273.996 330.311 273.464 330.311 272.991 330.252C273.05 330.252 273.109 330.371 273.109 330.371Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M274.471 330.194C274.352 330.194 274.293 330.134 274.175 330.075C274.175 328.952 274.116 327.887 274.234 326.763C276.008 307.601 277.783 288.38 279.616 269.218C281.745 246.27 283.933 223.382 286.122 200.435C288.133 178.966 290.084 157.497 292.036 136.028C294.165 113.081 296.353 90.1926 298.542 67.2452C300.671 44.8302 302.741 22.4151 304.811 0C329.059 0 353.249 0 377.497 0C372.766 15.1997 368.094 30.3993 363.303 45.599C345.324 103.145 327.285 160.691 309.306 218.296C297.891 254.787 286.477 291.337 275.062 327.887C274.826 328.597 274.766 329.307 274.589 330.016L274.471 330.194Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M275.476 330.431C282.219 325.522 288.961 320.672 295.644 315.704C374.54 257.448 453.496 199.252 532.392 140.997C532.865 140.642 533.398 140.405 533.871 140.109C533.871 164.949 533.871 189.73 533.871 214.57C533.043 214.925 532.215 215.221 531.446 215.575C446.872 253.722 362.298 291.87 277.724 330.076C277.073 330.371 276.541 330.845 275.95 331.199C275.654 331.022 275.358 330.845 275.062 330.726C275.24 330.608 275.358 330.549 275.476 330.431Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M533.871 449.957C521.273 444.221 508.676 438.425 496.078 432.688C423.274 399.686 350.469 366.684 277.723 333.624C277.073 333.328 276.541 332.855 276.008 332.5L275.949 332.559C276.541 332.618 277.132 332.559 277.723 332.677C313.209 340.366 348.754 348.055 384.239 355.802C425.521 364.733 466.803 373.663 508.025 382.653C516.66 384.546 525.295 386.497 533.93 388.39C533.871 408.912 533.871 429.435 533.871 449.957Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M275.949 331.139C280.621 330.193 285.353 329.365 290.025 328.359C338.463 317.95 386.901 307.482 435.339 297.073C467.867 290.094 500.336 283.115 532.865 276.136C533.22 276.077 533.575 275.9 533.929 275.781C533.929 294.529 533.929 313.337 533.929 332.085C533.279 332.085 532.628 332.144 531.919 332.144C499.686 332.144 467.394 332.085 435.161 332.085C382.938 332.026 330.774 331.967 278.551 331.908C277.664 331.908 276.836 332.322 275.949 332.558L276.008 332.499C275.712 332.676 275.417 332.795 275.121 332.972C275.003 332.972 274.825 332.972 274.707 332.972L274.766 333.031C275.121 332.381 275.535 331.789 275.949 331.139Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M272.991 334.215C271.808 337.705 270.625 341.135 269.502 344.624C233.543 450.667 197.584 556.769 161.625 662.812C161.211 663.995 160.975 665 159.2 665C131.936 664.941 104.671 664.941 77.4061 664.941C77.1103 664.941 76.8146 664.882 76.1641 664.823C141.694 554.403 207.106 444.22 272.518 333.979C272.695 334.038 272.814 334.156 272.991 334.215Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M274.59 333.86C275.004 334.451 275.536 334.924 275.832 335.575C334.62 440.317 393.467 544.999 452.254 649.741C455.034 654.709 457.814 659.618 460.653 664.822C432.737 664.822 405.059 664.822 377.084 664.822C375.428 659.559 373.772 654.177 372.116 648.795C349.346 575.221 326.517 501.766 303.688 428.252C294.166 397.616 284.644 366.98 275.181 336.344C274.945 335.516 274.826 334.629 274.649 333.801L274.59 333.86Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M271.986 333.801C182.149 431.564 92.3109 529.267 2.05914 627.503C2.05914 626.202 2.05914 625.433 2.05914 624.664C2.05914 592.609 2.05914 560.554 2 528.498C2 526.665 2.47314 525.6 4.01085 524.536C44.1096 495.97 84.1492 467.345 124.189 438.72C172.804 404.003 221.36 369.286 269.976 334.57C270.567 334.156 271.158 333.801 271.75 333.387C271.809 333.564 271.927 333.682 271.986 333.801Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M274.647 333.802C274.469 334.689 274.174 335.576 274.233 336.463C275.12 346.754 276.066 357.045 277.012 367.335C278.314 381.293 279.556 395.251 280.857 409.268C282.276 424.763 283.696 440.317 285.115 455.813C286.357 469.652 287.599 483.433 288.841 497.272C290.201 512.59 291.621 527.849 293.04 543.167C294.282 557.006 295.524 570.786 296.825 584.626C298.245 600.121 299.664 615.676 301.084 631.171C302.089 642.349 303.094 653.527 304.1 664.882C280.798 664.882 257.673 664.882 234.43 664.882C235.967 652.048 237.446 639.274 238.984 626.558C240.521 613.487 242.059 600.476 243.597 587.405C245.194 574.157 246.731 560.85 248.328 547.602C249.866 534.473 251.404 521.343 252.941 508.213C254.479 495.084 256.076 481.954 257.614 468.883C259.151 455.695 260.689 442.506 262.286 429.317C264.119 413.822 265.953 398.385 267.786 382.949C269.62 367.335 271.453 351.722 273.346 336.108C273.464 335.103 274.233 334.097 274.706 333.151L274.647 333.092C274.647 333.388 274.647 333.683 274.588 333.979C274.588 333.861 274.647 333.802 274.647 333.802Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M272.046 330.607V330.548C271.277 330.371 270.508 330.371 269.798 330.016C202.849 300.504 135.899 271.051 69.0087 241.538C47.3033 232.016 25.6571 222.435 4.01085 212.913C2.76886 212.381 2 211.908 2 210.252C2.05914 186.24 2.05914 162.228 2.05914 138.216C2.05914 137.92 2.05914 137.625 2.11829 137.033C2.76885 137.447 3.30114 137.684 3.83342 138.039C70.3098 185.708 136.727 233.436 203.144 281.105C226.092 297.665 249.039 314.106 272.046 330.607Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M272.58 332.382C182.506 371.83 92.4906 411.278 2.17969 450.785C2.17969 429.908 2.17969 409.267 2.17969 388.449C92.254 369.701 182.447 350.893 272.58 332.086C272.58 332.204 272.58 332.263 272.58 332.382Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
        <path
          d="M2.17633 274.6C91.8958 293.525 181.497 312.392 271.039 331.317C271.039 331.436 270.98 331.495 270.98 331.613C181.379 331.613 91.8366 331.613 2.11719 331.613C2.17633 312.51 2.17633 293.703 2.17633 274.6Z"
          fill={colorPallet?.secondary}
          fill-opacity="0.04"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate16;
