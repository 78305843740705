import { Button, Input, Drawer } from "antd";
import React from "react";
import CarouselMain from "./CarouselMain";

import CarouselSlides from "./CarouselSlides";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import CarouselPreview from "./CarouselPreview";
import CarouselCustomizationArea from "./CarouselCustomizationArea";
import CarouselTopicSelection from "./CarouselTopicSelection";

const AdvancedCarousel = ({ setIsDrawerOpen, setSelectedMediaType }) => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  return (
    // <Drawer
    //   title="Profile Analysis"
    //   placement="right"
    //   closable={false}
    //   size="large"
    //   width="80%"
    //   onClose={closeDrawer}
    //   visible={isDrawerOpen}
    //   style={{
    //     backgroundColor: "#f3f2ef",
    //     boxShadow: "0 4px 6px rgba(255, 255, 255, 0.5)",
    //   }}
    // >
    <section className="grid grid-cols-12 gap-4 ">
      <div className="col-span-10 col-start-2 ">
        {!carouselStates?.showPreview ? (
          <>
            {/* Title and Input Section */}
            <CarouselTopicSelection />

            {/* Carousel Main Area and Settings */}
            <div className="flex flex-col gap-4 pt-10">
              <div className="flex items-center h-full gap-4">
                {/* carousel display */}
                <CarouselMain
                  setIsDrawerOpen={setIsDrawerOpen}
                  setSelectedMediaType={setSelectedMediaType}
                />

                {/* carousel settings */}
                <CarouselCustomizationArea className="w-full" />
              </div>
              {/* Carousel Slides Area */}
              <CarouselSlides />
            </div>
          </>
        ) : (
          <>
            <CarouselPreview />
          </>
        )}
      </div>
    </section>
    // </Drawer>
  );
};

export default AdvancedCarousel;
