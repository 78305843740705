// Font family data
const fontFamilyData = [
  { name: "Arial" },
  { name: "Helvetica" },
  { name: "Times New Roman" },
  { name: "Courier New" },
  { name: "Verdana" },
  { name: "Georgia" },
  { name: "Palatino" },
  { name: "Garamond" },
  { name: "Bookman" },
  { name: "Avant Garde" },
  { name: "Comic Sans MS" },
  { name: "Trebuchet MS" },
  { name: "Arial Black" },
  { name: "Impact" },
  { name: "Charcoal" },
  { name: "Lucida Console" },
  { name: "Lucida Sans Unicode" },
  { name: "Tahoma" },
  { name: "Geneva" },
  { name: "Times" },
  { name: "Monaco" },
  { name: "Optima" },
  { name: "Andale Mono" },
  { name: "Candara" },
  { name: "Futura" },
  { name: "Didot" },
  { name: "Bodoni MT" },
  { name: "Franklin Gothic Medium" },
  { name: "Corsiva" },
  { name: "Rockwell" },
  { name: "Cambria" },
  { name: "Consolas" },
  { name: "Copperplate" },
  { name: "Gill Sans" },
  { name: "Brush Script MT" },
  { name: "Lobster" },
  { name: "Baskerville" },
  { name: "Century Gothic" },
  { name: "Cooper" },
  { name: "Bell MT" },
  { name: "Avenir" },
  { name: "Broadway" },
  { name: "Castellar" },
  { name: "Chiller" },
  { name: "Big Caslon" },
  { name: "Bauhaus 93" },
  { name: "Bradley Hand" },
];

export { fontFamilyData };
