// main

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { useMainLoaderStore } from "../linkedIn/ZustandStores/AppLoaderStore";

const ProtectedRoute = ({ children }) => {
  const { setIsMainLoaderRunning } = useMainLoaderStore();
  const { user } = useUserAuth();

  useEffect(() => {
    // Ensure `user` is treated as an object, even if it's null/undefined
    const userExists = Object.keys(user || {}).length > 0;
    setIsMainLoaderRunning(!userExists);
  }, [user, setIsMainLoaderRunning]);

  if (!user) {
    return <Navigate to="/user/signup" />;
  }

  return children;
};

export default ProtectedRoute;
