import { EditOutlined, RightOutlined, StarOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Form, Input, Space, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { BsDot } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../context/userAuthContext";

import DisplayGeneratedPosts from "../components/onBoarding/DisplayGeneratedPosts";
import MainAppLoader from "../components/loadingScreens/MainAppLoader";
import { useNavigate } from "react-router-dom";
import {
  useUserLinkedInStore,
  userLinkedInDataRefreshStore,
} from "../ZustandStores/UserLinkedInStore";
import OnBoardingLoader from "../components/loadingScreens/OnBoardingLoader";
import { triklPurple } from "../../../assets/Trikl_logo";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./onboardingForm.css";
import { toast } from "react-toastify";
import { useMainLoaderStore } from "../ZustandStores/AppLoaderStore";
import { displayLinebrakeContent } from "../../function/ConvertHtmlToFormattedText";

const OnBoardingForm = () => {
  // Zustand stores
  const { userLinkedInData, setUserLinkedInData } = useUserLinkedInStore();
  const { userLinkedinRefresh, setUserLinkedinRefresh } =
    userLinkedInDataRefreshStore();
  const { isMainLoaderRunning, setIsMainLoaderRunning } = useMainLoaderStore();
  // Context hook
  const { user, resendVerificationEmail } = useUserAuth();
  // Local state
  const [currentStep, setCurrentStep] = useState(0);

  const [generatedPosts, setGeneratedPosts] = useState([]);

  const VisionSuggestion = [
    {
      title: " Personal branding",
      content:
        "I want to post on LinkedIn to become an influencer within my professional field. By sharing my insights, experiences, and the latest trends, I aim to establish myself as a thought leader. This platform allows me to reach a wide audience of professionals who are equally passionate about my area of expertise.",
    },
    {
      title: " Get Customers",
      content:
        "I want to leverage LinkedIn to attract potential clients in my industry - [[Your Industry Name]]. By sharing valuable solutions, case studies, and industry trends, I aim to position myself as a trusted resource. I want to connect with a targeted audience of decision-makers facing challenges I can solve",
    },
    {
      title: " Knowledge sharing",
      content:
        "I'm passionate about sharing knowledge and empowering others in my field. LinkedIn provides a fantastic platform to connect with a broad network of professionals. By contributing valuable insights, experiences, and current industry trends, I hope to foster a space for collective learning and growth.I want to push the boundaries of our knowledge together!",
    },
    {
      title: " Thought leadership",
      content:
        "I want to share my unique insights, industry experience, and analysis of emerging trends. By consistently providing valuable content, I want to build credibility and position myself as a go-to resource within my field.  Engaging with a network of passionate professionals will  fuel new ideas and foster meaningful discussions that move the industry forward.",
    },
    {
      title: " Networking",
      content:
        "I'm actively building my network on LinkedIn to connect with inspiring professionals in [your industry].  I believe collaboration and knowledge sharing are key to growth, I want to exchange ideas and learn from one another",
    },
    {
      title: " Job search",
      content:
        "I want to showcase my skills and experience by sharing accomplishments, industry insights, and engaging content. I am building a network and want to connect with recruiters and hiring managers.",
    },
  ];

  // topics for linkedin fro step 2
  const topics = [
    "Career Growth Tips",
    "Industry Trends",
    "Work-Life Balance",
    "Leadership Lessons",
    "Innovation and Creativity",
    "Future of Work",
    "Product Launches",
    "Company Achievements",
    "Customer Success Stories",
    "Your Professional Journey",
    "Skills Showcase",
    "Networking Stories",
    "Technical Insights",
    "Marketing Strategies",
    "Sales Techniques",
    "Webinars and Workshops",
    "E-books and Resources",
    "Case Studies and Research",
    "Community Involvement",
    "Sustainability Efforts",
    "Employee Stories",
    "Industry Events",
    "Workplace Culture",
    "Book and Podcast Recommendations",
  ];
  //   Tags selection STATE
  const [form] = Form.useForm();
  const [isCreatingContent, setIsCreatingContent] = useState(false);
  const [storingUserVision, setStoringUserVision] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [formData, setFormData] = useState({
    linkedInProfileLink: "",
    topics: [],
    vision: VisionSuggestion[0].content,
    userTags: "",
    isLinkedInProfileLinkValid: true,
  });
  const navigate = useNavigate();

  // Functions

  // handle continue button click event
  const handleNext = async () => {
    if (currentStep === 0) {
      await storeUserVision();
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 1) {
      if (selectedTopics.length >= 3) {
        setCurrentStep(currentStep + 1);
      } else {
        toast.error("Please select at least 3 topics");
      }
    } else if (currentStep === 2) {
      if (selectedTemplates.length === 3) {
        setCurrentStep(currentStep + 1);
      } else {
        toast.error("Please select 3 templates");
      }
    } else if (currentStep === 3) {
      await form.validateFields(["url"]).then(async () => {
        await getUserLinkedInDetails();
        // setCurrentStep(currentStep + 1);
      });
    } else if (currentStep < 6 - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // handle continue button click event
  const handleBack = async () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  //  handle topics selection
  const toggleTopicSelection = (topic) => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(topic)) {
        return prevSelectedTopics.filter((t) => t !== topic);
      } else {
        return [...prevSelectedTopics, topic];
      }
    });
  };

  // --------------------
  // FUNCTIONS :
  // Get UserLinkedIn Details
  const getUserLinkedInDetails = async () => {
    setIsCreatingContent(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/get-linkedIn-profile`,
        {
          userId: user?.uid,
          profileLink: formData.linkedInProfileLink,
          selectedTopics: selectedTopics,
          selectedTemplates: selectedTemplates,
        }
      );
      if (!response?.data?.success || !response) {
        navigate("/user/profile");
      } else {
        window.location.reload();
        setIsCreatingContent(false);
        setUserLinkedinRefresh(!userLinkedinRefresh);
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
      setIsCreatingContent(false);
      window.location.reload();
    }
  };

  // store user vision
  const storeUserVision = async () => {
    setStoringUserVision(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/update-user-vision`,
        {
          userId: user?.uid,
          vision: formData.vision,
        }
      );

      setStoringUserVision(false);
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
      setStoringUserVision(false);
    }
  };

  // Display Ui conponent based on the current step
  const renderFormSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="grid items-center justify-start h-screen grid-cols-12 gap-10">
              <div className="col-span-5 col-start-3">
                <p className="text-sm text-left text-darkGrey">Step 1/4</p>

                <div className="flex-col w-full gap-3 ">
                  <p className="text-2xl text-left">
                    What's Your Goal On LinkedIn ?
                  </p>

                  <Card className="shadow-lg min-h-[37vh] mt-3">
                    <div className="flex justify-end pb-6 text-xs text-linkedInPeach">
                      <span className="pr-2 italic">Click on text to Edit</span>{" "}
                      <EditOutlined />
                    </div>
                    <div
                      contentEditable
                      className="pr-8 font-normal text-left"
                      style={{ outline: "none" }}
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          vision: e.target.innerHTML,
                        });
                      }}
                      dangerouslySetInnerHTML={{ __html: formData?.vision }}
                    ></div>
                  </Card>

                  {/* <div className="flex flex-row gap-2"> */}

                  <Button
                    className="flex items-center justify-center w-32 gap-2 mt-6 rounded-full "
                    type="primary"
                    size="large"
                    onClick={handleNext}
                  >
                    <span>Continue</span>
                    <FaArrowRight />
                  </Button>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-span-3 ">
                <p className="text-left text-md">Suggestions </p>

                <div className="flex flex-col gap-2 px-2 py-4 mt-3 rounded-md bg-gradient-to-r from-linkedInPeach/10 via-linkedInBase/5 to-lightGrey border-linkedInPeach">
                  {VisionSuggestion.map((suggestion, index) => (
                    <button
                      className="flex items-center justify-start m-1 cursor-pointer"
                      key={index}
                      onClick={() => {
                        setFormData({
                          ...formData,
                          vision: suggestion?.content,
                        });
                      }}
                    >
                      <div className="flex items-center justify-center ">
                        {formData.vision === suggestion.content ? (
                          <IoMdCheckmark className="w-6 h-6 p-1 text-linkedInPeach" />
                        ) : (
                          <BsDot className="w-6 h-6 " />
                        )}
                        <p
                          className={`text-darkGrey font-normal  ${
                            formData.vision === suggestion.content
                              ? "text-linkedInPeach font-semibold"
                              : ""
                          }`}
                        >
                          {suggestion.title}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <div className="grid items-center justify-start h-screen grid-cols-12 gap-10">
            <div className="col-span-6 col-start-4">
              <p className="flex text-sm text-left text-darkGrey">Step 2/4</p>

              {/* TOPIC Headers */}
              <p className="text-2xl text-left">
                Select Topics That Interests You The Most
              </p>

              <div className="relative flex flex-wrap gap-2 p-8 pb-12 my-4 bg-white rounded-md shadow-lg">
                <Button
                  className="absolute text-xs italic bottom-2 right-2"
                  type="link"
                  onClick={() => setSelectedTopics([])}
                  danger
                  size="small"
                >
                  clear Selection
                </Button>
                {topics.map((topic, index) => (
                  <Tag
                    key={index}
                    color={
                      selectedTopics.includes(topic) ? "purple" : "default"
                    }
                    onClick={() => toggleTopicSelection(topic)}
                    style={{ cursor: "pointer" }}
                  >
                    {topic}
                  </Tag>
                ))}
              </div>

              <div className="flex items-center justify-start w-1/2 gap-2 mt-4">
                {currentStep > 0 && (
                  <Button
                    className="flex items-center justify-center w-auto gap-2 rounded-full"
                    type="text"
                    // size="large"
                    onClick={handleBack}
                  >
                    <FaArrowLeft className="text-tNewInactiveTextAndIcons" />
                  </Button>
                )}
                <Button
                  className="flex items-center justify-center w-32 gap-2 rounded-full "
                  type="primary"
                  size="large"
                  onClick={handleNext}
                >
                  <span>Continue</span>
                  <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="grid items-center justify-start h-screen grid-cols-12 gap-10">
            <div className="col-span-10 col-start-2">
              <p className="flex w-1/2 text-sm text-left text-darkGrey">
                Step 3/4
              </p>
              <p className="pb-2 text-2xl font-semibold text-left">
                Select 3 templates
              </p>
              <div className="flex gap-7 h-[60vh] p-4 shadow-lg rounded-md bg-white overflow-scroll">
                {columns.map((column, colIndex) => (
                  <section
                    key={colIndex}
                    className="flex flex-col w-full gap-7"
                  >
                    {column?.map((item, itemIndex) => (
                      <main className="w-full" key={itemIndex}>
                        <EachCard
                          item={item}
                          itemIndex={itemIndex}
                          setSelectedTemplates={setSelectedTemplates}
                          selectedTemplates={selectedTemplates}
                        />
                      </main>
                    ))}
                  </section>
                ))}
              </div>
              <div className="flex flex-row items-center justify-start w-1/2 gap-2 mt-4">
                {currentStep > 0 && (
                  <Button
                    className="flex items-center justify-center w-auto gap-2 rounded-full"
                    type="text"
                    // className="flex items-center justify-center w-auto gap-2 rounded-full "
                    // type="primary"
                    // size="large"
                    onClick={handleBack}
                  >
                    <FaArrowLeft className="text-tNewInactiveTextAndIcons" />
                  </Button>
                )}
                <Button
                  className="flex items-center justify-center w-32 gap-2 rounded-full "
                  type="primary"
                  size="large"
                  onClick={handleNext}
                >
                  <span>Continue</span>
                  <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="grid items-center justify-start h-screen grid-cols-12 gap-10">
            <div className="col-span-6 col-start-4">
              <p className="flex text-sm text-left text-darkGrey">
                Step 4/4: Just One Last Step
              </p>
              <Form
                form={form}
                layout="vertical"
                className="flex flex-col mt-3 "
              >
                <Form.Item
                  name="url"
                  label={
                    <p className="text-2xl font-semibold">Your LinkedIn URL</p>
                  }
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            new Error("Please enter your LinkedIn profile URL!")
                          );
                        }
                      },
                    },
                    {
                      pattern:
                        /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9\-_]+\/?$/,
                      message: "Enter a valid LinkedIn profile link.",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    autoComplete="off"
                    placeholder="https://www.linkedin.com/in/username/"
                    className="w-full my-2 shadow-md border-lightGrey hover:border-lightGrey focus:border-lightGrey"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        linkedInProfileLink: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Form>

              <div className="flex flex-row items-center justify-start w-1/2 gap-2">
                {currentStep > 0 && (
                  <Button
                    className="flex items-center justify-center w-auto gap-2 rounded-full"
                    type="text"
                    // className="flex items-center justify-center w-auto gap-2 rounded-full "
                    // type="primary"
                    // size="large"
                    onClick={handleBack}
                  >
                    <FaArrowLeft className="text-tNewInactiveTextAndIcons" />
                  </Button>
                )}
                <Button
                  className="flex items-center justify-center gap-2 rounded-full "
                  type="primary"
                  size="large"
                  onClick={handleNext}
                >
                  <span>Train my AI</span>
                  <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <DisplayGeneratedPosts
            setGeneratedPosts={setGeneratedPosts}
            generatedPosts={generatedPosts}
          />
        );
    }
  };

  // Fetch user profile data
  const userProfileData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/get-user-linkedIn`,
        {
          userId: user?.uid,
        }
      );

      setUserLinkedInData(response.data?.user);
    } catch (error) {
      // toast.error("oops! something went wrong. Please try again.");
      // console.log("error in userProfileData", get-user-linkedIn);
    }
  };

  // If user already has a onboarding suggestion posts then skip the onboarding process
  useEffect(() => {
    if (
      userLinkedInData?.additionalDetails?.onboardingSuggestedPosts?.length > 0
    ) {
      setGeneratedPosts(
        userLinkedInData?.additionalDetails?.onboardingSuggestedPosts
      );
      setCurrentStep(4);
    }
  }, [userLinkedInData]);

  // ************************************* Temapltes Code ******************************************* //
  // function to get the templates from DB
  const getTemplatesData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVERURL}/template/getTemplates`
      );
      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const divideDataIntoColumns = (inputData) => {
    const columns = [[], [], []];
    inputData.forEach((item, index) => {
      columns[index % 3].push(item);
    });
    return columns;
  };

  const columns = divideDataIntoColumns(templates);

  useEffect(() => {
    getTemplatesData();
  }, []);

  // ************************************* Temapltes Code ******************************************* //

  useEffect(() => {
    if (user) {
      userProfileData();
    }
    // Display the loader till the user data is fetched
    const userExists = Object.keys(user || {}).length > 0;
    setIsMainLoaderRunning(!userExists);
  }, [user, userLinkedinRefresh]);

  return isCreatingContent ? (
    <OnBoardingLoader />
  ) : (
    <>
      {isMainLoaderRunning ? (
        <MainAppLoader />
      ) : (
        <>
          <img className="absolute top-8 left-8" src={triklPurple} />
          {renderFormSteps(currentStep)}
        </>
      )}
    </>
  );
};

export default OnBoardingForm;

// CARD COMPONENT:
const EachCard = ({
  item,
  itemIndex,
  setSelectedTemplates,
  selectedTemplates,
}) => {
  const [contentHidden, setContentHidden] = useState(true);

  return (
    <Card
      as="button"
      style={{ padding: "0px" }}
      className={`w-full border cursor-pointer ${
        selectedTemplates.includes(item._id)
          ? "border-triklAccentBlue bg-white"
          : "border-gray-200 border bg-gray-100/40"
      } rounded-md p-7`}
      onClick={() => {
        if (selectedTemplates.includes(item._id)) {
          setSelectedTemplates((prev) => prev.filter((id) => id !== item._id));
        } else {
          if (selectedTemplates.length < 3) {
            setSelectedTemplates((prev) => [...prev, item._id]);
          } else {
            toast.error("You can only select 3 templates");
          }
        }
      }}
    >
      <header className="flex items-center justify-between w-full pb-2 border-b text-blac borderblack/50">
        <div className="flex items-center justify-between w-full text-[0.7rem] text-gray-500">
          <p>{item.numLikes} Likes</p>
          <p>{item.numComments} Comments</p>
          <p>{item.numReposts} Reposts</p>
        </div>
      </header>
      <main className="flex flex-col justify-start pt-5 item-start ">
        <p
          dangerouslySetInnerHTML={{
            __html: contentHidden
              ? displayLinebrakeContent(item?.postContent?.slice(0, 255))
              : displayLinebrakeContent(item?.postContent),
          }}
        ></p>
        <span>
          <button
            onClick={() => {
              setContentHidden(!contentHidden);
            }}
            className="pt-4 text-xs font-semibold text-tNewButtonsAndActiveElement"
          >
            {contentHidden ? "...see more" : "...see less"}
          </button>
        </span>
      </main>
    </Card>
  );
};
