import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateCalenderModal from "./CreateCalenderModal";
import axios from "axios";
import { CalendarDataRefreshStore } from "../ZustandStores/ContentCalendarStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useUserMainDataStore } from "../ZustandStores/UserLinkedInStore";
import {
  useContentUrlStore,
  useFinalContentStore,
  useOverallStepStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
} from "../ZustandStores/LinkedInZustandStore";

const ContentCalendar = () => {
  const { calendarDataRefresh, setCalendarDataRefresh } =
    CalendarDataRefreshStore();
  const { userMainData } = useUserMainDataStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { setCurrentUrl } = useContentUrlStore();
  const { setOverallStep } = useOverallStepStore();
  const { setSelectedDraftId } = useSavedDraftIdStore();
  const { setSelectedScheduledPostId } = useScheduledPostIdStore();
  const [isOpen, setIsOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);

  let navigate = useNavigate();

  const getCalendars = async () => {
    // API call to get all the calendars
    // /get-calendar
    const response = await axios.get(
      `${process.env.REACT_APP_SERVERURL}/contentCalendar/get-calendars?userId=${userMainData?.userId}`
    );
    const data = response?.data?.userCalendarData?.reverse();
    setCalendarData(data);

    if (data && data.length > 0) {
      setSelectedCalendar(data[0]);
    }

    try {
    } catch (error) {
      toast.error("oops! something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getCalendars();
  }, [userMainData?.userId, calendarDataRefresh]);

  const handleCalendarClick = (calendar) => {
    setSelectedCalendar(calendar);
  };

  const stateUpdateFunction = (record) => {
    setCurrentUrl(record.name);
    setOverallStep(1);
    navigate("/linkedin/content-creation", {
      state: { recordName: record.name },
    });
    setSelectedDraftId("");
    setSelectedScheduledPostId("");
    setFinalContent({
      ...finalContent,
      finalText: "",
      mediaUrl: "",
      mediaType: "",
    });
  };

  const transformData = (originalData) => {
    return originalData?.map((item) => {
      const topicChildren = item?.topicSuggestion?.flatMap((suggestion) =>
        Object.entries(suggestion)?.map(([key, value], index) => ({
          key: `${item._id}-${index}`,
          name: value,
        }))
      );

      return {
        key: item._id,
        day: `${item.day} - ${item.category}`,
        children: topicChildren,
      };
    });
  };

  const transformedData = transformData(selectedCalendar?.calendarContent);

  const handleCopy = (record) => {
    try {
      navigator.clipboard.writeText(record.name);
      toast.success("Copied to clipboard");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
      toast.error("Unable to copy to clipboard");
    }
  };

  const handleGenerate = (record) => {
    if (finalContent?.finalText?.length > 0) {
      setSelectedRecord(record);
      setWarningModal(true);
    } else {
      stateUpdateFunction(record);
    }
  };

  const columns = [
    {
      title: "Day and Topics",
      key: "day",
      render: (text, record) => {
        if (record.day) {
          return record.day;
        }
        return record.name;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        if (!record.children) {
          return (
            <div className="flex gap-2">
              <Button onClick={() => handleGenerate(record)}>
                Generate Post
              </Button>
              <Button
                type="text"
                className="flex items-center justify-center text-black/40"
                onClick={() => handleCopy(record)}
              >
                <CopyOutlined />
              </Button>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const handleExpand = (expanded, record) => {
    const currentKey = record.key;
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, currentKey]
      : expandedRowKeys.filter((key) => key !== currentKey);
    setExpandedRowKeys(newExpandedRowKeys);
  };

  useEffect(() => {
    if (transformedData && transformedData.length > 0) {
      const firstRowKey = transformedData[0].key;
      if (!expandedRowKeys.includes(firstRowKey)) {
        setExpandedRowKeys([firstRowKey]);
      }
    }
  }, [transformedData, expandedRowKeys]);

  return (
    <Layout className="pt-20">
      <CreateCalenderModal isOpen={isOpen} onClose={() => setIsOpen(false)} />

      {/*  */}
      <section className="flex gap-3 p-3">
        <Card>
          <Button
            type="default"
            className="border hover:bg-linkedInPeach border-linkedInPeach text-linkedInPeach"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Content Calendar
          </Button>

          {calendarData?.map((calendar, index) => {
            return (
              <div key={index} className="flex flex-col gap-2 my-2">
                <Button
                  type="text"
                  className={`${
                    calendar?.calendarName === selectedCalendar?.calendarName
                      ? "bg-[#F5EAFA] text-[#6F42C1]"
                      : ""
                  }`}
                  onClick={() => {
                    handleCalendarClick(calendar);
                  }}
                >
                  {calendar?.calendarName}
                </Button>
              </div>
            );
          })}
        </Card>

        {/* Display Calendars */}
        <Card className="w-full">
          <h4 className="mb-2 text-lg font-semibold text-left uppercase">
            {selectedCalendar?.calendarName}
          </h4>
          <Table
            pagination={false}
            columns={columns}
            dataSource={transformedData}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleExpand}
          />
        </Card>
      </section>
      {/* </div> */}

      <GeneratePostModal
        isOpen={warningModal}
        onClose={() => setWarningModal(false)}
        // setCurrentUrl={setCurrentUrl}
        record={selectedRecord}
        // setOverallStep={setOverallStep}
        stateUpdateFunction={stateUpdateFunction}
      />
    </Layout>
  );
};

export default ContentCalendar;

const GeneratePostModal = ({
  isOpen,
  onClose,
  record,
  stateUpdateFunction,
}) => {
  return (
    <>
      <Modal
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        // width={"80vw"}
        footer={null}
      >
        <main className="flex flex-col items-center justify-center w-full h-full gap-4 text-lg text-left">
          <span className="text-red-600"> Are you sure? </span>
          This will discard your current changes
          <Button
            className="w-2/3 bg-triklAccentBlue"
            type="primary"
            onClick={() => {
              stateUpdateFunction(record);
            }}
          >
            Yes, I'm sure
          </Button>
        </main>
      </Modal>
    </>
  );
};
