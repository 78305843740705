import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";

import { auth } from "../firebase-config";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [emailVerified, setEmailVerified] = useState(
    user?.emailVerified || false
  );
  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function signUp(email, password, name) {
    return await createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        // Update user profile with the display name
        updateProfile(userCredential.user, {
          displayName: name,
        });

        // Send verification email
        sendEmailVerification(userCredential.user).then(() => {});
        return userCredential;
      }
    );
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function checkUserExists(email) {
    return fetchSignInMethodsForEmail(auth, email);
  }

  function resendVerificationEmail() {
    if (user && !user.emailVerified) {
      sendEmailVerification(user)
        .then(() => {
          alert("Verification email resent. Please check your inbox.");
        })
        .catch((error) => {
          console.error("Error resending verification email: ", error);
          alert("Error resending verification email. Please try again later.");
        });
    }
  }

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
  //     setUser(currentuser);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setEmailVerified(currentUser?.emailVerified || false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        signIn,
        signUp,
        resetPassword,
        logOut,
        googleSignIn,
        checkUserExists,
        resendVerificationEmail,
        emailVerified,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
