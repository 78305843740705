import React, { useEffect, useState } from "react";
import {
  useUserLinkedInStore,
  useUserMainDataStore,
} from "../ZustandStores/UserLinkedInStore";
import {
  useAiResponseStore,
  useFinalContentStore,
  useIsAiPromptRunningStore,
} from "../ZustandStores/LinkedInZustandStore";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useUserAuth } from "../../../context/userAuthContext";

const UserTemplatePost = () => {
  const { setAiResponse } = useAiResponseStore();
  const { userLinkedInData, setUserLinkedInData } = useUserLinkedInStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userMainData } = useUserMainDataStore();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { isPromptRunning, setIsPromptRunning } = useIsAiPromptRunningStore();
  const [finalTopic, setFinalTopic] = useState();
  const { user } = useUserAuth();

  const promptVariables = {
    finaltone: finalContent.finalTone,
    finalCategory: finalContent.finalCategory,
    finalCharacterLimit: finalContent.finalCharacterLimit,
    finalTopic: finalTopic,
  };

  const handleCreatePostFromUserTemplate = async () => {
    setIsPromptRunning(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/custom-linkedIn-post`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userMainData?.userId,
            promptVariables: promptVariables,
            selectedAIModel: "fourTurbo",
          }),
        }
      );

      if (response.status === 429) {
        toast.error(
          "Text limit reached, check your profile page for more details 🐹"
        );
        return;
      }

      if (!response.ok) {
        toast.error("Something went wrong, please try again! 🐹");
      }

      const reader = response.body.getReader();
      let aiAnswer = "";
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        aiAnswer += new TextDecoder().decode(value, { stream: true });
        const formattedAnswer = aiAnswer.replace(/\n/g, "<br>");
        setIsPromptRunning(false);
        setAiResponse(formattedAnswer);
        setFinalContent({
          ...finalContent,
          finalText: formattedAnswer,
        });
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
      }
    } finally {
      setIsPromptRunning(false);
    }
  };
  const defaultUserTopics = [
    "Leadership",
    "Networking",
    "Career Development",
    "Job Opportunities",
    "Industry Trends",
    "Remote Work",
    "Work-Life Balance",
    "Entrepreneurship",
    "Diversity and Inclusion",
    "Personal Branding",
    "Skills Training",
    "Recruitment",
    "Technology",
    "Marketing",
    "Sales Strategies",
  ];

  const userTemplates = userLinkedInData?.writingStyles || [];

  const userTopics =
    userLinkedInData?.postTopics && userLinkedInData.postTopics.length > 0
      ? userLinkedInData.postTopics
      : defaultUserTopics;

  const handleTopicSelect = async () => {
    const userTemplateIndex = Math.floor(Math.random() * userTemplates.length);

    const writingStyleFinalCategory = userTemplates[userTemplateIndex].tone;
    const writingStyleFinalTone =
      userTemplates[userTemplateIndex].tone_description;
    const writingStyleFinalCharacterLength =
      userTemplates[userTemplateIndex].characterLength;

    setFinalContent({
      ...finalContent,
      finalCategory: writingStyleFinalCategory,
      finalTone: writingStyleFinalTone,
      finalCharacterLimit: Number(writingStyleFinalCharacterLength),
    });
  };

  return (
    <>
      <div className="flex flex-wrap justify-center w-4/5 gap-3 mx-auto">
        {userTopics?.map((eachTopic, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedIndex(index); // Set the selected index
              setFinalTopic(eachTopic);
              handleTopicSelect();
            }}
            className={`
        flex items-center gap-2 px-2 py-1 border border-gray-300 rounded-md w-max
        transition-all duration-300 ease-in-out

        ${
          selectedIndex === index
            ? "bg-tNewPrimaryColor border-tNewButtonsAndActiveElement text-tNewButtonsAndActiveElementDark"
            : ""
        }
        `}
          >
            {eachTopic}
          </button>
        ))}
      </div>
      <div className="">
        <Button
          type="primary"
          disabled={!finalTopic || !user?.emailVerified}
          size="large"
          className="mt-10 text-white w-72 bg-triklAccentBlue "
          onClick={handleCreatePostFromUserTemplate}
        >
          Create Post
        </Button>
      </div>
    </>
  );
};

export default UserTemplatePost;
