import React, { useState } from "react";
import axios from "axios";
import FabricCanvas from "./FabricCanvas";
import {
  useCreatedImages,
  useFinalContentStore,
} from "../../ZustandStores/LinkedInZustandStore";
import { useCanvasImageUrlStore } from "../../ZustandStores/CanvasEditorStore";
import { BeatLoader } from "react-spinners";
import { storeGeneratedOrUploadedImg } from "../../function/LinkedInFunctions";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { userMediaFilesStore } from "../../ZustandStores/UserMediaStore";

const ImageModal = ({ isEditImage, setIsEditImage }) => {
  const { finalContent } = useFinalContentStore();
  const { setUserImagesArray } = useCreatedImages();
  const { canvasImageUrl } = useCanvasImageUrlStore();
  const { setUserMediaFiles } = userMediaFilesStore();
  const [isSaving, setIsSaving] = useState(false);
  const { userData } = useLinkedInUserContext();
  const handlesave = async () => {
    setIsSaving(true);
    const res = await axios.post(
      `${process.env.REACT_APP_SERVERURL}/textToImage/uploadImage`,
      {
        canvasUrl: canvasImageUrl,
      }
    );

    const imgURL = res.data;
    const userInput = "";
    const userId = userData.userId;
    const imgType = "uploads";
    const mediaType = "image";
    await storeGeneratedOrUploadedImg(
      userId,
      userInput,
      imgURL,
      imgType,
      setUserImagesArray,
      mediaType,
      setUserMediaFiles
    );

    setIsSaving(false);
    setIsEditImage(false);
  };

  if (!isEditImage) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute w-full h-full bg-gray-600 opacity-50 modal-overlay"></div>
      <div className="z-50 px-6 py-3 overflow-y-auto bg-gray-200 rounded shadow-lg modal-container">
        <div className="w-full h-full ">
          <FabricCanvas finalImageURL={finalContent.finalMediaUrl} />
        </div>
        <div className="flex justify-center gap-4 mt-4 text-right text-white ">
          <button
            className="p-3 rounded bg-triklAccentBlue"
            onClick={() => {
              setIsEditImage(false);
            }}
          >
            Discard
          </button>

          <button
            className="p-3 rounded bg-triklAccentBlue"
            onClick={handlesave}
          >
            {isSaving ? (
              <div className="flex items-center justify-center w-full ">
                <BeatLoader color="#fff" size={8} />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
