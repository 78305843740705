const V2BackgroundTemplate7 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3476)">
        <rect x="465" width="20" height="665" fill={colorPallet?.secondary} />
        <rect
          x="535"
          y="50"
          width="20"
          height="535"
          transform="rotate(90 535 50)"
          fill={colorPallet?.secondary}
        />
        <rect
          x="280"
          y="594"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="280"
          y="200"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="160"
          y="513"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="160"
          y="120"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="40"
          y="595"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="40"
          y="200"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="400"
          y="514"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
        <rect
          x="400"
          y="120"
          width="20"
          height="20"
          fill={colorPallet?.secondary}
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate7;
