import { create } from "zustand";

const usePostTemplateStore = create((set) => ({
  selectedPostTemplate: "",
  setSelectedPostTemplate: (newState) => {
    set(() => ({ selectedPostTemplate: newState }));
  },
}));

export { usePostTemplateStore };
