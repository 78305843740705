import React, { useEffect, useState } from "react";
import { usePostTemplateStore } from "../../ZustandStores/PostTemplateStore";
import { toast } from "react-toastify";
import axios from "axios";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import { Button, Card, Form, Input, Menu, Radio, Select } from "antd";
import {
  AppstoreOutlined,
  LoadingOutlined,
  MailOutlined,
  StarFilled,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { displayLinebrakeContent } from "../../../function/ConvertHtmlToFormattedText";
import TextArea from "antd/es/input/TextArea";
import { UserDataRefreshStore } from "../../ZustandStores/LinkedInZustandStore";
import bannerBg from "../../../../assets/bannerBg.png";
import { LoadingSpinnerLarge } from "../../../components/widgets/LoadingSpinner";

const TemplatesDisplay = () => {
  const [selected, setSelected] = useState([]);
  const [topic, setTopic] = useState(false);
  const [data, setData] = useState([]);
  const { userLinkedInData } = useUserLinkedInStore();
  const [showCustomInput, setShowCustomInput] = useState(false); // State to control visibility of custom input
  const [bookmarkedTemplates, setBookmarkedTemplates] = useState([]); // State to store bookmarked templates
  const [showBookmarkedTemplates, setShowBookmarkedTemplates] = useState(false); // State to control visibility of bookmarked templates
  const [personalTemplates, setPersonalTemplates] = useState([]); // State to store personal templates
  const [showPersonalTemplates, setShowPersonalTemplates] = useState(false); // State to control visibility of personal templates
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const items = [
    {
      key: "sub1",
      label: "Post Templates",
      icon: <MailOutlined />,
      children: [
        {
          key: "1",
          label: "Show All",
        },
        // {
        //   key: "2",
        //   label: "Announcement",
        // },
        // {
        //   key: "3",
        //   label: "Blog Repurpose",
        // },
        // {
        //   key: "4",
        //   label: "Brand Story",
        // },
        // {
        //   key: "5",
        //   label: "Experience",
        // },
        // {
        //   key: "6",
        //   label: "Expertise",
        // },
        // {
        //   key: "7",
        //   label: "Insightful",
        // },
        // {
        //   key: "8",
        //   label: "Learning",
        // },
        // {
        //   key: "9",
        //   label: "Motivation",
        // },
        // {
        //   key: "10",
        //   label: "Story",
        // },
        // {
        //   key: "11",
        //   label: "Tips",
        // },
        // {
        //   key: "12",
        //   label: "Webinar",
        // },
      ],
    },
    {
      key: "bookmark",
      label: "Bookmarked",
      icon: <AppstoreOutlined />,
    },
    {
      key: "personal",
      label: "Personal",
      icon: <UserOutlined />,
    },
    // {
    //   key: "sub2",
    //   label: "Brand Template",
    //   icon: <AppstoreOutlined />,
    //   children: [
    //     {
    //       key: "13",
    //       label: "Show All",
    //     },
    //     {
    //       key: "14",
    //       label: "Share Tips",
    //     },
    //     {
    //       key: "15",
    //       label: "Myth Buster",
    //     },
    //     {
    //       key: "16",
    //       label: "Expertise",
    //     },
    //     {
    //       key: "17",
    //       label: "Experience",
    //     },
    //     {
    //       key: "18",
    //       label: "Announcement",
    //     },
    //   ],
    // },
    // {
    //   key: "custom template",
    //   label: "Create from post",
    //   icon: <AppstoreOutlined />,
    // },
  ];

  const getData = async () => {
    setIsLoadingTemplates(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVERURL}/template/getTemplates`
      );
      const data = await response.json();
      setData(data);
      setIsLoadingTemplates(false);
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
      setIsLoadingTemplates(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const divideDataIntoColumns = (inputData) => {
    const columns = [[], [], []];
    inputData.forEach((item, index) => {
      columns[index % 3].push(item);
    });
    return columns;
  };

  const columns = topic
    ? divideDataIntoColumns(selected)
    : divideDataIntoColumns(data);

  const bookmarkedColumns = divideDataIntoColumns(bookmarkedTemplates);
  const personalColumns = divideDataIntoColumns(personalTemplates);

  const handleSelectedOption = async (selectedTopic, isBrand = null) => {
    setSelected([]);
    if (selectedTopic === "custom") {
      setShowCustomInput(true);
      setTopic(false);
      setShowBookmarkedTemplates(false);
      setShowPersonalTemplates(false);
    } else if (selectedTopic === "Bookmarked") {
      try {
        setShowBookmarkedTemplates(true);
        setShowCustomInput(false);
        setShowPersonalTemplates(false);

        const templateIds = userLinkedInData.bookmarked_templates;
        if (templateIds.length > 0) {
          const templatePromises = templateIds.map(async (templateId) => {
            const response = await axios.get(
              `${process.env.REACT_APP_SERVERURL}/template/getTemplateById?id=${templateId}`
            );
            return response.data;
          });
          const templates = await Promise.all(templatePromises);
          setBookmarkedTemplates(templates);
        }
      } catch (error) {
        toast.error("Failed to fetch bookmarked templates. Please try again.");
      }
    } else if (selectedTopic === "Personal") {
      try {
        setShowPersonalTemplates(true);
        setShowBookmarkedTemplates(false);
        setShowCustomInput(false);

        const templateIds = userLinkedInData.personal_templates;
        if (templateIds.length > 0) {
          const templatePromises = templateIds.map(async (templateId) => {
            const response = await axios.get(
              `${process.env.REACT_APP_SERVERURL}/template/getPersonalTemplateById?id=${templateId}`
            );
            return response.data;
          });
          const templates = await Promise.all(templatePromises);
          setPersonalTemplates(templates);
        }
        // setPersonalTemplates(userLinkedInData.personal_templates);
      } catch (error) {
        toast.error("Failed to fetch Personal templates. Please try again.");
      }
    } else if (selectedTopic === "Show All") {
      const selectedData = data?.filter((el) => el.isBrand === isBrand);
      setSelected(selectedData);
      setTopic(true);
      setShowCustomInput(false);
      setShowBookmarkedTemplates(false);
      setShowPersonalTemplates(false);
    } else {
      const selectedData = data?.filter((el) => {
        const matchesCategory =
          el.category.toLowerCase() === selectedTopic.toLowerCase();
        const matchesBrand = isBrand !== null ? el.isBrand === isBrand : true;
        return matchesCategory && matchesBrand;
      });
      setSelected(selectedData);
      setTopic(true);
      setShowCustomInput(false);
      setShowBookmarkedTemplates(false);
    }
  };

  // show bookmarked templates
  const showBookMarkedTemplates = async () => {
    try {
      setShowBookmarkedTemplates(true);
      setShowCustomInput(false);
      setShowPersonalTemplates(false);
      const templateIds = userLinkedInData.bookmarked_templates;
      if (templateIds.length > 0) {
        const templatePromises = templateIds.map(async (templateId) => {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVERURL}/template/getTemplateById?id=${templateId}`
          );
          return response.data;
        });
        const templates = await Promise.all(templatePromises);
        setBookmarkedTemplates(templates);
      }
    } catch (error) {
      toast.error("Failed to fetch bookmarked templates. Please try again.");
    }
  };

  // Show personal templates
  const PersonalTemplates = async () => {
    try {
      setShowPersonalTemplates(true);
      setShowBookmarkedTemplates(false);
      setShowCustomInput(false);

      let templateIds = userLinkedInData.personal_templates;

      if (templateIds.length > 0) {
        const templatePromises = templateIds.map(async (templateId) => {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVERURL}/template/getPersonalTemplateById?id=${templateId}`
          );
          return response.data;
        });
        const templates = await Promise.all(templatePromises);
        setPersonalTemplates(templates);
      }
    } catch (error) {
      toast.error("Failed to fetch Personal templates. Please try again.");
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  const onMenuClick = (e) => {
    let selectedItem;
    let isBrand = false;

    for (const item of items) {
      if (item.children) {
        for (const child of item.children) {
          if (child.key === e.key) {
            selectedItem = child;
            isBrand = item.key === "sub2"; // Set isBrand based on parent menu
            break;
          }
        }
      }
    }

    if (e.key === "bookmark") {
      showBookMarkedTemplates();
    }
    if (e.key === "personal") {
      PersonalTemplates();
    }
    if (e.key === "custom template") {
      setShowCustomInput(true);
      setTopic(false);
      setShowBookmarkedTemplates(false);
      setShowPersonalTemplates(true);
    }

    if (selectedItem) {
      handleSelectedOption(selectedItem.label, isBrand);
    }

    setSelectedKeys([e.key]);
  };

  if (isLoadingTemplates) {
    return (
      <div className="flex items-center justify-center h-[30vh] mb-[10em]">
        <LoadingSpinnerLarge />
      </div>
    );
  }

  return (
    <main className="container flex justify-between gap-4 py-8">
      {/* Button div */}

      {/* menu sidebar */}
      <Menu
        onClick={onMenuClick}
        onOpenChange={onOpenChange}
        style={{
          width: 256,
        }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        openKeys={openKeys}
        mode="inline"
        items={items}
      />

      {/* all template cards */}
      <main className="h-[80vh] flex items-start justify-center  overflow-scroll gap-7 w-full">
        {/* Input box for custom template: */}
        {showCustomInput && <CustomTemplateCreation />}

        {/* Section to render different templates */}
        <div className="grid w-full grid-cols-3 gap-4 p-4 pb-24">
          {showBookmarkedTemplates ? (
            <BookMarkedComp bookmarkedColumns={bookmarkedColumns} />
          ) : showPersonalTemplates ? (
            <PersonalComp personalColumns={personalColumns} />
          ) : (
            <DefaultComp columns={columns} />
          )}
        </div>
      </main>
    </main>
  );
};

export default TemplatesDisplay;

/////////////////////////////

// CARD COMPONENT:
const EachCard = ({ item }) => {
  // ZUSTAND STORES:
  const { selectedPostTemplate, setSelectedPostTemplate } =
    usePostTemplateStore();
  const [contentHidden, setContentHidden] = useState(true);
  const { userLinkedInData } = useUserLinkedInStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const bookmarkedTemplateIdArray = userLinkedInData.bookmarked_templates;
  const personalTemplateArray = userLinkedInData.personal_templates;
  const handleAddToBookmarks = async (templateId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/template/addTemplateToBookmarks`,
        {
          templateId: templateId,
          userId: userLinkedInData?.userId,
        }
      );

      if (response) {
        setUserDataRefresh(!userDataRefresh);
      }
      toast.success(response?.data?.msg);
    } catch (error) {
      console.log(error);
      toast.error("Error in adding template to bookmarks");
    }
  };

  return (
    <Card
      as="button"
      style={{ padding: "0px" }}
      className={`w-full border cursor-pointer ${
        selectedPostTemplate === item._id
          ? "border-triklAccentBlue bg-white"
          : "border-gray-200 border bg-gray-100/40"
      } rounded-md p-7`}
      onClick={() => {
        if (selectedPostTemplate === item._id) {
          setSelectedPostTemplate("");
        } else {
          setSelectedPostTemplate(item._id);
        }
      }}
    >
      <header className="flex items-center justify-between w-full pb-2 border-b text-blac borderblack/50">
        <div className="flex items-center justify-between w-full text-[0.7rem] text-gray-500">
          <p>{item.numLikes} Likes</p>
          <p>{item.numComments} Comments</p>
          <p>{item.numReposts} Reposts</p>
          {bookmarkedTemplateIdArray?.includes(item._id) ? (
            <StarFilled
              className="pl-2 text-sm cursor-pointer text-tNewButtonsAndActiveElement"
              onClick={() => {
                handleAddToBookmarks(item?._id);
              }}
            />
          ) : personalTemplateArray?.includes(item._id) ? (
            <></>
          ) : (
            <StarOutlined
              className="pl-2 text-sm cursor-pointer"
              onClick={() => {
                handleAddToBookmarks(item?._id);
              }}
            />
          )}
        </div>
      </header>
      <main className="pt-5">
        <div
          dangerouslySetInnerHTML={{
            __html: contentHidden
              ? displayLinebrakeContent(item?.postContent?.slice(0, 255))
              : displayLinebrakeContent(item?.postContent),
          }}
        ></div>

        <span>
          <button
            onClick={() => {
              setContentHidden(!contentHidden);
            }}
            className="pt-4 text-xs font-semibold text-tNewButtonsAndActiveElement"
          >
            {contentHidden ? "...see more" : "...see less"}
          </button>
        </span>
      </main>
    </Card>
  );
};

// If a person pastes a post under create from post
const CustomTemplateCreation = () => {
  const [customTemplateText, setCustomTemplateText] = useState("");

  const handleCustomSave = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/template/addCustomTemplate`,
        {
          category: "custom",
          isBrand: false,
          postContent: customTemplateText,
        }
      );

      toast.success("Custom template added successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error in adding custom template");
    }
  };

  // Ant Design form components
  const [form] = Form.useForm();
  const formLayout = "vertical";
  const buttonItemLayout = "vertical";
  // const [formLayout, setFormLayout] = useState("horizontal");
  // const onFormLayoutChange = ({ layout }) => {
  //   setFormLayout(layout);
  // };

  // const formItemLayout =
  //   formLayout === "horizontal"
  //     ? {
  //         labelCol: {
  //           span: 4,
  //         },
  //         wrapperCol: {
  //           span: 14,
  //         },
  //       }
  //     : null;
  // const buttonItemLayout =
  //   formLayout === "horizontal"
  //     ? {
  //         wrapperCol: {
  //           span: 14,
  //           offset: 4,
  //         },
  //       }
  //     : null;

  return (
    <Form
      onSubmitCapture={handleCustomSave}
      layout={formLayout}
      form={form}
      style={{
        width: 600,
      }}
    >
      <Form.Item label="Select a Post Type">
        <Select
          defaultValue="PersonalProfile"
          options={[
            {
              value: "PersonalProfile",
              label: "Personal Profile",
            },
            {
              value: "BrandContent",
              label: "Brand Content",
            },
          ]}
          placeholder="input placeholder"
        />
      </Form.Item>
      <Form.Item label="Enter Inspiration Post">
        <TextArea
          rows={10}
          onChange={(e) => setCustomTemplateText(e.target.value)}
          placeholder="Type or paste a LinkedIn post that inspired you..."
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button type="primary">Submit</Button>
      </Form.Item>
    </Form>
  );
};

const BookMarkedComp = ({ bookmarkedColumns }) => {
  return bookmarkedColumns[0].length > 0 ? (
    <>
      {bookmarkedColumns.map((column, colIndex) => (
        <section key={colIndex}>
          {column.map((item, itemIndex) => (
            <main key={itemIndex}>
              <EachCard item={item} itemIndex={itemIndex} />
            </main>
          ))}
        </section>
      ))}
    </>
  ) : (
    <div
      className="col-span-3 rounded-md shadow-lg"
      style={{
        backgroundImage: `url(${bannerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex items-center justify-center h-24 text-xl font-semibold">
        No bookmarks yet!
      </div>
    </div>
  );
};

const PersonalComp = ({ personalColumns }) => {
  return personalColumns[0].length > 0 ? (
    personalColumns?.map((column, colIndex) => (
      <section key={colIndex} className="flex flex-col gap-7">
        {column?.map((item, itemIndex) => (
          <main key={itemIndex}>
            <EachCard item={item} itemIndex={itemIndex} />
          </main>
        ))}
      </section>
    ))
  ) : (
    <div
      className="col-span-3 rounded-md shadow-lg"
      style={{
        backgroundImage: `url(${bannerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex items-center justify-center h-24 text-xl font-semibold">
        No Personal Templates yet!
      </div>
    </div>
  );
};

const DefaultComp = ({ columns }) => {
  return columns.map((column, colIndex) => (
    <section key={colIndex} className="flex flex-col w-full gap-7">
      {column?.map((item, itemIndex) => (
        <main className="w-full" key={itemIndex}>
          <EachCard item={item} itemIndex={itemIndex} />
        </main>
      ))}
    </section>
  ));
};
