import { create } from "zustand";

// To handle referesh token expiry on frontend
const uselinkedInReconnectModal = create((set) => ({
  linkedInReconnectModalVisible: false,
  setLinkedInReconnectModalVisible: (newState) =>
    set({ linkedInReconnectModalVisible: newState }),
}));

export { uselinkedInReconnectModal };
