import { Button } from "antd";
import React from "react";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import BackgroundDesignSelector from "./BackgroundDesignSelector";

const CarouselPreview = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  return (
    <div className="flex flex-col mt-6">
      {/* show exit & download button */}
      <div className="flex justify-end ">
        {/* <Button
          type="primary"
          size="large"
          className="mr-2 font-bold"
          onClick={() => {
            setCarouselStates({
              ...carouselStates,
              showPreview: false,
              isCreatingPdf: true,
            });
          }}
        >
          Download
        </Button> */}
        <Button
          // type="default"
          type="primary"
          size="large"
          className="font-bold "
          onClick={() => {
            setCarouselStates({
              ...carouselStates,
              showPreview: false,
            });
          }}
        >
          Exit Preview
        </Button>
      </div>
      <h2 className="text-lg font-semibold text-left">Preview</h2>

      {/* show carousel preview here */}
      <div className="flex items-center justify-center ">
        <div className="flex gap-2 p-4 overflow-x-auto ">
          {carouselStates.editedTexts.map((text, index) => (
            <div key={index} className="relative">
              <div
                className="flex flex-col p-2 text-left border rounded shadow-sm "
                style={{
                  minWidth: "400px",
                  maxWidth: "400px",
                  height: "500px",
                  backgroundColor: `${carouselStates.colorPalette.background}`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <BackgroundDesignSelector />

                <div className="flex flex-col justify-center h-full gap-2">
                  {/*Slide number */}
                  {index > 0 && carouselStates.pageNumberVisible && (
                    <div className="absolute bottom-14 right-8">
                      <p
                        style={{
                          color: carouselStates.colorPalette.primary,
                        }}
                      >
                        {index} / {carouselStates?.editedTexts.length - 1}
                      </p>
                    </div>
                  )}
                  {text.titleActive && (
                    <p
                      className="text-3xl font-bold"
                      style={{ color: carouselStates.colorPalette.primary }}
                    >
                      {text.title}
                    </p>
                  )}
                  {text.descriptionActive && (
                    <p
                      className="text-lg"
                      style={{ color: carouselStates.colorPalette.secondary }}
                    >
                      {text.description}
                    </p>
                  )}
                </div>
                {carouselStates.headshotData.visible && (
                  <div className="absolute z-10 flex items-center w-full gap-2 bottom-8 left-8">
                    {carouselStates.headshotData.imageVisible && (
                      <img
                        src={carouselStates.headshotData.image}
                        className="w-6 h-6 rounded-full"
                        alt="headshot"
                      />
                    )}
                    <div>
                      {carouselStates.headshotData.nameVisible && (
                        <h5
                          className="text-sm font-semibold"
                          style={{ color: carouselStates.colorPalette.primary }}
                        >
                          {carouselStates.headshotData.name}
                        </h5>
                      )}
                      {carouselStates.headshotData.profileLinkVisible && (
                        <p
                          className="text-sm"
                          style={{
                            color: carouselStates.colorPalette.secondary,
                          }}
                        >
                          @{carouselStates.headshotData.profileLink}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselPreview;
