import { create } from "zustand";

const useUserLinkedInStore = create((set) => ({
  userLinkedInData: {},
  setUserLinkedInData: (newState) => {
    set(() => ({ userLinkedInData: newState }));
  },
}));

const useUserMainDataStore = create((set) => ({
  userMainData: {},
  setUserMainData: (newState) => {
    set(() => ({ userMainData: newState }));
  },
}));

const userLinkedInDataRefreshStore = create((set) => ({
  userLinkedinRefresh: false,
  setUserLinkedinRefresh: (newState) => {
    set(() => ({ userLinkedinRefresh: newState }));
  },
}));

const userAttachedFilesStore = create((set) => ({
  attachedFile: {
    fileType: "",
    file: null,
  },
  setAttachedFile: (newState) => {
    set((state) => ({
      attachedFile: {
        ...state.attachedFile,
        ...newState,
      },
    }));
  },
}));

export {
  useUserLinkedInStore,
  useUserMainDataStore,
  userLinkedInDataRefreshStore,
  userAttachedFilesStore,
};
