import React, { useEffect, useRef } from "react";
import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
import { Button } from "antd";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Avatar from "boring-avatars";
import BackgroundDesignSelector from "./BackgroundDesignSelector";

const CarouselSlides = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();
  const markdownRefs = useRef([]);

  // Selecting the slide will display it in the carousel main area as the current page
  const handleSelectSlide = (index) => {
    setCarouselStates({ ...carouselStates, currentPage: index });
  };

  // Deleting a slide
  const handleDeleteSlide = (index) => {
    const updatedTexts = carouselStates.editedTexts.filter(
      (_, i) => i !== index
    );
    setCarouselStates({
      ...carouselStates,
      editedTexts: updatedTexts,
      currentPage: Math.max(0, carouselStates.currentPage - 1),
    });
  };

  // Adding a new slide
  const handleAddSlide = () => {
    const newSlide = {
      id: carouselStates.editedTexts.length,
      type: "content",
      title: "New Slide Title",
      titleActive: true,
      description: "New Slide Description",
      descriptionActive: true,
      invisible: false,
    };
    setCarouselStates({
      ...carouselStates,
      editedTexts: [...carouselStates.editedTexts, newSlide],
      currentPage: carouselStates.editedTexts.length,
    });
  };

  useEffect(() => {
    // Initialize the refs array to match the number of slides
    markdownRefs.current = carouselStates.editedTexts.map(
      (_, i) => markdownRefs.current[i]
    );
  }, [carouselStates.editedTexts.length]);

  return (
    <div className="flex gap-2 py-4 overflow-x-auto">
      {carouselStates.editedTexts.map((text, index) => (
        <div
          key={index}
          ref={(el) => (markdownRefs.current[index] = el)}
          className="relative"
        >
          <div
            onClick={() => handleSelectSlide(index)}
            className={`flex flex-col p-2 text-left border rounded shadow-sm cursor-pointer ${
              index === carouselStates.currentPage
                ? "border-triklAccentBlue"
                : ""
            }`}
            style={{
              minWidth: "200px",
              maxWidth: "200px",
              height: "240px",
              backgroundColor: `${carouselStates.colorPalette.background}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <BackgroundDesignSelector />
            <div className="absolute z-20 top-1 right-1 ">
              <Button
                type="text"
                className="text-triklRed"
                icon={<MdDelete />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteSlide(index);
                }}
              />
            </div>
            <div className="flex flex-col justify-center h-full gap-2">
              {/*Slide number */}
              {index > 0 && carouselStates.pageNumberVisible && (
                <div className="absolute bottom-5 right-5 text-[8px]">
                  <p
                    style={{
                      color: carouselStates.colorPalette.primary,
                    }}
                  >
                    {index} / {carouselStates?.editedTexts.length - 1}
                  </p>
                </div>
              )}
              {text.titleActive && (
                <p
                  className="text-xs font-bold"
                  style={{ color: carouselStates.colorPalette.primary }}
                >
                  {text.title}
                </p>
              )}
              {text.descriptionActive && (
                <p
                  className="text-[6px]"
                  style={{ color: carouselStates.colorPalette.secondary }}
                >
                  {text.description}
                </p>
              )}
            </div>
            {carouselStates.headshotData.visible && (
              <div className="flex items-center gap-1">
                {carouselStates?.headshotData?.imageVisible && (
                  <>
                    {carouselStates?.headshotData?.image ? (
                      <img
                        src={carouselStates?.headshotData?.image}
                        className="w-4 h-4 rounded-full"
                        alt="headshot"
                      />
                    ) : (
                      <Avatar size={20} variant="beam" />
                    )}
                  </>
                )}

                <div className="flex flex-col -space-y-2 ">
                  {carouselStates.headshotData.nameVisible && (
                    <h5
                      className="text-[9px] font-semibold "
                      style={{ color: carouselStates.colorPalette.primary }}
                    >
                      {carouselStates.headshotData.name}
                    </h5>
                  )}
                  {carouselStates.headshotData.profileLinkVisible && (
                    <p
                      className="text-[7px] pt-1"
                      style={{ color: carouselStates.colorPalette.secondary }}
                    >
                      @{carouselStates.headshotData.profileLink}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div
        onClick={handleAddSlide}
        className="flex items-center justify-center m-2 bg-transparent border-none cursor-pointer"
        style={{ minWidth: "100px", maxWidth: "100px", textAlign: "center" }}
      >
        <FaPlus className="p-2 text-4xl bg-gray-300 rounded-full" />
      </div>
    </div>
  );
};

export default CarouselSlides;

// Preview of Carousel Slides

// user can select any slides here it will be displayed in the carousel main area as current page

// User can delete any slide from here on clicking delete icon

// User can add new slide by clicking on add slide button

// import React from "react";
// import { useCarouselSettingsStore } from "../../../ZustandStores/UserMediaStore";
// import Card from "antd/es/card/Card";
// import { Button } from "antd";
// import { MdDelete, MdOutlinePlusOne } from "react-icons/md";
// import BackgroundDesignSelector from "./BackgroundDesignSelector";

// const CarouselSlides = () => {
//   const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

//   const handleSelectSlide = (index) => {
//     setCarouselStates({ ...carouselStates, currentPage: index });
//   };

//   const handleDeleteSlide = (index) => {
//     const updatedTexts = carouselStates.editedTexts.filter(
//       (_, i) => i !== index
//     );
//     setCarouselStates({
//       ...carouselStates,
//       editedTexts: updatedTexts,
//       currentPage: Math.max(0, carouselStates.currentPage - 1),
//     });
//   };

//   const handleAddSlide = () => {
//     const newSlide = {
//       id: carouselStates.editedTexts.length,
//       type: "content",
//       title: "New Slide Title",
//       titleActive: true,
//       description: "New Slide Description",
//       descriptionActive: true,
//       invisible: false,
//     };
//     setCarouselStates({
//       ...carouselStates,
//       editedTexts: [...carouselStates.editedTexts, newSlide],
//       currentPage: carouselStates.editedTexts.length,
//     });
//   };

//   return (
//     <>
//       <div className="flex gap-2 p-4 overflow-x-auto ">
//         {carouselStates.editedTexts.map((text, index) => (
//           <>
//             <div
//               onClick={() => handleSelectSlide(index)}
//               key={index}
//               className={`flex flex-col text-left   p-10  leading-relaxed  border rounded shadow-sm `}
//               style={{
//                 width: "480px",
//                 height: "600px",
//                 backgroundColor: `${carouselStates.colorPalette.background}`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 backgroundRepeat: "no-repeat",
//               }}
//             >
//               <BackgroundDesignSelector />

//               <div className="flex flex-col justify-center h-full gap-2 ">
//                 {/*Slide number */}
//                 {index > 0 && carouselStates.pageNumberVisible && (
//                   <div
//                     className={`flex justify-center items-center w-10 h-10 p-1 font-bold text-xl text-white  rounded-full`}
//                     style={{
//                       backgroundColor: carouselStates.colorPalette.secondary,
//                     }}
//                   >
//                     <p>{index}</p>
//                   </div>
//                 )}

//                 {/* Title  */}
//                 {carouselStates?.editedTexts[index].titleActive && (
//                   <p
//                     className="text-4xl font-bold "
//                     style={{ color: carouselStates.colorPalette.primary }}
//                   >
//                     {carouselStates?.editedTexts[index].title}
//                   </p>
//                 )}
//                 {/* Description */}
//                 {carouselStates?.editedTexts[index].descriptionActive && (
//                   <p style={{ color: carouselStates.colorPalette.secondary }}>
//                     {carouselStates?.editedTexts[index].description}
//                   </p>
//                 )}
//               </div>
//               {/* Show the headshot */}
//               {carouselStates?.headshotData?.visible && (
//                 <div className="absolute z-10 flex w-full gap-2 bottom-8 left-8">
//                   {/* Check if image is visible is enabled */}
//                   {carouselStates?.headshotData?.imageVisible && (
//                     <img
//                       src={carouselStates?.headshotData?.image}
//                       className="w-10 h-10 rounded-full"
//                       alt="headshot"
//                     />
//                   )}

//                   {/* Showing Name and profile link */}
//                   <div>
//                     {carouselStates?.headshotData?.nameVisible && (
//                       <h5
//                         className="font-semibold "
//                         style={{ color: carouselStates.colorPalette.primary }}
//                       >
//                         {carouselStates?.headshotData?.name}
//                       </h5>
//                     )}
//                     {carouselStates?.headshotData?.profileLinkVisible && (
//                       <p
//                         className="text-xs"
//                         style={{ color: carouselStates.colorPalette.secondary }}
//                       >
//                         @{carouselStates?.headshotData?.profileLink}
//                       </p>
//                     )}
//                   </div>
//                 </div>
//               )}
//             </div>

//             {/* <Card
//               key={index}
//               bodyStyle={{ padding: 0 }}
//               onClick={() => handleSelectSlide(index)}
//               className={`cursor-pointer m-2 border-2 ${
//                 index === carouselStates.currentPage
//                   ? "border-triklAccentBlue "
//                   : ""
//               }`}
//               style={{
//                 minWidth: "100px",
//                 maxWidth: "100px",
//                 textAlign: "center",
//                 position: "relative",
//               }}
//             >
//               <div className="absolute z-20 top-1 right-1">
//                 <Button
//                   type="text"
//                   icon={<MdDelete />}
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     handleDeleteSlide(index);
//                   }}
//                 />
//               </div>
//               <BackgroundDesignSelector />
//               <div style={{ height: "150px", overflow: "hidden" }}>
//                 <p>{text.title}</p>
//                 <p>{text.description}</p>
//               </div>
//             </Card> */}
//           </>
//         ))}

//         {/* {carouselStates.editedTexts.map((text, index) => (
//           <Card
//             key={index}
//             bodyStyle={{ padding: 0 }}
//             onClick={() => handleSelectSlide(index)}
//             className={`cursor-pointer m-2 border-2 ${
//               index === carouselStates.currentPage
//                 ? "border-triklAccentBlue "
//                 : ""
//             }`}
//             style={{
//               minWidth: "100px",
//               maxWidth: "100px",
//               textAlign: "center",
//               position: "relative",
//             }}
//           >
//             <div className="absolute z-20 top-1 right-1">
//               <Button
//                 type="text"
//                 icon={<MdDelete />}
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleDeleteSlide(index);
//                 }}
//               />
//             </div>
//             <BackgroundDesignSelector />
//             <div style={{ height: "150px", overflow: "hidden" }}>
//               <p>{text.title}</p>
//               <p>{text.description}</p>
//             </div>
//           </Card>
//         ))} */}
//         <Card
//           onClick={handleAddSlide}
//           className="flex items-center justify-center m-2 cursor-pointer"
//           style={{ minWidth: "100px", maxWidth: "100px", textAlign: "center" }}
//         >
//           <MdOutlinePlusOne style={{ fontSize: "24px" }} />
//         </Card>
//       </div>
//     </>
//   );
// };

// export default CarouselSlides;

// // Preview of Carousel Slides

// // user can select any slides here it will be displayed in the carousel main area as current page

// // User can delete any slide from here on clicking delete icon

// // User can add new slide by clicking on add slide button
