const V2BackgroundTemplate9 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3487)">
        {/* <mask
          id="mask1_3208_3487"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="40"
          width="169"
          height="148"
        >
          <rect y="40" width="169" height="148" fill="#D9D9D9" />
        </mask> */}
        <g mask="url(#mask1_3208_3487)">
          <path
            d="M88.5977 55L147.998 114.4L88.5977 173.8"
            stroke={colorPallet?.secondary}
            stroke-width="12.8664"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M-149 114.398H148"
            stroke={colorPallet?.secondary}
            stroke-width="12.8664"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        {/* <mask
          id="mask2_3208_3487"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="329"
          y="526"
          width="206"
          height="46"
        >
          <rect x="329" y="526" width="206" height="46" fill="#D9D9D9" />
        </mask> */}
        <g mask="url(#mask2_3208_3487)">
          <path
            d="M344 549.398H641"
            stroke={colorPallet?.secondary}
            stroke-width="12.8664"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate9;
