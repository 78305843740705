import React, { useEffect, useState } from "react";
import axios from "axios";

import { audioToContentFunction } from "../../../function/APIFunctions";
import { useFinalContentStore } from "../../ZustandStores/LinkedInZustandStore";
import Wysiwyg from "../../../Dashboard/subPages/contentCreation/Wysiwyg";
import ShareOnLinkedIn from "./ShareOnLinkedIn";
import { uploadImageToCloudinaryWithoutProgress } from "../../function/LinkedInFunctions";
import logoWithText from "../../../../assets/Trikl_logo/logoWithText.svg";

// Lottie File import:
import VoiceAnimation from "./VoiceAnimation.json";
import Lottie from "lottie-react";

import { PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";

const SpeechToText = () => {
  // ZUSTAND
  const { finalContent, setFinalContent } = useFinalContentStore();

  // useState for handleClick ->
  const [isRecording, setIsRecording] = useState(false);

  // Audio related useStates:
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioChunks = [];

  const [transcriptTxt, setTranscriptTxt] = useState(""); // useState to store 'speech to text' content:
  const [isTranscriptVisibleTxt, setIsTranscriptVisibleTxt] = useState(false);

  const [creationStep, setCreationStep] = useState(1); // useState to show components ->
  const [isGeneratingTxt, setIsGeneratingTxt] = useState(false);

  const [finalBlob, setFinalBlob] = useState();

  // Function to store the voice record
  const handleTapToRecord = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Starting the stream:
        const newMediaRecorder = new MediaRecorder(stream);

        newMediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };
        newMediaRecorder.start(1000);
        newMediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/mp4" });

          setFinalBlob(audioBlob);

          const newAudioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(newAudioUrl);

          audioChunks.length = 0; // Reset the chunks array for a new recording session
        };

        setMediaRecorder(newMediaRecorder);
      } catch (error) {
        toast.error("Something went wrong! Please try again");
      }
    } else {
      mediaRecorder.stop(); // If the button is already clicked, stop the recording
    }

    setIsRecording(!isRecording); // To toggle lottie animation:
  };

  // TO GET THE TRANSCRIPT FROM AUDIO FILE
  const handleGetTranscript = async () => {
    setIsGeneratingTxt(true);
    try {
      const formData = new FormData();
      formData.append("audio", finalBlob, "audio.mp4");
      // formData.append("audio", finalBlob, {
      //   type: "audio/mp4",
      //   filename: "audio.mp4",
      // });

      const audioTranscriptResponse = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/openai/audio-transcript`,
        formData
      );

      const transcript = audioTranscriptResponse.data.text;
      // Storing the transcript in useState
      setTranscriptTxt(transcript);
    } catch (error) {
      toast.error("Something went wrong! Please try again");
      setIsGeneratingTxt(false);
    }
  };

  const handleGetContent = async () => {
    try {
      const contentType = "audio";
      const aiResponse = await audioToContentFunction(
        transcriptTxt,
        contentType
      );

      setFinalContent({
        ...finalContent,
        finalText: aiResponse,
      });
      setIsGeneratingTxt(false);
      // ONCE FINAL CONTENT IS THERE, INCREASE STEP
      handleStepChange();
    } catch (error) {
      toast.error("Something went wrong! Please try again");
      setIsGeneratingTxt(false);
    }
  };

  const scrollToBottom = () => {
    const section = document.getElementById("speechToContentFooter");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleStepChange = () => {
    setCreationStep(creationStep + 1);
    scrollToBottom();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (transcriptTxt) {
      handleGetContent();
    }
  }, [transcriptTxt]);

  return (
    <main className="flex flex-col gap-20 bg-gradient-to-t from-linkedInBase to-white">
      {/* TRIKL LOGO */}
      <div className="flex justify-center w-full pt-10 pb-0 md:pb-10">
        <img src={logoWithText} alt="TRIKL logo" className="w-32 h-max" />
      </div>

      {/* First Step ---> Button & Lottie animation */}
      {creationStep >= 1 && (
        <section className="flex flex-col items-center justify-center w-full gap-20">
          {/* SHOW ANIMATING CIRCLE WHEN RECORDING */}
          {isRecording ? (
            <div
              className="duration-700 ease-in-out rounded-full transition-scale hover:scale-105"
              as="button"
              onClick={handleTapToRecord}
            >
              <Lottie
                as="div"
                className="transition-all duration-300 rounded-full h-80 md:h-96 aspect-square transition-scale hover:scale-105"
                animationData={VoiceAnimation}
              />
            </div>
          ) : (
            <button
              // SHOW BUTTON WHEN NOT RECORDING
              className="transition-all duration-300 rounded-full shadow-md h-80 md:h-96 aspect-square transition-scale hover:scale-105"
              style={{
                background:
                  "radial-gradient(circle, rgba(0,102,255,1) 0%, rgba(0,209,255,0.6) 50%, rgba(0,209,255,0.06) 100%)",
              }}
              onClick={handleTapToRecord}
            ></button>
          )}

          {/* CONTENT JUST BELOW RECORD CIRCLE */}
          <div className="text-xl tracking-widest uppercase md:text-4xl font-poppins">
            {isRecording ? (
              <h3>
                <div>Listening</div>
                <div className="text-base normal-case">Tap to stop</div>
              </h3>
            ) : (
              <h3>
                {audioUrl ? (
                  <div>
                    {/* Displaying media playback toolbar */}

                    <div
                      className="flex justify-center w-full pb-10"
                      // className="flex items-center justify-center w-full p-2 pt-2 pb-32 m-auto"
                    >
                      <audio controls src={audioUrl}>
                        Your browser does not support the <code>audio</code>{" "}
                        element.
                      </audio>
                    </div>

                    <div>Tap To Record Again</div>
                    <div className="pt-3 pb-5 text-sm normal-case">
                      Scroll down for content
                    </div>

                    {/* <div className="flex items-center justify-center w-full bg-purple-300"> */}
                    {/* <Link to="content" smooth={true}> */}

                    {/* GENERATE POST BUTTON */}
                    <button
                      // className="w-full px-6 py-4 text-base font-medium text-white rounded-full md:text-lg bg-triklAccentBlue"
                      className="flex items-center justify-center w-full p-4 overflow-hidden text-base font-semibold text-white rounded-md bg-triklAccentBlue disabled:bg-triklAccentBlue/70"
                      onClick={() => handleGetTranscript()}
                      disabled={isGeneratingTxt}
                    >
                      {!isGeneratingTxt ? (
                        "Generate Post"
                      ) : (
                        <PropagateLoader size={5} color="#ffffff" />
                      )}
                    </button>
                    {/* </Link> */}
                    {/* </div> */}
                  </div>
                ) : (
                  <div>
                    <div>Tap to Record</div>
                  </div>
                )}
              </h3>
            )}
          </div>
        </section>
      )}

      {/* STEP TWO ---> GENERATED TEXT AND EDITOR */}
      {creationStep >= 2 && (
        <section className="py-10">
          {/* To Display generated Post */}
          {isGeneratingTxt ? (
            <PropagateLoader size={5} color="#ffffff" />
          ) : (
            <div className="w-full px-4 pt-6 m-auto rounded-md md:w-2/3">
              <Wysiwyg />

              {/* Button to finalize text and to show img component */}
              <button
                className="flex items-center justify-center w-full p-4 my-4 overflow-hidden text-base font-semibold text-white rounded-md bg-triklAccentBlue disabled:bg-triklAccentBlue/70"
                onClick={handleStepChange}
              >
                Finalize Text & Continue
              </button>

              {/* SHOW OR HIDE TRANSCRIPT */}
              <button
                onClick={() =>
                  setIsTranscriptVisibleTxt(!isTranscriptVisibleTxt)
                }
                className="w-full px-12 py-2 text-black bg-gray-200 rounded-md cursor-pointer md:py-4"
              >
                {isTranscriptVisibleTxt ? (
                  <>
                    <div>Hide Transcript</div>
                    <div className="w-full pt-4 text-left text-gray-700">
                      {transcriptTxt}
                    </div>
                  </>
                ) : (
                  <div>Show Transcript</div>
                )}
              </button>
            </div>
          )}
        </section>
      )}

      {/* Upload img button + display uploaded Image */}
      {creationStep >= 3 && (
        <div className="flex flex-col gap-5 pt-16 pb-2 border-gray-300">
          <h4 className="text-xl font-semibold">Add Image to your Post</h4>
          <DisplayUploadedImg
            creationStep={creationStep}
            setCreationStep={setCreationStep}
          />

          {/* CTA linkedIn button */}
          {creationStep >= 4 && (
            <div className="px-4">
              <ShareOnLinkedIn />
            </div>
          )}
        </div>
      )}

      {/* Last element */}
      <footer id="speechToContentFooter" className="pt-32 pb-2">
        © TRIKL 2023
      </footer>
    </main>
  );
};

/////////////////////////
/////////////////////////
/////////////////////////
export default SpeechToText;
/////////////////////////
/////////////////////////
/////////////////////////

// UPLOAD IMAGE TO CLOUDINARY
const DisplayUploadedImg = ({ creationStep, setCreationStep }) => {
  const [isUploadingImg, setIsUploadingImg] = useState(false);

  const { finalContent, setFinalContent } = useFinalContentStore();
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setIsUploadingImg(true);
      const buffer = await file.arrayBuffer();
      try {
        const UploadedImage = await uploadImageToCloudinaryWithoutProgress(
          buffer
        );
        setFinalContent({
          ...finalContent,
          finalMediaUrl: UploadedImage,
        });

        setCreationStep(creationStep + 1);
      } catch (error) {
        toast.error("Some error occurred. Try again!");
      } finally {
        setTimeout(() => {
          setIsUploadingImg(false);
        }, 2000);
      }
    } else {
      toast.error("Invalid file type. Please upload JPG, JPEG, or PNG images.");
    }
    //   } catch (error) {
    //     // console.error("Error in file upload:", error);
    //     toast.error("Some error occured. Try again!");

    //     // Delayed execution
    //     setTimeout(() => {
    //       setIsUploadingImg(false);
    //     }, 2000);
    //   }
    // }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-5 p-4 m-auto">
      {isUploadingImg ? (
        <div className="flex flex-col items-center justify-center w-full h-32 py-4 rounded-md bg-[#e5e7eb]">
          <div className="pb-2">Uploading image</div>
          <PropagateLoader size={5} color="#6F42C1" />
        </div>
      ) : (
        <label>
          <span className="flex items-center justify-center w-full px-6 py-2 mb-2 text-black rounded-md cursor-pointer bg-triklDarkAccentBlue/30">
            Choose File
          </span>
          <input
            className="hidden"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleImageChange}
          />
          <div>
            <img
              className="w-[300px] lg:w-[450px]"
              src={
                finalContent.finalMediaUrl
                  ? finalContent.finalMediaUrl
                  : "https://res.cloudinary.com/dqg7jgqvq/image/upload/v1699767047/test/zrnspeknzdtutc6slj5p.png"
              }
            />
          </div>
        </label>
      )}
    </div>
  );
};
