import React from "react";
import { triklLogo } from "../../../assets/Trikl_logo";

const CanvasNavbarWithoutAuth = () => {
  return (
    <>
      <div className="flex flex-col py-2">
        <nav className="fixed top-0 z-50 flex flex-col items-center justify-center w-full bg-white border-b-2 border-gray-200">
          <main className="container flex justify-between px-2 py-2 mx-auto">
            <div className="flex items-center gap-10">
              <a
                as="div"
                className="flex items-center gap-2 text-center"
                href="https://trikl.ai/"
              >
                <img
                  src={triklLogo}
                  className="object-contain h-8"
                  alt="TRIKL's Logo"
                />
                <p className="text-lg font-semibold font-poppins text-triklBlueDark">
                  TRIKL
                </p>
              </a>
            </div>

            {/* sidebar right section */}
          </main>
        </nav>
      </div>
    </>
  );
};

export default CanvasNavbarWithoutAuth;
