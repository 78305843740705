import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import {
  UserDataRefreshStore,
  useFinalContentStore,
  useOverallStepStore,
  useRefreshSchedulerStore,
  useSavedDraftIdStore,
  useScheduledPostIdStore,
  useUserTypeStore,
} from "../../ZustandStores/LinkedInZustandStore";
import FinalizePost from "../summaryComponents/FinalizePost";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { toast } from "react-toastify";
import { convertHtmlToFormattedText } from "../../../function/ConvertHtmlToFormattedText";
import { BeatLoader } from "react-spinners";
import { updateUserOrganizations } from "../../function/LinkedInFunctions";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../../context/userAuthContext";
import { Button, DatePicker, Select, notification } from "antd";
import { useNavigate } from "react-router-dom";
const AddPostToScheduler = () => {
  // ZUSTAND
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { toggleRefresh } = useRefreshSchedulerStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userData } = useLinkedInUserContext();
  const { user } = useUserAuth();
  const { setUserType } = useUserTypeStore();
  const { setOverallStep } = useOverallStepStore();
  const { setSelectedDraftId } = useSavedDraftIdStore();

  const { userLinkedInData } = useUserLinkedInStore();

  const { selectedScheduledPostId, setSelectedScheduledPostId } =
    useScheduledPostIdStore();

  // LOCAL STATE
  const [isScheduleLoading, setIsScheduleLoading] = useState();
  const [newEventTitle, setNewEventTitle] = useState(
    finalContent.finalText?.slice(0, 30) + "..." || "LinkedIn Post"
  );

  const [newEventDateTime, setNewEventDateTime] = useState(dayjs());

  const navigate = useNavigate();
  const newArray = [
    {
      organizationId: "0",
      organizationName: "My personal Account",
      userType: "Individual",
    },
    ...(userLinkedInData?.organizations?.map((org) => ({
      ...org,
      userType: "organization",
    })) || []),
  ];

  const [selectedOrgId, setSelectedOrgId] = useState(
    userLinkedInData?.scheduledPosts?.find(
      (post) => post._id === selectedScheduledPostId
    )?.organizationId ||
      (newArray.length > 0 ? newArray[0].organizationId : undefined)
  );
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  //   FUNCTIONS
  const addGlobalEventToCalendar = async () => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to Schedule");
      return;
    }
    if (userLinkedInData?.linkedInUserId == "") {
      // return toast.info("Please connect your LinkedIn account to post");
      const key = "connectAccountNotification"; // Unique key for the notification
      notification.info({
        key, // Assign a unique key to the notification
        placement: "bottomRight",
        message: "Please connect your LinkedIn account to post",
        description: (
          <Button
            type="primary"
            onClick={() => {
              navigate("/user/profile");
              notification.destroy(key);
            }}
          >
            Connect Account
          </Button>
        ),
        duration: 2,
      });

      return;
    }
    setIsScheduleLoading(true);
    await handleScheduleClick();
    setNewEventTitle("");
    setNewEventDateTime("");
  };

  //   function to scheduling post
  const handleScheduleClick = async () => {
    try {
      const formattedContent = await convertHtmlToFormattedText(
        finalContent.finalText
      );
      const scheduledPostData = {
        id: new Date().getTime(),
        title: formattedContent || "",
        mediaUrl: finalContent.finalMediaUrl,
        mediaType: finalContent.finalMediaType,
        organizationId: finalContent?.finalOrganizationId,
        postType: finalContent?.finalPostType,
        userId: userData?.userId,
        start: newEventDateTime,
        end: newEventDateTime,
      };

      if (selectedScheduledPostId) {
        await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/update-scheduled-post`,
          {
            postId: selectedScheduledPostId,
            userId: userData?.userId,
            updatedData: scheduledPostData,
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/create-schedule-post`,
          scheduledPostData
        );
      }

      // clearing the final content data after scheduling and going back to step1
      setFinalContent({
        ...finalContent,
        finalText: "",
        finalMediaUrl: "",
        finalMediaType: "",
        finalTone: "",
        finalOrganizationId: 0,
        finalPostType: "individual",
        finalCharacterLimit: 0,
        additionalPersonalization: [],
      });
      setSelectedDraftId("");
      setSelectedScheduledPostId("");
      setOverallStep(1);
      toggleRefresh();
      toast.success("Post is scheduled");
      setIsScheduleLoading(false);
    } catch (error) {
      if (
        error?.response?.data?.error?._message === "Linkedin validation failed"
      ) {
        toast.error("Organisation is missing! ");
        setIsScheduleLoading(false);
        return;
      }
      if (error.response.status === 403) {
        toast.error("Access denied. Please make a payment!");
        setIsScheduleLoading(false);
        return;
      }

      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
      setIsScheduleLoading(false);
    }
  };

  // ----------------------- Handling Select Tag -----------------------

  const handleSelect = (selectedId) => {
    const selectedOption = newArray.find(
      (org) => org.organizationId === selectedId
    );
    if (selectedOption) {
      setFinalContent({
        ...finalContent,
        finalPostType: selectedOption.userType,
        finalOrganizationId: selectedOption.organizationId,
      });
      setUserType(selectedOption.userType);
      setSelectedOrgId(selectedOption.organizationId);
    } else {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  // Update user organizations
  const fetchOrganization = async () => {
    await updateUserOrganizations(userData?.userId);
    setUserDataRefresh(!userDataRefresh);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  // If scheduled post exists, then set the newEventDateTime to the scheduled post date and time
  useEffect(() => {
    const postTime = userLinkedInData?.scheduledPosts?.find(
      (post) => post._id === selectedScheduledPostId
    )?.start;

    setNewEventDateTime(dayjs(postTime).second(0).millisecond(0));
  }, [selectedScheduledPostId]);

  return (
    <div className="flex flex-col items-center justify-center w-1/2">
      {/* ACCOUNT SELECTION DROPDOWN */}
      <aside className="flex items-center justify-between w-full gap-2">
        <p className="min-w-max">Select Account: </p>

        <Select
          value={selectedOrgId}
          className="w-2/3"
          onChange={(value) => {
            handleSelect(value);
            setUserHasInteracted(true); // Indicate user interaction
          }}
          options={newArray.map((eachOrganization) => ({
            value: eachOrganization.organizationId,
            label: eachOrganization.organizationName,
          }))}
        />
      </aside>

      {/* Date and time selection */}
      <aside className="flex items-center justify-between w-full gap-2 py-5">
        <p className="min-w-max">Select Date and time:</p>
        <DatePicker
          allowClear={false}
          className="w-2/3 py-[0.3rem] border border-triklAccentBlue"
          placeholder="Select date and time"
          value={newEventDateTime}
          format="DD/MM/YYYY hh:mm A"
          defaultValue={dayjs()}
          onChange={(date, dateStr) => {
            const newDate = dayjs(date).second(0).millisecond(0);
            setNewEventDateTime(newDate);
          }}
          showTime={{ use12Hours: true }}
          disabledDate={(current) => {
            return current && current < dayjs().startOf("day");
          }}
          disabledTime={(current) => {
            const currentHour = dayjs().hour();
            const currentMinute = dayjs().minute();

            return {
              disabledHours: () => {
                const hours = [];
                if (
                  current &&
                  current.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
                ) {
                  for (let i = 0; i < currentHour; i++) {
                    hours.push(i);
                  }
                }
                return hours;
              },
              disabledMinutes: (selectedHour) => {
                const minutes = [];
                if (
                  current &&
                  current.format("YYYY-MM-DD") ===
                    dayjs().format("YYYY-MM-DD") &&
                  selectedHour === currentHour
                ) {
                  for (let i = 0; i < currentMinute; i++) {
                    minutes.push(i);
                  }
                }
                return minutes;
              },
              disabledSeconds: () => [],
            };
          }}
        />
      </aside>

      {/* SCHEDULE */}
      <button
        className="w-full px-4 py-2 mt-4 text-center text-white rounded bg-triklAccentBlue hover:bg-triklAccentBlue "
        onClick={addGlobalEventToCalendar}
        disabled={isScheduleLoading}
      >
        {isScheduleLoading ? (
          <BeatLoader color="#fff" size={10} />
        ) : selectedScheduledPostId ? (
          "Reschedule"
        ) : (
          "Schedule"
        )}
      </button>

      <FinalizePost />
    </div>
  );
};

export default AddPostToScheduler;
