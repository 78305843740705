import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useUserAuth } from "../../../../context/userAuthContext";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { useUserLinkedInStore } from "../../ZustandStores/UserLinkedInStore";
import { convertHtmlToFormattedTextOnboarding } from "../../../function/ConvertHtmlToFormattedText";
const AddToScheduler = ({ isOpen, onClose, postContent }) => {
  const { user } = useUserAuth();
  const { userLinkedInData } = useUserLinkedInStore();

  const [newEventDateTime, setNewEventDateTime] = useState(
    moment().add(1, "days").format("YYYY-MM-DDTHH:mm")
  );

  const [isScheduleLoading, setIsScheduleLoading] = useState(false);

  //   function to scheduling post
  const handleScheduleClick = async () => {
    try {
      if (userLinkedInData?.linkedInUserId == "") {
        return toast.info("Please connect your LinkedIn account to post");
      }
      // const formattedContent = await convertHtmlToFormattedText(
      //   finalContent.finalText
      // );

      await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userLinkedin/v2/create-schedule-post`,

        {
          id: new Date().getTime(),
          title: convertHtmlToFormattedTextOnboarding(postContent?.content),
          mediaUrl: postContent?.image,
          mediaType: "image",
          organizationId: 0,
          postType: "individual",
          userId: userLinkedInData?.userId,
          start: moment(newEventDateTime).toDate(),
          end: moment(newEventDateTime).toDate(),
        },
        {
          withCredentials: true,
        }
      );

      setIsScheduleLoading(false);
      toast.success("Your post is scheduled");
      onClose();
    } catch (error) {
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");

      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
      setIsScheduleLoading(false);
    }
  };

  return (
    <div>
      <Modal
        // title="Add post to scheduler"
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        maskClosable={false}
        footer={null}
      >
        <div className="flex flex-col items-center justify-center gap-5">
          <p className="text-lg text-linkedInPeach">{postContent?.title}</p>

          {/* Date time picker */}
          <input
            as="div"
            className="px-20 py-2 bg-transparent border-0 rounded-md"
            type="datetime-local"
            value={newEventDateTime}
            onChange={(e) => setNewEventDateTime(e.target.value)}
          />
          <Button
            loading={isScheduleLoading}
            type="primary"
            size="large"
            onClick={handleScheduleClick}
          >
            Add to schedular
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddToScheduler;
