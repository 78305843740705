const V2BackgroundTemplate4 = ({ colorPalette }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3444)">
        <rect x="25.5" y="25.5" width="484" height="614" stroke="black" />
        <path
          d="M25 53L37.1244 32H12.8756L25 53Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M25 70L37.1244 49H12.8756L25 70Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M25 87L37.1244 66H12.8756L25 87Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M510 612L522.124 633H497.876L510 612Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M510 595L522.124 616H497.876L510 595Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M510 578L522.124 599H497.876L510 578Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M510 190L522.124 211H497.876L510 190Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M25 492L37.1244 471H12.8756L25 492Z"
          fill={colorPalette?.secondary}
        />
        <path
          d="M510 140L522.124 161H497.876L510 140Z"
          fill={colorPalette?.secondary}
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate4;
