const V2BackgroundTemplate20 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3462)">
        <circle
          cx="39"
          cy="643"
          r="156"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <ellipse
          cx="195"
          cy="698.5"
          rx="101"
          ry="100.5"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <path
          d="M483.5 545C483.5 573.991 459.776 597.5 430.5 597.5C401.224 597.5 377.5 573.991 377.5 545C377.5 516.009 401.224 492.5 430.5 492.5C459.776 492.5 483.5 516.009 483.5 545Z"
          stroke={colorPallet?.secondary}
          stroke-opacity="0.5"
        />
        <circle
          cx="464.5"
          cy="58.5"
          r="111.5"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate20;
