const V2BackgroundTemplate10 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3208_3501)">
        {/* <mask id="mask1_3208_3501" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-2" y="0" width="535" height="665">
<rect x="-2" width="535" height="665" fill="#FF7878"/>
</mask> */}
        <g mask="url(#mask1_3208_3501)">
          <rect
            x="-33"
            y="82.9336"
            width="323.391"
            height="45"
            transform="rotate(-22.7261 -33 82.9336)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="-27"
            y="190.029"
            width="598.224"
            height="45"
            transform="rotate(-22.7261 -27 190.029)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="-27"
            y="300.598"
            width="633.138"
            height="45"
            transform="rotate(-22.7261 -27 300.598)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="-32"
            y="413.598"
            width="633.138"
            height="45"
            transform="rotate(-22.7261 -32 413.598)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="-33"
            y="522.598"
            width="633.138"
            height="45"
            transform="rotate(-22.7261 -33 522.598)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="-33"
            y="632.598"
            width="633.138"
            height="45"
            transform="rotate(-22.7261 -33 632.598)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="148.938"
            y="665.67"
            width="428.834"
            height="45"
            transform="rotate(-22.7261 148.938 665.67)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
          <rect
            x="213"
            y="748.67"
            width="428.834"
            height="45"
            transform="rotate(-22.7261 213 748.67)"
            fill={colorPallet?.secondary}
            fill-opacity="0.1"
          />
        </g>
        {/* <rect x="38" y="40" width="455" height="585" fill="white" /> */}
        <path
          d="M168.375 575.297C198.234 551.08 282.935 508.812 382.864 533.475C482.792 558.137 528.342 535.571 538.625 521.204"
          stroke={colorPallet?.primary}
          stroke-width="2"
          stroke-dasharray="6 6"
        />
      </g>
    </svg>
  );
};

export default V2BackgroundTemplate10;
