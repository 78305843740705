import React, { useEffect, useState } from "react";
import axios from "axios";
import MainContentArea from "./components/MainContentArea";
import CanvasSidebar from "./components/CanvasSidebar";
import {
  UserDataRefreshStore,
  useOverallStepStore,
  useUsageLimitTrackingStore,
} from "./ZustandStores/LinkedInZustandStore";
import ImgSidebar from "./components/imageComponents/ImgSidebar";
import SchedulerSidebar from "./components/schedulerComponents/Scheduler";
import { useLinkedInUserContext } from "../../context/linkedInUserContext";
import SubscriptionModal from "./components/modalsComponents/SubscriptionModal";
import { useNavigate } from "react-router-dom";
import ProductTour from "./components/modalsComponents/ProductTour";
import { useUserMainDataStore } from "./ZustandStores/UserLinkedInStore";
import { toast } from "react-toastify";
import { Spin } from "antd";

const Canvas = () => {
  const { overallStep } = useOverallStepStore();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  let { userData } = useLinkedInUserContext();
  const { userUsageData, setUserUsageData } = useUsageLimitTrackingStore();
  const [isLoading, setIsLoading] = useState(true);

  const [userTourModalOpen, setUserTourModalOpen] = useState(false);

  // ----------
  const { userMainData } = useUserMainDataStore();
  if (window.clarity) {
    window.clarity("set", "userEmail", userData?.email);
  }

  const navigate = useNavigate();

  async function fetchData() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERURL}/userMain/v2/get-usage-data`,
        { userId: userMainData?.userId }
      );

      setUserUsageData(response.data);
    } catch (error) {
      console.error("Failed to fetch user usage data:", error);
    } finally {
      // Set a timeout to delay setting the loading state to false
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }

  useEffect(() => {
    // onboarding page redirect if user subscription or trial is not active
    if (userData?.userId) {
      if (!userData?.subscription?.isActive) {
        toast.error(
          "Your trial has expired, kindly start a new plan or contact us"
        );
        navigate("/pricing");
      } else {
        fetchData();
      }
    }
  }, [userData, userDataRefresh]);

  const renderSidebar = (step) => {
    switch (step) {
      case 0:
        return <CanvasSidebar />;
      case 1:
        return <CanvasSidebar />;
      case 2:
        return <ImgSidebar />;
      case 3:
        return <SchedulerSidebar />;
      case 4:
        return <SchedulerSidebar />;
      case 6:
        return <ImgSidebar />;
      default:
        return <div>Error</div>;
    }
  };

  // Product Tour Modal
  useEffect(() => {
    const isModalOpen = userUsageData?.textLimit === 0 ? true : false;
    if (!userUsageData?.textLimit == 0) {
      setUserTourModalOpen(isModalOpen);
    }
  }, [userUsageData]);

  if (userData?.subscription?.isActive) {
    return (
      <div className="container mx-auto overflow-y-hidden border-l border-r border-gray-300 bg-linkedInBase">
        <div className="flex flex-col-reverse lg:flex-row">
          <ProductTour
            isModalOpen={userTourModalOpen}
            setIsModalOpen={() => setUserTourModalOpen(false)}
          />

          <MainContentArea className="w-full md:w-2/3" />
          <aside className="w-full p-6 lg:p-0 lg:w-1/3">
            {renderSidebar(overallStep)}
          </aside>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen ">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="bg-linkedInBase">
      <div className="container flex mx-auto overflow-y-hidden border-l border-r border-gray-300">
        <SubscriptionModal userEmail={userData?.email} />
      </div>
    </div>
  );
};

export default Canvas;
