import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const SubscriptionModal = ({ userEmail }) => {
  return (
    <>
      {/* OVERLAY */}
      <div className="bg-black/40 fixed backdrop-blur-sm h-screen w-screen z-[100] overflow-auto top-0 left-0 flex items-start justify-center">
        {/* TEXT BOX */}

        <main className="container relative w-2/3 py-12 m-10 mx-auto overflow-auto border rounded-md shadow bg-linkedInBase">
          <div className="pb-6 text-2xl font-semibold">
            <span className="text-triklAccentBlue">Professional Plan</span>{" "}
            suits best to you. Let's upgrade!
          </div>
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            customer-email={userEmail}
          ></stripe-pricing-table>
        </main>
      </div>
    </>
  );
};

export default SubscriptionModal;
