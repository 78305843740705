import React, { useState } from "react";
import { userMediaFilesStore } from "../../ZustandStores/UserMediaStore";
import {
  useCreatedImages,
  useFinalContentStore,
} from "../../ZustandStores/LinkedInZustandStore";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import axios from "axios";
import { getUserImageData } from "../../function/LinkedInFunctions";

import DocumentIcon from "../../../../assets/icons/documentIcon.svg";
import ImageGallery from "../../../../assets/icons/imageGallery.svg";
import VideoOutline from "../../../../assets/icons/videoOutline.svg";
import DocumentOutline from "../../../../assets/icons/documentOutline.svg";

const SavedMediaDisplay = () => {
  const MediaTypes = [
    { type: "Images", icon: ImageGallery },
    { type: "Videos", icon: VideoOutline },
    { type: "Documents", icon: DocumentIcon },
  ];

  const [selectedMediaType, setSelectedMediaType] = useState(
    MediaTypes[0].type
  );

  return (
    <div className="w-full ">
      {/* Media Type Cards  */}

      <div className="flex items-center justify-center gap-3 p-3 text-md text-darkGrey">
        {MediaTypes.map((eachMedia, index) => {
          return (
            <div
              key={index}
              className={`${
                selectedMediaType == eachMedia.type
                  ? "bg-triklAccentBlue/10 border-triklAccentBlue border-2"
                  : "bg-gray-200 border-triklAccentBlue"
              } flex flex-col justify-center w-1/3 rounded-md aspect-content aspect-[2] p-6`}
              onClick={() => {
                setSelectedMediaType(eachMedia.type);
              }}
            >
              <div className="flex flex-col items-center justify-center gap-1">
                <img src={eachMedia.icon} alt="" className="w-6 h-6" />
                {eachMedia.type}
              </div>
            </div>
          );
        })}
      </div>
      {/* Display Medias */}
      <DisplayMediaFiles selectedMediaType={selectedMediaType} />
    </div>
  );
};

export default SavedMediaDisplay;

const DisplayMediaFiles = ({ selectedMediaType }) => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userMediaFiles, setUserMediaFiles } = userMediaFilesStore();
  const { setUserImagesArray } = useCreatedImages();

  // User Data:
  let { userData } = useLinkedInUserContext();

  const userId = userData?.userId;
  // Function to change the status of the media file:
  const handleDelete = (item) => {
    const imgId = item._id;

    const url = `${process.env.REACT_APP_SERVERURL}/userMedia/v2/deleteMedia?imgId=${imgId}&userId=${userId}`;
    axios
      .patch(url)
      .then((res) => {
        if (res.status === 200) {
          getUserImageData(userId, setUserImagesArray, setUserMediaFiles);

          toast.success("Media is successfully removed.");
        } else {
          toast.error("Something went wrong, please try again");
        }
      })
      .catch((err) =>
        toast.error("oops! something went wrong. Please try again.")
      );
  };

  return (
    <div className="p-3">
      {/* Display images */}
      <p className="my-3 text-lg font-semibold text-left">
        Saved {selectedMediaType}
      </p>
      {selectedMediaType == "Images" && (
        // <div className="flex justify-center">
        <div className="flex flex-wrap gap-2 ">
          {userMediaFiles?.images?.length > 0 ? (
            <>
              {userMediaFiles?.images?.map((eachImage, index) => {
                return (
                  <div key={index} className="relative group">
                    <img
                      className="object-cover w-[90px] h-[90px] rounded-lg"
                      src={eachImage.mediaURL}
                      alt={eachImage.mediaURL}
                      onClick={() => {
                        setFinalContent({
                          finalMediaUrl: eachImage.mediaURL,
                          finalMediaType: eachImage.mediaType,
                        });
                      }}
                    />
                    {/* Overlay div */}
                    <div
                      className={`absolute inset-0 rounded-lg ${
                        finalContent?.finalMediaUrl === eachImage.mediaURL
                          ? "bg-triklAccentBlue/50"
                          : "hidden"
                      }`}
                      onClick={() => {
                        setFinalContent({
                          finalMediaUrl: eachImage.mediaURL,
                          finalMediaType: eachImage.mediaType,
                        });
                      }}
                    />
                    <div
                      className="absolute -top-0.5 -right-0.5 hidden p-1 text-white bg-red-500 rounded-full cursor-pointer group-hover:block"
                      onClick={() => {
                        handleDelete(eachImage);
                      }}
                    >
                      <MdDelete />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p>No images found</p>
            </>
          )}
        </div>
        // </div>
      )}

      {/* Display videos */}
      {selectedMediaType == "Videos" && (
        <div className="flex gap-3 ">
          {userMediaFiles?.videos?.length > 0 ? (
            <>
              {userMediaFiles?.videos?.map((eachVideo, index) => {
                return (
                  <div key={index} className="relative group">
                    <video
                      className="object-cover w-40 h-40 rounded-lg"
                      src={eachVideo.mediaURL}
                      controls={false}
                      alt={eachVideo.mediaURL}
                      onClick={() => {
                        setFinalContent({
                          finalMediaUrl: eachVideo.mediaURL,
                          finalMediaType: eachVideo.mediaType,
                        });
                      }}
                    />
                    {/* Overlay div */}
                    <div
                      className={`absolute inset-0 rounded-lg ${
                        finalContent?.finalMediaUrl === eachVideo.mediaURL
                          ? "bg-triklAccentBlue/50 border-2 border-triklAccentBlue"
                          : "hidden"
                      }`}
                      onClick={() => {
                        setFinalContent({
                          finalMediaUrl: eachVideo.mediaURL,
                          finalMediaType: eachVideo.mediaType,
                        });
                      }}
                    />
                    <div
                      className="absolute -top-0.5 -right-0.5 hidden p-1 text-white bg-red-500 rounded-full cursor-pointer group-hover:block"
                      onClick={() => {
                        handleDelete(eachVideo);
                      }}
                    >
                      <MdDelete />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p>No videos found</p>
          )}
        </div>
      )}

      {/* Display pdf */}
      {selectedMediaType == "Documents" && (
        <div>
          {userMediaFiles?.documents?.length > 0 ? (
            <>
              {userMediaFiles?.documents?.map((eachDocument, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      finalContent?.finalMediaUrl === eachDocument.mediaURL
                        ? "bg-triklPeachLight border border-triklAccentBlue"
                        : "bg-white"
                    } flex items-center my-2 group border border-gray-300 rounded-lg `}
                    onClick={() => {
                      setFinalContent({
                        finalMediaUrl: eachDocument.mediaURL,
                        finalMediaType: eachDocument.mediaType,
                      });
                    }}
                  >
                    <div
                      className={`${
                        finalContent?.finalMediaUrl === eachDocument.mediaURL
                          ? "bg-triklAccentBlue font-semibold text-white"
                          : ""
                      }  font-semibold bg-gray-200 flex items-center justify-center w- h-16 mr-3 rounded-r-md `}
                      style={{
                        writingMode: "vertical-lr",
                        transform: "rotate(180deg)",
                      }}
                    >
                      {/* file extension display */}
                      {eachDocument?.mediaURL.split(".").pop().toUpperCase()}
                    </div>
                    {/* Icon */}
                    <img
                      src={DocumentOutline}
                      className="w-6 h-6 mr-4 text-gray-800"
                    />

                    <div className="flex flex-col items-start flex-grow ">
                      <span className="text-md">
                        {(() => {
                          // Decode the mediaURL for display
                          const decodedUrl = decodeURIComponent(
                            eachDocument?.mediaURL
                          );
                          const slicedUrl = decodedUrl.slice(52, 73);
                          const displayUrl =
                            slicedUrl + (decodedUrl.length > 73 ? "..." : "");
                          return displayUrl;
                        })()}
                      </span>
                      <span className="text-xs text-gray-600">
                        {eachDocument?.createdOn}
                      </span>
                    </div>
                    <p
                      className="hidden mr-2 text-lg cursor-pointer text-darkGrey group-hover:block "
                      onClick={() => {
                        handleDelete(eachDocument);
                      }}
                    >
                      <MdDelete />
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <p>No Documents found</p>
          )}
        </div>
      )}
    </div>
  );
};
