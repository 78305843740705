import React, { useEffect, useRef, useState } from "react";
import "../../linkedin.css";
import axios from "axios";
import { FiRefreshCcw } from "react-icons/fi";
import { BounceLoader } from "react-spinners";
import ImageGallery from "../../../../assets/icons/imageGallery.svg";
import Carousel from "../../../../assets/icons/carousel.svg";
///// image style
import {
  UserDataRefreshStore,
  useAIPromptRecommedationStore,
  useAIRecommendationClickStore,
  useCreatedImages,
  useFinalContentStore,
  useFinalImgCreationUniqNameStore,
  useIsImageGenerationLoadingStore,
  useOverallStepStore,
  useSelectedImgStore,
} from "../../ZustandStores/LinkedInZustandStore";

import {
  AllImageCategories,
  OverallImgCategories,
} from "../../../../assets/imgPromptAndOptions/allImgPrompts";
import {
  carouselImg1,
  carouselImg2,
  carouselImg3,
  carouselImg4,
  carouselImg5,
  carouselImg6,
  carouselImg7,
  carouselImg8,
  carouselImg9,
  carouselImg10,
  carouselImg11,
} from "../../../../assets/carouselTemplates";
import { toast } from "react-toastify";
import { getAIRecoJSONPrompt } from "../../../../assets/textPromptAndOptions/allTextPrompts";
import { storeGeneratedOrUploadedImg } from "../../function/LinkedInFunctions";
import { useLinkedInUserContext } from "../../../../context/linkedInUserContext";
import { useUserMainDataStore } from "../../ZustandStores/UserLinkedInStore";
import { useUserAuth } from "../../../../context/userAuthContext";
import {
  useCarouselSettingsStore,
  userMediaFilesStore,
} from "../../ZustandStores/UserMediaStore";
import { useImagePromptStore } from "../../ZustandStores/ImageGenerationStore";
import { Button, Drawer, Select } from "antd";
import { backgroundDesign } from "../../../../assets/icons";
import AdvancedCarousel from "../mediaComponents/carousel";

const MediaCreation = () => {
  // ZUSTAND
  const { userImagesArray, setUserImagesArray } = useCreatedImages();
  const { userDataRefresh, setUserDataRefresh } = UserDataRefreshStore();
  const { finalizedImgUniqName } = useFinalImgCreationUniqNameStore();
  const { finalContent, setFinalContent } = useFinalContentStore();
  const { userMainData } = useUserMainDataStore();
  const { setUserMediaFiles } = userMediaFilesStore();
  const { setIsImageGenerationLoading } = useIsImageGenerationLoadingStore();
  const { setOverallStep } = useOverallStepStore();
  const { user } = useUserAuth();
  // STATE MANAGEMENT
  const [selectedCategory, setSelectedCategory] = useState("");
  const [enteredImgPrompt, setEnteredImgPrompt] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isdalle, setIsDalle] = useState(true);

  const [selectedImgModel, setSelectedImgModel] = useState("dalle");
  // const [unsplashImages, setUnsplashImages] = useState([]);

  // Media creation types
  const MediaTypes = [
    { type: "Images", icon: ImageGallery, MainAreaSteps: 2 },
    { type: "Carousels", icon: Carousel, MainAreaSteps: 2 },
  ];

  const [selectedMediaType, setSelectedMediaType] = useState(
    MediaTypes[0].type
  );

  // close the drawer
  const closeDrawer = () => {
    setSelectedMediaType("Images");
    setIsDrawerOpen(false);
  };

  const handleImageRequest = async () => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }
    console.log("selectedImgModel Main ---> ", selectedImgModel);
    setIsImageGenerationLoading(true);
    let url;
    if (enteredImgPrompt === "") {
      toast.error("Please write prompt first");
      setIsImageGenerationLoading(false);
      return;
    }
    try {
      if (selectedImgModel === "dalle") {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/userOpenai/v2/dalle-3`,
          {
            prompt: enteredImgPrompt,
            userId: userMainData?.userId,
          }
        );
        url = response.data.image;
      } else if (selectedImgModel === "sdxl") {
        // Stable diffusion XL model
        const data = {
          finalizedImgUniqName: finalizedImgUniqName,
          prompt: enteredImgPrompt,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_SERVERURL}/textToImage/getImage-v2`,
          {
            data: data,
            userId: userMainData?.userId,
          }
        );
        url = response.data;
      }
      //  else if (selectedImgModel === "unsplash") {
      //   console.log("selectedImgModel ---> ", selectedImgModel);

      //   // Unsplash images
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_SERVERURL}/textToImage/getUnsplashImages`,
      //     {
      //       userId: userMainData?.userId,
      //       prompt: enteredImgPrompt,
      //     }
      //   );
      //   // url = response.data.image;
      //   // console.log("Unsplash response:---> ", response.data);
      //   setUnsplashImages(response.data?.images);
      // }
      // ------------------------------- Storing Generated ImgURL, Prompt, userId -------------------------------------

      // If image is generated from Dalle or Stable diffusion model, then store it in the userImagesArray
      if (selectedImgModel === "dalle" || selectedImgModel === "sdxl") {
        const imgURL = url;
        const userInput = enteredImgPrompt;
        const userId = userMainData.userId;
        const imgType = selectedCategory;
        const mediaType = finalContent.finalMediaType || "image";
        await storeGeneratedOrUploadedImg(
          userId,
          userInput,
          imgURL,
          imgType,
          setUserImagesArray,
          mediaType,
          setUserMediaFiles
        );
        setFinalContent({
          ...finalContent,
          finalMediaUrl: imgURL,
          finalMediaType: "image",
        });
      }
      setUserDataRefresh(!userDataRefresh);
      setIsImageGenerationLoading(false);
    } catch (error) {
      if (error.response.status === 403) {
        toast.error("Access denied. Please make a payment!");
        setIsImageGenerationLoading(false);
        return;
      }
      toast.error("Oops! Our hamsters stopped running. Give it another go! 🐹");
      setIsImageGenerationLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedImgModel(event);
  };

  useEffect(() => {
    if (
      finalContent?.finalMediaUrl?.length > 0 &&
      userImagesArray.length === 0
    ) {
      const imgObj = {
        imageData: finalContent.finalMediaUrl,
        imagePrompt: "",
      };
      // setImagesArray([imgObj, ...imagesArray]);
    }
  }, [finalContent]);

  // AUTO ADJUST ROW HEIGHT
  const textAreaRef = useRef(null);
  const autoGrow = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = "5px";
    if (textArea.scrollHeight > 100) {
      textArea.style.height = "120px";
      textArea.style.overflowY = "auto";
    } else {
      textArea.style.height = textArea.scrollHeight + "px";
      textArea.style.overflowY = "hidden";
    }
  };
  const [isStyleVisible, setIsStyleVisible] = useState(false);
  const handleStyles = () => {
    setIsStyleVisible(!isStyleVisible);
  };

  const imgPromptBoxProps = {
    setEnteredImgPrompt,
    enteredImgPrompt,
    textAreaRef,
    autoGrow,
    handleStyles,
    handleImageRequest,
    isStyleVisible,
    // unsplashImages,
  };

  return (
    <section className="flex flex-col items-center justify-center w-full gap-5 px-5 pt-6">
      {/* Media Type Cards  */}
      <div className="flex items-center justify-center gap-3 p-3 text-md text-darkGrey">
        {MediaTypes.map((eachMedia, index) => {
          return (
            <div
              key={index}
              className={`${
                selectedMediaType == eachMedia.type
                  ? "bg-triklAccentBlue/10 border-triklAccentBlue border-2"
                  : "bg-gray-200 border-triklAccentBlue"
              } cursor-pointer flex flex-col justify-center  rounded-md aspect-content aspect-[2] p-6`}
              onClick={() => {
                setSelectedMediaType(eachMedia.type);
                setOverallStep(eachMedia.MainAreaSteps);
                if (selectedMediaType === "Images") {
                  setIsDrawerOpen(true);
                }
              }}
            >
              <div className="flex flex-col items-center justify-center gap-1">
                <img src={eachMedia.icon} alt="" className="w-6 h-6" />
                {eachMedia.type}
              </div>
            </div>
          );
        })}
      </div>
      {/* // Display Image Creation  */}
      {selectedMediaType === "Images" && (
        <>
          <section className="flex items-center justify-center w-full gap-4 pb-5">
            <label
              htmlFor="simple-dropdown"
              className="pt-1 text-sm font-medium text-gray-700 min-w-max"
            >
              Image Models
            </label>

            <Select
              className="flex w-full"
              defaultValue="dalle"
              onChange={handleChange}
              options={[
                { value: "DALLE-3", label: "dalle" },
                { value: "sdxl", label: "Trained Stable Diffusion XL" },
                // { value: "unsplash", label: "Unsplash" },
              ]}
            />
          </section>

          <ImgPromptBox
            selectedImgModel={selectedImgModel}
            imgPromptBoxProps={imgPromptBoxProps}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </>
      )}
      {/* // Display Carousel Creation */}
      {/* {selectedMediaType === "Carousels" && <CarouselCustomization />} */}

      <Drawer
        title="Carousel Maker"
        placement="right"
        closable={false}
        size="large"
        width="80%"
        onClose={closeDrawer}
        open={isDrawerOpen}
        style={{
          backgroundColor: "#f3f2ef",
          boxShadow: "0 4px 6px rgba(255, 255, 255, 0.5)",
        }}
      >
        <AdvancedCarousel
          setIsDrawerOpen={setIsDrawerOpen}
          setSelectedMediaType={setSelectedMediaType}
        />
      </Drawer>
    </section>
  );
};
////// EXPORT ///////
export default MediaCreation;

// Image Prompt Box
const ImgPromptBox = ({
  selectedImgModel,
  linkedInRephrase,
  imgPromptBoxProps,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { finalContent } = useFinalContentStore();
  const { finalizedImgUniqName } = useFinalImgCreationUniqNameStore();
  const { isImageGenerationLoading } = useIsImageGenerationLoadingStore();
  const { aiPromptRecommedation, setAIPromptRecommedation } =
    useAIPromptRecommedationStore();
  const { userMainData } = useUserMainDataStore();
  const { user } = useUserAuth();

  const {
    setEnteredImgPrompt,
    enteredImgPrompt,
    textAreaRef,
    autoGrow,
    handleImageRequest,
    // unsplashImages,
  } = imgPromptBoxProps;

  const { isImagePromptLoading, setIsImagePromptLoading } =
    useImagePromptStore();

  // Get AI Image Recommendation
  const getAIImgReco = async () => {
    if (!user?.emailVerified) {
      toast.info("Please verify your email to generate content");
      return;
    }
    setIsImagePromptLoading(true);

    try {
      const aiRecoAsArray = await getAIRecoJSONPrompt(
        userMainData?.userId,
        finalContent.finalText,
        selectedImgModel
      );
      if (aiRecoAsArray.length > 1) {
        setAIPromptRecommedation(aiRecoAsArray);
      }

      setEnteredImgPrompt(aiRecoAsArray[0]);
      setIsImagePromptLoading(false);
    } catch (error) {
      console.error("Error fetching image:", error);
      setIsImagePromptLoading(false);
    }
  };

  const { isAIRecommendationClick, setIsAIRecommendationClick } =
    useAIRecommendationClickStore();

  useEffect(() => {
    if (isAIRecommendationClick) {
      if (finalContent.finalText.length < 10) {
        toast.info("Please write a longer content to get AI recommendation");
        return;
      }

      if (finalContent.finalText.length > 10) {
        getAIImgReco();
      }

      setIsAIRecommendationClick(false);
    }
  }, [isAIRecommendationClick]);
  return (
    <>
      <main className="w-full">
        <div className="flex items-center gap-2 pb-1 text-xs text-left text-black/50">
          Write Image Prompt
        </div>
        <textarea
          id="chat"
          onChange={(event) => setEnteredImgPrompt(event.target.value)}
          value={enteredImgPrompt}
          ref={textAreaRef}
          disabled={isImageGenerationLoading}
          onInput={autoGrow}
          // maxLength={50}
          className="block px-2.5 pt-4 pb-6 mb-8 border border-gray-300 rounded-lg w-full text-sm text-gray-900 placeholder:text-gray-500"
          placeholder="Describe your image..."
        />

        {/* {selectedImgModel !== "unsplash" && (
          <section className="flex justify-center pb-2 font-semibold text-center">
            <button
              as="div"
              className="flex items-center gap-2 text-center text-transparent bg-clip-text bg-gradient-to-r from-triklAccentBlue to-[#b938ff]"
              onClick={() => {
                if (finalContent.finalText.length > 10) {
                  getAIImgReco();
                }
              }}
              disabled={isImageGenerationLoading}
            >
              <FiRefreshCcw
                className={`text-triklAccentBlue ${
                  isImagePromptLoading ? "animate-spin" : ""
                }`}
              />
              Get AI Recommendation
            </button>
          </section>
        )} */}

        {/* {selectedImgModel === "unsplash" && (
          <ShowUnsplashImages unsplashImages={unsplashImages} />
        )} */}

        {/* RECOMMENDATION ARRAY */}
        {aiPromptRecommedation && aiPromptRecommedation?.length > 1 && (
          <section className="flex flex-col gap-1 pb-3">
            {aiPromptRecommedation &&
              aiPromptRecommedation?.map((each, index) => (
                <button
                  key={index}
                  onClick={() => setEnteredImgPrompt(each)}
                  // px-2 w-max flex-shrink-0  py-1 text-xs border rounded-md
                  className={`rounded-md p-2 text-left italic

                  ${
                    enteredImgPrompt === each
                      ? "bg-triklDarkAccentBlue/40 text-black border-triklAccentBlue"
                      : "bg-white/50 border border-gray-300 text-gray-600"
                  }
                  `}
                >
                  {each}
                </button>
              ))}
          </section>
        )}
        {selectedImgModel === "sdxl" && (
          <div className="w-full overflow-scroll rounded-md">
            <ImgPromptOptions
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          </div>
        )}

        {selectedImgModel === "sdxl" && (
          <div
            className={`w-full overflow-scroll rounded-md ${
              selectedCategory ? "" : "hidden"
            }`}
          >
            <FinalImgSelection
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          </div>
        )}

        {/* GENERATE BUTTON */}
        {isImageGenerationLoading ? (
          <button
            as="div"
            className="flex justify-center w-full px-8 py-1 text-base text-center text-white rounded-md disabled:bg-gray-400 bg-triklAccentBlue"
          >
            <BounceLoader size={25} className="" color="#FFFFFF" />
          </button>
        ) : (
          <button
            className="w-full px-6 py-1 text-base text-white rounded-md disabled:bg-gray-400 bg-triklAccentBlue"
            disabled={
              // isdalle ||
              (enteredImgPrompt.length > 3 && selectedImgModel === "dalle") ||
              (enteredImgPrompt &&
                selectedCategory &&
                finalizedImgUniqName &&
                enteredImgPrompt.length > 0) ||
              enteredImgPrompt.length > 3
                ? false
                : true
            }
            onClick={handleImageRequest}
          >
            Generate Image
          </button>
        )}
      </main>
    </>
  );
};

const ImgPromptOptions = ({ selectedCategory, setSelectedCategory }) => {
  return (
    <>
      {/* OVERALL SELECTION */}

      <section className="flex gap-2 overflow-auto text-sm rounded-lg custom-h-scrollbar">
        {OverallImgCategories.map((each, index) => (
          <div key={index} className="flex-shrink-0">
            <button
              key={index}
              as="div"
              onClick={() => setSelectedCategory(each.name)}
              className={`flex-shrink-0 p-2 border rounded-lg cursor-pointer ${
                selectedCategory === each.name
                  ? "bg-triklDarkAccentBlue/50 border-triklAccentBlue font-semibold"
                  : "bg-white text-triklBlueDark"
              } transition-all duration-300 ease-in-out
          `}
            >
              <img
                src={each.img}
                className="object-cover rounded-lg w-28 h-28"
                alt={each.name}
              />
              <div className="pt-1">{each.name}</div>
            </button>
          </div>
        ))}
      </section>
    </>
  );
};

// FINAL IMAGE SELECTION
const FinalImgSelection = ({ selectedCategory }) => {
  const { finalizedImgUniqName, setFinalizedImgUniqName } =
    useFinalImgCreationUniqNameStore();
  return (
    <section className="flex items-center gap-2 overflow-auto text-sm rounded-lg custom-h-scrollbar">
      {selectedCategory &&
        AllImageCategories[selectedCategory].map((each, index) => (
          <button
            as="div"
            onClick={() => setFinalizedImgUniqName(each.uniqueName)}
            className={`flex-shrink-0 p-2 border rounded-lg ${
              finalizedImgUniqName === each.uniqueName
                ? "bg-triklDarkAccentBlue/50 border-triklAccentBlue font-semibold"
                : "bg-white text-triklBlueDark"
            } transition-all duration-300 ease-in-out
      `}
            key={index}
          >
            <img
              src={each.sampleImg}
              className="object-cover rounded-lg w-28 h-28"
              alt={each.displayName}
            />
            <div className="pt-1">{each.displayName}</div>
          </button>
        ))}
    </section>
  );
};

const CarouselCustomization = () => {
  const { carouselStates, setCarouselStates } = useCarouselSettingsStore();

  const backgroundImages = [
    carouselImg2,
    carouselImg1,
    carouselImg3,
    carouselImg4,
    carouselImg5,
    carouselImg6,
    carouselImg7,
    carouselImg8,
    carouselImg9,
    carouselImg10,
    carouselImg11,
  ];
  return (
    <div className="w-full ">
      <h1 className="text-lg font-semibold text-left">
        Customize Your Carousel:
      </h1>

      {/* Settings for carousel: */}
      <main className="flex flex-col items-start justify-start w-full gap-4 py-10 text-sm ">
        {/* Color Gradient Settings: */}
        {/* <section className="flex my-4"> */}
        <div className="flex items-center w-1/2">
          {/* color -1 */}
          <p className="mr-2">Gradient Color 1:</p>
          <input
            type="color"
            value={carouselStates.gradient.color1}
            onChange={(e) =>
              setCarouselStates({
                gradient: {
                  ...carouselStates.gradient,
                  color1: e.target.value,
                },
              })
            }
          />
        </div>

        {/* color -2  */}
        <div className="flex items-center w-1/2">
          <p className="mr-2">Gradient Color 2:</p>
          <input
            type="color"
            value={carouselStates.gradient.color2}
            onChange={(e) =>
              setCarouselStates({
                gradient: {
                  ...carouselStates.gradient,
                  color2: e.target.value,
                },
              })
            }
          />
        </div>
        {/* </section> */}

        {/* ------ Angle  and background section*/}

        {/* <section className="flex "> */}
        <div className="flex items-center">
          <label className="mr-2">Gradient Angle:</label>
          <input
            type="range"
            className="in-range:bg-triklAccentBlue "
            min="0"
            max="360"
            value={carouselStates.gradient.angle}
            onChange={(e) => {
              setCarouselStates({
                gradient: {
                  ...carouselStates.gradient,
                  angle: e.target.value,
                },
              });
            }}
          />
          {/* Angle */}
          <div className="p-0.5 border ml-0.5">
            {carouselStates?.gradient?.angle}°
          </div>
        </div>

        {/* Background design */}
        <div className="flex gap-2">
          <label className="flex items-center mr-2 ">
            Click to change background:
          </label>
          <img
            // src={backgroundImages[carouselStates.backgroundIndex]}
            src={backgroundDesign}
            alt="Background"
            className="h-6 cursor-pointer "
            onClick={() => {
              setCarouselStates({
                ...carouselStates.backgroundIndex,
                backgroundIndex:
                  (carouselStates.backgroundIndex + 1) %
                  backgroundImages.length,
              });
            }}
          />
        </div>
        {/* </section> */}

        {/* action Buttons  */}
        <div className="flex items-center justify-center w-full gap-2 mt-8 ">
          <Button
            size="large"
            loading={carouselStates?.isCreatingPdf}
            className="w-1/2 font-semibold border text-triklAccentBlue border-triklAccentBlue"
            type="default"
            onClick={() => {
              setCarouselStates({
                ...carouselStates,
                isCreatingPdf: true,
              });
            }}
          >
            Download PDF
          </Button>
          {/* <Button
            loading={carouselStates?.isUploadingPdf}
            size="large"
            className="w-1/2 font-semibold "
            type="primary"
            onClick={() => {
              setCarouselStates({
                ...carouselStates,
                isUploadingPdf: true,
              });
            }}
          >
            Save Carousel
          </Button> */}
        </div>
      </main>
    </div>
  );
};

// // Show Unsplash Images
// const ShowUnsplashImages = ({ unsplashImages }) => {
//   const { finalContent, setFinalContent } = useFinalContentStore();
//   return (
//     <div className="relative flex flex-wrap items-start justify-start w-full gap-2 my-2">
//       {unsplashImages.map((eachImageUrl, index) => (
//         <div key={index} className="relative flex gap-2">
//           <div className="relative group">
//             <img
//               className="object-cover w-[90px] h-[90px] rounded-lg"
//               src={eachImageUrl}
//               alt={`image-${index}`}
//               onClick={() => {
//                 setFinalContent({
//                   finalMediaUrl: eachImageUrl,
//                   finalMediaType: "image",
//                 });
//               }}
//             />
//             {/* Overlay div */}
//             <div
//               className={`absolute inset-0 rounded-lg ${
//                 finalContent?.finalMediaUrl === eachImageUrl
//                   ? "bg-triklAccentBlue/50"
//                   : "hidden"
//               }`}
//             ></div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

////////////////////////////////////////

export { ImgPromptBox };
