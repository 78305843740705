import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Wysiwyg.css";
import { useFinalContentStore } from "../../../linkedIn/ZustandStores/LinkedInZustandStore";
import TextContentOptimiser from "../../../components/widgets/TextContentOptimiser";
import { fleschKincaid } from "flesch-kincaid";
import { Button } from "antd";
import TextSuggestionPopup from "../../../components/widgets/TextSuggestionPopup";
import rs from "text-readability";
import writeGood from "write-good";
import axios from "axios";
import TextAnalyserDrawer from "./TextAnalyserDrawer";
// import nSpell from "nspell";
// import dictionary from "dictionary-en";

const Wysiwyg = () => {
  const { finalContent, setFinalContent } = useFinalContentStore();
  const [selectedText, setSelectedText] = useState(null);
  const [updateText, setUpdateText] = useState(false);
  const [aiOptimisedText, setAiOptimisedText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [range, setRange] = useState({});
  const [replaceHighlightedText, setReplaceHighlightedText] = useState(false);
  let suggestions;
  // Add state for popup visibility and content
  const [popUpInfo, setPopUpInfo] = useState({
    visible: false,
    text: "",
    type: "",
    position: {},
    highlightColor: "",
    tbReplaceText: "",
  });

  const [scores, setScores] = useState({
    readability: "0",
    Sentences: 0,
    Suggestions: suggestions,
  });

  // const [settings, setSettings] = useState({
  //   highlightHardSentences: true,
  //   highlightVeryHardSentences: true,
  //   highlightAdverbs: true,
  //   highlightPassiveVoice: true,
  //   highlightSimplerWords: true,
  // });

  const quillRef = useRef(null);
  const reactQuillRef = useRef(null);
  const quillScrollContainerRef = useRef(null);
  const modules = {
    toolbar: [], // Ensure no toolbar options that apply formatting
  };

  // const formats = []; // Do not allow any formats to be used
  const formats = ["background"]; // Enable background format for highlighting

  const handleChange = (value) => {
    if (value === "<p><br></p>") {
      setFinalContent({
        ...finalContent,
        finalText: "",
      });
    } else {
      setFinalContent({
        ...finalContent,
        finalText: value,
      });
    }
  };

  const styleCombinations = {
    "": {
      bold: "bold",
      italic: "italic",
      underline: "underline",
    },
    bold: {
      italic: "bold-italic",
      underline: "bold-underline",
      "italic-underline": "bold-italic-underline",
    },
    italic: {
      bold: "bold-italic",
      underline: "italic-underline",
      "bold-underline": "bold-italic-underline",
    },
    underline: {
      bold: "bold-underline",
      italic: "italic-underline",
      "bold-italic": "bold-italic-underline",
    },
    "bold-italic": {
      underline: "bold-italic-underline",
    },
    "italic-underline": {
      bold: "bold-italic-underline",
    },
    "bold-underline": {
      italic: "bold-italic-underline",
    },
  };

  const reverseUnicodeMapping = {};
  for (const style in unicodeMapping) {
    for (const char in unicodeMapping[style]) {
      reverseUnicodeMapping[unicodeMapping[style][char]] = style;
    }
  }

  const detectCurrentStyle = (char) => {
    return reverseUnicodeMapping[char] || "";
  };

  const applyStyles = (styles) => {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection(true);
    if (range && range.length > 0) {
      const text = quill.getText(range.index, range.length);
      let styledText = text
        .split("")
        .map((char) => {
          const currentStyle = detectCurrentStyle(char);
          const newStyle = styles.reduce((acc, style) => {
            return styleCombinations[acc][style] || acc;
          }, currentStyle);
          return unicodeMapping[newStyle][char] || char;
        })
        .join("");
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, styledText);
    }
  };

  const handleKeyDown = (event) => {
    let styles = [];
    if (event.ctrlKey || event.metaKey) {
      if (event.key.toLowerCase() === "b") styles.push("bold");
      if (event.key.toLowerCase() === "i") styles.push("italic");
      if (event.key.toLowerCase() === "u") styles.push("underline");

      if (styles.length > 0) {
        event.preventDefault();
        applyStyles(styles);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [unicodeMapping]);

  const analyzeText = () => {
    const editor = reactQuillRef.current.getEditor();
    const text = editor.getText();

    const readabilityScore = rs.fleschReadingEase(text);
    const numberSentence = rs.sentenceCount(text);

    suggestions = writeGood(text);

    setScores({
      readability: readabilityScore,
      Sentences: numberSentence,
      Suggestions: suggestions,
    });

    // suggestions.forEach((suggestion) => {
    //   const start = suggestion.index;
    //   const end = start + suggestion.offset;

    //   // Apply new highlight
    //   editor.formatText(start, end, { background: "#FECACA" });
    // });

    setIsDrawerOpen(true);
  };

  const checkTextSelection = () => {
    const selectedText = getSelectedText();
    if (selectedText && selectedText.length > 0) {
      setSelectedText(selectedText);
    } else {
      setSelectedText("");
    }
  };

  // const replaceText = () => {
  //   const quill = reactQuillRef.current.getEditor();
  //   const range = quill.getSelection();
  //   if (range && range.length > 0) {
  //     quill.deleteText(range.index, range.length);
  //     quill.insertText(range.index, popUpInfo.tbReplaceText);
  //   }
  // };

  // // v2 of replacing highlighted text
  // const replaceHighlightedTextContent = () => {
  //   const quill = reactQuillRef.current.getEditor();
  //   const range = quill.getSelection();

  //   if (!range) {
  //     console.error("Cursor is not active.");
  //     return;
  //   }

  //   const text = quill.getText();
  //   const delta = quill.getContents();
  //   let start = range.index;
  //   let end = range.index;

  //   // Find the start and end of the highlighted text around the cursor
  //   while (start > 0) {
  //     const format = quill.getFormat(start - 1, 1);
  //     if (!format.background) break;
  //     start--;
  //   }
  //   while (end < text.length) {
  //     const format = quill.getFormat(end, 1);
  //     if (!format.background) break;
  //     end++;
  //   }

  //   const highlightedText = text.slice(start, end);

  //   // Check if the highlighted text matches the popup text
  //   if (highlightedText === popUpInfo.text) {
  //     quill.deleteText(start, end - start);
  //     quill.insertText(start, popUpInfo.tbReplaceText);
  //   } else {
  //     console.error("Highlighted text does not match the popup text.");
  //   }
  // };

  const getSelectedText = () => {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection();
    if (range && range.length > 0) {
      setRange({ index: range.index, length: range.length });
      return quill.getText(range.index, range.length);
    }
    return null;
  };

  const replaceSelectedText = async (newText, range) => {
    const quill = reactQuillRef.current.getEditor();

    if (range) {
      await quill.deleteText(range.index, range.length);
      await quill.insertText(range.index, newText);
    }
  };

  // const handleClick = (event) => {
  //   const node = event.target;
  //   const highlightType = node.style.backgroundColor; // This example uses background color to determine type
  //   let type = "";

  //   switch (highlightType) {
  //     case "rgb(255, 239, 138)": // Hard Sentence
  //       type = "Hard Sentence";
  //       break;
  //     case "rgb(254, 202, 202)": // Very Hard Sentence
  //       type = "Very Hard Sentence";
  //       break;
  //     case "rgb(191, 239, 254)": // Adverb
  //       type = "Adverb";
  //       break;

  //     case "rgb(187, 247, 208)": // Passive Voice
  //       type = "Passive Voice";
  //       break;
  //     case "rgb(233, 213, 255)": // Simpler Word
  //       type = "Simpler Word";
  //       break;
  //   }
  //   if (type) {
  //     setPopUpInfo({
  //       tbReplaceText: "",
  //       visible: true,
  //       text: node.textContent,
  //       type: type,
  //       position: {
  //         x: event.clientX, // Capture the x coordinate of the click
  //         y: event.clientY + 3, // Capture the y coordinate of the click and add 3px
  //       },
  //       highlightColor: highlightType,
  //     });
  //   } else {
  //     setPopUpInfo({ ...popUpInfo, visible: false });
  //   }
  // };

  // ****************************************** Text analysis Drawer ****************************************** //
  // close the profile drawer

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (quillRef.current) {
      // setIsQuillMounted(true);
      quillRef.current.addEventListener("mouseup", checkTextSelection);
      quillRef.current.addEventListener("keyup", checkTextSelection);

      const quill = reactQuillRef.current.getEditor();
      quillScrollContainerRef.current = quill.scrollingContainer;
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.removeEventListener("mouseup", checkTextSelection);
        quillRef.current.removeEventListener("keyup", checkTextSelection);
      }
    };
  }, []);

  useEffect(() => {
    const quill = reactQuillRef.current.getEditor();
    quill.on("text-change", (delta, oldDelta, source) => {
      if (source === "user") {
        const currentScrollPosition = quillScrollContainerRef.current.scrollTop;
        setTimeout(() => {
          quillScrollContainerRef.current.scrollTop = currentScrollPosition;
        }, 0);
      }
    });

    return () => {
      quill.off("text-change");
    };
  }, []);

  useEffect(() => {
    if (updateText) {
      replaceSelectedText(aiOptimisedText, range);
      setAiOptimisedText("");
      setSelectedText("");
      setUpdateText(false);
    }
  }, [updateText]);

  // useEffect(() => {
  //   const quillContainer = reactQuillRef.current.getEditor().container;
  //   quillContainer.addEventListener("click", handleClick);
  //   return () => {
  //     quillContainer.removeEventListener("click", handleClick);
  //   };
  // }, []);

  // //
  // useEffect(() => {
  //   if (replaceHighlightedText) {
  //     replaceHighlightedTextContent(); // Updated function to replace the highlighted text
  //     setReplaceHighlightedText(false); // Reset the flag
  //   }
  // }, [replaceHighlightedText]);

  return (
    <>
      <TextSuggestionPopup
        popUpInfo={popUpInfo}
        setPopUpInfo={setPopUpInfo}
        setReplaceHighlightedText={setReplaceHighlightedText}
      />
      <TextContentOptimiser
        selectedText={selectedText}
        aiOptimisedText={aiOptimisedText}
        setAiOptimisedText={setAiOptimisedText}
        setUpdateText={setUpdateText}
      />
      {/* <TextAnalyserDrawer
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        scores={scores}
      /> */}
      <div className="rounded-md" ref={quillRef}>
        <ReactQuill
          ref={reactQuillRef}
          className="flex flex-col-reverse h-full text-xl border-none rounded-md w-[85vw] md:w-full bg-white/60 hover:bg-white active:bg-white text-triklBlueDark my-quill"
          value={finalContent.finalText}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          placeholder="Post something to get your community talking!"
        />
      </div>
      {/* Section for analyzing the text */}
      {/* {!(finalContent.finalText === "" || !finalContent.finalText) && (
        <>
          <Button
            size="large"
            type="primary"
            className="mt-4"
            hidden={finalContent === "" || !finalContent}
            onClick={analyzeText}
          >
            Analyze
          </Button>
        </>
      )} */}
    </>
  );
};

export default Wysiwyg;

const unicodeMapping = {
  bold: {
    a: "𝗮",
    b: "𝗯",
    c: "𝗰",
    d: "𝗱",
    e: "𝗲",
    f: "𝗳",
    g: "𝗴",
    h: "𝗵",
    i: "𝗶",
    j: "𝗷",
    k: "𝗸",
    l: "𝗹",
    m: "𝗺",
    n: "𝗻",
    o: "𝗼",
    p: "𝗽",
    q: "𝗾",
    r: "𝗿",
    s: "𝘀",
    t: "𝘁",
    u: "𝘂",
    v: "𝘃",
    w: "𝘄",
    x: "𝘅",
    y: "𝘆",
    z: "𝘇",
    A: "𝗔",
    B: "𝗕",
    C: "𝗖",
    D: "𝗗",
    E: "𝗘",
    F: "𝗙",
    G: "𝗚",
    H: "𝗛",
    I: "𝗜",
    J: "𝗝",
    K: "𝗞",
    L: "𝗟",
    M: "𝗠",
    N: "𝗡",
    O: "𝗢",
    P: "𝗣",
    Q: "𝗤",
    R: "𝗥",
    S: "𝗦",
    T: "𝗧",
    U: "𝗨",
    V: "𝗩",
    W: "𝗪",
    X: "𝗫",
    Y: "𝗬",
    Z: "𝗭",
  },
  italic: {
    a: "𝘢",
    b: "𝘣",
    c: "𝘤",
    d: "𝘥",
    e: "𝘦",
    f: "𝘧",
    g: "𝘨",
    h: "𝘩",
    i: "𝘪",
    j: "𝘫",
    k: "𝘬",
    l: "𝘭",
    m: "𝘮",
    n: "𝘯",
    o: "𝘰",
    p: "𝘱",
    q: "𝘲",
    r: "𝘳",
    s: "𝘴",
    t: "𝘵",
    u: "𝘶",
    v: "𝘷",
    w: "𝘸",
    x: "𝘹",
    y: "𝘺",
    z: "𝘻",
    A: "𝘈",
    B: "𝘉",
    C: "𝘊",
    D: "𝘋",
    E: "𝘌",
    F: "𝘍",
    G: "𝘎",
    H: "𝘏",
    I: "𝘐",
    J: "𝘑",
    K: "𝘒",
    L: "𝘓",
    M: "𝘔",
    N: "𝘕",
    O: "𝘖",
    P: "𝘗",
    Q: "𝘘",
    R: "𝘙",
    S: "𝘚",
    T: "𝘛",
    U: "𝘜",
    V: "𝘝",
    W: "𝘞",
    X: "𝘟",
    Y: "𝘠",
    Z: "𝘡",
  },
  underline: {
    a: "a̲",
    b: "b̲",
    c: "c̲",
    d: "d̲",
    e: "e̲",
    f: "f̲",
    g: "g̲",
    h: "h̲",
    i: "i̲",
    j: "j̲",
    k: "k̲",
    l: "l̲",
    m: "m̲",
    n: "n̲",
    o: "o̲",
    p: "p̲",
    q: "q̲",
    r: "r̲",
    s: "s̲",
    t: "t̲",
    u: "u̲",
    v: "v̲",
    w: "w̲",
    x: "x̲",
    y: "y̲",
    z: "z̲",
    A: "A̲",
    B: "B̲",
    C: "C̲",
    D: "D̲",
    E: "E̲",
    F: "F̲",
    G: "G̲",
    H: "H̲",
    I: "I̲",
    J: "J̲",
    K: "K̲",
    L: "L̲",
    M: "M̲",
    N: "N̲",
    O: "O̲",
    P: "P̲",
    Q: "Q̲",
    R: "R̲",
    S: "S̲",
    T: "T̲",
    U: "U̲",
    V: "V̲",
    W: "W̲",
    X: "X̲",
    Y: "Y̲",
    Z: "Z̲",
  },
  "bold-italic": {
    a: "𝙖",
    b: "𝙗",
    c: "𝙘",
    d: "𝙙",
    e: "𝙚",
    f: "𝙛",
    g: "𝙜",
    h: "𝙝",
    i: "𝙞",
    j: "𝙟",
    k: "𝙠",
    l: "𝙡",
    m: "𝙢",
    n: "𝙣",
    o: "𝙤",
    p: "𝙥",
    q: "𝙦",
    r: "𝙧",
    s: "𝙨",
    t: "𝙩",
    u: "𝙪",
    v: "𝙫",
    w: "𝙬",
    x: "𝙭",
    y: "𝙮",
    z: "𝙯",
    A: "𝘼",
    B: "𝘽",
    C: "𝘾",
    D: "𝘿",
    E: "𝙀",
    F: "𝙁",
    G: "𝙂",
    H: "𝙃",
    I: "𝙄",
    J: "𝙅",
    K: "𝙆",
    L: "𝙇",
    M: "𝙈",
    N: "𝙉",
    O: "𝙊",
    P: "𝙋",
    Q: "𝙌",
    R: "𝙍",
    S: "𝙎",
    T: "𝙏",
    U: "𝙐",
    V: "𝙑",
    W: "𝙒",
    X: "𝙓",
    Y: "𝙔",
    Z: "𝙕",
  },
  "bold-italic-underline": {
    a: "𝙖̲",
    b: "𝙗̲",
    c: "𝙘̲",
    d: "𝙙̲",
    e: "𝙚̲",
    f: "𝙛̲",
    g: "𝙜̲",
    h: "𝙝̲",
    i: "𝙞̲",
    j: "𝙟̲",
    k: "𝙠̲",
    l: "𝙡̲",
    m: "𝙢̲",
    n: "𝙣̲",
    o: "𝙤̲",
    p: "𝙥̲",
    q: "𝙦̲",
    r: "𝙧̲",
    s: "𝙨̲",
    t: "𝙩̲",
    u: "𝙪̲",
    v: "𝙫̲",
    w: "𝙬̲",
    x: "𝙭̲",
    y: "𝙮̲",
    z: "𝙯̲",
    A: "𝘼̲",
    B: "𝘽̲",
    C: "𝘾̲",
    D: "𝘿̲",
    E: "𝙀̲",
    F: "𝙁̲",
    G: "𝙂̲",
    H: "𝙃̲",
    I: "𝙄̲",
    J: "𝙅̲",
    K: "𝙆̲",
    L: "𝙇̲",
    M: "𝙈̲",
    N: "𝙉̲",
    O: "𝙊̲",
    P: "𝙋̲",
    Q: "𝙌̲",
    R: "𝙍̲",
    S: "𝙎̲",
    T: "𝙏̲",
    U: "𝙐̲",
    V: "𝙑̲",
    W: "𝙒̲",
    X: "𝙓̲",
    Y: "𝙔̲",
    Z: "𝙕̲",
  },
  "bold-underline": {
    a: "𝗮̲",
    b: "𝗯̲",
    c: "𝗰̲",
    d: "𝗱̲",
    e: "𝗲̲",
    f: "𝗳̲",
    g: "𝗴̲",
    h: "𝗵̲",
    i: "𝗶̲",
    j: "𝗷̲",
    k: "𝗸̲",
    l: "𝗹̲",
    m: "𝗺̲",
    n: "𝗻̲",
    o: "𝗼̲",
    p: "𝗽̲",
    q: "𝗾̲",
    r: "𝗿̲",
    s: "𝘀̲",
    t: "𝘁̲",
    u: "𝘂̲",
    v: "𝘃̲",
    w: "𝘄̲",
    x: "𝘅̲",
    y: "𝘆̲",
    z: "𝘇̲",
    A: "𝗔̲",
    B: "𝗕̲",
    C: "𝗖̲",
    D: "𝗗̲",
    E: "𝗘̲",
    F: "𝗙̲",
    G: "𝗚̲",
    H: "𝗛̲",
    I: "𝗜̲",
    J: "𝗝̲",
    K: "𝗞̲",
    L: "𝗟̲",
    M: "𝗠̲",
    N: "𝗡̲",
    O: "𝗢̲",
    P: "𝗣̲",
    Q: "𝗤̲",
    R: "𝗥̲",
    S: "𝗦̲",
    T: "𝗧̲",
    U: "𝗨̲",
    V: "𝗩̲",
    W: "𝗪̲",
    X: "𝗫̲",
    Y: "𝗬̲",
    Z: "𝗭̲",
  },
  "italic-underline": {
    a: "𝘢̲",
    b: "𝘣̲",
    c: "𝘤̲",
    d: "𝘥̲",
    e: "𝘦̲",
    f: "𝘧̲",
    g: "𝘨̲",
    h: "𝘩̲",
    i: "𝘪̲",
    j: "𝘫̲",
    k: "𝘬̲",
    l: "𝘭̲",
    m: "𝘮̲",
    n: "𝘯̲",
    o: "𝘰̲",
    p: "𝘱̲",
    q: "𝘲̲",
    r: "𝘳̲",
    s: "𝘴̲",
    t: "𝘵̲",
    u: "𝘶̲",
    v: "𝘷̲",
    w: "𝘸̲",
    x: "𝘹̲",
    y: "𝘺̲",
    z: "𝘻̲",
    A: "𝘈̲",
    B: "𝘉̲",
    C: "𝘊̲",
    D: "𝘋̲",
    E: "𝘌̲",
    F: "𝘍̲",
    G: "𝘎̲",
    H: "𝘏̲",
    I: "𝘐̲",
    J: "𝘑̲",
    K: "𝘒̲",
    L: "𝘓̲",
    M: "𝘔̲",
    N: "𝘕̲",
    O: "𝘖̲",
    P: "𝘗̲",
    Q: "𝘘̲",
    R: "𝘙̲",
    S: "𝘚̲",
    T: "𝘛̲",
    U: "𝘜̲",
    V: "𝘝̲",
    W: "𝘞̲",
    X: "𝘟̲",
    Y: "𝘠̲",
    Z: "𝘡̲",
  },
};
