import React from "react";

const BackgroundTemplate6 = ({ colorPallet }) => {
  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 535 665"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <mask
        id="mask0_2563_1510"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="535"
        height="665"
      >
        <rect width="535" height="665" fill="#D9D9D9" />
      </mask> */}
      <g mask="url(#mask0_2563_1510)">
        <rect
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          y="200"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          y="400"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          y="600"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="100"
          y="100"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="100"
          y="500"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="100"
          y="300"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="200"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="200"
          y="200"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="200"
          y="400"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="200"
          y="600"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="300"
          y="100"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="300"
          y="500"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="300"
          y="300"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="400"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="400"
          y="200"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="400"
          y="400"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="400"
          y="600"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="500"
          y="100"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="500"
          y="500"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="500"
          y="300"
          width="100"
          height="100"
          fill={colorPallet?.secondary}
          fill-opacity="0.07"
        />
        <rect
          x="301"
          y="101"
          width="98"
          height="98"
          // stroke="#2D6808"
          stroke={colorPallet?.primary}
          stroke-width="2"
        />
        <rect
          x="401"
          y="601"
          width="98"
          height="98"
          // stroke="#2D6808"
          stroke={colorPallet?.primary}
          stroke-width="2"
        />
        <rect
          x="1"
          y="1"
          width="98"
          height="98"
          // stroke="#2D6808"
          stroke={colorPallet?.primary}
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default BackgroundTemplate6;
